import React, { useState, useEffect } from 'react'
import SwitchDashboardButton from "../../../detectdashboard/component/SwitchDashboardButton"
import TopBarTitle from '../../../detectdashboard/component/TopBarTitle'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { usePost } from 'hook/usePost'
import { useGet } from 'hook/useGet'
import { API, urlRoot } from 'constant/index'
import TimeSelector from './TimeSelector'
import { IDateRange } from 'interface'
import { processNumber } from 'util/index'
import DarkModeBtn from 'component/DarkModeBtn'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import { useAlert } from 'hook/useAlert'
import './TopBar.scss'

export interface TopBarProps {
    dateModuleData: IDateRange,
    setDateModuleData: React.Dispatch<React.SetStateAction<IDateRange>>,
}

const TopBar = (props: TopBarProps) => {
    const { dateModuleData, setDateModuleData } = props;
    const language = useSelector(_language);
    const postMutation = usePost<number>();
    const [processCount, setProcessCount] = useState<number>(0);
    const [totalAgent, setTotalAgent] = useState<number>(0);
    const timeSelectRange = ['24', '48', '72', '0', ''];
    const { showAlert } = useAlert();

    const {
        data: agentCount,
        isSuccess: isAgentCountSuccess,
    } = useGet<number>({
        query: 'AgentCount',
        root: `${urlRoot}`,
        route: `${API.agentCount}`,
    })

    useEffect(() => {
        if (isAgentCountSuccess && agentCount.data) {
            setTotalAgent(agentCount.data)
        }
    }, [isAgentCountSuccess, agentCount])

    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.highRiskProcess}/count`,
            body: {
                cache: 0, // 分析儀表板沒有用 cache 了
                from: Math.round(dateModuleData.startTime / 1000),
                to: Math.round(dateModuleData.endTime / 1000),
            },
        }, {
            onSuccess: (res) => {
                setProcessCount(res.data);
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message);
                } else {
                    console.log(error);
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [dateModuleData])

    return (
        <div className='topBarContainer'>
            <SwitchDashboardButton />
            <div className='topBarTitleContainer'>
                <BlurBox
                    text={dic[language]["dashboard_top_bar_agent_count"]}
                    boldNumber={totalAgent}
                    position="left"
                />
                <TopBarTitle title={dic[language]["analysis_dashboard_title"]} />
                <BlurBox
                    text={dic[language]["dashboard_top_bar_level3_process_count"]}
                    boldNumber={processCount}
                    position="right"
                />
            </div>
            <TimeSelector
                defaultValues={[
                    { name: dic[language]["24_hours"], value: 24 },
                    { name: dic[language]["48_hours"], value: 48 },
                    { name: dic[language]["72_hours"], value: 72 },
                    { name: dic[language]["customized"], value: 0 },
                ]}
                dateRange={dateModuleData}
                setDateRange={setDateModuleData}
                selectRange={timeSelectRange}
                labelValue={dic[language]["dashboard_top_bar_time"]}
            />
            <DarkModeBtn />
        </div>
    )
}

export default TopBar

interface BlurBoxProps {
    text: string,
    boldNumber: number,
    position: string,
}

const BlurBox = (props: BlurBoxProps) => {
    return (
        <Tooltip
            followCursor
            title={props.boldNumber}
        >
            <Typography
                variant="body1"
                className='blurBox'
                style={{
                    margin: props.position === "left" ? "0px 0px 0px 186px" : "0px 50px 0px 0px",
                }}
            >
                {props.text}
                <Typography
                    variant="h5"
                    component="span"
                    className='topBarValue'
                >
                    {processNumber(props.boldNumber)}
                </Typography>
            </Typography>
        </Tooltip>
    )
}
