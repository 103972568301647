import { useSelector } from 'react-redux';
import { Data } from './TableBox';
import { _language } from 'redux/UtilSlice';
import { useState } from 'react';
import dic from 'constant/dictionary';
import { API, urlRoot } from 'constant/index';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, FormControl } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { usePost } from 'hook/usePost';
import { useToast } from 'hook/useToast';

interface IFormDialog {
    titleText: string // 標題文字
    confirmText: string // 確認按鈕文字
    rows: Data[] // 外部 table 的資料
    inputLableList: string[] // 輸入框的標籤名稱列表
    refetchImageTable: () => void

    row?: Data //tablerow 點擊編輯時的 row data
}

const FormDialog = (props: IFormDialog) => {
    const {
        titleText,
        confirmText,
        inputLableList,
        row,
        rows,
        refetchImageTable,
    } = props
    const language = useSelector(_language)
    const [open, setOpen] = useState(false)
    const [inputString, setInputString] = useState(row ? [row.path, row.keyword, row.os] : ['', '', ''])
    const [os, setOs] = useState(row ? row.os : '');
    const [isLegal, setIsLegal] = useState(true)
    const [isInitData, setIsInitData] = useState(true)
    const addMutation = usePost();
    const updateMutation = usePost();
    const toast = useToast();

    const memoryInputString = (input: string, index: number) => {
        // 比較 input 在 tablelist 中是否已經存在
        const checkInputDataIsLegal = (tableList: Data[], input: string[]) => {
            let isLegal = true;
            tableList.forEach((item: Data) => {
                if (item.path === input[0] && item.keyword === input[1] && item.os === os) {
                    isLegal = false;
                }
            })
            return isLegal
        }

        const checkInputDataIsInit = (initData: Data, input: string[]) => {
            let isInit = true;
            if (initData.path !== input[0] || initData.keyword !== input[1] || initData.os !== os) {
                isInit = false
            }
            return isInit
        }

        let newInputString = [...inputString]
        newInputString[index] = input
        setIsLegal(checkInputDataIsLegal(rows, newInputString))
        if (row) {
            setIsInitData(checkInputDataIsInit(row, newInputString))
        }
        setInputString(newInputString)
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (event: SelectChangeEvent) => {
        setOs(event.target.value as string);
    };

    const handleUpdate = () => {
        updateMutation.mutate({
            method: 'put',
            root: `${urlRoot}`,
            route: `${API.keyImage}`,
            body: {
                id: row?.id,
                type: 'customized',
                path: inputString[0],
                keyword: inputString[1],
                os: os,
            }
        }, {
            onSuccess: () => {
                toast.show({content: dic[language]['update_success']});
                refetchImageTable();
                setOpen(false);
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    toast.show({content: dic[language]['update_fail'] || error.response.data.message, type: 'error'});
                } else {
                    console.log(error.response?.data.message)
                }
            }
        })
    }

    const handleAdd = () => {
        addMutation.mutate({
            method: 'post',
            root: `${urlRoot}`,
            route: `${API.keyImage}`,
            body: {
                type: 'customized',
                path: inputString[0],
                keyword: inputString[1],
                os: os,
            }
        }, {
            onSuccess: () => {
                toast.show({content: dic[language]['add_success']});
                refetchImageTable();
                setInputString(['', '']);
                setOs('');
                setOpen(false);
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    toast.show({content: dic[language]['add_fail'] || error.response.data.message});
                } else {
                    console.log(error.response?.data.message)
                }
            }
        })
    }

    return (
        <>
            {titleText !== dic[language]['add'] && (
                <IconButton
                    className="actionButton"
                    onClick={handleClickOpen}
                >
                    <EditIcon />
                </IconButton>
            )}

            {titleText === dic[language]['add'] && (
                <Button variant="contained" onClick={handleClickOpen}>
                    {dic[language]['add']}
                </Button>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                    sx={{
                        backgroundColor: titleText === dic[language]['delete'] || titleText === dic[language]['renew']
                            ? '#D32F2F' : '#2196F3',
                        color: 'white',
                        padding: '5px 15px',
                        fontSize: '1rem',
                    }}
                >
                    {titleText + (language === 'zh' ? '' : ' ') + dic[language]['setting_image_tool']}
                </DialogTitle>

                <DialogContent sx={{ width: 350 }}>
                    {inputLableList.map((item: string, index: number) => {
                        return (
                            <TextField
                                key={index}
                                margin="dense"
                                label={item}
                                type='text'
                                fullWidth
                                variant="standard"
                                value={inputString[index]}
                                onChange={(e) =>
                                    memoryInputString(
                                        e.target.value,
                                        index
                                    )
                                }
                            />
                        )
                    })}
                    <FormControl sx={{ minWidth: 120, m: '1rem 0' }} size="small">
                        <InputLabel id="os-select-label">OS</InputLabel>
                        <Select
                            labelId="os-select-label"
                            id="os-select"
                            value={os}
                            label="OS"
                            onChange={handleChange}
                        >
                            <MenuItem value={'windows'}>Windows</MenuItem>
                            <MenuItem value={'linux'}>Linux</MenuItem>
                        </Select>
                    </FormControl>
                    {isInitData ? (
                        <DialogContentText style={{ color: 'gray' }}>
                            {dic[language]['data_not_change']}
                        </DialogContentText>
                    ) : isLegal ? null : (
                        <DialogContentText style={{ color: 'red' }}>
                            {dic[language]['input_repeated']}
                        </DialogContentText>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button
                        disabled={!isLegal && isInitData}
                        onClick={
                            confirmText === dic[language]['submit'] ? handleAdd : handleUpdate
                        }
                    >
                        {confirmText}
                    </Button>
                    <Button
                        onClick={handleClose}
                        sx={{ color: 'gray' }}
                    >
                        {dic[language]['cancel']}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default FormDialog