import IconButton from '@mui/material/IconButton'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import Tooltip from '@mui/material/Tooltip'
import { setDisplayMode, _displayMode } from 'redux/UtilSlice'
import { useSelector, useDispatch } from 'react-redux'

const DarkModeBtn = () => {
    const displayMode = useSelector(_displayMode);
    const dispatch = useDispatch();

    return (
        <IconButton
            className='dark-mode-icon-button'
            onClick={() => dispatch(setDisplayMode(displayMode === 'dark'?'light':'dark'))}
        >
            <Tooltip
                followCursor
                title="Dark/Light Mode"
            >
                {displayMode === 'dark' ?
                    <LightModeIcon className="icon" /> :
                    <DarkModeIcon className="icon" />}
            </Tooltip>
        </IconButton>
    )
}

export default DarkModeBtn
