/** @format */

// 引入所需的套件和組件
import { FormControl, TextField } from '@mui/material'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { HookFormContainer, HookFormFlexBox } from './StyledComponent'
import { ICommonFuncData } from '../../page/setting/subpage/system/Child/Child_CommonFunc'
import { IAgentSettingData } from '../../page/setting/subpage/system/Child/Child_AgentSetting'

// 定義組件所需的屬性接口
interface ICommonTextForm {
    control:
        | Control<FieldValues, any>
        | Control<ICommonFuncData | any>
        | Control<IAgentSettingData | any>
    title: string // 表單標題
    name: string // 表單項目名稱
    defaultType?: 'string' | 'number' | 'boolean'
    inputWidth? : number
    styleOutter? : React.CSSProperties 
    FormControlStyle? : React.CSSProperties 
}

// 表單輸入組件
const InlineInputByHooks = (props: ICommonTextForm) => {
    // 解構取得屬性
    const {
        control,
        title,
        name,
        defaultType,
        inputWidth,
        styleOutter,
        FormControlStyle
    } = props

    // width: 560px;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;


    const defaultTypeValue = defaultType || 'string'

    return (
        <div style={{ ...styleOutter }}>
            <div
                style={{ 
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'
                }}
            >
                <span>{title} :</span>
                <div style={{ width: inputWidth?inputWidth:400 }}>
                    <FormControl style={{ ...FormControlStyle }}>
                        <Controller
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    fullWidth
                                    size="small"
                                    hiddenLabel
                                    {...field}
                                    error={!!error}
                                    type={defaultTypeValue}
                                    helperText={error ? error.message : null}
                                />
                            )}
                            name={name}
                            control={control}
                        />
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default InlineInputByHooks
