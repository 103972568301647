/** @format */

import { useForm } from 'react-hook-form'
import {
    HookFormSubmit,
    HookFormSubmitContainer,
} from '../../../../../component/FormInput/StyledComponent'
import FormInputByHooks from '../../../../../component/FormInput/FormInputByHooks'
import {
    CommonHoriLine,
    CommonTitle,
} from '../../../../../component/CommonStyledComponent'
import CheckInputByHooks from '../../../../../component/FormInput/CheckInputByHooks'
import SelectInputByHooks from '../../../../../component/FormInput/SelectInputByHooks'
import { MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { usePost } from 'hook/usePost'
import { useGet } from 'hook/useGet'
import { API, urlRoot } from 'constant/index'
import FullLoading from '../../../../../component/FullLoading'
import { useToast } from 'hook/useToast'
import { useDispatch } from 'react-redux'
import { setGlobalDirty } from 'redux/SettingSlice'
import '../../../index.scss'
import dic from "constant/dictionary";
import { useSelector } from 'react-redux';
import { _language } from 'redux/UtilSlice';

export interface IAgentSettingData {
    mission: {
        fileAnalysis: boolean
        fileAnalysisMainDisk: string
        memoryScan: boolean
        collection: boolean
        generateImage: boolean
    }
    generateAgent: {
        serverIP: string
        memoryScan: boolean
        collection: boolean
        generateImage: boolean
    }
    cpuPriority: string
}

const defaultValues: IAgentSettingData = {
    mission: {
        fileAnalysis: true,
        fileAnalysisMainDisk: '',
        memoryScan: true,
        collection: true,
        generateImage: true,
    },
    generateAgent: {
        serverIP: '',
        memoryScan: true,
        collection: true,
        generateImage: true,
    },
    cpuPriority: '',
}

const Child_AgentSetting = () => {
    const toast = useToast()
    const language = useSelector(_language)
    const [pageData, setPageData] = useState<IAgentSettingData>(defaultValues)
    const dispatch = useDispatch()

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isSubmitting, isDirty, isValid, isSubmitSuccessful },
    } = useForm<IAgentSettingData>({ defaultValues: pageData })

    const { mutate, isSuccess, isLoading: postLoading } = usePost()
    const { 
        data: defaultData, 
        isLoading: getLoading,
        isSuccess: isGetDefaultSuccess,
    } = useGet({
        query: 'AgentSetting',
        root: `${urlRoot}`,
        route: `${API.agentSetting}`,
        state: { data: isSubmitSuccessful },
    })

    useEffect(() => {
        if (isGetDefaultSuccess && defaultData.data) {
            const formData = defaultData.data as IAgentSettingData
            setPageData(formData)
            reset(formData)
        }
    }, [isGetDefaultSuccess, reset, defaultData])

    const onSubmit = (result: IAgentSettingData) => {
        console.log('onSubmit', result)
        mutate({
            root: `${urlRoot}`,
            route: `${API.agentSetting}`,
            body: result,
        })
    }

    useEffect(() => {
        if (isDirty) {
            dispatch(setGlobalDirty(true))
        }
        return () => {
            dispatch(setGlobalDirty(false))
        }
    }, [isDirty])

    useEffect(() => {
        if (isSuccess) {
            toast.show({content: dic[language]["save_success"]})
        }
    }, [isSuccess])

    return (
        <div className='NavigationBody'>
            <FullLoading open={postLoading || getLoading} />
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: 'flex', flexWrap: 'wrap' }}
            >
                <CommonTitle>
                    {dic[language]["setting_system_agent_prescheduled_task"]}
                </CommonTitle>
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_file_table_analysis"]}
                    name="mission.fileAnalysis"
                    watch={watch('mission.fileAnalysis')}
                />
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_primary_disk"]}
                    name="mission.fileAnalysisMainDisk"
                    label="fileAnalysisMainDisk"
                    formControlWidth={100}
                    marginGap={170}
                >
                    <MenuItem value="Disk/C">C :</MenuItem>
                    <MenuItem value="Disk/D">D :</MenuItem>
                    <MenuItem value="Disk/E">E :</MenuItem>
                </SelectInputByHooks>
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_memory_scan"]}
                    name="mission.memoryScan"
                    watch={watch('mission.memoryScan')}
                />
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_collect"]}
                    name="mission.collection"
                    watch={watch('mission.collection')}
                />
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_image"]}
                    name="mission.generateImage"
                    watch={watch('mission.generateImage')}
                />

                <CommonHoriLine />

                <CommonTitle>
                    {dic[language]["setting_system_agent_generate"]}
                </CommonTitle>
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_server_ip"]}
                    name="generateAgent.serverIP"
                />
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_memory_scan"]}
                    name="generateAgent.memoryScan"
                    watch={watch('generateAgent.memoryScan')}
                />
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_collect"]}
                    name="generateAgent.collection"
                    watch={watch('generateAgent.collection')}
                />
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_image"]}
                    name="generateAgent.generateImage"
                    watch={watch('generateAgent.generateImage')}
                />

                <CommonHoriLine />
                <CommonTitle>
                    {dic[language]["setting_system_agent_other"]}
                </CommonTitle>
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_agent_cpu_priority"]}
                    name="cpuPriority"
                    label="cpuPriority"
                    formControlWidth={170}
                >
                    <MenuItem value="normal">{dic[language]["setting_system_agent_cpu_priority_low"]}</MenuItem>
                    <MenuItem value="veryNormal">{dic[language]["setting_system_agent_cpu_priority_normal"]}</MenuItem>
                </SelectInputByHooks>

                <CommonHoriLine />

                <HookFormSubmitContainer>
                    <HookFormSubmit
                        type="submit"
                        value={dic[language]["submit"]}
                        disabled={!isDirty || !isValid || isSubmitting}
                    />
                </HookFormSubmitContainer>
            </form>
        </div>
    )
}

export default Child_AgentSetting
