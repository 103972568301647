/** @format */
import { useAlert } from 'hook/useAlert'
import { useSelector } from 'react-redux'
import { _isGlobalDirty, setGlobalDirty } from 'redux/SettingSlice'
import { useDispatch } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface HeaderNavigationProps {
    header: string
    setHeader: React.Dispatch<React.SetStateAction<string>>
    HeaderList: string[]
}
// 設定頁面 - 系統子頁面的header
// 負責切換子頁面

const HeaderNavigation = (props: HeaderNavigationProps) => {
    const isGlobalDirty = useSelector(_isGlobalDirty)
    const { header, setHeader, HeaderList } = props
    const alert = useAlert().showAlert
    const dispatch = useDispatch()

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setHeader(newValue)
    }

    const leavePage = (item: string) => {
        setHeader(item)
    }

    return (
        <Tabs
            value={header}
            onChange={handleChange}
            aria-label="Header Navigation"
        >
            {HeaderList.map((item, index) => {
                return (
                    <Tab
                        key={index}
                        label={item}
                        value={item}
                        onClick={
                            // 判斷是否有修改過，有修改過需要確認
                            () => {
                                if (isGlobalDirty) {
                                    alert('是否要離開此頁面?', () => {
                                        leavePage(item)
                                        dispatch(setGlobalDirty(false))
                                    })
                                    setHeader(header)
                                }
                            }
                        }
                        sx={{ fontSize: '1rem', textTransform: 'none' }}
                    />
                )
            })}
        </Tabs>
    )
}

export default HeaderNavigation
