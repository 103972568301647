import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { SelectedBoxes } from 'interface'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'

interface FilterDropProps {
    filter: SelectedBoxes
    setFilter: React.Dispatch<React.SetStateAction<SelectedBoxes>>
}

const FilterDrop = (props: FilterDropProps) => {
    const { filter, setFilter } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const language = useSelector(_language)

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name: keyof SelectedBoxes = event.target
            .name as keyof SelectedBoxes
        const checked = event.target.checked

        setFilter((prev) => {
            const now = { ...prev }

            if (name === 'memory') {
                now.detectionMode = true
                now.scanSchedule = true
                now.scanFinishTime = true
            }

            if (name === 'collect') {
                now.collectSchedule = true
                now.collectFinishTime = true
            }

            if (name === 'file') {
                now.fileDownloadDate = true
                now.fileFinishTime = true
            }

            now[name] = checked

            return now
        })
    }

    return (
        <div>
            <span
                onClick={handleMenuClick}
                style={{ display: 'flex', alignItems: 'flex-end' }}
            >
                <FilterAltIcon
                    style={{
                        color: '#BDBDBD',
                        cursor: 'pointer',
                        marginRight: 10,
                    }}
                    fontSize="large"
                />
            </span>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div>
                    <ParentMenuItem
                        title={dic[language]['detect_task_type_memory']}
                        name="memory"
                        checked={filter.memory}
                        onChange={handleClick}
                    />
                    <ChildMenuItem
                        title={dic[language]['detect_detect_mode']}
                        name="detectionMode"
                        disabled={filter.memory}
                        checked={filter.detectionMode}
                        onChange={handleClick}
                    />
                    <ChildMenuItem
                        title={dic[language]['detect_table_scan_schedule']}
                        name="scanSchedule"
                        disabled={filter.memory}
                        checked={filter.scanSchedule}
                        onChange={handleClick}
                    />
                    <ChildMenuItem
                        title={dic[language]['detect_table_scan_finish_time']}
                        name="scanFinishTime"
                        disabled={filter.memory}
                        checked={filter.scanFinishTime}
                        onChange={handleClick}
                    />
                </div>
                <div>
                    <ParentMenuItem
                        title={dic[language]['detect_task_type_collect']}
                        name="collect"
                        checked={filter.collect}
                        onChange={handleClick}
                    />
                    <ChildMenuItem
                        title={dic[language]['detect_table_collect_schedule']}
                        name="collectSchedule"
                        disabled={filter.collect}
                        checked={filter.collectSchedule}
                        onChange={handleClick}
                    />
                    <ChildMenuItem
                        title={dic[language]['detect_table_collect_finish_time']}
                        name="collectFinishTime"
                        disabled={filter.collect}
                        checked={filter.collectFinishTime}
                        onChange={handleClick}
                    />
                </div>
                <div>
                    <ParentMenuItem
                        title={dic[language]['detect_task_type_drive']}
                        name="file"
                        checked={filter.file}
                        onChange={handleClick}
                    />
                    <ChildMenuItem
                        title={dic[language]['detect_table_drive_schedule']}
                        name="fileDownloadDate"
                        disabled={filter.file}
                        checked={filter.fileDownloadDate}
                        onChange={handleClick}
                    />
                    <ChildMenuItem
                        title={dic[language]['detect_table_drive_finish_time']}
                        name="fileFinishTime"
                        disabled={filter.file}
                        checked={filter.fileFinishTime}
                        onChange={handleClick}
                    />
                </div>
                <div>
                    <ParentMenuItem
                        title={dic[language]['detect_table_image_finish_time']}
                        name="imageFinishTime"
                        checked={filter.imageFinishTime}
                        onChange={handleClick}
                    />
                </div>
                <div>
                    <ParentMenuItem
                        title={dic[language]['detect_table_yara_finish_time']}
                        name="yaraRuleFinishTime"
                        checked={filter.yaraRuleFinishTime}
                        onChange={handleClick}
                    />
                </div>
            </Menu>
        </div>
    )
}

export default FilterDrop

interface ParentMenuItemProps {
    title: string
    name: string
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ParentMenuItem = ({
    title,
    name,
    checked,
    onChange,
}: ParentMenuItemProps) => {
    return (
        <MenuItem>
            <FormControlLabel
                control={
                    <Checkbox
                        name={name}
                        size="small"
                        checked={checked}
                        onChange={onChange}
                    />
                }
                label={title}
            />
        </MenuItem>
    )
}

interface ChildMenuItemProps {
    title: string
    name: string
    checked: boolean
    disabled: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ChildMenuItem = ({
    title,
    name,
    checked,
    disabled,
    onChange,
}: ChildMenuItemProps) => {
    return (
        <MenuItem>
            <span style={{ width: 20 }}></span>
            <FormControlLabel
                control={
                    <Checkbox
                        name={name}
                        size="small"
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                    />
                }
                label={title}
            />
        </MenuItem>
    )
}
