// TODO: move this to common components
import { FormControl, FormLabel, TextField } from "@mui/material"

interface ICommonTextForm {
    value: string
    title: string
    isPWD?: boolean
    required: boolean
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
const TextInput = (props: ICommonTextForm) => {
    const { value, title, isPWD, required, handleChange } = props

    return (
        <FormControl sx={{ width: '100%', margin: '0.5rem 0' }}>
            <FormLabel
                required={required}
                component="legend"
                sx={{ marginBottom: '0.3rem' }}
            >
                {title}
            </FormLabel>
            <TextField
                value={value}
                inputProps={{
                    autoComplete: 'off'
                }}
                fullWidth
                size="small"
                hiddenLabel
                required={required}
                type={isPWD ? 'password' : 'text'}
                onChange={handleChange}
            />
        </FormControl>
    )
}

export default TextInput