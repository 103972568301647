import { FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import GroupHeader from './GroupHeader'
import GroupNav from './GroupNav'
import GroupGrid from './GroupGrid/GroupGrid'
import {
    IGenerateGroup,
    oneHostData,
} from 'interface'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setSelectedId } from 'redux/AnalysisSlice'
import { _displayMode, _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

export interface IGroupCount {
    group: string
    state: boolean
}
export interface IdIdentify {
    group: string
    ids: string[]
}

const GroupDropDown = () => {
    const displayMode = useSelector(_displayMode);
    const analysisData = useSelector(_analysisData);
    const { groups, selectedHostsIDs } = analysisData
    const dispatch = useDispatch();
    const [showingGroup, setShowingGroup] = useState<string>('')
    const [renderData, setRenderData] = useState<oneHostData[]>([]) // each page's data
    const [groupCount, setGroupCount] = useState<IGroupCount[]>([]) // count the group's state
    const language = useSelector(_language);

    useEffect(() => {
        // set the default group
        if (showingGroup === '' && groups.length > 0) {
            if (groups[0]) {
                setShowingGroup(groups[0].group)
            }
        }
    }, [groups])

    useEffect(() => {
        let selectedGroup:IGenerateGroup;
        if (showingGroup === 'all') {
            selectedGroup = {group: "all", devices:[]};
            groups.forEach(group => {
                selectedGroup.devices = selectedGroup?.devices.concat(group.devices);
            })
        }
        else {
            // filter the data by group name
            selectedGroup = groups.find((item) => item.group === showingGroup) || {group:'not found', devices:[]};
        }
        if (selectedGroup) {
            setRenderData(selectedGroup.devices)
        }
    }, [showingGroup])

    const handlePageSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        let tempSelected:string[] = JSON.parse(JSON.stringify(selectedHostsIDs));
        const renderingIds = renderData.map((n) => n.id);
        if (event.target.checked) {
            //add rendering rows to selectedid
            tempSelected = tempSelected.concat(renderingIds)
        } else {
            //remove rendering rows in selectedid
            tempSelected = tempSelected.filter(selected => !renderingIds.includes(selected));
        }
        dispatch(setSelectedId(tempSelected))
    }

    return (
        <div className='optionsContainer'>
            <span>
                {dic[language]['analysis_devices']}
            </span>
            <FormControl
                sx={{
                    m: 1,
                    width: 200,
                    backgroundColor: displayMode === 'dark' ? '' : 'white',
                    height: 'min-content',
                }}
                size="small"
            >
                <Select
                    multiple
                    displayEmpty
                    value={selectedHostsIDs}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        return (
                            <em>
                                {selected.length === 0
                                    ? dic[language]['analysis_please_select_devices']
                                    : dic[language]['analysis_has_selected'] + selected.length + dic[language]['analysis_has_selected_unit']}
                            </em>
                        )
                    }}
                >
                    <div
                        style={{
                            width: 800,
                            height: 700,
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}
                    >
                        <GroupHeader
                            groupCount={groupCount}
                        />
                        <div
                            style={{
                                width: '100%',
                                height: '90%',
                                display: 'flex',
                            }}
                        >
                            <GroupNav
                                groupCount={groupCount}
                                setGroupCount={setGroupCount}
                                selectedHost={showingGroup}
                                setSelectedHost={setShowingGroup}
                            />
                            <GroupGrid
                                data={renderData}
                                handlePageSelectAll={handlePageSelectAll}
                                selectedHost={showingGroup}
                                setSelectedHost={setShowingGroup}
                            />
                        </div>
                    </div>
                </Select>
            </FormControl>
        </div>
    )
}

export default GroupDropDown