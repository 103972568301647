import { useContext } from 'react'
import { AlertContext, IToast } from 'context/AlertContext'
import CloseIcon from '@mui/icons-material/Close';

import { Alert, AlertTitle, Button, IconButton, Slide, SlideProps, Snackbar } from '@mui/material'

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />
}

const Toast = (props: IToast) => {
    const { toasts } = useContext(AlertContext)
    const { content, onDismiss, type, title, closeBtnText } = props
    const alertType = type ? type : 'success'

    return (
        <Snackbar
            open={toasts.length !== 0}
            onClose={onDismiss}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={2000}
            TransitionComponent={SlideTransition}
        >
            <Alert
                severity={alertType}
                sx={{ minWidth: '300px', maxWidth: '500px' }}
                action={
                    closeBtnText?
                        <Button onClick={onDismiss}>
                            {closeBtnText}
                        </Button>
                        :<IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={onDismiss}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                }
            >
                { title && <AlertTitle>{title}</AlertTitle>}
                
                {content}
            </Alert>
        </Snackbar>
    )
}
export default Toast

export const useToast = () => {
    const { toastCount, toasts, setToasts } = useContext(AlertContext)

    const show = (toast: Omit<IToast, 'id'>) => {
        const id = toastCount.current++
        const obj:IToast = { id: id, ...toast }
        setToasts([...toasts, obj])
    }

    return { show }
}