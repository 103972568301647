import {
    Stack,
    Paper,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGridPro, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import dic from 'constant/dictionary';
import SearchBarWithChips from '../../../component/SearchBarWithChips';
import { useSelector } from 'react-redux';
import { _language, _displayMode } from 'redux/UtilSlice';
import MultiSelectDropdown from '../../../component/MultiSelectDropDown';

export interface deviceInfo {
    id: string
    ip: string
    name: string
    macAddress: string
    os: string
    version: string
    group: string[]
    hasPendingTask: boolean
}

interface AgentTableProps {
    defaultDeviceList: deviceInfo[]
    ToolBarButtons: React.ReactNode
    selectedIDs: string[]
    setSelectedIDs: React.Dispatch<React.SetStateAction<string[]>>
    height?: string
    blockSelection?: boolean
}

const AgentTable = (props: AgentTableProps) => {
    const {
        defaultDeviceList,
        ToolBarButtons,
        selectedIDs,
        setSelectedIDs,
        height = 'calc(100% - 3.5rem)',
        blockSelection = false,
    } = props;
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);
    const [deviceList, setDeviceList] = useState<deviceInfo[]>(defaultDeviceList);
    const [searchKeywordList, setSearchKeywordList] = useState<string[]>([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedGroupsInFilter, setSelectedGroupsInFilter] = useState<string[]>([]);

    useEffect(() => {
        if (searchKeywordList.length || selectedGroupsInFilter.length) {
            setDeviceList(defaultDeviceList.filter((device) => {
                return searchKeywordList.every((keyword) => {
                    return Object.values(device).some((value) => {
                        if (typeof value === 'object') {
                            return value.some((innerValue: string) =>
                                innerValue.toLowerCase().includes(keyword.toLowerCase())
                            );
                        }
                        return value.toString().toLowerCase().includes(keyword.toLowerCase());
                    })
                }) && selectedGroupsInFilter.every((group) => {
                    return device.group.includes(group) || (group === dic[language]['no_group'] && device.group.length === 0);
                });
            }));
        } else {
            setDeviceList(defaultDeviceList);
        }
    }, [defaultDeviceList, searchKeywordList, selectedGroupsInFilter])

    useEffect(() => {
        if (selectedIDs.length == 0) {
            setRowSelectionModel([])
        }
    }, [selectedIDs])

    const deviceListColumn: GridColDef[] = [
        { field: 'ip', headerName: dic[language]['setting_formtable_inner_ip'], flex: 1 },
        { field: 'name', headerName: dic[language]['setting_formtable_device_name'], flex: 1 },
        { field: 'macAddress', headerName: 'Mac Address', flex: 1 },
        { field: 'version', headerName: dic[language]['setting_formtable_version'], flex: 1, valueGetter: (params) => params.value.split(',')[0] },
        {
            field: 'group',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            valueGetter: (params) => (params.value.length ? params.value.join(', ') : dic[language]['no_group']),
            renderHeader: () => (
                <Stack direction="row" alignItems="center">
                    <span>{dic[language]['setting_formtable_group']}</span>
                    <MultiSelectDropdown
                        options={Array.from(new Set(defaultDeviceList.flatMap((device) => device.group))).concat(dic[language]['no_group'])}
                        selectedGroupsInFilter={
                            selectedGroupsInFilter
                        }
                        setSelectedGroupsInFilter={
                            setSelectedGroupsInFilter
                        }
                    />
                </Stack>
            ),
        },
    ];

    return (
        <Paper
            sx={{
                backgroundColor: displayMode === 'dark' ? 'var(--gray-800)' : 'var(--gray-200)',
                padding: '1rem',
                paddingTop: 0,
                height: height,
                width: '100%'
            }}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant='body1'>{dic[language]['checked']} {rowSelectionModel.length}</Typography>
                <SearchBarWithChips
                    searchKeywordList={searchKeywordList}
                    setSearchKeywordList={setSearchKeywordList}
                    chipLength={6}
                />
                {ToolBarButtons}
            </Stack>

            <DataGridPro
                rows={deviceList}
                rowHeight={34}
                columnHeaderHeight={42}
                columns={deviceListColumn}
                disableRowSelectionOnClick
                hideFooterSelectedRowCount
                checkboxSelection
                pagination
                initialState={{ pagination: { paginationModel: { pageSize: 25, page: 0 } } }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                    setSelectedIDs(newRowSelectionModel as string[])
                }}
                isRowSelectable={(params) => !params.row.hasPendingTask && !blockSelection}
                rowSelectionModel={rowSelectionModel}
                sx={{
                    maxHeight: 'calc(100% - 4rem)',
                    bgcolor: 'background.default',
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                }}
            />
        </Paper>
    )
}

export default AgentTable