import { axiosClient } from 'util/index';
import { useEffect, useState } from 'react';
import { _language } from 'redux/UtilSlice';
import {
    _selectedYaraFiles,
    setGlobalDirty,
    setSelectedYaraFiles,
} from 'redux/SettingSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Stack,
    Divider,
    IconButton,
    CircularProgress,
    Tooltip,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { usePost, ErrorResponse } from 'hook/usePost';
import { useGet } from 'hook/useGet';
import { API, urlRoot } from 'constant/index';
import { processNumber } from 'util/index';
import { useAlert } from 'hook/useAlert';
import { useToast } from 'hook/useToast';
import '../../index.scss';
import dic from 'constant/dictionary';
import useDownloadFile from 'hook/useDownloadFile';
import { AxiosError, isAxiosError } from 'axios';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

interface YaraFileInfo {
    id: number
    fileName: string
    fileSize: number
    fileType: string
}

export interface SelectedYaraFileInfo {
    id: number
    fileName: string
    fileSize: number
    fileType: string
    verified: boolean
    errorMsg: string
}

const YaraRule = () => {
    const language = useSelector(_language);
    const dispatch = useDispatch();
    const [files, setFiles] = useState<File[]>([]);
    const selectedFiles = useSelector(_selectedYaraFiles);
    const [uploadedFiles, setUploadedFiles] = useState<YaraFileInfo[]>([]);
    const [noDeleted, setNoDeleted] = useState(true);
    const deletePending = usePost();
    const toast = useToast();
    const { showAlert } = useAlert();
    const uploadMutation = usePost();

    const {
        data: YaraFileInfo,
        refetch: refetchYaraFileInfo,
        isSuccess: isYaraFileInfoSuccess,
    } = useGet<YaraFileInfo[]>({
        query: 'YaraFileInfo',
        root: `${urlRoot}`,
        route: `${API.yaraRule}/info/applied`,
    });

    useEffect(() => {
        if (isYaraFileInfoSuccess && YaraFileInfo.data) {
            setUploadedFiles(YaraFileInfo.data.map((file, index) => {
                return {
                    id: index,
                    fileName: file.fileName,
                    fileSize: file.fileSize,
                    fileType: file.fileType,
                }
            }));
        }
    }, [isYaraFileInfoSuccess, YaraFileInfo])

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            let verifiedFiles: File[] = [], tempSelectedFiles: SelectedYaraFileInfo[] = [];
            Array.from(e.target.files).forEach((file, index) => {
                if (uploadedFiles.some((uploadedFile) => uploadedFile.fileName === file.name)) {
                    showAlert(dic[language]['setting_yara_file_exist'] + ': ' + file.name);
                } else {
                    if (file.name.split(".").pop() === "yara" || file.name.split(".").pop() === "yar") {
                        // Fize size limit: 100MB
                        if (file.size < 100000000 && file.size > 0) {
                            dispatch(setGlobalDirty(true));
                            verifiedFiles.push(file);
                            tempSelectedFiles.push({
                                id: index,
                                fileName: file.name,
                                fileSize: file.size,
                                fileType: file.name.split(".").pop() ?? 'yara',
                                verified: false,
                                errorMsg: '',
                            })
                        } else {
                            showAlert(dic[language]['setting_yara_empty_file'] + ': ' + file.name);
                        }
                    } else {
                        showAlert(dic[language]['setting_yara_wrong_file_type'] + ': ' + file.name);
                    }
                }
            })
            setFiles(verifiedFiles);
            for (let index = 0; index < tempSelectedFiles.length; index++) {
                const formData = new FormData();
                formData.append('file', verifiedFiles[index]);
                try {
                    const res = await axiosClient.post(`${urlRoot}${API.yaraRule}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
                    tempSelectedFiles[index] = {
                        ...tempSelectedFiles[index],
                        verified: true
                    };
                    dispatch(setSelectedYaraFiles([...tempSelectedFiles])); // Ensure immutability
                } catch (error: any) {
                    tempSelectedFiles[index] = {
                        ...tempSelectedFiles[index],
                        errorMsg: error.response.data.message,
                    };
                    dispatch(setSelectedYaraFiles([...tempSelectedFiles])); // Ensure immutability
                }
            }
        } else {
            showAlert(dic[language]['setting_yara_choose_file_first']);
        }
    }

    const handleApply = () => {
        const appliedFileNameLists = [], pendingFileNameLists = [];
        for (let index = 0; index < uploadedFiles.length; index++) {
            appliedFileNameLists.push(uploadedFiles[index].fileName);
        }
        for (let index = 0; index < selectedFiles.length; index++) {
            pendingFileNameLists.push(selectedFiles[index].fileName);
        }
        uploadMutation.mutate({
            method: 'put',
            root: `${urlRoot}`,
            route: `${API.yaraRule}`,
            body: {
                applied: appliedFileNameLists,
                pending: pendingFileNameLists
            },
        }, {
            onSuccess: () => {
                toast.show({content: dic[language]['setting_yara_upload_success']});
                dispatch(setGlobalDirty(false));
                setFiles([]);
                dispatch(setSelectedYaraFiles([]));
                refetchYaraFileInfo();
                setNoDeleted(true);
            },
        });
    }

    const { download } = useDownloadFile({
        apiDefinition: (apiRoute: string) => {
            return axiosClient.get(apiRoute, { responseType: 'blob' })
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                const axiosError = error as AxiosError<ErrorResponse>;
                console.log(axiosError.response?.data.message || axiosError.message);
            } else {
                showAlert(dic[language]['setting_yara_download_error']);
            }
        }
    });

    const selectedColumns: GridColDef[] = [
        {
            field: 'verified',
            headerName: '',
            disableColumnMenu: true,
            width: 50,
            align: 'center',
            renderCell: ({ row }) => (
                row.errorMsg !== '' ? (
                    <Tooltip title={row.errorMsg} placement='top'>
                        <ErrorIcon color="error" />
                    </Tooltip>
                ) : row.verified ? (
                    <Tooltip title={dic[language]['setting_yara_verified']} placement='top'>
                        <CheckCircleIcon color="success" />
                    </Tooltip>
                ) : (
                    <CircularProgress size={20} />
                )
            ),
        },
        { field: 'fileName', headerName: dic[language]['setting_yara_file_name'], flex: 1 },
        { field: 'fileSize', headerName: dic[language]['setting_yara_file_size'], flex: 1, valueGetter: (params) => processNumber(params.value) },
        { field: 'fileType', headerName: dic[language]['setting_yara_file_type'], flex: 1 },
        {
            field: 'actions',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            align: 'right',
            renderCell: ({ row }) => (
                <>
                    <IconButton
                        onClick={() => {
                            dispatch(setSelectedYaraFiles(selectedFiles.filter((file) => file.fileName !== row.fileName)));
                            setFiles(files.filter((file) => file.name !== row.fileName));
                            deletePending.mutate({
                                method: 'delete',
                                root: `${urlRoot}`,
                                route: `${API.yaraRule}/${row.fileName}`,
                                body: {}
                            });
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    const uploadedColumn: GridColDef[] = [
        { field: 'fileName', headerName: dic[language]['setting_yara_file_name'], flex: 1 },
        { field: 'fileSize', headerName: dic[language]['setting_yara_file_size'], flex: 1, valueGetter: (params) => processNumber(params.value) },
        { field: 'fileType', headerName: dic[language]['setting_yara_file_type'], flex: 1 },
        {
            field: 'actions',
            headerName: '',
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) => (
                <>
                    <IconButton
                        onClick={() => {
                            download(`${urlRoot}${API.yaraRule}/${row.fileName}`, row.fileName);
                        }}
                    >
                        <DownloadRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                        setUploadedFiles(uploadedFiles.filter((file) => file.fileName !== row.fileName));
                        setNoDeleted(false);
                        dispatch(setGlobalDirty(true));
                    }}>
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <div className='ChildInnerWhiteContainerSmall'>
            <Stack
                direction="row"
                spacing={5}
                alignItems='center'
                marginTop={2}
            >
                <span>{dic[language]['setting_yara_choose_upload_file']}</span>
                <Button
                    variant="contained"
                    component="label"
                    sx={{ textTransform: 'none' }}
                    disabled={selectedFiles.length > 0}
                >
                    {dic[language]['setting_yara_choose_file']}
                    <input type="file" multiple hidden onChange={handleFileChange} />
                </Button>
            </Stack>
            {/* Display the uploaded files */}
            {uploadedFiles.length > 0 && (
                <>
                    <Divider sx={{ margin: '1% 0' }} />
                    <div style={{ marginBottom: "10px" }}>
                        {dic[language]['setting_yara_uploaded_files']}
                    </div>
                    <DataGridPro
                        sx={{ maxHeight: 110 + 38 * Math.min(uploadedFiles.length, 5) }}
                        rows={uploadedFiles}
                        rowHeight={38}
                        columns={uploadedColumn}
                        rowCount={Math.min(uploadedFiles.length, 5)}
                        hideFooterSelectedRowCount
                    />
                </>
            )}
            {/* Display the currently upload file info */}
            {selectedFiles.length > 0 && (
                <>
                    <Divider sx={{ margin: '1% 0' }} />
                    <div style={{ marginBottom: "10px" }}>
                        {dic[language]['setting_yara_choosed_file']}
                    </div>
                    <DataGridPro
                        sx={{ maxHeight: 110 + 38 * Math.min(selectedFiles.length, 5) }}
                        rows={selectedFiles}
                        rowHeight={38}
                        columns={selectedColumns}
                        rowCount={Math.min(selectedFiles.length, 5)}
                        hideFooterSelectedRowCount
                    />
                </>
            )}
            <Divider sx={{ margin: '1% 0' }} />
            <Button
                variant="contained"
                color="warning"
                onClick={handleApply}
                disabled={selectedFiles.some((file) => !file.verified) || ((selectedFiles.length === 0) && noDeleted)}
            >
                {dic[language]['setting_yara_upload_file']}
            </Button>
        </div>
    )
}

export default YaraRule
