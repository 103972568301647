import React, { ErrorInfo, ReactNode, useEffect } from "react";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, Container, Divider, Slider, Stack, TextField } from "@mui/material";
import { Navigate } from "react-router-dom";
import { ReactComponent as SvgLogo } from 'assets/img/eDetctorLogo.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//@ts-ignore
import JSONGrid from '@redheadphone/react-json-grid'

import 'App.scss'
import './index.scss'
import { env, VERSION } from "constant";
import { store } from "redux/store";
import { logout } from "redux/AuthSlice";

type CrashReportDescription = {
    regularity: 0|1|2|3
    note: string
}

type CrashReport = {
    title: string
    timestamp: number
    version: string
    url: string
    browser: string
    userAgent: string
    error: any
    errorInfo: ErrorInfo
    description: CrashReportDescription
    env: any
}

type CrashReportAutoCollected = Omit<CrashReport, "description">

export type ErrorState = {
    error: Error | null,
    errorInfo: ErrorInfo | null,
    crashReport: null | CrashReportAutoCollected
    description: CrashReportDescription
}

export type ErrorBoundaryProps = {
    children: ReactNode
}

// by react 18, Error Boundary can only be implemented as a *Class Component* using ComponentDidCatch
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { 
            error: null, 
            errorInfo: null, 
            crashReport: null,
            description: {
                note: "",
                regularity: 0
            }
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {

        const crashReport:CrashReportAutoCollected = {
            title: "eDetector crash report",
            timestamp: +new Date(),
            version: VERSION,
            url: window.location.href,
            error: JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))),
            errorInfo: errorInfo,
            browser: (function(){
                var ua= navigator.userAgent;
                var tem; 
                var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
                if(/trident/i.test(M[1])){
                    tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                    return 'IE '+(tem[1] || '');
                }
                if(M[1]=== 'Chrome'){
                    tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                    if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
                }
                M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
                if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
                return M.join(' ');
            })(),
            userAgent: navigator.userAgent,
            env: env
        }

        this.setState({
            error: error,
            errorInfo: errorInfo,
            crashReport: crashReport,
        });
    }

    handleReturn () {
        store.dispatch(logout());
        this.setState({
            error: null,
            errorInfo: null
        });
    }

    render() {
        return this.state.errorInfo || this.state.error?
            <div id="errorPage">
                {/* <CommonHeader/> cannot be used here since it use `useNavigate` hook */}
                <header>
                    <div onClick={() => this.handleReturn()}>
                        <SvgLogo className='logoText' />
                    </div>
                </header>
                <main>
                <Card className="errorContainer">
                    <CardContent>
                        <h2>{"An unexpected error has occured :("}</h2>
                        <Divider/>
                        <p>
                            Please try following methods to solve the problem:
                            <ul>
                                <li>
                                    Wait for a while, press back to home and try again.
                                </li>
                                <li>
                                    Contact your IT administrator to solve the problem.
                                </li>
                                <li>
                                    If the problem still exist, please consider keep the crash report and contact BlockChain Security.
                                </li>
                            </ul>
                        </p>

                    </CardContent>
                    <CardActions sx={{justifyContent: 'flex-end'}}>
                        <Button onClick={() => {
                            window.location.reload();
                        }}>Try Again</Button>
                        <Button onClick={() => this.handleReturn()}>Restart</Button>
                    </CardActions>
                </Card>
                    <Accordion className="errorContainer">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                        <h3>Crash Report</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <JSONGrid data={this.state.crashReport}/>

                            <Stack direction="row" className="description">
                                <span>Please describe the problem you encounter: </span>
                                <div className="inputField">
                                    <TextField 
                                        multiline 
                                        rows={3}
                                        value={this.state.description.note}
                                        onChange={(event) => {
                                            this.setState({
                                                description: {
                                                    ...this.state.description,
                                                    note: event.currentTarget.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </Stack>
                            <Stack direction="row" className="regularitySlider">
                                <span>How often do you encounter into this error?</span>
                                <div className="slider">
                                    <Slider
                                        value={this.state.description.regularity}
                                        onChange={(event, newValue) => {
                                            this.setState({
                                                description: {
                                                    ...this.state.description,
                                                    regularity: (newValue as 0|1|2|3)
                                                }
                                            })
                                        }}
                                        step={null}
                                        valueLabelDisplay="off"
                                        min={0}
                                        max={3}
                                        marks={[
                                            {
                                                value: 0,
                                                label: 'First time',
                                            },
                                            {
                                                value: 1,
                                                label: 'Often',
                                            },
                                            {
                                                value: 2,
                                                label: 'Usually',
                                            },
                                            {
                                                value: 3,
                                                label: 'Always',
                                            },
                                        ]}
                                    />
                                </div>
                            </Stack>
                        </AccordionDetails>

                        <AccordionActions>
                            <Button onClick={() => {
                                if (!this.state.crashReport) {
                                    alert("no crash report");
                                    return;
                                }
                                const report: CrashReport = {
                                    ...this.state.crashReport,
                                    description: this.state.description
                                };

                                const link = document.createElement("a");
                                const file = new Blob([JSON.stringify(report)], { type: 'text/plain' });
                                link.href = URL.createObjectURL(file);
                                link.download = "eDetectorCrashReport.json";
                                link.click();
                                URL.revokeObjectURL(link.href);
                            }}>Save</Button>
                        </AccordionActions>
                    </Accordion>
                </main>
            </div>
        :this.props.children;
    }
}

export default ErrorBoundary;