import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import SearchBar from 'component/SearchBar'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import SubKeywordBox from './SubKeywordBox'
import Button from '@mui/material/Button'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setMainKeyword, setSubKeyword } from 'redux/AnalysisSlice'
import { _uuidList } from 'redux/ReportSlice'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { CircularProgress, Divider, Menu, MenuItem } from '@mui/material';
import ReportMenu from './ReportMenu';
import { useAlert } from 'hook/useAlert'
import { replaceInString } from 'util/index'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { CSV_REPORT_LIMIT, urlRoot } from 'constant/index'
import useFetchReportRows from 'page/analysis/useFetchReportRows'
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext'
import { stringArray2IAnalaysisNodeArray } from './util'

interface IGridHeaderProps {
    totalCount: number
    setPageInit: () => void
}

const GridHeader = (props: IGridHeaderProps) => {
    const {
        totalCount,
        setPageInit,
    } = props

    const { showAlert } = useAlert()
    const language = useSelector(_language)

    const [key, setKey] = useState<string>('')
    const onChangeFunc = (e: ChangeEvent<HTMLInputElement>) => {
        setKey(e.target.value)
    }
    const analysisData = useSelector(_analysisData);
    const dispatch = useDispatch();
    const reportUUIDList = useSelector(_uuidList);
    const {fetch: reportRowFetch, loading: aiReportLoading} = useFetchReportRows();
    const {fetch: csvRowFetch, loading: csvLoading} = useFetchReportRows();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const reportMenuOpen = Boolean(anchorEl);
    const handleReportMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleReportMenuClose = () => {
        setAnchorEl(null);
    };

    const [btnAnchorEl, setBtnAnchorEl] = useState<null | HTMLElement>(null);
    const reportBtnMenuOpen = Boolean(btnAnchorEl);
    const handleReportBtnMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setBtnAnchorEl(event.currentTarget);
    };
    const handleReportBtnMenuClose = () => {
        setBtnAnchorEl(null);
    }

    const reportWindow = useRef<Window | null>(null);
    const controller = (useContext(AnalysisController) as TAnalysisController);

    const generateReport = useMutation({
        mutationFn: () => {
            let rows: any = {}
            return reportRowFetch().then((reportRows:any) => {
                reportRows.forEach((row:any) => {
                    if (rows[row.type_main] === undefined) {
                        rows[row.type_main] = [];
                    }
                    rows[row.type_main].push(row)
                });
                return axios.post(`${urlRoot}report`, { rows: rows });
            })
        },
        onMutate: () => {
            //open the new window and write your HTML to it
            reportWindow.current = window.open("", "_blank");
            reportWindow.current?.document.write(`<svg version="1.1" id="loading-animation" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="150px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50" xml:space="preserve"><path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"/></path></svg><style>body,html{display:flex;align-items:center;justify-content:center;width:100vw;height:100vh;padding:0;margin:0;background-color:#eee}#loading-animation path,#loading-animation rect{fill:#ccc}</style>`);
            //close() document will make the next write() replace the content of current documenet
            reportWindow.current?.document.close()
        },
        onSuccess: (res) => {
            let responseHtml = res.data;
            reportWindow.current?.document.write(responseHtml);
            reportWindow.current?.document.close();
        },
        onError: (err: AxiosError) => {
            reportWindow.current?.document.write('error occured while generating report: ' + err.message);
            reportWindow.current?.document.close();
        }
    });
    useEffect(() => {
        const selectedItemName = analysisData.leftSelectedList.selectedIndex;
        let indexList = stringArray2IAnalaysisNodeArray(selectedItemName);
        if (indexList.length === 0) {
            indexList = analysisData.indexList;
        }
        controller.mutateSelectedIndex(indexList);
        setPageInit()
    }, [analysisData.subKeywordList])

    const searchKeyWords = (key: string) => {
        const replaceCharactersInArray = (stringList: string[]) => {
            return stringList.map((str) => replaceInString(str))
        }

        try {
            const subSearchKeyword: string = key
            if (
                !analysisData.subKeywordList.includes(
                    subSearchKeyword
                ) &&
                subSearchKeyword !== ''
            ) {
                // const tokens = subSearchKeyword.split(/(\s+|[^\w\s])/).filter(token => token.trim() !== "")
                const tokens = [subSearchKeyword]
                const newSubSearchKeywordList: string[] = [
                    ...analysisData.subKeywordList,
                    ...tokens,
                ]
                dispatch(setSubKeyword(replaceCharactersInArray(newSubSearchKeywordList)))
                setKey('')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        marginBottom: '5px',
                    }}
                >
                    <p
                        style={{
                            width: 150,
                            marginRight: '10px',
                        }}
                    >
                        {dic[language]['analysis_table_number_rows']}
                    </p>
                    <p style={{ width: 80, marginRight: '10px' }}>
                        {totalCount > 0 ? totalCount : '--'}
                    </p>

                    <SearchBar
                        labelValue={dic[language]['search']}
                        query={key}
                        setQuery={setKey}
                        onChangeFunc={onChangeFunc}
                        onSearchFunc={searchKeyWords}
                    />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        id="report-menu-button"
                        aria-controls={reportMenuOpen ? 'report-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={reportMenuOpen ? 'true' : undefined}
                        onClick={handleReportMenuClick}
                    >
                        <InfoOutlinedIcon color='info' />
                    </IconButton>
                    <ReportMenu
                        anchorEl={anchorEl}
                        reportMenuOpen={reportMenuOpen}
                        handleReportMenuClose={handleReportMenuClose}
                    />
                    <Typography
                        variant='body2'
                        color='text.secondary'
                        component='span'
                        sx={{ marginRight: '0.5rem' }}
                    >
                        x{reportUUIDList.length}
                    </Typography>

                    <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                    />

                    <Button
                        sx={{ marginLeft: '15px' }}
                        variant='contained'
                        endIcon={<ArrowDropDownIcon />}
                        onClick={handleReportBtnMenuClick} // TODO: 產生報告
                    >
                        {dic[language]['analysis_generate_report']}
                    </Button>
                    <Menu
                        id="report-menu-button"
                        anchorEl={btnAnchorEl}
                        open={reportBtnMenuOpen}
                        onClose={handleReportBtnMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'report-menu-button',
                        }}
                    >
                        <MenuItem
                            onClick={
                                () => {
                                    if (reportUUIDList.length === 0) {
                                        showAlert(dic[language]['analysis_ai_report_no_select_error'])
                                    } else if (reportUUIDList.length <= 1000){
                                        showAlert(
                                            dic[language]['analysis_ai_report_alert'],
                                                () => generateReport.mutate());
                                    } else {
                                        showAlert(dic[language]['analysis_ai_report_error(over limit)']);
                                    }
                            }}
                        >
                            {aiReportLoading?<CircularProgress style={{width: '1rem', height: '1rem', marginRight: '.5rem'}}/>:null}{dic[language]['analysis_ai_report']}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                if (reportUUIDList.length === 0) {
                                    showAlert(dic[language]['analysis_ai_report_no_select_error'])
                                } else if (reportUUIDList.length > CSV_REPORT_LIMIT){
                                    showAlert(dic[language]['analysis_csv_error(over limit)'])
                                } else {
                                    csvRowFetch().then(reportRows => {
                                        exportJsonAsCsv(reportRows, "analysis-report", "analysis-report");
                                    })
                                }
                            }}
                        >{csvLoading?<CircularProgress style={{width: '1rem', height: '1rem', marginRight: '.5rem'}}/>:null}{dic[language]['analysis_csv_file']}</MenuItem>
                        <MenuItem>{dic[language]['analysis_yara_export']}</MenuItem>
                    </Menu>
                </div>
            </div>
            {
                analysisData.subKeywordList.length !== 0 &&
                <div style={{
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <SubKeywordBox />

                    <Button
                        variant='text'
                        style={{
                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                            padding: '0px 20px',
                            borderRadius: '50px',
                            marginLeft: '10px',
                            marginBottom: '5px',
                            height: '30px',
                        }}
                        onClick={() => {
                            dispatch(setMainKeyword(''))
                            dispatch(setSubKeyword([]))
                        }}
                    >
                        {dic[language]['clear']}
                    </Button>
                </div>

            }
        </div>
    )
}

export default GridHeader

// 篩選筆數
// 局部搜尋
// 產出報告

/**
 * 
 */
function exportJsonAsCsv(JSONData:any, ReportTitle:string, ShowLabel:string) {

    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    //This condition will generate the Label/Header
    if (ShowLabel) {
        var row = "";

        //This loop will extract the label from 1st index of on array
        for (var index in arrData[0]) {
            //Now convert each value to string and comma-seprated
            row += index + ',';
        }
        row = row.slice(0, -1);
        //append Label row with line break
        CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        var row = "";
        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {
            row += '"' + arrData[i][index] + '",';
        }
        row.slice(0, row.length - 1);
        //add a line break after each row
        CSV += row + '\r\n';
    }

    if (CSV == '') {
        alert("Invalid data");
        return;
    }

    //this trick will generate a temp "a" tag
    var link = document.createElement("a");
    link.id = "lnkDwnldLnk";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);

    var csv = CSV;
    let blob = new Blob([csv], { type: 'text/csv' });
    var csvUrl = window.webkitURL.createObjectURL(blob);
    var filename =  (ReportTitle || 'UserExport') + '.csv';
    document.querySelector("#lnkDwnldLnk")?.setAttribute("download",filename);
    document.querySelector("#lnkDwnldLnk")?.setAttribute("href",csvUrl);

    (document.querySelector("#lnkDwnldLnk") as HTMLElement).click();
    document.body.removeChild(link);
}