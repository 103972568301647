import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Box,
} from "@mui/material";
import dic from "constant/dictionary";
import { _language } from "redux/UtilSlice";
import RadioGroupBox from "../../../setting/subpage/image/RadioGroupBox";
import { useSelector } from "react-redux";
import { IFormatedDevice } from "interface";
import ActionButton from "../HorizontalBar/ActionPlug/CommonComponents/ActionButton";
import TableBox from "../../../setting/subpage/image/TableBox";
import { _SelectedImageType } from "redux/SettingSlice";
import { API, urlRoot } from "constant";
import "./style.scss";

interface ImagesDialogProps {
    open: boolean;
    onClose: () => void;
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>;
    data: any[]
}

const ImagesDialog = (props: ImagesDialogProps) => {
    const { open, onClose, setData, data } = props;
    const language = useSelector(_language);
    const selectedImageType = useSelector(_SelectedImageType);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth='lg'
            aria-labelledby="image-generation"
        >
            <DialogTitle
                sx={{
                    backgroundColor: '#2196F3',
                    color: 'white',
                    fontSize: '1rem',
                    padding: '0.25rem 1rem',
                }}
            >
                {dic[language]['dashboard_start_get_image']}
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction="column"
                    spacing={1}
                    marginTop={1}
                    sx={{ height: '65vh' }}
                >
                    <RadioGroupBox />
                    <Box className="ImageTableBackground">
                        {selectedImageType === 'default' && (
                            <TableBox
                                isEditable={false}
                                root={`${urlRoot}`}
                                route={`${API.keyImageDefault}`}
                            />
                        )}
                        {selectedImageType === 'advanced' && (
                            <TableBox
                                isEditable={false}
                                root={`${urlRoot}`}
                                route={`${API.keyImageAdvanced}`}
                            />
                        )}
                        {selectedImageType === 'customized' && (
                            <TableBox
                                isEditable={false}
                                root={`${urlRoot}`}
                                route={`${API.keyImageCustomized}`}
                            />
                        )}
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ paddingRight: '1.5rem' }}>
                <ActionButton data={data} setData={setData} onClose={onClose} />
            </DialogActions>
        </Dialog>
    )
}

export default ImagesDialog;