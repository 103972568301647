import {
    Box,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableRow,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import {
    IGenerateGroup,
    oneHostData,
} from 'interface'
import EnhancedTableHead from './EnhancedTableHead'
import { IdIdentify } from '../GroupDropDown'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import { descendingComparator, getComparator, stableSort } from '../..'
import { Order } from 'util/index'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setSelectedId } from 'redux/AnalysisSlice'
import { _displayMode } from 'redux/UtilSlice'

interface GroupGridProps {
    data: oneHostData[]
    selectedHost: string
    setSelectedHost: React.Dispatch<React.SetStateAction<string>>
    handlePageSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const GroupGrid = (props: GroupGridProps) => {
    const {
        data,
        handlePageSelectAll,
        selectedHost,
    } = props

    const displayMode = useSelector(_displayMode);
    const analysisData = useSelector(_analysisData);
    const dispatch = useDispatch();
    const {
        selectedHostsIDs,
    } = analysisData;

    const [order, setOrder] = useState<Order>('asc') // the order of table
    const [orderBy, setOrderBy] = useState<keyof oneHostData>('ip') // the origin order of table
    const [search, setSearch] = useState<string>('');
    //render data by choosen group

    const handleRequestSort = (
        //排序方法
        event: React.MouseEvent<unknown>,
        property: keyof oneHostData
    ) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        //表格單列排序
        const selectedIndex = selectedHostsIDs.indexOf(name)
        let newSelected: string[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedHostsIDs, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedHostsIDs.slice(1))
        } else if (selectedIndex === selectedHostsIDs.length - 1) {
            newSelected = newSelected.concat(selectedHostsIDs.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedHostsIDs.slice(0, selectedIndex),
                selectedHostsIDs.slice(selectedIndex + 1)
            )
        }
        dispatch(setSelectedId(newSelected))
    }

    const isSelected = (name: string) => selectedHostsIDs.indexOf(name) !== -1

    const [visibleRows, setVisibleRows] = useState<oneHostData[]>([]);

    useEffect(() => {
        let filteredRows = data.filter((row) => {
            return Object.entries(row).some(([key, value]) => {
                return value
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
            })
        })
        setVisibleRows(stableSort(filteredRows, getComparator(order, orderBy)).slice(
            0,
            filteredRows.length + 1
        ))
    },[order, orderBy, selectedHost, search, data])

    return (
        <Box
            sx={{
                width: '80%',
                backgroundColor: displayMode === 'dark' ? 'var(--gray-900)' : 'var(--gray-100)',
                marginRight: 1,
                padding: 2,
                overflow: 'auto',
            }}
        >
            <EnhancedTableToolbar
                pageData={data}
                selectedHost={selectedHost}
                selectedHostsIDs={selectedHostsIDs}
                search={search}
                setSearch={(val) => setSearch(val)}
            />
            <Paper
                sx={{
                    width: '100%',
                    mb: 2,
                    backgroundColor: displayMode === 'dark' ? 'var(--gray-1000)' : 'var(--gray-0)',
                }}
            >
                <TableContainer>
                    <Table
                        sx={{ minWidth: 450 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            pageData={data}
                            selectedHostsIDs={selectedHostsIDs}
                            numSelected={selectedHostsIDs.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handlePageSelectAll}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                            selectedHost={selectedHost}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.id)
                                const labelId = `enhanced-table-checkbox-${index}`

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row.id)
                                        }
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCellComponent align="left">
                                            {row.ip}
                                        </TableCellComponent>
                                        <TableCellComponent align="left">
                                            {row.name}
                                        </TableCellComponent>
                                        <TableCellComponent align="left">
                                            {row.macAddress}
                                        </TableCellComponent>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default GroupGrid

interface TableCellComponentProps extends TableCellProps {
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
    children: React.ReactNode
}

const TableCellComponent = ({
    align = 'left',
    children,
    ...other
}: TableCellComponentProps) => {
    return (
        <TableCell
            style={{ whiteSpace: 'pre-line', padding: 0 }}
            align={align}
            {...other}
        >
            {children}
        </TableCell>
    )
}
