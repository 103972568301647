import MainComponent from './MainComponent'
import '../../../index.scss'

const Parent = () => {
    return (
        <div className='ChildInnerWhiteContainer'>
            <MainComponent />
        </div>
    )
}

export default Parent
