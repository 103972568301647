import { useState } from "react";
import { useSelector } from "react-redux";
import { _uuidList } from "redux/ReportSlice";
import { axiosClient } from "../../util";
import { elasticRoot } from "constant";

const useFetchReportRows = () => {

    const uuidList = useSelector(_uuidList);
    const [loading, setLoading] = useState(false);

    const fetch = async () => {
        let tempList: any[] = [];
        setLoading(true);
        for (let i = 0; i < uuidList.length; i += 10000){
            let end = i+10000;
            let query = JSON.stringify({
                size: 10000,
                query: {
                    terms: {
                        uuid: uuidList.slice(i, end > uuidList.length?uuidList.length:end)
                    }
                }
            })
            const res = await axiosClient.post(`${elasticRoot}*/_search`, query);
            if (!(res && res.data && res.data._shards.total > 0)) return;
            tempList = [...tempList, ...res.data.hits.hits.map((hit: any) => {
                return hit._source;
            })];
        }
        setLoading(false);
        return [...tempList];
    }

    return {fetch, loading}
}

export default useFetchReportRows;