/** @format */

import { Outlet } from 'react-router-dom'
import SettingSideBar from './component/SettingSideBar'
import './index.scss'

const SettingPage = () => {

    return (
        <div id='SettingPage'>
            <SettingSideBar />
            <div className="subPageContainer">
                <Outlet />
            </div>
        </div>
    )
}

export default SettingPage
