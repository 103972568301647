import { useDispatch, useSelector } from "react-redux";
import TimeSelector from "../../../component/TimeSelector";
import { _dateModuleData, setDateRange } from "redux/NetworkSlice";
import { useEffect, useState } from "react";
import { NETWORK_REFETCH_INTERVAL } from "constant";
import { _language } from "redux/UtilSlice";
import dic from "constant/dictionary";

const NetworkTimeSelector = (props: {refetch: () => void}) => {

    const dateModuleData = useSelector(_dateModuleData);
    const dispatch = useDispatch();
    const [selectedIndex, setSelectedIndex] = useState(3);
    const language = useSelector(_language);

    const fetchRealTimeData = () => {
        dispatch(setDateRange({
            startTime: +new Date() - 60 * 60 * 1000,
            endTime: +new Date()
        }))
        props.refetch();
    }

    useEffect(() => {
        if (dateModuleData.startTime === 0 && dateModuleData.endTime === 0 && selectedIndex === 3){
            fetchRealTimeData();
        }
        const refetchTimer = setInterval(() => {
            if (selectedIndex === 3){ //is real time
                fetchRealTimeData();
            }
        }, NETWORK_REFETCH_INTERVAL)
        return () => {
            clearInterval(refetchTimer);
        }
    }, [])

    return (
        <TimeSelector
            defaultValues={[
                { name: dic[language]["last_24_hr"], value: 24 },
                { name: dic[language]["last_week"], value: 168 },
                { name: dic[language]["custom"], value: 0 },
                { name: dic[language]["real_time"], value: -1 },
            ]}
            dateRange={dateModuleData}
            setDateRange={(arg: any) => dispatch(setDateRange(arg))}
            selectedDateIndex={selectedIndex}
            setSelectedDateIndex={setSelectedIndex}
            disabled={selectedIndex === 3}
        />
    )
}

export default NetworkTimeSelector;