import HighRiskProcess from "./HighRiskProcess"
import KeywordHit from "./KeywordHit"
import NoSignProcess from "./NoSignProcess"
import ProcessIP from "./ProcessIP"
import { EarthData, CountryEarthData, SelectedRow, KeywordHitData, LineChartData } from "../Interfaces"
import { IDateRange } from 'interface'
import '../../index.scss'

interface RightBarProps {
    dateModuleData: IDateRange
    setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setBarChartData: React.Dispatch<React.SetStateAction<KeywordHitData[]>>
    setLineChartData: React.Dispatch<React.SetStateAction<LineChartData>>
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const RightBar = (props: RightBarProps) => {
    const {
        dateModuleData,
        setEarthData,
        selectedRow,
        setSelectedRow,
        setBarChartData,
        setLineChartData,
        setOpenSnackBar,
    } = props

    return (
        <div className="rightBarContainer">
            <HighRiskProcess
                dateModuleData={dateModuleData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setLineChartData={setLineChartData}
            />
            <KeywordHit
                dateModuleData={dateModuleData}
                setSelectedRow={setSelectedRow}
                setBarChartData={setBarChartData}
            />
            <NoSignProcess
                dateModuleData={dateModuleData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setLineChartData={setLineChartData}
            />
            <ProcessIP
                dateModuleData={dateModuleData}
                setEarthData={setEarthData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default RightBar
