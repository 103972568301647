import { useAlert } from 'hook/useAlert'
import { Button } from '@mui/material'
import { useToast } from 'hook/useToast'
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'

interface ICustomedButton {
    setIsOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
}

export const CustomedButton = (props: ICustomedButton) => {
    const { setIsOpenDialog } = props
    const language = useSelector(_language)

    const handleClick = () => {
        setIsOpenDialog(true)
    }

    return (
        <Button
            onClick={handleClick}
            variant="contained"
            sx={{ margin: '0px 8px' }}
        >
            {dic[language]['setting_user_add']}
        </Button>
    )
}

interface IAlertButton {
    selectIds: readonly string[]
    listRefetchFn: () => void
}

export const AlertButton = (props: IAlertButton) => {
    const { selectIds, listRefetchFn } = props;
    const language = useSelector(_language);
    const alert = useAlert().showAlert;
    const toast = useToast();
    const stringArrayToNumberArray = (arr: readonly string[]) => arr.map(Number);
    const { mutate } = usePost()

    const handleClick = () => {
        if (selectIds.length === 0) {
            toast.show({
                type: 'error',
                content: dic[language]['setting_user_select_user']
            })
            return
        }

        const callBackFunction = () => {
            const ids = stringArrayToNumberArray(selectIds)
            const obj = { ids: ids }

            mutate({
                root: `${urlRoot}`,
                route: `${API.user}`,
                body: obj,
                method: 'delete',
            }, {
                onSuccess: () => {
                    toast.show({content: dic[language]['setting_user_delete_user_success']});
                    listRefetchFn();
                },
                onError: (error) => {
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        toast.show({
                            content: dic[language]['setting_user_delete_user_fail'] || error.response?.data.message,
                            type: 'error'
                        });
                    } else {
                        console.log(error.response?.data.message)
                    }
                }
            })
        }

        alert(dic[language]['setting_user_delete_user_confirm'], callBackFunction)
    }

    return (
        <Button
            color="error"
            onClick={handleClick}
            variant="contained"
            sx={{ margin: '0px 0px 0px 8px' }}
        >
            {dic[language]['setting_user_remove']}
        </Button>
    )
}
