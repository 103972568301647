import React, { useEffect, useState } from 'react'
import AnalysisTable, { Column } from "../AnalysisTable"
import { VirusTotalData, VirusTotalResponse, SelectedRow, EarthData, CountryEarthData } from "../Interfaces"
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { IDateRange } from 'interface'
import { sortRows, InitRequest } from '../functions'
import '../../index.scss'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import { useAlert } from 'hook/useAlert'

interface VirusTotalProps {
    dateModuleData: IDateRange
    setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const VirusTotal = (props: VirusTotalProps) => {
    const {
        dateModuleData,
        setEarthData,
        selectedRow,
        setSelectedRow,
        setOpenSnackBar,
    } = props
    const language = useSelector(_language);
    const postMutation = usePost<VirusTotalResponse[]>();
    const earthMutation = usePost<EarthData>();
    const [virusTotalData, setVirusTotalData] = useState<VirusTotalData[]>([]);
    const { showAlert } = useAlert();

    // fetch table data
    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.virusTotal}/none`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                const data: VirusTotalData[] = res.data ? res.data.map((item) => {
                    return {
                        ip: item.ip,
                        times: item.times,
                        positives: `${item.malicious}/${item.total}`,
                        hit: Math.round((item.malicious / item.total)),
                    }
                }) : []
                setVirusTotalData(sortRows(data, 'hit', 'desc'))
            },
            onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.error(error.response?.data.message)
				}
			}
        })
    }

    useEffect(() => {
        fetchData()
    }, [dateModuleData])

    // fetch earth data
    const fetchAndSetEarthData = (rowData: VirusTotalData | null) => {
        if (!rowData) return
        earthMutation.mutateAsync({
            root: `${urlRoot}`,
            route: `${API.virusTotal}/${rowData.ip}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                if (res.data) {
                    setEarthData(res.data);
                    if (res.data.connections === null || res.data.connections.length === 0) {
                        setOpenSnackBar(true);
                    }
                }
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchAndSetEarthData(selectedRow.virusTotal)
    }, [selectedRow.virusTotal])

    const VirusTotalColumns: Column<VirusTotalData, keyof VirusTotalData>[] = [
        { key: 'ip', label: 'IP', minWidth: 100 },
        { key: 'times', label: dic[language]['times'], minWidth: 85 },
        { key: 'positives', label: 'Positives', minWidth: 85 },
    ]

    return (
        <div className='tableContainer'>
            <AnalysisTable
                tableTitle={'VirusTotal'}
                lines={4}
                tableData={virusTotalData}
                tableColumns={VirusTotalColumns}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                isLoading={postMutation.isLoading}
                showDirectBtn
                directBtnCallback={(row) => { window.open(`https://www.virustotal.com/gui/ip-address/${(row as { ip: string }).ip}`, '_blank') }}
            />
        </div>
    )
}

export default VirusTotal
