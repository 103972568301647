/** @format */

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { API, urlRoot } from 'constant/index'
import {
    convertTimeStringsToString,
    updateGridInitial,
} from 'util/index'
import RadioGroupEnd from '../../SettingBarItem/RadioGroupEnd'
import DropDownMenu, {
    NumberString0To24,
} from '../../SettingBarItem/DropDownMenu'
import { Button } from '@mui/material'
import {
    ActionPlugTitle,
    StraightSeparator,
} from '../../StyledComponents'
import ActionButton from './CommonComponents/ActionButton'
import { IFormatedDevice } from 'interface'
import { useAlert } from 'hook/useAlert'
import FullLoading from '../../../../../component/FullLoading'
import { useDispatch, useSelector } from 'react-redux'
import { _scanModule, _scheduleScan, _selectedID, setScanModule, setScheduleScan } from 'redux/DetectSlice'
import { axiosClient } from 'util/index'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { AxiosError } from 'axios'

interface ActionPlugProps {
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
}

const ActionPlug = (props: ActionPlugProps) => {
    const { setData, data } = props
    const navigate = useNavigate()
    const alert = useAlert().showAlert
    const language = useSelector(_language)
    const scheduleScan = useSelector(_scheduleScan);
    const scanModule = useSelector(_scanModule);
    const dispatch = useDispatch()

    const [selectedTimes, setSelectedTimes] = useState<NumberString0To24[]>([])
    const ids = useSelector(_selectedID)

    const convertToUTC = (time: NumberString0To24[]) => {
        let temp: string = ""
        time.forEach((time) => {
            const date = new Date()
            date.setHours(Number(time))
            temp += date.toLocaleTimeString('en-US', { timeZone: 'UTC' }).split(':')[0] + ','
        })
        return temp[temp.length - 1] === ',' ? temp.slice(0, -1) : temp
    }

    // command to schedule scan
    const scheduledScanRequest = useMutation({
        mutationKey: ['scheduledScanRequest'],
        mutationFn: () => {
            return axiosClient.post(`${urlRoot}${API.schedule}/scan`, {
                mode: scheduleScan,
                scanTime: convertToUTC(selectedTimes),
                devices: ids,
            })
        },
        onError: (error: AxiosError<any, any>) => {
            if (error.response?.data.message.includes('already processing the same task')) {
                alert('Already processing the same task.')
            } else {
                alert(error.response?.data.message || error.message);
            }
        }
    })

    // command to change detection mode
    const detectionModeRequest = useMutation({
        mutationKey: ['detectionModeRequest'],
        mutationFn: () => {
            return axiosClient.post(`${urlRoot}${API.detectMode}`, {
                mode: scanModule,
                deviceId: ids,
            })
        },
        onSuccess: () => {
            setData(
                data.map((obj) =>
                    updateGridInitial(obj, ids, {
                        detectionMode: scanModule ? 'true' : 'false',
                    })
                )
            )
        },
        onError: (error: AxiosError<any, any>) => {
            if (error.response?.data.message.includes('already processing the same task')) {
                alert('Already processing the same task.')
            } else {
                alert(error.response?.data.message || error.message);
            }
        }
    })

    return (
        <div className='actionPlugContainer'>
            <FullLoading
                open={
                    scheduledScanRequest.isLoading ||
                    detectionModeRequest.isLoading
                }
            />
            <ActionPlugTitle>
                {dic[language]['detect_scan_schedule']}
            </ActionPlugTitle>
            <RadioGroupEnd
                setTrueOrFalse={input => dispatch(setScheduleScan(input))}
                defaultValue={scheduleScan}
            />
            <DropDownMenu
                paramas={selectedTimes}
                onParamChange={setSelectedTimes}
            />
            <Button
                disabled={
                    (selectedTimes.length === 0 && scheduleScan) ||
                    ids.length === 0
                }
                variant="contained"
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() => {
                    scheduledScanRequest.mutate()
                }}
            >
                {dic[language]['apply']}
            </Button>
            <StraightSeparator />
            <ActionPlugTitle>
                {dic[language]['detect_detect_mode']}
            </ActionPlugTitle>
            <RadioGroupEnd
                setTrueOrFalse={input => dispatch(setScanModule(input))}
                defaultValue={scanModule}
            />
            <Button
                variant="contained"
                disabled={ids.length === 0}
                style={{ marginLeft: 20, marginRight: 20 }}
                onClick={() => {
                    detectionModeRequest.mutate()
                }}
            >
                {dic[language]['apply']}
            </Button>

            <StraightSeparator />
            <ActionPlugTitle>
                {dic[language]['detect_task_type_memory']}
            </ActionPlugTitle>
            <ActionButton data={data} setData={setData} />
        </div>
    )
}

export default ActionPlug
