import { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import TableSortLabel from '@mui/material/TableSortLabel'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'

export interface ConnectData {
    id: number
    name: string
    count: number
    connected: number
    disconnected: number
}

export interface TaskData {
    id: number
    name: string
    count: number
    done: number
    running: number
    unknown: number
}

export interface TableTitleDetail {
    groupCount: number
    deviceCount: number
    done: number
    unknown: number
}

interface GroupTableProps {
    tableTitle: string
    tableTitleDetail: TableTitleDetail
    connectData?: ConnectData[]
    taskData?: TaskData[]
}

const GroupTable = (props: GroupTableProps) => {
    const language = useSelector(_language);
    const { tableTitle, tableTitleDetail, connectData, taskData } = props

    const [connectRows, setConnectRows] = useState<ConnectData[]>([])
    const [connectSortColumn, setConnectSortColumn] = useState<
        keyof ConnectData | null
    >(null)

    const [taskRows, setTaskRows] = useState<TaskData[]>([])
    const [taskSortColumn, setTaskSortColumn] = useState<keyof TaskData | null>(
        null
    )

    // 要判斷初始值要在 useEffect (這裡涵括了生命週期的初始值)
    useEffect(() => {
        if (taskData) setTaskRows(taskData)
    }, [taskData])

    useEffect(() => {
        if (connectData) setConnectRows(connectData)
    }, [connectData])

    const handleConnectColumnSort = (columnId: keyof ConnectData) => {
        if (connectSortColumn === columnId) {
            setConnectSortColumn(null)
            setConnectRows(connectData || [])
            return
        }
        setConnectSortColumn(columnId)
        setConnectRows(sortedConnectRows(columnId))
    }

    const sortedConnectRows = (connectSortColumn: keyof ConnectData) => {
        return connectRows.sort((a, b) => {
            if (connectSortColumn) {
                const valueA = a[connectSortColumn]
                const valueB = b[connectSortColumn]

                if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return valueA - valueB
                }

                return valueA.toString().localeCompare(valueB.toString())
            }
            return 0
        })
    }

    const handleTaskColumnSort = (columnId: keyof TaskData) => {
        if (taskSortColumn === columnId) {
            setTaskSortColumn(null)
            setTaskRows(taskData || [])
            return
        }
        setTaskSortColumn(columnId)
        setTaskRows(sortedTaskRows(columnId))
    }

    const sortedTaskRows = (taskSortColumn: keyof TaskData) => {
        return taskRows.sort((a, b) => {
            if (taskSortColumn) {
                const valueA = a[taskSortColumn]
                const valueB = b[taskSortColumn]

                if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return valueA - valueB
                }

                return valueA.toString().localeCompare(valueB.toString())
            }
            return 0
        })
    }

    interface ConnectColumn {
        id: 'name' | 'count' | 'connected' | 'disconnected'
        label: string
        minWidth: number
        align?: 'right'
    }

    const connectColumns: readonly ConnectColumn[] = [
        { id: 'name', label: dic[language]["group"], minWidth: 150 },
        { id: 'count', label: dic[language]["agent_count"], minWidth: 50 },
        { id: 'connected', label: dic[language]["connected"], minWidth: 50 },
        { id: 'disconnected', label: dic[language]["disconnected"], minWidth: 50 },
    ]

    interface TaskColumn {
        id: 'name' | 'count' | 'done' | 'running' | 'unknown'
        label: string
        minWidth: number
        align?: 'right'
    }

    const taskColumns: readonly TaskColumn[] = [
        { id: 'name', label: dic[language]["group"], minWidth: 110 },
        { id: 'count', label: dic[language]["agent_count"], minWidth: 55 },
        { id: 'done', label: dic[language]["finished"], minWidth: 55 },
        { id: 'running', label: dic[language]["running"], minWidth: 55 },
        { id: 'unknown', label: dic[language]["unknown"], minWidth: 55 },
    ]

    return (
        <div style={{ marginTop: '5px' }}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1">{tableTitle}</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2">
                        {dic[language]["group"]} {tableTitleDetail.groupCount}
                    </Typography>
                    <Typography variant="body2">
                        {dic[language]["agent_count"]} {tableTitleDetail.deviceCount}
                    </Typography>
                    <Typography variant="body2">
                        {connectData !== undefined ? dic[language]["connected"] : dic[language]["finished"]}{' '}
                        {tableTitleDetail.done}
                    </Typography>
                    <Typography variant="body2">
                        {connectData !== undefined ? dic[language]["disconnected"] : dic[language]["unknown"]}{' '}
                        {tableTitleDetail.unknown}
                    </Typography>
                </Stack>
            </Stack>
            <Paper sx={{ width: '100%' }}>
                <TableContainer className='groupTableContainer'>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                {connectData !== undefined
                                    ? connectColumns.map((column) => (
                                        <TableCell
                                            className='groupTableCell'
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: '150px',
                                            }}
                                        >
                                            <TableSortLabel
                                                active={
                                                    connectSortColumn ===
                                                    column.id
                                                }
                                                direction={'asc'}
                                                onClick={() =>
                                                    handleConnectColumnSort(
                                                        column.id
                                                    )
                                                }
                                                IconComponent={
                                                    ArrowDropDownIcon
                                                }
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                    : null}
                                {taskData !== undefined
                                    ? taskColumns.map((column) => (
                                        <TableCell
                                            className='groupTableCell'
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            <TableSortLabel
                                                active={
                                                    taskSortColumn ===
                                                    column.id
                                                }
                                                direction={'asc'}
                                                onClick={() =>
                                                    handleTaskColumnSort(
                                                        column.id
                                                    )
                                                }
                                                IconComponent={
                                                    ArrowDropDownIcon
                                                }
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                    : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {connectData !== undefined
                                ? connectRows.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            {connectColumns.map((column) => {
                                                const value = row[column.id]
                                                return (
                                                    <TableCell
                                                        className='groupTableCell'
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{
                                                            maxWidth: '200px',
                                                            overflow:
                                                                'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {value}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })
                                : null}

                            {taskData !== undefined
                                ? taskRows.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            {taskColumns.map((column) => {

                                                // todo : （看完刪除這段）小建議，由於這邊的組件和 connectData 的組件重複。
                                                // 建議抽出來寫在下面好統一管理（如果遇到很邪門的設計師這裡可能會很搞！？）
                                                // 我的想法是這裡的元件由於可能會重複使用或大量使用，
                                                // 因此我在別處確實拉出來統一管理 例如 DetectPage 的表格

                                                const value = row[column.id]
                                                return (
                                                    <TableCell
                                                        className='groupTableCell'
                                                        key={column.id}
                                                        align={column.align}
                                                        sx={{
                                                            maxWidth: '200px',
                                                            overflow:
                                                                'hidden',
                                                            textOverflow:
                                                                'ellipsis',
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        {value}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })
                                : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default GroupTable
