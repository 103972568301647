import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from './store'
import { SelectedYaraFileInfo } from '../page/setting/subpage/IoC/YaraRule'


export interface settingState {
    isGlobalDirty: boolean
    isApplyingBlackList: boolean
    isApplyingWhiteList: boolean
    isApplyingHackList: boolean
    SelectedImageType: string
    selectedYaraFiles: SelectedYaraFileInfo[]
}

const initialState: settingState = {
    isGlobalDirty: false,
    isApplyingBlackList: false,
    isApplyingWhiteList: false,
    isApplyingHackList: false,
    SelectedImageType: 'default',
    selectedYaraFiles: []
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState: initialState,
    reducers: {
        setGlobalDirty: (state: settingState, action: PayloadAction<boolean>) => {
            state.isGlobalDirty = action.payload
        },
        setApplyingBlackList: (state: settingState, action: PayloadAction<boolean>) => {
            state.isApplyingBlackList = action.payload
        },
        setApplyingWhiteList: (state: settingState, action: PayloadAction<boolean>) => {
            state.isApplyingWhiteList = action.payload
        },
        setApplyingHackList: (state: settingState, action: PayloadAction<boolean>) => {
            state.isApplyingHackList = action.payload
        },
        setSelectedImageType: (state: settingState, action: PayloadAction<string>) => {
            state.SelectedImageType = action.payload
        },
        setSelectedYaraFiles: (state: settingState, action: PayloadAction<SelectedYaraFileInfo[]>) => {
            state.selectedYaraFiles = action.payload
        },
    }
});

export const {
    setGlobalDirty,
    setApplyingBlackList,
    setApplyingWhiteList,
    setApplyingHackList,
    setSelectedImageType,
    setSelectedYaraFiles,
} = settingSlice.actions;
export const _isGlobalDirty = (state: RootState) => state.setting.isGlobalDirty;
export const _isApplyingBlackList = (state: RootState) => state.setting.isApplyingBlackList;
export const _isApplyingWhiteList = (state: RootState) => state.setting.isApplyingWhiteList;
export const _isApplyingHackList = (state: RootState) => state.setting.isApplyingHackList;
export const _SelectedImageType = (state: RootState) => state.setting.SelectedImageType;
export const _selectedYaraFiles = (state: RootState) => state.setting.selectedYaraFiles;

export default settingSlice.reducer;
