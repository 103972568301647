import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import './ForensicsDropSelector.css'
import {
    oneHostData,
    IAllFilesDropDownData,
    IForensicDropDownData,
    IMemoryDropDownData,
    RiskLevel,
    InjectActive,
    ProcessBeInjected,
    Boot,
    Hook,
    Hide,
} from 'interface'
// import { forensicsDropSelectorTranfer } from 'constant/AnalysisPage/functionToolbox'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setForensicsSelectedData } from 'redux/AnalysisSlice'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'
import TDictionaryKeys from 'constant/dict.type'

// --網站瀏覽紀錄與書籤--      browsingHistoryAndBookmarks

// Chrome 瀏覽紀錄      ed_chromehistory
// Chrome 下載紀錄     ed_chromedownload
// Chrome 登入帳密     ed_chromekeywordsearch
// Chrome 關鍵字搜尋紀錄       ed_chromelogin
// Chrome 書籤         ed_chromebookmarks

// Edge 瀏覽紀錄        ed_edgehistory
// IE 瀏覽紀錄          ed_iehistory
// Edge 登入帳密          ed_edgelogin
// IE 登入帳密              ed_ielogin
// Edge 書籤            ed_edgebookmarks

// Firefox 瀏覽紀錄     ed_firefoxhistory
// Firefox 登入帳密     ed_firefoxlogin
// Firefox 書籤             ed_firefoxbookmarks

// -- cache 和 cookie 紀錄--     group_cache_and_cookie_history
// Chrome cache        ed_chromecache
// Chrome cookies      ed_chromecookies

// Edge cache          ed_edgecache
// Edge cookies        ed_edgecookies

// IE cache        ed_iecache

// Firefox cache       ed_firefoxcache
// Firefox cookies     ed_firefoxcookies

// --當下網路連線紀錄--        group_current_network_connection_history

// 網路網路資訊        ed_netadapters
// 無線基本資訊        ed_wireless
// 網路資源        ed_networkresources

// --最近執行軟體紀錄--        group_recently_executed_software_log

// 已安裝軟體      ed_installedsoftware
// 一般系統服務        ed_service
// 細部系統服務        ed_baseservice
// 開機啟動程式            ed_startrun
// 遠端桌面登入帳密        ed_remotedesktoplogin
// JumpList            ed_jumplist
// 系統資訊            ed_systeminfo
// MUI 快取            ed_muicache
// Prefetch            ed_prefetch
// 應用程式日誌檔      ed_eventapplication
// 排程工作            ed_taskschedule
// 機碼使用痕跡            ed_userassist
// 程式網路流量痕跡        ed_networkdatausagemonitor
// 程式讀寫痕跡            ed_appresourceusagemonitor
// DNS資訊             ed_dnsinfo

// --消逝性紀錄--      group_evanescent_records
// 執行程序            ed_process
// 程序連線資訊        ed_openedfiles
// 程式開啟之檔案      ed_network
// ARP快取             ed_arpcache

// --最近開啟文件紀錄--        group_recently_opened_file_history
// 相關捷徑        ed_shortcuts
// 開啟的資料夾路徑        ed_userprofiles
// 主機用戶資料              ed_windowsactivity
// 最近開啟檔案            ed_shellbags
// Windows Activity        ed_recentfile

// --USB使用設備紀錄--     usbUsageDeviceRecord
// USB 裝置資訊            ed_usbdevices
// 安全性日誌檔            ed_eventsystem
// 系統日誌檔              ed_eventsecurity

// --電子郵件清單紀錄(.pst,.ost)--         emailListReacord
// 電子郵件路徑            ed_emailpath
// 電子郵件清單紀錄        ed_email

// 痕跡取證中英對照表

const ItemInForensicsDropSelector = (props: {
    parentName: string,
    title: TDictionaryKeys
}) => {
    
    const analysisData = useSelector(_analysisData)
    const dispatch = useDispatch();
    const language = useSelector(_language);
    const forensicDropDownData = analysisData.forensicDropDownData;  
    let titleInType = props.title as keyof IForensicDropDownData
    const isLittleElementSelected = () => {
        if (
            forensicDropDownData[titleInType] === undefined ||
            typeof forensicDropDownData[titleInType] === 'string'
        ) {
            return false
        }
        return forensicDropDownData[titleInType] === true
    }
    const isChecked = isLittleElementSelected()
    return (
        <div>
            <FormControlLabel
                label={dic[language][props.title]}
                control={
                    <Checkbox
                        size="small"
                        name={props.title}
                        value={props.parentName}
                        checked={isChecked}
                        onChange={(e) => {
                            dispatch(setForensicsSelectedData({
                                ...forensicDropDownData,
                                [e.target.name]: e.target.checked,
                            }))
                        }}
                    />
                }
            />
        </div>
    )
}
export default ItemInForensicsDropSelector

// function ItemInForensicsDropSelector({
//     title,
//     forensicDropDownData,
//     setForensicsSelectedData,
//   }: {
//     title: string;
//     forensicDropDownData: IForensicDropDownData;
//     setForensicsSelectedData: React.Dispatch<React.SetStateAction<IForensicDropDownData>>;
//   }): JSX.Element {
//     const titleInType = title as keyof IForensicDropDownData;
//     const isChecked = isLittleElementSelected();

//     function isLittleElementSelected(): boolean {
//       if (forensicDropDownData[titleInType] === undefined || typeof forensicDropDownData[titleInType] === 'string') {
//         return false;
//       }
//       return forensicDropDownData[titleInType] === true;
//     }

//     return (
//       <div>
//         <Checkbox
//           size="small"
//           name={title}
//           checked={isChecked}
//           onChange={(e) => {
//             handleLittleElementChange(e, setForensicsSelectedData);
//           }}
//         ></Checkbox>
//         <span>電子郵件路徑</span>
//       </div>
//     );
//   }

//   export default ItemInForensicsDropSelector;
