import { WEBSOCKET_STATUS } from "constant";
import WebSocketContext from "context/WebSocketContext";
import { IDevice } from "interface";
import { StatusInfo } from "page/home/subpage/detectdashboard/component/ServerStatus";
import { useContext, useEffect, useState } from "react";

export interface WS_MESSAGE_ACTION_PROGRESS_UPDATE {
    status: WEBSOCKET_STATUS.ACTION_PROGRESS_UPDATE,
    uuid: string
    action: "DumpDll" | "DumpProcess" | "DumpDrive" | "DT",
    progress: number
    failed: string
}
export interface WS_MESSAGE_LOAD_DLL {
    status: WEBSOCKET_STATUS.LOAD_DLL,
    uuid: string
    data: string
    failed: string
}
export interface WS_MESSAGE_SERVER_STATUS_UPDATE {
    status: WEBSOCKET_STATUS.SERVER_STATUS_UPDATE,
    data: StatusInfo
}
export interface WS_MESSAGE_AUTHORIZE_SUCCESS {
    status: WEBSOCKET_STATUS.AUTHORIZE_SUCCESS,
    auxiliary: {
        pendingDumps: [
            {
                filename: string,
                uuid: string,
                progress: number,
                failed: string,
                timestamp: number
            }
        ]
    }
}
export interface WS_MESSAGE_DETECT_UPDATE {
    status: WEBSOCKET_STATUS.DETECT_UPDATE,
    data: IDevice[]
}

//function overload
function useSocketHandler(statusCode: WEBSOCKET_STATUS.ACTION_PROGRESS_UPDATE, handler: (message: WS_MESSAGE_ACTION_PROGRESS_UPDATE, params?: any) => void, params?: any): void;
function useSocketHandler(statusCode: WEBSOCKET_STATUS.SERVER_STATUS_UPDATE, handler: (message: WS_MESSAGE_SERVER_STATUS_UPDATE, params?: any) => void, params?: any): void;
function useSocketHandler(statusCode: WEBSOCKET_STATUS.AUTHORIZE_SUCCESS, handler: (message: WS_MESSAGE_AUTHORIZE_SUCCESS, params?: any) => void, params?: any): void;
function useSocketHandler(statusCode: WEBSOCKET_STATUS.DETECT_UPDATE, handler: (message: WS_MESSAGE_DETECT_UPDATE, params?: any) => void, params?: any): void;
function useSocketHandler(statusCode: WEBSOCKET_STATUS.LOAD_DLL, handler: (message: WS_MESSAGE_LOAD_DLL, params?: any) => void, params?: any): void;

//implementation
function useSocketHandler (statusCode: WEBSOCKET_STATUS, handler: (message: any, params?:any) => void, params?:any) {

    const socket = useContext(WebSocketContext);
    
    const handle = (event: MessageEvent) => {
        const data = JSON.parse(event.data);
        if (data.status === statusCode) {
            if (params !== undefined) {
                handler(data, params);
            } else {
                handler(data)
            }
        }
    }

    useEffect(() => {
        if (socket === null) return;
        socket?.addEventListener("message", handle);

        return () => {
            socket?.removeEventListener("message", handle);
        }
    }, [params, socket])

}

export default useSocketHandler