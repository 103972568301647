import {
    CommonHoriLine,
    CommonSpaceGap,
    StyledDivInSettingV,
    StyledSpan,
    StyledSpan1,
    StyledTitle,
} from '../../../../component/CommonStyledComponent'
import { Stack } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import '../../index.scss'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { urlRoot, API } from 'constant/index'
import { useGet } from 'hook/useGet'
import { useEffect, useState } from 'react'
import { useAlert } from 'hook/useAlert';
import { useToast } from 'hook/useToast';
import { usePost } from 'hook/usePost';
import { env } from 'constant/index'

interface LicenseInfo {
    uuid: string
    agentCount: number
    expirationDate: string
}

const Version = () => {
    const language = useSelector(_language);
    const { showAlert } = useAlert();
    const toast = useToast();
    const { mutate: licenseUpload, isLoading: licenseUploading } = usePost<LicenseInfo>();
    const [licenseInfo, setLicenseInfo] = useState<LicenseInfo>({
        uuid: '',
        agentCount: 0,
        expirationDate: '',
    })

    const {
        data,
        isSuccess,
    } = useGet<LicenseInfo>({
        query: 'getLicenseInfo',
        root: `${urlRoot}`,
        route: `${API.license}`,
    })

    useEffect(() => {
        if (isSuccess && data.data) {
            setLicenseInfo(data.data)
        }
    }, [isSuccess, data])

    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0] && e.target.files[0].name === 'license.key') {
            const formData = new FormData()
            formData.append('file', e.target.files[0])
            licenseUpload({
                root: `${urlRoot}`,
                route: `${API.license}`,
                body: formData,
                contentType: 'multipart/form-data',
            }, {
                onSuccess: (res) => {
                    setLicenseInfo(res.data)
                    toast.show({content: dic[language]['setting_software_update_license_success']})
                },
                onError: (error) => {
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        showAlert(dic[language]['setting_software_update_license_fail'] || error.response.data.message)
                    } else {
                        console.log(error.response?.data.message)
                    }
                }
            })
        } else {
            showAlert(dic[language]['setting_software_update_license_fail'])
        }
    }

    return (
        <div className='ChildInnerWhiteContainerSmall'>
            <Stack direction='row' justifyContent='space-between'>
                <StyledTitle>
                    {dic[language]['setting_software_authorization_information']}
                </StyledTitle>
                <LoadingButton
                    variant='contained'
                    component="label"
                    loading={licenseUploading}
                    sx={{ textTransform: 'none', margin: '1rem 0' }}
                >
                    {dic[language]['setting_software_update_license']}
                    <input type='file' hidden onChange={handleUpload} />
                </LoadingButton>
            </Stack>
            <StyledDivInSettingV>
                {dic[language]['setting_software_authorization_issuer']}：
                <StyledSpan>鑒真數位</StyledSpan>
            </StyledDivInSettingV>
            <StyledDivInSettingV>
                {dic[language]['setting_software_authorization_code']}：
                <StyledSpan>{licenseInfo.uuid}</StyledSpan>
            </StyledDivInSettingV>
            <StyledDivInSettingV>
                {dic[language]['setting_software_authorization_connections']}：
                <StyledSpan>{licenseInfo.agentCount}</StyledSpan>
            </StyledDivInSettingV>
            <StyledDivInSettingV>
                {dic[language]['setting_software_authorization_date']}：
                <StyledSpan1>{licenseInfo.expirationDate}</StyledSpan1>
            </StyledDivInSettingV>
            <CommonHoriLine />
            <CommonSpaceGap />

            <StyledTitle>e-Detector</StyledTitle>
            <StyledDivInSettingV>
                {dic[language]['setting_software_version']}：
                <StyledSpan>{env.REACT_APP_VERSION}</StyledSpan>
            </StyledDivInSettingV>
            <CommonSpaceGap />
            <StyledDivInSettingV>
                {dic[language]['setting_authorization_text1']}
                <CommonSpaceGap />
                {dic[language]['setting_authorization_text2']}
            </StyledDivInSettingV>
        </div>
    )
}

export default Version
