import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from './store'

export interface reportState {
    uuidList: string[]
}

const initialState: reportState = {
    uuidList: [],
    // selected
}

export const reportSlice = createSlice({
    name: 'report',
    initialState: initialState,
    reducers: {
        addReportRow: (state, action: PayloadAction<string | string[]>) => {
            if (Array.isArray(action.payload)){
                state.uuidList = Array.from(new Set([...state.uuidList, ...(action.payload as string[])]));
            } else if (typeof action.payload === 'string'){
                if (state.uuidList.includes(action.payload)) return;
                state.uuidList.push(action.payload);
            }
        },
        removeReportRow: (state, action: PayloadAction<string | string[]>) => {
            if (Array.isArray(action.payload)){
                state.uuidList = state.uuidList.filter(uuid => !action.payload.includes(uuid));
            } else if (typeof action.payload === 'string'){
                state.uuidList = state.uuidList.filter(uuid => uuid !== action.payload);

            }
        },
        clearReportRows: (state) => {
            state.uuidList = [];
        }
    }
});

export const { addReportRow, removeReportRow, clearReportRows } = reportSlice.actions;
export const _uuidList = (state: RootState) => state.report.uuidList;

export default reportSlice.reducer;