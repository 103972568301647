import * as React from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { IDateModule, IDateRange } from 'interface'
import { ListItemButton } from '@mui/material'
import { _analysisData, setDateSelectIndex } from 'redux/AnalysisSlice'
import { _displayMode, _language } from 'redux/UtilSlice'
import { useSelector, useDispatch } from 'react-redux'
import dic from 'constant/dictionary'

interface TimeSelectorProps {
    defaultValues: IDateModule[]
    dateRange: IDateRange
    setDateRange: React.Dispatch<React.SetStateAction<IDateRange>>
    width?: string,
    selectedDateIndex: number
    setSelectedDateIndex: (arg: number) => void
    disabled?: boolean
}

const TimeSelector = (props: TimeSelectorProps) => {
    const {
        defaultValues,
        dateRange,
        setDateRange,
        width,
        // labelValue,
        selectedDateIndex,
        setSelectedDateIndex,
        disabled
    } = props

    const dispatch = useDispatch();
    const displayMode = useSelector(_displayMode);
    const language = useSelector(_language);
    const startDateValue = dateRange.startTime;
    const endDateValue = dateRange.endTime;

    const [menuIsOpen, setMenuIsOpen] = React.useState(false)

    const handleChange = (item: IDateModule) => {
        const newValue = item.value.toString()
        // 檢查是否是有效的選項，如果是就更新狀態，否則設定預設值或顯示錯誤訊息
        let startTimestamp = +new Date() - 60 * 60 * 1000 * parseInt(newValue)
        let endTimestamp = +new Date()
        setDateRange({
            startTime: startTimestamp,
            endTime: endTimestamp,
        })
    }

    return (
        <div className="optionsContainer">
            <span className='timeSelectorTitle'>
                {/* {labelValue ? labelValue : dic[language]['analysis_time']} */dic[language]['analysis_time']}
            </span>
            <FormControl
                sx={{
                    m: 1,
                    width: width ? width : 200,
                    borderRadius: '4px',
                    height: 'min-content',
                    backgroundColor: displayMode === 'dark' ? '' : '#fff',
                }}
                size="small"
            >
                <Select
                    multiple
                    value={['']}
                    renderValue={() => <>
                        {defaultValues[selectedDateIndex].name}
                    </>}
                    open={menuIsOpen}
                    onOpen={() => {
                        setMenuIsOpen(true)
                    }}
                    onClose={() => {
                        setMenuIsOpen(false)
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gridTemplateColumns: '1fr 5fr',
                        }}
                    >
                        <div style={{ width: 150 }}>
                            {defaultValues.map(
                                (item: IDateModule, i) => {
                                    return (
                                        <ListItemButton
                                            defaultValue=""
                                            style={{
                                                paddingRight: '5px',
                                                marginRight: '0px',
                                            }}
                                            selected={selectedDateIndex === i}
                                            key={i}
                                            onClick={() => {
                                                setSelectedDateIndex(i)
                                                handleChange(item)
                                            }}
                                        >
                                            {item.name}
                                        </ListItemButton>
                                    )
                                }
                            )}
                        </div>

                        <div style={{ width: 400, marginLeft: 5, opacity: props.disabled?0.6:undefined }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 20,
                                            width: '65px'
                                        }}
                                    >
                                        {dic[language]['begin_time']}
                                    </div>
                                    <DemoContainer
                                        sx={{
                                            paddingTop: '0px',
                                            height: 50,
                                            justifyContent: 'flex-end',
                                        }}
                                        components={[
                                            'DateTimePicker',
                                            'DateTimePicker',
                                        ]}
                                    >
                                        <DateTimePicker
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                },
                                            }}
                                            label={dic[language]['begin_time']}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: null,
                                            }}
                                            value={dayjs(startDateValue)}
                                            onChange={(e) => {
                                                dispatch(setDateSelectIndex(3))
                                                setDateRange({
                                                    ...dateRange,
                                                    startTime: dayjs(e).valueOf(),
                                                })
                                            }}
                                        />
                                    </DemoContainer>
                                </div>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 20,
                                            width: '65px'
                                        }}
                                    >
                                        {dic[language]['end_time']}
                                    </div>
                                    <DemoContainer
                                        sx={{
                                            paddingTop: '0px',
                                            height: 50,
                                            justifyContent: 'flex-end',
                                        }}
                                        components={[
                                            'DateTimePicker',
                                            'DateTimePicker',
                                        ]}
                                    >
                                        <DateTimePicker
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                },
                                            }}
                                            label={dic[language]['end_time']}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: null,
                                            }}
                                            value={dayjs(endDateValue)}
                                            onChange={(e) => {
                                                dispatch(setDateSelectIndex(3))
                                                setDateRange({
                                                    ...dateRange,
                                                    endTime: dayjs(e).valueOf(),
                                                })
                                            }}
                                        />
                                    </DemoContainer>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                </Select>
            </FormControl>
        </div>
    )
}

export default TimeSelector

