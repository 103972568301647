import { UseMutationResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useState, useEffect, useContext } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import { _analysisData } from 'redux/AnalysisSlice'
import { generateTimeListForChart } from 'page/analysis/useElasticQueryFactory'
import dayjs from 'dayjs'
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext'
import Chart from "react-apexcharts";
import { _displayMode } from 'redux/UtilSlice'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface IDotsForChart {
    name: string
    totalCount: number
}

interface AnalysisPageChartProps {
    toggle: () => void
    isOpen: boolean
}

const AnalysisPageChart = (props: AnalysisPageChartProps) => {
    const [dotsForChart, setDotsForChart] = useState<IDotsForChart[]>([])
    const analysisData = useSelector(_analysisData);
    const {fetchChart} = (useContext(AnalysisController) as TAnalysisController);
    const displayMode = useSelector(_displayMode);

    useEffect(() => {
        if (fetchChart.isSuccess && fetchChart.data !== null) {
            let timeList = generateTimeListForChart(
                analysisData.dateRange.startTime,
                analysisData.dateRange.endTime
            )
            if (timeList.length <= 0) return;
            let dotsListForChartInBoxQuery = []
            for (
                let i = 0;
                i < fetchChart.data.data.responses.length;
                i++
            ) {
                dotsListForChartInBoxQuery.push({
                    name: dayjs(timeList[i].startTime).format('YYYY/MM/DD HH:mm'),
                    totalCount: fetchChart.data.data.responses[i].hits.total.value
                })
            }
            setDotsForChart(dotsListForChartInBoxQuery)
        }
    }, [fetchChart])

    return (
        <div className="leftChart">
            <div className="header" style={{display: 'flex'}}>
                <span style={{flex: 1}}>
                    ELASTIC DOCUMENTS COUNT CHART
                </span>
                <KeyboardArrowUpIcon 
                    fontSize="inherit" 
                    className={`toogleIcon ${props.isOpen?'open':''}`}
                    onClick={() => props.toggle()}/>
            </div>
            {
                fetchChart.isLoading? 
                (
                    <div
                        style={{
                            height: '200px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress></CircularProgress>
                    </div>
                ) : 
                (
                    <Chart
                        options={{
                            chart: {
                                id: "analysis-chart",
                                toolbar: {
                                    show: false
                                },
                                type: 'area',
                                zoom: {
                                    enabled: false
                                }
                            },
                            stroke: {
                                curve: 'smooth',
                                width: 3,
                            },
                            xaxis: {
                                categories: dotsForChart.map(_ => _.name),
                                labels: {
                                    show: false
                                },
                                tooltip: {enabled: false}
                            },
                            yaxis: {
                                labels:{
                                    offsetX: -10,
                                    style: {
                                        colors: [displayMode==='dark'?'#F7F7F7B3':'#000000B3']
                                    }
                                }
                            },
                            tooltip: {
                                theme: displayMode,
                                marker: {show:false}
                            },
                            legend: {
                                show: true
                            },
                            grid: {
                                row:{
                                    opacity: 0.3
                                }
                            }
                        }}
                        series={[
                            {
                                name: "count",
                                data: dotsForChart.map(_ => _.totalCount)
                            }
                        ]}
                    />
                )
            }
        </div>
    )
}

export default AnalysisPageChart
