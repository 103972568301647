import SearchIcon from '@mui/icons-material/Search'
import * as React from 'react'
import Box from '@mui/material/Box'
import { InputAdornment, TextField } from '@mui/material'
import { setMainKeyword, _analysisData } from 'redux/AnalysisSlice'
import { useDispatch } from 'react-redux'
import { _displayMode, _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import { replaceInString, restoreInString } from 'util/index'
import dic from 'constant/dictionary'

interface IKeywordSearchInputProps {
    btnRef: React.RefObject<HTMLButtonElement>
}

const KeywordSearchInput = ({ btnRef }: IKeywordSearchInputProps) => {
    // 將打勾選項清空
    const displayMode = useSelector(_displayMode);
    const analysisData = useSelector(_analysisData);
    const dispatch = useDispatch()
    const language = useSelector(_language);

    return (
        <div className='optionsContainer' id="keyword-option">
            <span>
                {dic[language]['analysis_global_keyword_search']}
            </span>
            <Box
                sx={{
                    '& > :not(style)': {
                        m: 1,
                        width: '25ch',
                        backgroundColor: displayMode === 'dark' ? '' : '#fff',
                        display: 'flex',
                    },
                }}
            >
                <TextField
                    sx={{ width: 250 }}
                    id="input-with-icon-textfield"
                    label={dic[language]['analysis_global_keyword']}
                    value={restoreInString(analysisData.mainKeyword)}
                    size="small"
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === 'Enter') {
                            btnRef.current?.click();
                        }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch(setMainKeyword(replaceInString(event.target.value)))
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon onClick={() => { }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </div>
    )
}

export default KeywordSearchInput
