import {
    Stack,
    Button,
    Typography,
} from '@mui/material'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { useToast } from 'hook/useToast'
import SearchBarWithChips from '../../../../../component/SearchBarWithChips'
import { API, urlRoot } from 'constant/index'
import { usePost } from 'hook/usePost'
import { useAlert } from 'hook/useAlert'
import KeywordFormDialog from './KeywordFormDialog'
import { KeywordData } from '.'

interface TableToolbarProps {
    selectedList: readonly string[]
    searchKeywordList: string[]
    setSearchKeywordList: React.Dispatch<React.SetStateAction<string[]>>
    rows: KeywordData[]
    refetchDefaultKeywordsList: () => void
}

const TableToolbar = (props: TableToolbarProps) => {
    const {
        searchKeywordList,
        setSearchKeywordList,
        selectedList,
        rows,
        refetchDefaultKeywordsList,
    } = props;
    const language = useSelector(_language);
    const toast = useToast();
    const { showAlert } = useAlert();
    const deleteMutation = usePost();

    const handleDelete = () => {
        showAlert(dic[language]['setting_keyword_remove_hint'], () => {
            deleteMutation.mutate({
                method: 'delete',
                root: `${urlRoot}`,
                route: `${API.keywordlist}`,
                body: { ids: selectedList },
            }, {
                onSuccess: () => {
                    refetchDefaultKeywordsList();
                    toast.show({content: dic[language]['delete_success']});
                },
                onError: (error) => {
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        showAlert(dic[language]['delete_fail']);
                    } else {
                        console.log(error.response?.data.message)
                    }
                }
            })
        })
    }

    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant='body1' color='text.primary'>
                {dic[language]['checked']} {selectedList.length}
            </Typography>
            <SearchBarWithChips
                searchKeywordList={searchKeywordList}
                setSearchKeywordList={setSearchKeywordList}
                chipLength={5}
            />
            <Stack
                direction="row"
                spacing={2}
            >
                <KeywordFormDialog
                    titleText={dic[language]['add']}
                    inputLableList={[dic[language]['setting_keyword_list_name'], dic[language]['setting_keyword_list_reason']]}
                    refetchDefaultKeywordsList={refetchDefaultKeywordsList}
                    rows={rows}
                />
                <Button
                    color="error"
                    variant="contained"
                    onClick={handleDelete}
                >
                    {dic[language]['setting_keyword_remove']}
                </Button>
            </Stack>
        </Stack>
    )
}

export default TableToolbar