import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice';
import { useSelector } from 'react-redux';


interface SortBtnProps {
    handleSortBtn?: (sortOrder: 'asc' | 'desc') => void,
    setOrder?: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>,
}

const SortBtn = (props: SortBtnProps) => {
    const language = useSelector(_language);
    const { handleSortBtn, setOrder } = props
    const [moreSelected, setMoreSelected] = useState(true)
    const [lessSelected, setLessSelected] = useState(false)

    return (
        <div>
            <ToggleButton
                value="more"
                component="span"
                selected={moreSelected}
                onChange={() => {
                    if (lessSelected) setLessSelected(!lessSelected)
                    setMoreSelected(!moreSelected)
                    if (handleSortBtn) handleSortBtn('desc')
                    if (setOrder) setOrder('desc')
                }}
                sx={{
                    color: 'rgba(31, 31, 31, 0.8)',
                    background: 'rgba(255, 255, 255, 1)',
                    "&.Mui-selected, &.Mui-selected:hover, &:hover": {
                        color: 'rgba(31, 31, 31, 0.8)',
                        background: 'rgba(242, 252, 255, 1)',
                    },
                    height: '24px',
                    minWidth: '30px',
                    padding: '0px',
                    border: '1px solid rgba(222, 222, 222, 1)',
                    borderRadius: '5px 0px 0px 5px',
                }}
            >
                {dic[language]['more']}
            </ToggleButton>
            <ToggleButton
                value="less"
                component="span"
                selected={lessSelected}
                onChange={() => {
                    if (moreSelected) setMoreSelected(!moreSelected)
                    setLessSelected(!lessSelected)
                    if (handleSortBtn) handleSortBtn('asc')
                    if (setOrder) setOrder('asc')
                }}
                sx={{
                    color: 'rgba(31, 31, 31, 0.8)',
                    background: 'rgba(255, 255, 255, 1)',
                    "&.Mui-selected, &.Mui-selected:hover, &:hover": {
                        color: 'rgba(31, 31, 31, 0.8)',
                        background: 'rgba(242, 252, 255, 1)',
                    },
                    height: '24px',
                    minWidth: '30px',
                    padding: '0px',
                    border: '1px solid rgba(222, 222, 222, 1)',
                    borderRadius: '0px 5px 5px 0px',
                }}
            >
                {dic[language]['less']}
            </ToggleButton>
        </div>
    )
}

export default SortBtn