import { useState } from 'react'
import '../../index.scss'
import HeaderNavigation from '../../component/HeaderNavigation'
import BlackListTableBox, { BlackWhiteListData } from './BlackListTableBox'
import WhiteListTableBox from './WhiteListTableBox'
import ToolSetting, { HackListData } from './ToolSetting'
import KeywordList from './KeywordList'
import { urlRoot, API } from 'constant/index'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'

export interface HeadCell {
    id: keyof BlackWhiteListData | keyof HackListData
    label: string
    numeric: boolean
    minWidth: number
}

const SettingWhiteList = () => {
    const language = useSelector(_language);
    const [header, setHeader] = useState(dic[language]['setting_blacklist']);

    const HeaderList: string[] = [
        dic[language]['setting_blacklist'],
        dic[language]['setting_whitelist'],
        dic[language]['setting_hacker_tool'],
        dic[language]['setting_keyword_list'],
    ];

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>
                {dic[language]['setting_white_black_keyword_list']}
            </h2>
            <HeaderNavigation
                header={header}
                setHeader={setHeader}
                HeaderList={HeaderList}
            />
            <div className='NavigationBody'>
                {header === dic[language]['setting_blacklist'] && (
                    <BlackListTableBox
                        root={`${urlRoot}`}
                        route={`${API.blackList}`}
                    />
                )}
                {header === dic[language]['setting_whitelist'] && (
                    <WhiteListTableBox
                        root={`${urlRoot}`}
                        route={`${API.whiteList}`}
                    />
                )}
                {header === dic[language]['setting_hacker_tool'] && (
                    <ToolSetting
                        root={`${urlRoot}`}
                        route={`${API.hackList}`}
                    />
                )}
                {header === dic[language]['setting_keyword_list'] && (
                    <KeywordList />
                )}
            </div>
        </div>
    )
}

export default SettingWhiteList
