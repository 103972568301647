/** @format */

import { Box, Button } from '@mui/material'
import React, { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { API, urlRoot } from 'constant/index'

import ActionButtonLoading from 'component/PartialLoading/ActionButtonLoading'
import { IFormatedDevice } from 'interface'
import { updateGridInitial } from 'util/index'
import { useAlert } from 'hook/useAlert'

import { useDispatch, useSelector } from 'react-redux'
import {
    _selectedOption,
    title2name,
} from 'redux/DetectSlice'
import { _selectedID, setDialogOpen } from 'redux/DetectSlice'
import { axiosClient } from 'util/index'
import { _language } from 'redux/UtilSlice'
import dic, { TLanguages } from 'constant/dictionary'
import { _SelectedImageType } from 'redux/SettingSlice'
import { AxiosError } from 'axios'

interface PActionButton {
    name?: string
    isColor?: boolean
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
    ubuntuNumber?: number
    onClose?: () => void
}

// The action button is used to send mission to the device,
// and the theme will be shared with normal mission and stop permission.
// the action Type = "記憶體" | "痕跡取證" | "檔案總表" | "關鍵映像檔" | "任務執行"

const ActionButton = (props: PActionButton) => {
    const alert = useAlert().showAlert
    const { name, isColor, setData, data, ubuntuNumber, onClose } = props
    const dispatch = useDispatch()
    const language = useSelector(_language)
    const selectedImageType = useSelector(_SelectedImageType)

    const option = useSelector(_selectedOption)
    const ids = useSelector(_selectedID)

    const ubuntuFlag = ubuntuNumber === undefined ? 0 : ubuntuNumber

    // TODO: Task 可能還會有新的
    const processMissionFilter = (option: string, language: TLanguages) => {
        if (option === dic[language]['detect_task_type_memory']) {
            return "Ring0Process"
        } else if (option === dic[language]['detect_task_type_collect'] ||
            option === dic[language]['detect_task_type_drive'] ||
            option === dic[language]['detect_task_type_yara'] ||
            option === dic[language]['detect_task_type_memory_tree'] ||
            option === dic[language]['detect_task_terminate']) {
            return "null"
        } else if (option === dic[language]['detect_task_type_image']) {
            return selectedImageType
        } else {
            return "error"
        }
    }

    const actionRequest = useMutation({
        mutationKey: ['missionRequest'],
        mutationFn: () => {
            return axiosClient.post(`${urlRoot}${API.detectMission}`, {
                action: title2name(option, language).axiosTitle,
                deviceId: ids,
                filter: processMissionFilter(option, language),
            })
        },
        onSuccess: (res) => {
            setData(data.map((obj) =>
                updateGridInitial(obj, ids, conditionalMission(option, language))
            ))
            onClose && onClose()
        },
        onError: (err: AxiosError<any, any>) => {
            alert(err.response?.data?.message || dic[language]['detect_task_failed'])
        }
    })

    const handleFetchMission = () => {
        
        if (option === dic[language]['detect_task_type_yara'] &&
            data.filter(row => ids.includes(row.deviceId))
                .filter((row: IFormatedDevice) =>
                    typeof row.fileFinishTime === "number" ||
                    row.fileFinishTime === 'Pending...' )
                .length > 0) {
            alert("Yara rule scanning task will be started after Drive scan is finished.", () => {
                actionRequest.mutate()
            });
            return;
        }
        if (option === dic[language]['detect_task_type_drive'] &&
            data.filter(row => ids.includes(row.deviceId))
                .filter((row: IFormatedDevice) =>
                    typeof row.yaraRuleFinishTime === "number" ||
                    row.yaraRuleFinishTime === 'Pending...')
                .length > 0) {
            alert("Explorer scanning task will be started after Yara rule scan is finished.", () => {
                actionRequest.mutate()
            });
            return;
        }
        if (option === dic[language]['detect_task_type_yara']) {
            alert("Yara rule scan is based on previous snapshot of scanning explorer, we suggest scan explorer before executing yara task in order to get most accurate result.", () => {
                actionRequest.mutate()
            })
            return;
        }
        actionRequest.mutate()
    }

    // if the action is stop, then the page will alert the user .
    useEffect(() => {
        const handleCloseDialog = () => {
            const timer = setTimeout(() => {
                if (actionRequest.isSuccess) {
                    dispatch(setDialogOpen(false))
                } else if (actionRequest.isError) {
                    dispatch(setDialogOpen(false))
                }
            }, 2000)
            return () => clearTimeout(timer)
        }
        handleCloseDialog()
    }, [actionRequest.isSuccess, actionRequest.isError])

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
            <Button
                color={isColor ? 'error' : 'primary'}
                variant={option === dic[language]['detect_task_type_image'] ? 'text' : 'contained'}
                disabled={ids.length === 0 || actionRequest.isLoading || ubuntuFlag > 0}
                onClick={() => {
                    handleFetchMission()
                }}
            >
                {name ? name : title2name(option, language).buttonTitle}
            </Button>
            <ActionButtonLoading loading={actionRequest.isLoading} />
        </Box>
    )
}

export default ActionButton

const conditionalMission = (option: string, language: TLanguages): Partial<IFormatedDevice> => {
    if (option === dic[language]['detect_task_type_memory']) {
        return { scanFinishTime: 'Pending...' }
    } else if (option === dic[language]['detect_task_type_collect']) {
        return { collectFinishTime: 'Pending...' }
    } else if (option === dic[language]['detect_task_type_drive']) {
        return { fileFinishTime: 'Pending...' }
    } else if (option === dic[language]['detect_task_type_image']) {
        return { imageFinishTime: 'Pending...' }
    } else if (option === dic[language]['detect_task_type_yara']) {
        return { yaraRuleFinishTime: 'Pending...' }
    } else if (option === dic[language]['detect_task_type_memory_tree']) {
        return { memoryTreeFinishTime: 'Pending...' }
    } else {
        return {
            scanFinishTime: dic[language]['detect_task_terminating'],
            collectFinishTime: dic[language]['detect_task_terminating'],
            fileFinishTime: dic[language]['detect_task_terminating'],
            imageFinishTime: dic[language]['detect_task_terminating'],
            yaraRuleFinishTime: dic[language]['detect_task_terminating'],
        }
    }
}
