// TODO: make the length of the chips to be calculated by the width of the container
import {
    Popover,
    Toolbar,
    IconButton,
    List,
    ListItem,
} from '@mui/material'
import { ChangeEvent, useState } from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import SearchBar from './SearchBar'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

interface SearchBarWithChipsProps {
    searchKeywordList: string[]
    setSearchKeywordList: React.Dispatch<React.SetStateAction<string[]>>
    chipLength: number
}

const SearchBarWithChips = (props: SearchBarWithChipsProps) => {
    const {
        searchKeywordList,
        setSearchKeywordList,
        chipLength,
    } = props
    const language = useSelector(_language)
    const [hint, setHint] = useState<string>(dic[language]["search"])
    const [key, setKey] = useState<string>('')

    const searchKeyWords = (key: string) => {
        if (key === '') {
            setHint(dic[language]["search"])
            return
        }
        if (!searchKeywordList.includes(key)) {
            setSearchKeywordList([...searchKeywordList, key])
        }
    }

    const onChangeFunc = (e: ChangeEvent<HTMLInputElement>) => {
        setHint(dic[language]["search"])
        setKey(e.target.value)
    }

    return (
        <Toolbar
            disableGutters
            sx={{ flex: 1 }}
        >
            <SearchBar
                width={200}
                labelValue={hint}
                query={key}
                setQuery={setKey}
                onChangeFunc={onChangeFunc}
                onSearchFunc={searchKeyWords}
            />
            <SubKeywordBox
                dataForDisplay={searchKeywordList}
                setDataForDisplay={setSearchKeywordList}
                displayCount={chipLength}
            />
        </Toolbar>
    )
}

interface ISubKeywordBoxProps {
    dataForDisplay: string[]
    setDataForDisplay: React.Dispatch<React.SetStateAction<string[]>>
    displayCount: number
}

const SubKeywordBox = (props: ISubKeywordBoxProps) => {
    const { dataForDisplay, setDataForDisplay, displayCount } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
    const open = Boolean(anchorEl);

    const handleDelete = (name: string) => {
        let newSubSearchKeywordList = dataForDisplay.filter(
            (item: string) => item !== name
        )
        setDataForDisplay(newSubSearchKeywordList)
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Stack direction="row" spacing={1} alignItems='center'>
            {dataForDisplay.map((item: string, index: number) => {
                if (index >= displayCount) {
                    return null
                }
                return (
                    <Chip
                        key={index}
                        label={item}
                        onDelete={() => {
                            handleDelete(item)
                        }}
                    />
                )
            })}
            {displayCount < dataForDisplay.length && (
                <>
                    <IconButton onClick={handleClick}>
                        <MoreHorizIcon fontSize='small' />
                    </IconButton>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <List>
                            {dataForDisplay.map((item: string, index: number) => {
                                if (index < displayCount) {
                                    return null
                                }
                                return (
                                    <ListItem key={index}>
                                        <Chip
                                            label={item}
                                            onDelete={() => {
                                                handleDelete(item)
                                            }}
                                        />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Popover>
                </>
            )}
        </Stack>
    )
}

export default SearchBarWithChips;
