import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { UseMutationResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { _analysisData } from 'redux/AnalysisSlice'
import { DynamicObject, stringArray2IAnalaysisNodeArray } from './util'
import { ILastDataInEachPage } from '.'
import { useSelector } from 'react-redux'
import { _displayMode, _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { useContext, useEffect } from 'react'
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext'
import { SortModel } from 'redux/NetworkSlice'

interface IDridFooter {
    totalCount: number
    gridData: DynamicObject[]
    lastDataInEachPage: any[]
    setLastDataInEachPage: React.Dispatch<React.SetStateAction<ILastDataInEachPage[]>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    sort: SortModel
}

const GridFooter = (props: IDridFooter) => {
    const {
        totalCount,
        gridData,
        lastDataInEachPage,
        setLastDataInEachPage,
        pageNumber,
        setPageNumber,
        sort
    } = props;

    const controller = (useContext(AnalysisController) as TAnalysisController);

    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);
    const analysisData = useSelector(_analysisData);

    const onePageCount = 1000
    const everyPageLastUUID: string[] = []
    const lastUUID = gridData[gridData.length - 1].uuid

    const totalPage = Math.ceil(totalCount / onePageCount) // 3頁  分別是 0 1 2


    const startCount = pageNumber * onePageCount + 1
    const endCount = (pageNumber + 1) * onePageCount > totalCount ? totalCount : (pageNumber + 1) * onePageCount

    function handlePageChange(pageChange: number) {
        try {
            everyPageLastUUID.push(lastUUID)
            const selectedItemName = analysisData.leftSelectedList.selectedIndex;
            let indexList = stringArray2IAnalaysisNodeArray(selectedItemName);
            if (indexList.length === 0) {
                indexList = analysisData.indexList
            }
            
            const lastRow = pageChange === 1?
                gridData[gridData.length - 1]:
                lastDataInEachPage[pageNumber+pageChange];

            const nextPageNumber = pageNumber + pageChange;
            if (nextPageNumber >= 0 && nextPageNumber < totalPage) {
                if (sort === null || sort.field === ''){ 
                    controller.mutateAfter(indexList, {
                        lastUUID: lastRow.uuid,
                        lastMainDate: lastRow.date_main
                    })
                } else {
                    controller.mutateAfter(indexList, {
                        lastUUID: lastRow.uuid,
                        lastMainDate: lastRow.date_main,
                        lastSort: lastRow[sort.field]
                    }, sort)
                }
                const pageList = [...lastDataInEachPage, lastRow]
                setPageNumber(pageNumber + pageChange)
                pageChange === 1 && lastDataInEachPage.length - 1 === pageNumber && setLastDataInEachPage(pageList)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div
            style={{
                width: '100%',
                backgroundColor: displayMode === 'dark' ? 'black' : 'white',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'flex-end',
                height: 40,
                alignItems: 'center',
                border: displayMode === 'dark' ? 'rgba(81, 81, 81, 1) 1px solid' : 'rgba(224, 224, 224, 1) 1px solid',
                borderTop: 'none',
                borderRadius: '0 0 3px 3px'
            }}
        >
            <p style={{ marginRight: 20, opacity: 0.75 }}>
                {dic[language]['analysis_table_current_rows']} &nbsp;{startCount} - {endCount} / {dic[language]['analysis_table_total_rows']} &nbsp;
                {totalCount}
            </p>
            <div
                style={{
                    margin: 'auto 20px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    handlePageChange(-1)
                }}
            >
                <KeyboardArrowLeftIcon />
            </div>
            <div
                style={{
                    margin: 'auto 20px',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    handlePageChange(1)
                }}
            >
                <KeyboardArrowRightIcon />
            </div>
        </div>
    )
}

export default GridFooter
