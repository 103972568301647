interface CountryData {
    [key: string]: {
        lat: number,
        long: number,
        name: string,
    }
}

export const countryData: CountryData = {
    "AD": {
        "name": "安道爾",
        "lat": 42.5,
        "long": 1.6
    },
    "AE": {
        "name": "阿聯",
        "lat": 24,
        "long": 54
    },
    "AF": {
        "name": "阿富汗",
        "lat": 33,
        "long": 65
    },
    "AG": {
        "name": "安提瓜和巴布達",
        "lat": 17.05,
        "long": -61.8
    },
    "AI": {
        "name": "英屬安圭拉",
        "lat": 18.25,
        "long": -63.1667
    },
    "AL": {
        "name": "阿爾巴尼亞",
        "lat": 41,
        "long": 20
    },
    "AM": {
        "name": "亞美尼亞",
        "lat": 40,
        "long": 45
    },
    "AO": {
        "name": "安哥拉",
        "lat": -12.5,
        "long": 18.5
    },
    "AQ": {
        "name": "南極洲",
        "lat": -90,
        "long": 0
    },
    "AR": {
        "name": "阿根廷",
        "lat": -34,
        "long": -64
    },
    "AS": {
        "name": "美屬薩摩亞",
        "lat": -14.3333,
        "long": -170
    },
    "AT": {
        "name": "奧地利",
        "lat": 47.3333,
        "long": 13.3333
    },
    "AU": {
        "name": "澳洲",
        "lat": -27,
        "long": 133
    },
    "AW": {
        "name": "阿魯巴",
        "lat": 12.5,
        "long": -69.9667
    },
    "AZ": {
        "name": "亞塞拜然",
        "lat": 40.5,
        "long": 47.5
    },
    "BA": {
        "name": "波赫",
        "lat": 44,
        "long": 18
    },
    "BB": {
        "name": "巴貝多",
        "lat": 13.1667,
        "long": -59.5333
    },
    "BD": {
        "name": "孟加拉",
        "lat": 24,
        "long": 90
    },
    "BE": {
        "name": "比利時",
        "lat": 50.8333,
        "long": 4
    },
    "BF": {
        "name": "布吉納法索",
        "lat": 13,
        "long": -2
    },
    "BG": {
        "name": "保加利亞",
        "lat": 43,
        "long": 25
    },
    "BH": {
        "name": "巴林",
        "lat": 26,
        "long": 50.55
    },
    "BI": {
        "name": "蒲隆地",
        "lat": -3.5,
        "long": 30
    },
    "BJ": {
        "name": "貝南",
        "lat": 9.5,
        "long": 2.25
    },
    "BM": {
        "name": "百慕達",
        "lat": 32.3333,
        "long": -64.75
    },
    "BN": {
        "name": "汶萊",
        "lat": 4.5,
        "long": 114.6667
    },
    "BO": {
        "name": "玻利維亞",
        "lat": -17,
        "long": -65
    },
    "BR": {
        "name": "巴西",
        "lat": -10,
        "long": -55
    },
    "BS": {
        "name": "巴哈馬",
        "lat": 24.25,
        "long": -76
    },
    "BT": {
        "name": "不丹",
        "lat": 27.5,
        "long": 90.5
    },
    "BV": {
        "name": "布威島",
        "lat": -54.4333,
        "long": 3.4
    },
    "BW": {
        "name": "波札那",
        "lat": -22,
        "long": 24
    },
    "BY": {
        "name": "白俄羅斯",
        "lat": 53,
        "long": 28
    },
    "BZ": {
        "name": "貝里斯",
        "lat": 17.25,
        "long": -88.75
    },
    "CA": {
        "name": "加拿大",
        "lat": 60,
        "long": -95
    },
    "CC": {
        "name": "可可斯群島",
        "lat": -12.5,
        "long": 96.8333
    },
    "CD": {
        "name": "民主剛果",
        "lat": 0,
        "long": 25
    },
    "CF": {
        "name": "中非",
        "lat": 7,
        "long": 21
    },
    "CG": {
        "name": "剛果",
        "lat": -1,
        "long": 15
    },
    "CH": {
        "name": "瑞士",
        "lat": 47,
        "long": 8
    },
    "CI": {
        "name": "象牙海岸",
        "lat": 8,
        "long": -5
    },
    "CK": {
        "name": "庫克群島",
        "lat": -21.2333,
        "long": -159.7667
    },
    "CL": {
        "name": "智利",
        "lat": -30,
        "long": -71
    },
    "CM": {
        "name": "喀麥隆",
        "lat": 6,
        "long": 12
    },
    "CN": {
        "name": "中國",
        "lat": 35,
        "long": 105
    },
    "CO": {
        "name": "哥倫比亞",
        "lat": 4,
        "long": -72
    },
    "CR": {
        "name": "哥斯大黎加",
        "lat": 10,
        "long": -84
    },
    "CU": {
        "name": "古巴",
        "lat": 21.5,
        "long": -80
    },
    "CV": {
        "name": "維德角",
        "lat": 16,
        "long": -24
    },
    "CX": {
        "name": "聖誕島",
        "lat": -10.5,
        "long": 105.6667
    },
    "CY": {
        "name": "塞普勒斯",
        "lat": 35,
        "long": 33
    },
    "CZ": {
        "name": "捷克",
        "lat": 49.75,
        "long": 15.5
    },
    "DE": {
        "name": "德國",
        "lat": 51,
        "long": 9
    },
    "DJ": {
        "name": "吉布地",
        "lat": 11.5,
        "long": 43
    },
    "DK": {
        "name": "丹麥",
        "lat": 56,
        "long": 10
    },
    "DM": {
        "name": "多米尼克",
        "lat": 15.4167,
        "long": -61.3333
    },
    "DO": {
        "name": "多明尼加",
        "lat": 19,
        "long": -70.6667
    },
    "DZ": {
        "name": "阿爾及利亞",
        "lat": 28,
        "long": 3
    },
    "EC": {
        "name": "厄瓜多",
        "lat": -2,
        "long": -77.5
    },
    "EE": {
        "name": "愛沙尼亞",
        "lat": 59,
        "long": 26
    },
    "EG": {
        "name": "埃及",
        "lat": 27,
        "long": 30
    },
    "EH": {
        "name": "西撒哈拉",
        "lat": 24.5,
        "long": -13
    },
    "ER": {
        "name": "厄立垂亞",
        "lat": 15,
        "long": 39
    },
    "ES": {
        "name": "西班牙",
        "lat": 40,
        "long": -4
    },
    "ET": {
        "name": "衣索比亞",
        "lat": 8,
        "long": 38
    },
    "FI": {
        "name": "芬蘭",
        "lat": 64,
        "long": 26
    },
    "FJ": {
        "name": "斐濟",
        "lat": -18,
        "long": 175
    },
    "FK": {
        "name": "福克蘭群島",
        "lat": -51.75,
        "long": -59
    },
    "FM": {
        "name": "密克羅尼西亞聯邦",
        "lat": 6.9167,
        "long": 158.25
    },
    "FO": {
        "name": "法羅群島",
        "lat": 62,
        "long": -7
    },
    "FR": {
        "name": "法國",
        "lat": 46,
        "long": 2
    },
    "GA": {
        "name": "加彭",
        "lat": -1,
        "long": 11.75
    },
    "GB": {
        "name": "英國",
        "lat": 54,
        "long": -2
    },
    "GD": {
        "name": "格瑞那達",
        "lat": 12.1167,
        "long": -61.6667
    },
    "GE": {
        "name": "喬治亞",
        "lat": 42,
        "long": 43.5
    },
    "GF": {
        "name": "法屬圭亞那",
        "lat": 4,
        "long": -53
    },
    "GG": {
        "name": "根息島",
        "lat": 49.5,
        "long": -2.56
    },
    "GH": {
        "name": "迦納",
        "lat": 8,
        "long": -2
    },
    "GI": {
        "name": "直布羅陀",
        "lat": 36.1833,
        "long": -5.3667
    },
    "GL": {
        "name": "格陵蘭",
        "lat": 72,
        "long": -40
    },
    "GM": {
        "name": "甘比亞",
        "lat": 13.4667,
        "long": -16.5667
    },
    "GN": {
        "name": "幾內亞",
        "lat": 11,
        "long": -10
    },
    "GP": {
        "name": "瓜德魯普島",
        "lat": 16.25,
        "long": -61.5833
    },
    "GQ": {
        "name": "赤道幾內亞",
        "lat": 2,
        "long": 10
    },
    "GR": {
        "name": "希臘",
        "lat": 39,
        "long": 22
    },
    "GS": {
        "name": "南喬治亞與南三明治群島",
        "lat": -54.5,
        "long": -37
    },
    "GT": {
        "name": "瓜地馬拉",
        "lat": 15.5,
        "long": -90.25
    },
    "GU": {
        "name": "關島",
        "lat": 13.4667,
        "long": 144.7833
    },
    "GW": {
        "name": "幾內亞比索",
        "lat": 12,
        "long": -15
    },
    "GY": {
        "name": "蓋亞那",
        "lat": 5,
        "long": -59
    },
    "HK": {
        "name": "香港",
        "lat": 22.25,
        "long": 114.1667
    },
    "HM": {
        "name": "赫德及麥當勞群島",
        "lat": -53.1,
        "long": 72.5167
    },
    "HN": {
        "name": "洪都拉斯",
        "lat": 15,
        "long": -86.5
    },
    "HR": {
        "name": "克羅地亞",
        "lat": 45.1667,
        "long": 15.5
    },
    "HT": {
        "name": "海地",
        "lat": 19,
        "long": -72.4167
    },
    "HU": {
        "name": "匈牙利",
        "lat": 47,
        "long": 20
    },
    "ID": {
        "name": "印尼",
        "lat": -5,
        "long": 120
    },
    "IE": {
        "name": "愛爾蘭",
        "lat": 53,
        "long": -8
    },
    "IL": {
        "name": "以色列",
        "lat": 31.5,
        "long": 34.75
    },
    "IM": {
        "name": "曼島",
        "lat": 54.23,
        "long": -4.55
    },
    "IN": {
        "name": "印度",
        "lat": 20,
        "long": 77
    },
    "IO": {
        "name": "英屬印度洋地區",
        "lat": -6,
        "long": 71.5
    },
    "IQ": {
        "name": "伊拉克",
        "lat": 33,
        "long": 44
    },
    "IR": {
        "name": "伊朗",
        "lat": 32,
        "long": 53
    },
    "IS": {
        "name": "冰島",
        "lat": 65,
        "long": -18
    },
    "IT": {
        "name": "義大利",
        "lat": 42.8333,
        "long": 12.8333
    },
    "JE": {
        "name": "澤西島",
        "lat": 49.21,
        "long": -2.13
    },
    "JM": {
        "name": "牙買加",
        "lat": 18.25,
        "long": -77.5
    },
    "JO": {
        "name": "約旦",
        "lat": 31,
        "long": 36
    },
    "JP": {
        "name": "日本",
        "lat": 36,
        "long": 138
    },
    "KE": {
        "name": "肯亞",
        "lat": 1,
        "long": 38
    },
    "KG": {
        "name": "吉爾吉斯",
        "lat": 41,
        "long": 75
    },
    "KH": {
        "name": "柬埔寨",
        "lat": 13,
        "long": 105
    },
    "KI": {
        "name": "吉里巴斯",
        "lat": 1.4167,
        "long": 173
    },
    "KM": {
        "name": "葛摩",
        "lat": -12.1667,
        "long": 44.25
    },
    "KN": {
        "name": "聖克里斯多福及尼維斯",
        "lat": 17.3333,
        "long": -62.75
    },
    "KP": {
        "name": "北韓",
        "lat": 40,
        "long": 127
    },
    "KR": {
        "name": "南韓",
        "lat": 37,
        "long": 127.5
    },
    "KW": {
        "name": "科威特",
        "lat": 29.3375,
        "long": 47.6581
    },
    "KY": {
        "name": "開曼群島",
        "lat": 19.5,
        "long": -80.5
    },
    "KZ": {
        "name": "哈薩克",
        "lat": 48,
        "long": 68
    },
    "LA": {
        "name": "寮國",
        "lat": 18,
        "long": 105
    },
    "LB": {
        "name": "黎巴嫩",
        "lat": 33.8333,
        "long": 35.8333
    },
    "LC": {
        "name": "聖露西亞",
        "lat": 13.8833,
        "long": -61.1333
    },
    "LI": {
        "name": "列支敦斯登",
        "lat": 47.1667,
        "long": 9.5333
    },
    "LK": {
        "name": "斯里蘭卡",
        "lat": 7,
        "long": 81
    },
    "LR": {
        "name": "賴比瑞亞",
        "lat": 6.5,
        "long": -9.5
    },
    "LS": {
        "name": "賴索托",
        "lat": -29.5,
        "long": 28.5
    },
    "LT": {
        "name": "立陶宛",
        "lat": 56,
        "long": 24
    },
    "LU": {
        "name": "盧森堡",
        "lat": 49.75,
        "long": 6.1667
    },
    "LV": {
        "name": "拉脫維亞",
        "lat": 57,
        "long": 25
    },
    "LY": {
        "name": "利比亞",
        "lat": 25,
        "long": 17
    },
    "MA": {
        "name": "摩洛哥",
        "lat": 32,
        "long": -5
    },
    "MC": {
        "name": "摩納哥",
        "lat": 43.7333,
        "long": 7.4
    },
    "MD": {
        "name": "摩爾多瓦",
        "lat": 47,
        "long": 29
    },
    "ME": {
        "name": "蒙特內哥羅",
        "lat": 42,
        "long": 19
    },
    "MG": {
        "name": "馬達加斯加",
        "lat": -20,
        "long": 47
    },
    "MH": {
        "name": "馬紹爾群島",
        "lat": 9,
        "long": 168
    },
    "MK": {
        "name": "馬其頓",
        "lat": 41.8333,
        "long": 22
    },
    "ML": {
        "name": "馬利",
        "lat": 17,
        "long": -4
    },
    "MM": {
        "name": "緬甸",
        "lat": 22,
        "long": 98
    },
    "MN": {
        "name": "蒙古",
        "lat": 46,
        "long": 105
    },
    "MO": {
        "name": "澳門",
        "lat": 22.1667,
        "long": 113.55
    },
    "MP": {
        "name": "北馬里亞納群島",
        "lat": 15.2,
        "long": 145.75
    },
    "MQ": {
        "name": "法屬馬丁尼克",
        "lat": 14.6667,
        "long": -61
    },
    "MR": {
        "name": "茅利塔尼亞",
        "lat": 20,
        "long": -12
    },
    "MS": {
        "name": "蒙塞拉特島",
        "lat": 16.75,
        "long": -62.2
    },
    "MT": {
        "name": "馬耳他",
        "lat": 35.8333,
        "long": 14.5833
    },
    "MU": {
        "name": "模里西斯",
        "lat": -20.2833,
        "long": 57.55
    },
    "MV": {
        "name": "馬爾地夫",
        "lat": 3.25,
        "long": 73
    },
    "MW": {
        "name": "馬拉威",
        "lat": -13.5,
        "long": 34
    },
    "MX": {
        "name": "墨西哥",
        "lat": 23,
        "long": -102
    },
    "MY": {
        "name": "馬來西亞",
        "lat": 2.5,
        "long": 112.5
    },
    "MZ": {
        "name": "莫三比克",
        "lat": -18.25,
        "long": 35
    },
    "NA": {
        "name": "奈米比亞",
        "lat": -22,
        "long": 17
    },
    "NC": {
        "name": "新喀里多尼亞島",
        "lat": -21.5,
        "long": 165.5
    },
    "NE": {
        "name": "尼日",
        "lat": 16,
        "long": 8
    },
    "NF": {
        "name": "諾福克島",
        "lat": -29.0333,
        "long": 167.95
    },
    "NG": {
        "name": "奈及利亞",
        "lat": 10,
        "long": 8
    },
    "NI": {
        "name": "尼加拉瓜",
        "lat": 13,
        "long": -85
    },
    "NL": {
        "name": "荷蘭",
        "lat": 52.5,
        "long": 5.75
    },
    "NO": {
        "name": "挪威",
        "lat": 62,
        "long": 10
    },
    "NP": {
        "name": "尼泊爾",
        "lat": 28,
        "long": 84
    },
    "NR": {
        "name": "諾魯",
        "lat": -0.5333,
        "long": 166.9167
    },
    "NU": {
        "name": "紐埃",
        "lat": -19.0333,
        "long": -169.8667
    },
    "NZ": {
        "name": "紐西蘭",
        "lat": -41,
        "long": 174
    },
    "OM": {
        "name": "阿曼",
        "lat": 21,
        "long": 57
    },
    "PA": {
        "name": "巴拿馬",
        "lat": 9,
        "long": -80
    },
    "PE": {
        "name": "秘魯",
        "lat": -10,
        "long": -76
    },
    "PF": {
        "name": "法屬玻里尼西亞",
        "lat": -15,
        "long": -140
    },
    "PG": {
        "name": "巴布亞新幾內亞",
        "lat": -6,
        "long": 147
    },
    "PH": {
        "name": "菲律賓",
        "lat": 13,
        "long": 122
    },
    "PK": {
        "name": "巴基斯坦",
        "lat": 30,
        "long": 70
    },
    "PL": {
        "name": "波蘭",
        "lat": 52,
        "long": 20
    },
    "PM": {
        "name": "聖皮耶與密克隆群島",
        "lat": 46.8333,
        "long": -56.3333
    },
    "PN": {
        "name": "皮特康島",
        "lat": -24.7,
        "long": -127.4
    },
    "PR": {
        "name": "波多黎各",
        "lat": 18.25,
        "long": -66.5
    },
    "PS": {
        "name": "巴勒斯坦",
        "lat": 32,
        "long": 35.25
    },
    "PT": {
        "name": "葡萄牙",
        "lat": 39.5,
        "long": -8
    },
    "PW": {
        "name": "帛琉",
        "lat": 7.5,
        "long": 134.5
    },
    "PY": {
        "name": "巴拉圭",
        "lat": -23,
        "long": -58
    },
    "QA": {
        "name": "卡達",
        "lat": 25.5,
        "long": 51.25
    },
    "RE": {
        "name": "留尼旺",
        "lat": -21.1,
        "long": 55.6
    },
    "RO": {
        "name": "羅馬尼亞",
        "lat": 46,
        "long": 25
    },
    "RS": {
        "name": "塞爾維亞",
        "lat": 44,
        "long": 21
    },
    "RU": {
        "name": "俄羅斯",
        "lat": 60,
        "long": 100
    },
    "RW": {
        "name": "盧安達",
        "lat": -2,
        "long": 30
    },
    "SA": {
        "name": "沙烏地阿拉伯",
        "lat": 25,
        "long": 45
    },
    "SB": {
        "name": "所羅門群島",
        "lat": -8,
        "long": 159
    },
    "SC": {
        "name": "塞席爾",
        "lat": -4.5833,
        "long": 55.6667
    },
    "SD": {
        "name": "蘇丹",
        "lat": 15,
        "long": 30
    },
    "SE": {
        "name": "瑞典",
        "lat": 62,
        "long": 15
    },
    "SG": {
        "name": "新加坡",
        "lat": 1.3667,
        "long": 103.8
    },
    "SH": {
        "name": "聖赫勒拿島",
        "lat": -15.9333,
        "long": -5.7
    },
    "SI": {
        "name": "斯洛維尼亞",
        "lat": 46,
        "long": 15
    },
    "SJ": {
        "name": "斯瓦巴及尖棉島",
        "lat": 78,
        "long": 20
    },
    "SK": {
        "name": "斯洛伐克",
        "lat": 48.6667,
        "long": 19.5
    },
    "SL": {
        "name": "獅子山",
        "lat": 8.5,
        "long": -11.5
    },
    "SM": {
        "name": "聖馬利諾",
        "lat": 43.7667,
        "long": 12.4167
    },
    "SN": {
        "name": "塞內加爾",
        "lat": 14,
        "long": -14
    },
    "SO": {
        "name": "索馬利亞",
        "lat": 10,
        "long": 49
    },
    "SR": {
        "name": "蘇利南",
        "lat": 4,
        "long": -56
    },
    "SS": {
        "name": "南蘇丹",
        "lat": 8,
        "long": 30
    },
    "ST": {
        "name": "聖多美普林西比",
        "lat": 1,
        "long": 7
    },
    "SV": {
        "name": "薩爾瓦多",
        "lat": 13.8333,
        "long": -88.9167
    },
    "SY": {
        "name": "敘利亞",
        "lat": 35,
        "long": 38
    },
    "SZ": {
        "name": "史瓦濟蘭",
        "lat": -26.5,
        "long": 31.5
    },
    "TC": {
        "name": "土克斯及開科斯群島",
        "lat": 21.75,
        "long": -71.5833
    },
    "TD": {
        "name": "查德",
        "lat": 15,
        "long": 19
    },
    "TF": {
        "name": "法屬南部屬地",
        "lat": -43,
        "long": 67
    },
    "TG": {
        "name": "多哥",
        "lat": 8,
        "long": 1.1667
    },
    "TH": {
        "name": "泰國",
        "lat": 15,
        "long": 100
    },
    "TJ": {
        "name": "塔吉克",
        "lat": 39,
        "long": 71
    },
    "TK": {
        "name": "托克勞群島",
        "lat": -9,
        "long": -172
    },
    "TL": {
        "name": "東帝汶",
        "lat": -8.55,
        "long": 125.5167
    },
    "TM": {
        "name": "土庫曼",
        "lat": 40,
        "long": 60
    },
    "TN": {
        "name": "突尼西亞",
        "lat": 34,
        "long": 9
    },
    "TO": {
        "name": "東加",
        "lat": -20,
        "long": -175
    },
    "TR": {
        "name": "土耳其",
        "lat": 39,
        "long": 35
    },
    "TT": {
        "name": "千里達及托巴哥",
        "lat": 11,
        "long": -61
    },
    "TV": {
        "name": "吐瓦鲁",
        "lat": -8,
        "long": 178
    },
    "TW": {
        "name": "台灣",
        "lat": 23.5,
        "long": 121
    },
    "TZ": {
        "name": "坦尚尼亞",
        "lat": -6,
        "long": 35
    },
    "UA": {
        "name": "烏克蘭",
        "lat": 49,
        "long": 32
    },
    "UG": {
        "name": "烏干達",
        "lat": 1,
        "long": 32
    },
    "UM": {
        "name": "美國邊疆小島",
        "lat": 19.2833,
        "long": 166.6
    },
    "US": {
        "name": "美國",
        "lat": 38,
        "long": -97
    },
    "UY": {
        "name": "烏拉圭",
        "lat": -33,
        "long": -56
    },
    "UZ": {
        "name": "烏茲別克",
        "lat": 41,
        "long": 64
    },
    "VA": {
        "name": "梵蒂岡",
        "lat": 41.9,
        "long": 12.45
    },
    "VC": {
        "name": "聖文森及格瑞那丁",
        "lat": 13.25,
        "long": -61.2
    },
    "VE": {
        "name": "委內瑞拉",
        "lat": 8,
        "long": -66
    },
    "VG": {
        "name": "英屬維爾京群島",
        "lat": 18.5,
        "long": -64.5
    },
    "VI": {
        "name": "美屬維爾京群島",
        "lat": 18.3333,
        "long": -64.8333
    },
    "VN": {
        "name": "越南",
        "lat": 16,
        "long": 106
    },
    "VU": {
        "name": "萬那杜",
        "lat": -16,
        "long": 167
    },
    "WF": {
        "name": "沃里斯與伏塔那島",
        "lat": -13.3,
        "long": -176.2
    },
    "WS": {
        "name": "薩摩亞",
        "lat": -13.5833,
        "long": -172.3333
    },
    "YE": {
        "name": "葉門",
        "lat": 15,
        "long": 48
    },
    "YT": {
        "name": "美亞特",
        "lat": -12.8333,
        "long": 45.1667
    },
    "ZA": {
        "name": "南非",
        "lat": -29,
        "long": 24
    },
    "ZM": {
        "name": "尚比亞",
        "lat": -15,
        "long": 30
    },
    "ZW": {
        "name": "辛巴威",
        "lat": -20,
        "long": 30
    },
    "-": {
        "name": "台灣",
        "lat": 23.5,
        "long": 121
    }
}
