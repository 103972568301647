import { createContext, useEffect, useRef, useState } from "react";
import useElasticQueryFactory, { IMutationQuery } from "page/analysis/useElasticQueryFactory";
import useFetchAnalysis from "page/analysis/useFetchAnalysis";
import { IAnalaysisIndexNode } from "page/analysis/constant/indexTree";
import { socketRoot, WEBSOCKET_STATUS } from "constant";
import { useSelector } from "react-redux";
import { _isLogin, _token } from "redux/AuthSlice";
import { v4 as uuidv4 } from 'uuid';
import { useAlert } from "hook/useAlert";
import { WS_MESSAGE_AUTHORIZE_SUCCESS } from "hook/useSocketHandler";
import { useToast } from "hook/useToast";

const WebSocketContext = createContext<WebSocket|null>(null);

export default WebSocketContext;

interface WS_MESSAGE_HEARTBEAT {
    status: WEBSOCKET_STATUS.HEARTBEAT;
}
interface WS_MESSAGE_ERROR {
    status: WEBSOCKET_STATUS.ERROR;
}
interface WS_MESSAGE_DUPLICATE_CONNECTION {
    status: WEBSOCKET_STATUS.DUPLICATE_CONNECTION_ERROR;
}

type WS_MESSAGE_4_CONTEXT = WS_MESSAGE_HEARTBEAT | WS_MESSAGE_ERROR | WS_MESSAGE_DUPLICATE_CONNECTION | WS_MESSAGE_AUTHORIZE_SUCCESS;

enum WS_CONNECTION_STATUS {
    IDLE = 0,
    PENDING4AUTHORIZE = 1,
    CONNECTED = 2,
    LOST_CONNECTION = 3,
    ERROR = 4
}

const TOLERANCE = 15000;

export const WebSocketProvider = (props: any) => {

    const [socket, setS] = useState<WebSocket|null>(null);
    const [retry, setRetry] = useState(false);
    const token = useSelector(_token);
    const isLogin = useSelector(_isLogin);
    const HBTimer = useRef<NodeJS.Timeout| null>(null);
    const [status, setStatus] = useState<WS_CONNECTION_STATUS>(WS_CONNECTION_STATUS.IDLE);
    const {showAlert} = useAlert();
    const toast = useToast();

    useEffect(() => {
        console.log('[ws] current connection status: '+status);
        switch (status) {
            case WS_CONNECTION_STATUS.ERROR:
                toast.show({
                    type: 'error',
                    title: 'Websocket connection error',
                    content: 'Error occured while trying to connect to ws server',
                    closeBtnText: 'RETRY',
                    onDismiss: () => {setRetry(!retry);}
                });
                break;
            case WS_CONNECTION_STATUS.LOST_CONNECTION:
                toast.show({
                    type: 'error',
                    title: 'Websocket lost connection',
                    content: 'Edetector cannot receive message from ws server, please try to reconnect',
                    closeBtnText: 'RETRY',
                    onDismiss: () => {setRetry(!retry);}
                });
                break;
            
        }
    }, [status])

    const keepHeartBeat = (tolerance: number = TOLERANCE) => {
        if (HBTimer.current !== null) clearTimeout(HBTimer.current);
        console.log('[ws] passed tolerance')
        HBTimer.current = setTimeout(() => {
            setStatus(WS_CONNECTION_STATUS.LOST_CONNECTION);
        }, tolerance)
    }

    useEffect(() => {
        let _socket:(null|WebSocket) = null;
        if (token && isLogin && status !== WS_CONNECTION_STATUS.PENDING4AUTHORIZE && status !== WS_CONNECTION_STATUS.CONNECTED){
            try {
                _socket = new WebSocket(socketRoot);
                setStatus(WS_CONNECTION_STATUS.PENDING4AUTHORIZE);
                _socket.addEventListener('open', () => {
                    _socket?.send(JSON.stringify({
                        authorization: token
                    }))
                    keepHeartBeat();
                })
                _socket.addEventListener('message', (event: MessageEvent) => {
                    const data:WS_MESSAGE_4_CONTEXT = JSON.parse(event.data);
                    keepHeartBeat();
                    switch (data.status) {
                        case WEBSOCKET_STATUS.AUTHORIZE_SUCCESS:
                            setStatus(WS_CONNECTION_STATUS.CONNECTED)
                            break;
                        case WEBSOCKET_STATUS.HEARTBEAT:
                            // keepHeartBeat();
                            break;
                        case WEBSOCKET_STATUS.ERROR:
                            setStatus(WS_CONNECTION_STATUS.ERROR)
                            break;
                        case WEBSOCKET_STATUS.DUPLICATE_CONNECTION_ERROR:
                            setStatus(WS_CONNECTION_STATUS.ERROR)
                            break;
                        default:
                            //non-context handling message
                            break;
                    }
                })
                setS(_socket);
            } catch (err) {
                console.error('socket connection error: ', err);
                setStatus(WS_CONNECTION_STATUS.ERROR);
            }
        }
        return () => {
            if (HBTimer.current !== null) clearTimeout(HBTimer.current)
            _socket?.close();
            setS(null);
            setStatus(WS_CONNECTION_STATUS.IDLE);
        }
    }, [retry, token])

    return (
        <WebSocketContext.Provider value={socket}>
            {props.children}
        </WebSocketContext.Provider>
    )
}