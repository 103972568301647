import { useState } from 'react'
import Language from './Language'
import Version from './Version'
import DailyLogs from './DailyLogs'
import { Tabs, Tab } from '@mui/material'
import '../../index.scss'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import useDenyIfNot from 'hook/useDenyIfNot'

const SettingSoftware = () => {
    const [value, setValue] = useState(0);
    const language = useSelector(_language);
    useDenyIfNot('setting');

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>
                {dic[language]['setting_software']}
            </h2>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
            >
                <Tab
                    label={dic[language]["setting_software_language"]}
                    sx={{ textTransform: 'none' }}
                />
                <Tab
                    label={dic[language]['setting_software_authorization_version']}
                    sx={{ textTransform: 'none' }}
                />
                <Tab
                    label={dic[language]['setting_software_logs']}
                    sx={{ textTransform: 'none' }}
                />
            </Tabs>
            {value === 0 && <Language />}
            {value === 1 && <Version />}
            {value === 2 && <DailyLogs />}
        </div>
    )
}

export default SettingSoftware
