import { UseMutationResult } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import PartialLoading from '../PartialLoading'
import { useEffect, useState } from 'react'
import { DynamicObject, translateTime } from 'util/index'
import RightBarRow from './RightBarRow'

import { IconButton } from '@mui/material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { filterProcess } from 'redux/NetworkSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { flattenDoc } from 'util/index'
interface IRightBarProps {
    fetchElasticDetail: UseMutationResult<
        AxiosResponse<any, any>,
        unknown,
        void,
        unknown
    >
    searchKeywordList: any
}

export const AnalysisRightBarRowFactory = (props: IRightBarProps) => {

    const excludingList: string[] = ['uuid', 'agent', 'index', 'id', 'task_id', 'child', 'item_main', 'etc_main', 'date_main', 'type_main'] //不需要的欄位，可以持續增加

    const {
        fetchElasticDetail,
        searchKeywordList,
    } = props
    const { data, isLoading, isError } = fetchElasticDetail
    const [elasticDetail, setElasticDetail] = useState<DynamicObject>({})
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setElasticDetail({}) //避免有殘留，先清空，之後需要優化
        if (data?.data && data.data.hits.hits.length !== 0) {
            //判斷是否elastic有搜尋成功的方法
            setElasticDetail(translateTime(flattenDoc(data.data.hits.hits[0])))
        }
    }, [fetchElasticDetail])

    if (isError) {
        return (
            <div
                style={{
                    backgroundColor: '#F5F5F5',
                    padding: 10,
                    margin: '0px auto',
                    width: '90%',
                }}
            >
                <p style={{ color: '#00000099', marginTop: 30 }}>連線錯誤</p>
            </div>
        )
    }

    const searchList = searchKeywordList

    return <div>
    {isLoading ? <PartialLoading /> :
        Object.keys(elasticDetail).map((name, index) => excludingList.includes(name)?null:
            <RightBarRow name={name} key={`${name}-${index}-Grid`} data={elasticDetail[name]} searchList={searchList}/>)
    }
    {
        (!isLoading && elasticDetail['processConnectIP']!==undefined)?
            elasticDetail['processConnectIP'] == 'true'?
                <div className='property'  style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{flexGrow: 1}}>processConnectIP</span>
                        <IconButton sx={{marginLeft: '1rem'}} onClick={() => {
                            dispatch(filterProcess({
                                processId: Number(elasticDetail['processId']),
                                agent: elasticDetail['agent'],
                                processCreateTime: elasticDetail['__pct']
                            }))
                            navigate('/memory');
                        }}>
                            <ManageSearchIcon />
                        </IconButton>
                    </div>
                    :
                    <div className='property'>
                        <span>processConnectIP</span>
                        <p className='propertyTextField'>{elasticDetail['processConnectIP']}</p>
                    </div>
                : null
        }
    </div>

}


export const ViewDetailRightBarRowFactory = (props: { rightBarData: any }) => {
    
    const excludingList: string[] = ['uuid', 'task_id', 'accessTime', 'createTime', 'entryModifiedTime', 'writeTime', 'child', 'agent'];

    return (
        <div>
            {
                Object.keys(props.rightBarData).map((name) => {
                    return excludingList.includes(name)?null:<RightBarRow name={name} key={name} data={props.rightBarData[name]} />
                })
            }
        </div>
    )
}