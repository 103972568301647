import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { TPermission, _translatedPermission } from "redux/UtilSlice";

const useDenyIfNot = (require: TPermission) => {
    const permission = useSelector(_translatedPermission);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!permission.includes(require)){
            alert("permission denied");
            if (permission.length===0 || (permission.length === 1 && permission[0] == 'setting') || location.key === 'default'){
                navigate('/setting/SettingAgentActions');
            } else {
                window.history.back();
            }
        }
    }, [permission,require])
}

export default useDenyIfNot;