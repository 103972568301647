import { useState } from 'react'
import Child_ServerAndEmail from './Child_ServerAndEmail'
// import { SettingSystemChildren } from 'constant/index'
import HeaderNavigation from '../../../component/HeaderNavigation'
import Child_InterfaceSetting from './Child_InterfaceSetting'
import Child_CommonFunc from './Child_CommonFunc'
import Child_AgentSetting from './Child_AgentSetting'
// import Child_AgentDeliver from './Child_AgentDeliver/Child_AgentDeliver'
import Paper from '@mui/material/Paper'
import '../../../index.scss'
import dic from "constant/dictionary";
import { useSelector } from 'react-redux';
import { _language } from 'redux/UtilSlice';

const Parent = () => {
    const language = useSelector(_language)
    const HeaderList: string[] = [
        dic[language]["setting_system_tab_server_mail"],
        dic[language]["setting_system_tab_common"],
        dic[language]["setting_system_tab_agent_setting"],
        // 'AGENT派送',
        dic[language]["setting_system_tab_interface"],
    ]
    const [header, setHeader] = useState<string>(HeaderList[0])

    return (
        <>
            <HeaderNavigation
                header={header}
                setHeader={setHeader}
                HeaderList={HeaderList}
            />
            <Paper elevation={0} className='ChildInnerWhiteContainerScroll'>
                {header === dic[language]["setting_system_tab_server_mail"] && <Child_ServerAndEmail />}
                {header === dic[language]["setting_system_tab_common"] && <Child_CommonFunc />}
                {header === dic[language]["setting_system_tab_agent_setting"] && <Child_AgentSetting />}
                {/* {header === 'AGENT派送' && <Child_AgentDeliver />} */}
                {header === dic[language]["setting_system_tab_interface"] && <Child_InterfaceSetting />}
            </Paper>
        </>
    )
}

export default Parent
