import {
    SelectedRow,
    EarthData,
    CountryEarthData,
    LineChartData,
    Request,
    RiskLevelCount
} from "./Interfaces"

export const sortRows = (rows: any[], sortKey: string, sortDirection: string) => {
    const comparer = (a: any, b: any) => {
        if (sortDirection === 'asc') {
            return a[sortKey] > b[sortKey] ? 1 : -1
        } else if (sortDirection === 'desc') {
            return a[sortKey] < b[sortKey] ? 1 : -1
        }
        return 0
    }
    return [...rows].sort(comparer)
}

export const InitSelectedRow = (): SelectedRow => {
    return {
        connectCountry: null,
        ipConnect: null,
        processIP: null,
        riskLevel: null,
        virusTotal: null,
        loginFailure: null,
        highRiskProcess: null,
        noSignProcess: null,
    }
}

export const InitEarthData = (): EarthData => {
    return {
        country: "",
        latitude: 0,
        longitude: 0,
        times: 0,
        connections: []
    }
}

export const InitLineChartData = (): LineChartData => {
    return {
        title: '',
        timestamps: [],
        dots: [],
        leftLabel: '',
        leftValue: '',
        rightLabel: '',
        rightValue: '',
    }
}

export const InitRequest = (cache: number | undefined, from: number, to: number): Request => {
    return {
        cache: cache || 0,
        from: Math.round(from / 1000),
        to: Math.round(to / 1000),
    }
}

export const InitRiskLevelCount = (): RiskLevelCount => {
    return {
        level4: 0,
        level3: 0,
        level2: 0,
    }
}

export const IsEarthData = (data: EarthData | CountryEarthData): boolean => {
    return data.hasOwnProperty('country') &&
        data.hasOwnProperty('latitude') &&
        data.hasOwnProperty('longitude') &&
        data.hasOwnProperty('connections')
}
