import { useState, useEffect } from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { API, urlRoot } from 'constant/index'
import { useGet } from 'hook/useGet'
import dic, { TLanguages } from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'

interface UserTasktimeline {
    task: string
    userID: number
    username: string
    timestamp: number
}

const UserTask = () => {
    // todo : layout break when data is too long
    const language = useSelector(_language)
    const [timeLineData, setTimeLineData] = useState<UserTasktimeline[]>([])

    const {
        data: userTasktimeline,
        isSuccess: isUserTasktimelineSuccess,
    } = useGet<UserTasktimeline[]>({
        query: 'userTask',
        root: `${urlRoot}`,
        route: `${API.timeline}`,
    })

    useEffect(() => {
        if (isUserTasktimelineSuccess && userTasktimeline.data) {
            setTimeLineData(userTasktimeline.data || [])
        }
    }, [isUserTasktimelineSuccess, userTasktimeline])

    return (
        <div className='userTaskContainer'>
            {dic[language]['dashboard_user_task_timeline']}
            <div className='timelineContainer'>
                <Timeline className='timeline'
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {timeLineData.map((item, index) => {
                        return timelineItem(
                            index,
                            item.task,
                            item.username,
                            item.timestamp,
                            index === timeLineData.length - 1,
                            language
                        )
                    })}
                    {timeLineData.length === 0 && (
                        <div
                            style={{
                                marginTop: '45%',
                                width: '100%',
                                textAlign: 'center',
                            }}
                        >
                            {dic[language]["no_task"]}
                        </div>
                    )}
                </Timeline>
            </div>
        </div>
    )
}

export default UserTask

const taskToChinese = (task: string, language: TLanguages) => {
    if (task === 'StartScan') {
        return dic[language]['dashboard_start_scan']
    } else if (task === 'StartCollect') {
        return dic[language]['dashboard_start_collect']
    } else if (task === 'StartGetDrive') {
        return dic[language]['dashboard_start_get_drive']
    } else if (task === 'ChangeDetectMode') {
        return dic[language]['dashboard_change_detect_mode']
    } else if (task === 'StartGetImage') {
        return dic[language]['dashboard_start_get_image']
    } else if (task === 'ChangeDetectMode') {
        return dic[language]['dashboard_change_detect_mode']
    } else if (task === 'Terminate') {
        return dic[language]['dashboard_terminate']
    } else if (task === 'StartYaraRule') {
        return dic[language]['dashboard_start_yara_rule']
    } else if (task === 'StartMemoryTree') {
        return dic[language]['dashboard_start_memory_tree']
    } else if (task === 'StartRemove') {
        return dic[language]['dashboard_start_remove_agent']
    } else if (task === 'StartUpdate') {
        return dic[language]['dashboard_start_update_agent']
    } else {
        return "Unknown"
    }
}

const timelineItem = (
    index: number,
    task: string,
    username: string,
    timestamp: number,
    lastOne: boolean,
    language: TLanguages
) => {
    // convert timestamp to local time
    const date = new Date(timestamp * 1000)

    return (
        <TimelineItem key={index} sx={{ flex: lastOne ? 1 : null }}>
            <TimelineSeparator>
                <TimelineDot className='timelineDot' />
                <TimelineConnector className='timelineConnector' />
            </TimelineSeparator>
            <TimelineContent>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body1" component="span">
                        {taskToChinese(task, language)}
                    </Typography>
                    <Typography variant="body1" component="span">
                        {date.toLocaleString('zh-CN', { hour12: false })}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={0.8}>
                    <PeopleAltIcon fontSize="small" />
                    <Typography variant="body2">
                        {dic[language]["user"]}：{username}
                    </Typography>
                </Stack>
            </TimelineContent>
        </TimelineItem>
    )
}
