import { useEffect, useState } from "react";
import { DataGridPro, GridSlotsComponentsProps, GridColDef, GridSortModel, GridCallbackDetails, GridSortItem } from "@mui/x-data-grid-pro";
import { Button, Chip, InputAdornment, Stack, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch, useSelector } from "react-redux";
import { _processFilter, _filterMode, clearFilter, filterSearchIP, _otherIP, _searchIP, _page, pageDec, pageInc, _timeFilter, _dateModuleData, setDateRange, changeSortModel, SortModel } from "redux/NetworkSlice";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import dayjs from "dayjs";
import useFetchNetwork from "page/memory/useFetchNetwork";
import { _language } from "redux/UtilSlice";
import { useAlert } from "hook/useAlert";
import dic from "constant/dictionary";
import NetworkTimeSelector from "../component/NetworkTimeSelector";
import { PAGE_SIZE } from "page/memory/useFetchNetwork";

const NetworkConnectionPage = () => {
    
    const alert = useAlert();
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState<string>('');
    const { status, isRefetching, table, total, refetch } = useFetchNetwork();
    const processFilter = useSelector(_processFilter);
    const filterMode = useSelector(_filterMode);
    const otherIP = useSelector(_otherIP);
    const searchIP = useSelector(_searchIP);
    const language = useSelector(_language);
    const [tempSearchIP, setTempSearchIP] = useState('');

    useEffect(() => {
        if (tempSearchIP !== searchIP) setTempSearchIP(searchIP);
    },[searchIP])

    useEffect(() => {
        let filterText = '';
        switch(filterMode){
            case 'specificProcess':
                filterText = `Agent ID: ${processFilter.agent}, pid: ${processFilter.processId}, process created at ${dayjs(processFilter.processCreateTime).toString()}`;
                break;
            case 'otherIP':
                filterText = `other IP: ${otherIP}`;
                break;
            case 'off':
            default:
                break;
        }
        setFilterText(filterText)
    }, [processFilter, filterMode])

    const ipValidatingRegex = /^(((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4})?$/

    const search = () => {
        if (!ipValidatingRegex.test(tempSearchIP)) {
            alert.showAlert(dic[language]['network_wrong_ip_hint']);
            return;
        }
        dispatch(filterSearchIP(tempSearchIP))
        refetch();
    }

    const hiddenFields = ['_group'];

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns
            .filter((column) => !hiddenFields.includes(column.field))
            .map((column) => column.field);
    };

    return (
        <>
            <div className="gap" style={{height: '.5rem'}}></div>
            <Stack direction="row" id="topBar">
                <div className="searchOptionsContainer">

                    <TextField
                        className="searchIPInputField"
                        label={dic[language]['netwrok_search_ip']}
                        size="small"
                        value={tempSearchIP}
                        onChange={e => {
                            setTempSearchIP(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                        id="searchButton"
                        variant="contained"
                        onClick={search}
                        sx={{ textTransform: 'none' }}
                    >
                        {dic[language]['search']}
                    </Button>
                    <NetworkTimeSelector refetch={refetch}/>
                </div>

            <div id="processFilter" className={filterMode === 'off'? 'hidden' : ''}>
                    <p>Filter: </p>
                    <Chip
                        label={filterText}
                        onDelete={() => {
                            dispatch(clearFilter())
                            setFilterText('')
                        }}
                    />
                </div>
            </Stack>
            <DataGridPro
                className="table"
                rowHeight={30}
                columns={[
                    { field: 'pName', headerName: 'Process name', flex: 1, sortable: false},
                    { field: 'memory_network.processId', headerName: 'Process ID', flex: 1},
                    { field: 'memory_network.action', headerName: 'Action', flex: 1},
                    { field: 'agentName', headerName: 'Agent name', flex: 1, sortable: false},
                    { field: 'agentIP', headerName: 'Agent IP', flex: 1},
                    { field: 'memory_network.srcAddress', headerName: 'Source IP', flex: 1},
                    { field: 'memory_network.dstAddress', headerName: 'Destination IP', flex: 1},
                    { field: 'memory_network.srcPort', headerName: 'Source Port', flex: 1},
                    { field: 'memory_network.dstPort', headerName: 'Destination Port', flex: 1},
                    {
                        field: 'memory_network.processCreateTime',
                        headerName: 'Process Create Time',
                        flex: 1,
                        renderCell: ({ row }) => row["memory_network.processCreateTime"] === 0? "Unknown":dayjs.unix(row['memory_network.processCreateTime']).format("YYYY-MM-DD HH:mm:s") },
                    {
                        field: 'memory_network.virusTotal',
                        headerName: 'VirusTotal Score',
                        flex: 1,
                        renderCell: ({ row }) => {
                            if (row["memory_network.virusTotal"] === 0) return null;
                            if (row["memory_network.virusTotal"] === -1) return <a className="vtCellUnverified" href={"https://www.virustotal.com/gui/ip-address/" + row['memory_network.otherIP']} target="_blank">Unverified</a>
                            const score = row["memory_network.malicious"] / row["memory_network.virusTotal"];
                            const color = (score > (2 / 3)) ? 'high' : (score > (1 / 3)) ? 'mid' : 'low';
                            return (
                                <a className="vtCell" href={"https://www.virustotal.com/gui/ip-address/" + row["memory_network.otherIP"]} target="_blank">
                                    <div className={"content " + color}></div>
                                    <div className="number">{row["memory_network.malicious"]}/{row["memory_network.virusTotal"]}</div>
                                </a>
                            )
                        }
                    },
                ]}
                rows={table}
                rowCount={1000}
                paginationMode="server"
                loading={status === 'loading' || isRefetching}
                disableColumnFilter
                sortingMode="server"
                onSortModelChange={(model) => dispatch(changeSortModel((model[0] as (SortModel | undefined))))}
                slots={{ footer: NetworkPageGridFooter }}
                slotProps={{
                    footer: { processCount: total },
                    columnsPanel: { getTogglableColumns },
                }}
            />
        </>
    )
}


declare module '@mui/x-data-grid' {
    interface FooterPropsOverrides {
        page: number,
        setPage: (page: number) => void,
        processCount: number,
        filterMode: string,
    }
}

const NetworkPageGridFooter = (props: NonNullable<GridSlotsComponentsProps['footer']>) => {

    const page = useSelector(_page);
    const dispatch = useDispatch();

    return (
        <div className="MuiDataGrid-footerContainer MuiDataGrid-withBorderColor footer">
            {
                //@ts-ignore
                <div className="currentPageNumber">Process: {page * PAGE_SIZE} - {Math.min((page + 1) * PAGE_SIZE, props.processCount)}/{props.processCount}</div>
            }

            {/*@ts-ignore*/ }
            <Button onClick={() => {dispatch(pageDec())}} disableRipple disabled={page <= 0 || props.processCount === 0}>
                <KeyboardArrowLeftIcon color="action" />
            </Button>

            {/*@ts-ignore*/ }
            <Button onClick={() => {dispatch(pageInc())}} disableRipple disabled={(page + 1) * PAGE_SIZE >= props.processCount || props.processCount === 0}>
                <KeyboardArrowRightIcon color="action"/>
            </Button>
        </div>
    );
}


export default NetworkConnectionPage;