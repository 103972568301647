import TimeSelector from 'component/TimeSelector'
import KeywordSearchInput from './AnalysisSettingBarItem/KeywordSearchInput/KeywordSearchInput'
import AnalysisDataDropSelector from './AnalysisSettingBarItem/ForensicsDropDownSelector/AnalysisDataDropSelector'
import GroupDropDown from './GroupFilter/DropDown/GroupDropDown'
import React, { useContext } from 'react'
import { Button, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setDateRange, setDateSelectIndex } from 'redux/AnalysisSlice'
import { _displayMode, _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext'
import Popper from '@mui/material/Popper';
import './AnalysisSettingBar.scss'

interface IAnalysisSettingBarProps {
    setRefreshTemplate: React.Dispatch<React.SetStateAction<boolean>>
}

const AnalysisSettingBar = (props: IAnalysisSettingBarProps) => {
    const {
        setRefreshTemplate
    } = props

    const analysisData = useSelector(_analysisData);
    const dispatch = useDispatch();
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);
    
    const controller = (useContext(AnalysisController) as TAnalysisController);
    const {fetchTable, fetchListCount, fetchChart} = controller;

    const {
        selectedHostsIDs,
        dateRange,
        dateSelectIndex
    } = analysisData;

    const btnRef = React.useRef<HTMLButtonElement>(null);

    return (
        <div className="analysis-setting-bar">
            {/* for scrolling purpose */}
            <div className="container">
                <GroupDropDown />

                <AnalysisDataDropSelector
                    setRefreshTemplate={setRefreshTemplate}
                />
                <TimeSelector
                    defaultValues={[
                        { name: dic[language]["last_24_hr"], value: 24 },
                        { name: dic[language]["last_week"], value: 168 },
                        { name: dic[language]["last_month"], value: 720 },
                        { name: dic[language]["custom"], value: 0 }
                    ]}
                    dateRange={dateRange}
                    setDateRange={(arg: any) => { dispatch(setDateRange(arg)) }}
                    selectedDateIndex={dateSelectIndex}
                    setSelectedDateIndex={(arg:number) => {dispatch(setDateSelectIndex(arg))}}
                />
                <KeywordSearchInput btnRef={btnRef} />
                <Button
                    ref={btnRef}
                    disabled={
                        (selectedHostsIDs !== undefined && selectedHostsIDs.length === 0) ||
                        dateRange.startTime === 0 ||
                        dateRange.endTime === 0 || dateRange.endTime - dateRange.startTime === 0
                    }
                    variant="contained"
                    id="analysisBarButton"
                    onClick={() => {
                        controller.fullMutate();
                    }}
                >
                    {dic[language]['analysis_start']}
                </Button>
                <Popper open={fetchTable.isIdle && fetchListCount.isIdle && fetchChart.isIdle} anchorEl={btnRef.current}>
                    <div className="analysis-hint">
                        <svg id="top-notch" width={16} height={12}>
                            <Box
                                component="polygon"
                                points="0,12 8,00, 16,12"
                                sx={{
                                fill: displayMode==='light'?"#2196F3":"rgb(144, 202, 249)"
                                }}
                            />
                        </svg>
                        <span>{dic[language]['analysis_search_hint']}</span>
                    </div>
                </Popper>
            </div>
        </div>
    )
}

export default AnalysisSettingBar
