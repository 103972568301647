import { API, WEBSOCKET_STATUS, urlRoot } from "constant";
import { useAlert } from "hook/useAlert";
import useSocketHandler from "hook/useSocketHandler";
import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { _token } from "redux/AuthSlice";

export type DownloadData = {
    uuid: string
    filename: string
    progress: number,
    /**
     * failed: 
     * - empty string: no failure, keep going
     * - start with "partial paths failed:": partial path under the directory faild, keep going
     * - other: failure, stop downloading  
     */
    failed: string
    timestamp: number
}

export interface DumpDllRequest {
    action: "StartDumpDll"
	deviceId: string
    name: string
	filter: {
		pid: string
		path: string
		processCreateTime: number
	}
}

export interface DumpDriveRequest {
    action: "StartDumpDrive"
	deviceId: string
    name: string
	filter: {
		path: string
        fileId: string
	}
}

export interface DumpProcessRequest {
    action: "StartDumpProcess"
	deviceId: string
    name: string
	filter: {
		pid: string
		processCreateTime: number
	}
}

export interface LoadDllRequest {
    action: "StartLoadDll"
	deviceId: string
    name: ""
	filter: {
		pid: string
		processCreateTime: number
	}
}

export type DumpRequestObj = DumpProcessRequest | DumpDriveRequest | DumpDllRequest;

const initialContextValue = {
    files: [],
    addItem: (request: DumpRequestObj) => null,
    deleteItem: (uuid: string) => null
}

const DownloadContext = createContext<{
    files: DownloadData[],
    addItem: (request: DumpRequestObj) => void,
    deleteItem: (uuid: string) => void
}>(initialContextValue);

export const DownloadProvider = (props:{children:any}) => {
    const [files, setFiles] = useState<DownloadData[]>([])
    const token = useSelector(_token);
    const alert = useAlert();

    useSocketHandler(WEBSOCKET_STATUS.AUTHORIZE_SUCCESS, (message) => {
        if (message.auxiliary.pendingDumps) {
            setFiles(message.auxiliary.pendingDumps);
        }
    })

    useSocketHandler(WEBSOCKET_STATUS.ACTION_PROGRESS_UPDATE, (message, params: {files: DownloadData[]}) => {
        const newList = params.files.map(file => {
            if (file.uuid !== message.uuid) {
                return file;
            } else {
                return {
                    ...file,
                    progress: message.progress,
                    failed: message.failed
                }
            }
        })
        setFiles(newList);
    }, {files: files})

    const deleteItem = (uuid: string) => {
        fetch(`${urlRoot}${API.detectDump}/${uuid}`, {
            method: 'DELETE',
            headers: {
                'Authorization': token
            }
        }).then((res) => {
            setFiles(files.filter(file => file.uuid !== uuid));
        }).catch(err => {
            setFiles(files.filter(file => file.uuid !== uuid));
            console.error(err);
        });
    }

    const addItem = (req: DumpRequestObj) => {
        (async function(){
            try {
                const res = await fetch(`${urlRoot}${API.detectDump}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify(req),
                });
                const json = await res.json();
                if (!res.ok) {
                    alert.showAlert(json.message);
                    return;
                }
                setFiles([...files, {
                    uuid: json.data.uuid,
                    filename: req.name,
                    progress: 0,
                    failed: "",
                    timestamp: json.data.timestamp
                }]);
            } catch(err) {
                console.error(err);
            }
        })()
    }

    return <DownloadContext.Provider value={{files, deleteItem, addItem}}>
        {props.children}
    </DownloadContext.Provider>
}

export default DownloadContext;