import { Checkbox, IconButton, Menu, MenuItem } from "@mui/material"
import { useState } from "react"
import FilterAltIcon from '@mui/icons-material/FilterAlt'

interface MultiSelectDropdownProps {
    options: string[]
    selectedGroupsInFilter: string[]
    setSelectedGroupsInFilter: React.Dispatch<React.SetStateAction<string[]>>
}

const MultiSelectDropdown = (option: MultiSelectDropdownProps) => {
    const {
        options,
        selectedGroupsInFilter,
        setSelectedGroupsInFilter,
    } = option
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <IconButton
                className="dropdown-header"
                id="group-filter-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <FilterAltIcon fontSize="small" />
            </IconButton>
            <Menu
                id="basic-menu"
                variant="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {options.map((option, index) => (
                    <MenuItem
                        dense
                        key={index}
                        onClick={() => {
                            if (selectedGroupsInFilter.includes(option)) {
                                setSelectedGroupsInFilter(
                                    selectedGroupsInFilter.filter(
                                        (item) => item !== option
                                    )
                                )
                            } else {
                                setSelectedGroupsInFilter([
                                    ...selectedGroupsInFilter,
                                    option,
                                ])
                            }
                        }}
                    >
                        <Checkbox
                            size="small"
                            checked={selectedGroupsInFilter.includes(option)}
                            sx={{ padding: 0, marginRight: '0.5rem' }}
                        />
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default MultiSelectDropdown