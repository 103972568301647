import { useDispatch, useSelector } from 'react-redux';
import { removeReportRow, clearReportRows, _uuidList } from 'redux/ReportSlice';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { _displayMode, _language } from 'redux/UtilSlice'
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { CircularProgress, ListItem } from '@mui/material';
import dic from 'constant/dictionary';
import useFetchReportRows from 'page/analysis/useFetchReportRows';
import { useEffect, useState } from 'react';

interface ReportMenuProps {
    anchorEl: null | HTMLElement;
    reportMenuOpen: boolean;
    handleReportMenuClose: () => void;
}

const ReportMenu = (props: ReportMenuProps) => {
    const { anchorEl, reportMenuOpen, handleReportMenuClose } = props;
    const reportUUIDList = useSelector(_uuidList);
    const displayMode = useSelector(_displayMode);
    const language = useSelector(_language);
    const dispatch = useDispatch();
    const [reportRows, setReportRows] = useState<any[]>([]);

    const { fetch } = useFetchReportRows();
    useEffect(() => {
        if (reportMenuOpen) {
            fetch().then((reportRows:any) => {
                setReportRows(reportRows);
            })
        }
    }, [reportUUIDList, reportMenuOpen]);

    // TODO: Infinite scroll
    const Row = (props: ListChildComponentProps) => {
        const { index, style } = props;
        if (!reportRows[index]) return null;

        return (
            <div style={style}>
                <ListItem dense key={index} component="div">
                    <ListItemText>
                        <Tooltip title={reportRows[index].item_main} placement="bottom">
                            <Typography variant="inherit" noWrap>
                                {reportRows[index].item_main}
                            </Typography>
                        </Tooltip>
                    </ListItemText>
                    <ListItemIcon>
                        <IconButton
                            onClick={() => dispatch(removeReportRow(reportRows[index].uuid))}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </ListItemIcon>
                </ListItem>
            </div>
        )
    };

    return (
        <Menu
            id="report-menu"
            anchorEl={anchorEl}
            open={reportMenuOpen}
            onClose={handleReportMenuClose}
            MenuListProps={{
                'aria-labelledby': 'report-menu-button',
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ px: 2, paddingBottom: 1 }}
            >
                <Typography variant="subtitle1">
                    {dic[language]['analysis_has_selected']} {reportUUIDList.length} {dic[language]['analysis_report_selected_unit']}
                </Typography>
                <Button
                    onClick={() => dispatch(clearReportRows())}
                    variant="text"
                    size="small"
                    startIcon={<DeleteIcon />}
                >
                    {dic[language]['analysis_clear_all']}
                </Button>
            </Stack>
            <Divider />
            {reportUUIDList.length > 0 && reportRows.length === 0 ? (
                <CircularProgress />
            ) : (
                <VariableSizeList
                    height={600}
                    width={400}
                    itemSize={() => 44}
                    itemCount={reportUUIDList.length}
                    overscanCount={5}
                    className={displayMode === 'dark' ? 'scrollbarYDark' : 'scrollbarY'}
                >
                    {Row}
                </VariableSizeList>
            )}
        </Menu>
    )
}

export default ReportMenu;
