import RiskLevel from "./RiskLevel"
import IPConnect from "./IPConnect"
import VirusTotal from "./VirusTotal"
import { EarthData, CountryEarthData, SelectedRow } from "../Interfaces"
import { IDateRange } from 'interface'
import { LineChartData } from "../Interfaces"
import '../../index.scss'

interface LeftBarProps {
    dateModuleData: IDateRange
    setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setLineChartData: React.Dispatch<React.SetStateAction<LineChartData>>
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const LeftBar = (props: LeftBarProps) => {
    const {
        dateModuleData,
        setEarthData,
        selectedRow,
        setSelectedRow,
        setLineChartData,
        setOpenSnackBar,
    } = props

    return (
        <div className="leftBarContainer">
            <RiskLevel
                dateModuleData={dateModuleData}
                setEarthData={setEarthData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setOpenSnackBar={setOpenSnackBar}
            />
            <IPConnect
                dateModuleData={dateModuleData}
                setEarthData={setEarthData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setOpenSnackBar={setOpenSnackBar}
            />
            <VirusTotal
                dateModuleData={dateModuleData}
                setEarthData={setEarthData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setOpenSnackBar={setOpenSnackBar}
            />
        </div>
    )
}

export default LeftBar
