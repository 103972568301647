import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Typography } from '@mui/material'

import assets from 'assets'
import { useGet } from 'hook/useGet'
import { API, NO_GROUP_KEYWORD, urlRoot } from 'constant/index'
import { PieChart, taskPieData } from '../Pie'
import PointStatus from '../PointStatus'
import GroupTable, {
    TableTitleDetail,
    TaskData,
} from '../commonComponent/GroupTable'

import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'

interface data {
    id: number
    name: string
    done: number
    failed: number
    running: number
    success: number
    unknown: number
    color: string
}

interface total {
    amount: number
    done: number
    failed: number
    running: number
    success: number
    unknown: number
}

interface IRawDataSet {
    data: data[] | undefined
    total: total
}

interface IDashBoardRawData {
    collect: IRawDataSet
    drive: IRawDataSet
    scan: IRawDataSet
    image: IRawDataSet
}

interface IPieFormatData {
    collect: taskPieData[]
    drive: taskPieData[]
    scan: taskPieData[]
    image: taskPieData[]
}

interface TaskDataSet {
    collect: TaskData[]
    drive: TaskData[]
    scan: TaskData[]
    image: TaskData[]
}

interface TableTitleDetailSet {
    collect: TableTitleDetail
    drive: TableTitleDetail
    scan: TableTitleDetail
    image: TableTitleDetail
}

const initDashBoardRawDat: IDashBoardRawData = {
    collect: {
        data: [],
        total: {
            amount: 0,
            done: 0,
            failed: 0,
            running: 0,
            success: 0,
            unknown: 0,
        },
    },
    drive: {
        data: [],
        total: {
            amount: 0,
            done: 0,
            failed: 0,
            running: 0,
            success: 0,
            unknown: 0,
        },
    },
    scan: {
        data: [],
        total: {
            amount: 0,
            done: 0,
            failed: 0,
            running: 0,
            success: 0,
            unknown: 0,
        },
    },
    image: {
        data: [],
        total: {
            amount: 0,
            done: 0,
            failed: 0,
            running: 0,
            success: 0,
            unknown: 0,
        },
    },
}

const initTaskDataSet: TaskDataSet = {
    collect: [],
    drive: [],
    scan: [],
    image: [],
}

const initTableTitleDetailSet: TableTitleDetailSet = {
    collect: {
        groupCount: 0,
        deviceCount: 0,
        done: 0,
        unknown: 0,
    },
    drive: {
        groupCount: 0,
        deviceCount: 0,
        done: 0,
        unknown: 0,
    },
    scan: {
        groupCount: 0,
        deviceCount: 0,
        done: 0,
        unknown: 0,
    },
    image: {
        groupCount: 0,
        deviceCount: 0,
        done: 0,
        unknown: 0,
    },
}

const BottomDashBoard = () => {
    const language = useSelector(_language);
    const [fetchData, setFetchData] =
        useState<IDashBoardRawData>(initDashBoardRawDat)
    const [taskData, setTaskData] = useState<TaskDataSet>(initTaskDataSet)
    const [pieData, setPieData] = useState<IPieFormatData>({
        collect: [],
        drive: [],
        scan: [],
        image: [],
    })
    const [tableTitleDetail, setTableTitleDetail] = useState<TableTitleDetailSet>(initTableTitleDetailSet)

    const {
        data: dashBoardData,
        isSuccess: isDashBoardDataSuccess,
    } = useGet<IDashBoardRawData>({
        query: 'dashBoardData',
        root: `${urlRoot}`,
        route: `${API.taskStatus}`,
    })

    useEffect(() => {
        if (!dashBoardData) return
        const data = dashBoardData.data
        if (!data || !isDashBoardDataSuccess) return
        const processData = (items: IRawDataSet) => {
            if (!items || !items.data || items.total === undefined || !Array.isArray(items.data)) {
                return {
                    groupCount: 0,
                    deviceCount: 0,
                    done: 0,
                    unknown: 0
                }
            }
            return {
                groupCount: items.data ? items.data.length : 0,
                deviceCount: items.total.amount,
                done: items.total.done,
                unknown: items.total.unknown,
            }
        }
        const dataAfterFormat: TableTitleDetailSet = {
            collect: processData(data.collect),
            drive: processData(data.drive),
            scan: processData(data.scan),
            image: processData(data.image),
        }
        data.collect.data?.forEach(item => {if(item.name === NO_GROUP_KEYWORD) item.name = dic[language]["no_group"]});
        data.drive.data?.forEach(item => {if(item.name === NO_GROUP_KEYWORD) item.name = dic[language]["no_group"]});
        data.image.data?.forEach(item => {if(item.name === NO_GROUP_KEYWORD) item.name = dic[language]["no_group"]});
        data.scan.data?.forEach(item => {if(item.name === NO_GROUP_KEYWORD) item.name = dic[language]["no_group"]});
        if (dataAfterFormat) setTableTitleDetail(dataAfterFormat)
    }, [isDashBoardDataSuccess, dashBoardData])

    useEffect(() => {
        if (!dashBoardData) return
        const data = dashBoardData.data
        setFetchData(data)

        const formatPieData = (data: IDashBoardRawData | undefined) => {
            if (!data || !isDashBoardDataSuccess) return

            const processData = (items: data[] | undefined) => {
                if (!items) return []
                return items.map((item) => ({
                    name: item.name,
                    amount:
                        item.done +
                        item.running +
                        item.unknown,
                    done: item.done,
                    running: item.running,
                    unknown: item.unknown,
                    color: item.color,
                }))
            }

            const res: IPieFormatData = {
                collect: processData(data.collect.data),
                drive: processData(data.drive.data),
                scan: processData(data.scan.data),
                image: processData(data.image.data),
            }

            return res
        }

        const formTaskData = (data: IDashBoardRawData | undefined) => {
            if (!data || !isDashBoardDataSuccess) return

            const processTaskData = (items: data[] | undefined) => {
                if (!items) return []
                return items.map((item) => ({
                    id: item.id,
                    name: item.name,
                    count:
                        item.done +
                        item.running +
                        item.unknown,
                    done: item.done,
                    running: item.running,
                    unknown: item.unknown,
                }))
            }

            const res: TaskDataSet = {
                collect: processTaskData(data.collect.data),
                drive: processTaskData(data.drive.data),
                scan: processTaskData(data.scan.data),
                image: processTaskData(data.image.data),
            }

            return res
        }

        const pieData = formatPieData(data)
        if (pieData) setPieData(pieData)

        const taskDataAfterForm = formTaskData(data)
        if (taskDataAfterForm) setTaskData(taskDataAfterForm)
    }, [isDashBoardDataSuccess, dashBoardData])

    return (
        <div className='bottomDashboard'>
            <div className='serverStatusContainer'>
                <BottomHeader />
            </div>
            <div className='bottomDashboardContainer'>
                <div className='RWDSizedContainer rightPadding'>
                    <div className='innerContainer'>
                        <div className='titleContainer'>
                            <assets.MemoryIcon className='titleIcon' />
                            <p className='title'>
                                {dic[language]['memory']}
                            </p>
                        </div>
                        <div className='statusContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_scan_status']}
                            </p>
                            <PointStatus
                                point={calculatePercentage(fetchData.scan.total.done, fetchData.scan.total.amount)}
                                state="success"
                                category="scanFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.scan.total.running, fetchData.scan.total.amount)}
                                state="working"
                                category="scanFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.scan.total.unknown, fetchData.scan.total.amount)}
                                state="non-working"
                                category="scanFinishTime"
                            />
                        </div>

                        <div className='pieContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_Scan_rate']}
                            </p>
                            <PieChart data={pieData.scan} />
                        </div>

                        <div style={{ width: '100%', height: '100%' }}>
                            <GroupTable
                                tableTitle={dic[language]['dashboard_collect_table_title']}
                                tableTitleDetail={tableTitleDetail.scan}
                                taskData={taskData.scan}
                            />
                        </div>
                    </div>
                </div>
                {/* gap line */}
                <div className='RWDSizedContainer'>
                    <div className='innerContainer'>
                        <div className='titleContainer'>
                            <assets.EyeIcon className='titleIcon' />
                            <p className='title'>
                                {dic[language]['dashboard_collect']}
                            </p>
                        </div>
                        <div className='statusContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_collect_status']}
                            </p>
                            <PointStatus
                                point={calculatePercentage(fetchData.collect.total.done, fetchData.collect.total.amount)}
                                state="success"
                                category="collectFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.collect.total.running, fetchData.collect.total.amount)}
                                state="working"
                                category="collectFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.collect.total.unknown, fetchData.collect.total.amount)}
                                state="non-working"
                                category="collectFinishTime"
                            />
                        </div>

                        <div className='pieContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_collect_rate']}
                            </p>
                            <PieChart data={pieData.collect} />
                        </div>

                        <div style={{ width: '100%', height: '100%' }}>
                            <GroupTable
                                tableTitle={dic[language]['dashboard_collect_table_title']}
                                tableTitleDetail={tableTitleDetail.collect}
                                taskData={taskData.collect}
                            />
                        </div>
                    </div>
                </div>
                {/* gap line */}
                <div className='RWDSizedContainer rightPadding'>
                    <div className='innerContainer'>
                        <div className='titleContainer'>
                            <assets.FileIcon className='titleIcon' />
                            <p className='title'>
                                {dic[language]['dashboard_drive']}
                            </p>
                        </div>
                        <div className='statusContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_drive_status']}
                            </p>
                            <PointStatus
                                point={calculatePercentage(fetchData.drive.total.done, fetchData.drive.total.amount)}
                                state="success"
                                category="fileFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.drive.total.running, fetchData.drive.total.amount)}
                                state="working"
                                category="fileFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.drive.total.unknown, fetchData.drive.total.amount)}
                                state="non-working"
                                category="fileFinishTime"
                            />
                        </div>

                        <div className='pieContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_drive_rate']}
                            </p>
                            <PieChart data={pieData.drive} />
                        </div>

                        <div style={{ width: '100%', height: '100%' }}>
                            <GroupTable
                                tableTitle={dic[language]['dashboard_drive_table_title']}
                                tableTitleDetail={tableTitleDetail.drive}
                                taskData={taskData.drive}
                            />
                        </div>
                    </div>
                </div>
                {/* gap line */}
                <div className='RWDSizedContainer'>
                    <div className='innerContainer'>
                        <div className='titleContainer'>
                            <assets.IsoIcon className='titleIcon' />
                            <p className='title'>
                                {dic[language]['dashboard_image']}
                            </p>
                        </div>
                        <div className='statusContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_image_status']}
                            </p>
                            <PointStatus
                                point={calculatePercentage(fetchData.image.total.done, fetchData.image.total.amount)}
                                state="success"
                                category="imageFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.image.total.running, fetchData.image.total.amount)}
                                state="working"
                                category="imageFinishTime"
                            />
                            <PointStatus
                                point={calculatePercentage(fetchData.image.total.unknown, fetchData.image.total.amount)}
                                state="non-working"
                                category="imageFinishTime"
                            />
                        </div>

                        <div className='pieContainer'>
                            <p className='dashBoardP'>
                                {dic[language]['dashboard_image_rate']}
                            </p>
                            <PieChart data={pieData.image} />
                        </div>

                        <div style={{ width: '100%', height: '100%' }}>
                            <GroupTable
                                tableTitle={dic[language]['dashboard_image_table_title']}
                                tableTitleDetail={tableTitleDetail.image}
                                taskData={taskData.image}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BottomDashBoard

// round to 1 decimal place
const calculatePercentage = (numerator: number, denominator: number): number => {
    if (denominator === 0) {
        //   throw new Error("Denominator cannot be zero.");
        return 0;
    }
    const result = (numerator / denominator) * 100;
    return Math.round(result * 10) / 10; // 保留一位小數
};

const BottomHeader = () => {
    const language = useSelector(_language);

    return (
        <div className='serverStatus'>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                className='head'
            >
                <assets.homeISO />
                <Typography variant="subtitle1">
                    {dic[language]['dashboard_task_status']}
                </Typography>

            </Stack>
            <div className='triangle' />
        </div>
    )
}
