import { useState, useEffect } from 'react'
import LinearProgress, {
    LinearProgressProps,
} from '@mui/material/LinearProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { API, urlRoot } from 'constant/index'
import { useGet } from 'hook/useGet'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'

interface OSCountInfo {
    os: string
    count: number
}

interface OSCountResponse {
    data: OSCountInfo[]
    total: number
}

const colors: (
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'primary'
    | 'inherit'
    | 'secondary'
)[] = ['info', 'success', 'warning', 'error', 'secondary', 'primary', 'inherit']

const OSCount = () => {
    const language = useSelector(_language);
    const [OSCountData, setOSCountData] = useState<OSCountInfo[]>([])
    const [osTotal, setOsTotal] = useState<number>(0)

    const { 
        data: osCountResponse, 
        isSuccess: isOSCountSuccess,
    } = useGet<OSCountResponse>({
        query: 'osCount',
        root: `${urlRoot}`,
        route: `${API.osCount}`,
    })

    useEffect(() => {
        if (isOSCountSuccess && osCountResponse.data) {
            setOSCountData(osCountResponse.data.data || [])
            setOsTotal(osCountResponse.data.total || 0)
        }
    }, [isOSCountSuccess, osCountResponse])

    const [hoveredItems, setHoveredItems] = useState<boolean[]>(
        Array(OSCountData.length).fill(false)
    )

    const handleMouseEnter = (index: number) => {
        const updatedHoveredItems = [...hoveredItems]
        updatedHoveredItems[index] = true
        setHoveredItems(updatedHoveredItems)
    }

    const handleMouseLeave = (index: number) => {
        const updatedHoveredItems = [...hoveredItems]
        updatedHoveredItems[index] = false
        setHoveredItems(updatedHoveredItems)
    }

    return (
        <div className='osCountContainer'>
            {dic[language]["dashboard_os_count"]}
            <Stack className='osCountBox' spacing={1}>
                {OSCountData.map((item, index) => {
                    return (
                        <LinearProgressWithLabel
                            key={index}
                            value={(item.count / osTotal) * 100}
                            title={item.os}
                            count={item.count}
                            color={colors[index % colors.length]}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                        />
                    )
                })}
            </Stack>
        </div>
    )
}

export default OSCount

const LinearProgressWithLabel = (
    props: LinearProgressProps & {
        value: number
        title: string
        count: number
    }
) => {
    const language = useSelector(_language);

    return (
        <Stack>
            <Stack direction="row" sx={{ width: '100%' }}>
                <Typography variant="body2">{props.title}</Typography>
            </Stack>
            <Tooltip
                title={`${props.count} ${language === 'en' ? '' : '台'}`}
                placement="top"
                followCursor
                arrow
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', mr: 2 }}>
                        <LinearProgress variant="determinate" {...props} />
                    </Box>
                    <Box sx={{ minWidth: 35, mr: 1 }}>
                        <Typography variant="body1">
                            {`${Math.round(props.value)}%`}
                        </Typography>
                    </Box>
                </Box>
            </Tooltip>
        </Stack>
    )
}
