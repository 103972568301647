/** @format */

import axios from 'axios'
import { elasticRoot, urlRoot, TASK_STATUS } from 'constant/index'
import { store, RootState } from 'redux/store'

import {
    IConnectStatus,
    IDevice,
    IFormtedConnectStatus,
    IFormatedDevice,
    scanning,
    dateForm,
} from 'interface'
import dic, { TLanguages } from 'constant/dictionary'

const axiosInstance = axios.create({
    baseURL: urlRoot,
    headers: {
        'Content-Type': 'application/json',
    },
})

const selectToken = (state: RootState) => state.auth.token

// * set token to header & basic URL
axiosInstance.interceptors.request.use(
    (config) => {
        // 從 redux store 將 token 取出
        let token = selectToken(store.getState())

        // 如果 token 存在的話，則帶入到 headers 當中
        if (token) {
            config.headers['Authorization'] = `${token}`
        }
        return config
    },
    (err) => Promise.reject(err)
)

export const axiosClient = axiosInstance

const clientElastic = axios.create({
    baseURL: elasticRoot,
    headers: {
        'Content-Type': 'application/json',
    },
})

clientElastic.interceptors.request.use(
    (config) => {
        // 從 redux store 將 token 取出
        let token = selectToken(store.getState())

        // 如果 token 存在的話，則帶入到 headers 當中
        if (token) {
            config.headers['Authorization'] = `${token}`
        }
        return config
    },
    (err) => Promise.reject(err)
)

export const axiosElastic = clientElastic

const clientElasticNdjson = axios.create({
    baseURL: elasticRoot,
    headers: {
        'Content-Type': 'application/x-ndjson',
    },
})

clientElasticNdjson.interceptors.request.use(
    (config) => {
        // 從 redux store 將 token 取出
        let token = selectToken(store.getState())

        // 如果 token 存在的話，則帶入到 headers 當中
        if (token) {
            config.headers['Authorization'] = `${token}`
        }
        return config
    },
    (err) => Promise.reject(err)
)

export const axiosElasticNdjson = clientElasticNdjson

/**
 * 如果數字太大，轉成 K, M, G
 * @param num number
 * @returns string
 */
export const processNumber = (num: number): string => {
    if (num >= 1000000000) {
        return `${(num / 1000000000).toFixed(1)}G`
    } else if (num >= 1000000) {
        return `${(num / 1000000).toFixed(1)}M`
    } else if (num >= 1000) {
        return `${(num / 1000).toFixed(1)}K`
    } else {
        return `${num}`
    }
}

/**
 * 將檔案大小轉成 KB, MB, GB
 * @param size number
 * @returns string
 */
export const processFileSize = (size: number): string => {
    if (size >= 1073741824) {
        return `${(size / 1073741824).toFixed(1)}G`
    } else if (size >= 1048576) {
        return `${(size / 1048576).toFixed(1)}M`
    } else if (size >= 1024) {
        return `${(size / 1024).toFixed(1)}K`
    } else {
        return `${size}`
    }
}

/**
 * 替換 Elasticsearch Query 中的特殊字元
 * @param inputString string
 * @returns string
 */
export const replaceInString = (inputString: string) => {
    const characterMap = {
        '"': '_doublequotes_',
        '\\': '_backslash_',
        '/': '_slash_',
        '(': '_leftParentheses_',
        ')': '_rightParentheses_',
        '[': '_leftBracket_',
        ']': '_rightBracket_',
        '*': '_asterisks_',
        '?': '_question_',
        '!': '_exclamation_',
        ':': '_colon_',
        '+': '_plus_',
        '-': '_minus_',
        '~': '_tilde_',
        '^': '_caret_',
        '@': '_at_',
        '=': '_equal_',
        '>': '_moreThan_',
        '<': '_lessThan_',
        ';': '_semicolon_',
        '{': '_rightBrace_',
        '}': '_leftBrace_',
        '&': '_and_',
        '|': '_pipe_',
        '%': '_percent_',
    }

    const regex = new RegExp(`[${Object.keys(characterMap).map((char) => "\\" + char).join('')}]`, 'g')
    return inputString.replace(regex, (match) => characterMap[match as keyof typeof characterMap])
}

/**
 * 將 Elasticsearch Query 中的特殊字元還原
 * @param inputString string
 * @returns string
 */
export const restoreInString = (inputString: string) => {
    const reverseCharacterMap = {
        '_doublequotes_': '"',
        '_backslash_': '\\',
        '_slash_': '/',
        '_leftParentheses_': '(',
        '_rightParentheses_': ')',
        '_leftBracket_': '[',
        '_rightBracket_': ']',
        '_asterisks_': '*',
        '_question_': '?',
        '_exclamation_': '!',
        '_colon_': ':',
        '_plus_': '+',
        '_minus_': '-',
        '_tilde_': '~',
        '_caret_': '^',
        '_at_': '@',
        '_equal_': '=',
        '_moreThan_': '>',
        '_lessThan_': '<',
        '_semicolon_': ';',
        '_rightBrace_': '{',
        '_leftBrace_': '}',
        '_and_': '&',
        '_pipe_': '|',
        '_percent_': '%',
    };

    const regex = new RegExp(`(${Object.keys(reverseCharacterMap).join('|')})`, 'g')
    return inputString.replace(regex, (match) => reverseCharacterMap[match as keyof typeof reverseCharacterMap])
}

/**
 * @param hit example: {
 *    _source: {
 *      _a: 0,
 *      _b: 1,
 *      category: "memory",
 *      memory: {
 *         a: 1,
 *         b: 2,
 *         c: 3
 *      }
 *    }
 * }
 * @returns example: {
 *      a: 1,
 *      b: 2,
 *      c: 3
 * }
 */
export const extractDoc = (hit: any) => hit._source[hit._source.category];

/**
 * @param hit example: {
 *    _source: {
 *      _a: 0,
 *      _b: 1,
 *      category: "memory",
 *      memory: {
 *         a: 1,
 *         b: 2,
 *         c: 3
 *      }
 *    }
 * }
 * @returns example: {
 *      a: 1,
 *      b: 2,
 *      c: 3,
 *      _a: 0,
 *      _b: 1
 * }
 */
export const flattenDoc = (hit: any) => {
    const temp = { ...hit._source, ...extractDoc(hit) };
    delete temp[hit._source.category];
    delete temp.category;
    return temp;
}

/**
 * @param hit example: {
 *    _source: {
 *      _a: 0,
 *      _b: 1,
 *      category: "memory",
 *      memory: {
 *         a: 1,
 *         b: 2,
 *         c: 3
 *      }
 *    }
 * }
 * @returns example: {
 *      memory.a: 1,
 *      memory.b: 2,
 *      memory.c: 3,
 *      _a: 0,
 *      _b: 1
 * }
 */
export const flattenDocStrict = (hit: any) => {
    const tempInner = hit._source[hit._source.category];
    const tICloned: any = {}
    for (let key in tempInner) {
        tICloned[`${hit._source.category}.${key}`] = tempInner[key];
    }
    const temp = { ...hit._source, ...tICloned };
    delete temp[hit._source.category];
    delete temp.category;
    return temp;
}

/**
 * 轉換 unix timestamp 成指定 timezone 的時間格式
 * @param timestamp number
 * @returns string
 */
export function formatTimestamp(timestamp: number): string {
    if (timestamp <= 0) {
        return '- -'
    }

    const date = new Date(timestamp * 1000)
    const tz = store.getState().util.userInfo.timezone || 'Asia/Taipei';
    return date.toLocaleString('zh-CN', { timeZone: tz, hour12: false })
}

export const processTimeField = (data: any) => {
    return {
        ...data,
        accessTime: formatTimestamp(data.accessTime),
        createTime: formatTimestamp(data.createTime),
        date_main: formatTimestamp(data.date_main),
        entryModifiedTime: formatTimestamp(data.entryModifiedTime),
        writeTime: formatTimestamp(data.writeTime),
    };
}

// 轉換成字串
export function convertToStringWithCommas(arr: string[] | null, language: TLanguages): string {
    if (!arr) {
        return '- -'
    } else if (arr.length === 0) {
        return dic[language]['no_group']
    }
    return arr.join(', ')
}

/**
 * 把 hour array 轉換成 string (eg. [1,2,3] -> '1:00,2:00,3:00')
 * @param arr string[] | null
 * @returns string
 */
export function convertToStringArray(arr: string[] | null): string {
    if (!arr) return '-  -'
    else if (arr.length === 0) return '-  -'
    const stringArray = arr.map((item) => {
        const utcDate = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), Number(item), 0, 0, 0));
        const hour = utcDate.toLocaleTimeString('en-US', { hour12: false }).split(':')[0]
        return `${hour}:00`
    })
    return stringArray.join(',')
}

// 轉換成百分比(loading)或時間
// 如果有結束時間則顯示日期時間，否則出現數字progress

// status
// -1: - -
// 0: pending
// 1: sending
// 2: working    ->  get progress
// 3: finished   ->  get finish time
// 4: terminated ->  get message
// 5: failed     ->  get message

const customObjectTime = (data: scanning, language: TLanguages) => {
    if (!data || data.status === TASK_STATUS['unknown']) {
        return '- -'
    } else if (data.status === TASK_STATUS['pending'] || data.status === TASK_STATUS['waitingForExplorer']) {
        return 'Pending...'
    } else if (data.status === TASK_STATUS['sending']) {
        return 'Sending...'
    } else if (data.status === TASK_STATUS['working']) {
        return data.progress
    } else if (data.status === TASK_STATUS['finished']) {
        if (data.message === 'nil') {
            return formatTimestamp(data.finishTime)
        } else {
            return `${formatTimestamp(data.finishTime)}|${data.message}`
        }
    } else if (data.status >= TASK_STATUS['terminated']) {
        return `${data.message}`
    } else {
        return dic[language]['refresh']
    }
}

// 轉換成每月幾號 每日幾點
export const formatObjectTime = (data: dateForm, language: TLanguages) => {
    if (data.date === '' || data.time === '') {
        return '- -'
    }
    const offset = new Date(`01/01/2024 00:00`).getTimezoneOffset() / 60
    const originTime = Number(data.time) - offset
    console.log(originTime, data.date, offset)
    if (originTime >= 24) {
        return language === 'zh' ?
            `每月${Number(data.date) + 1}日 ${originTime - 24}:00` :
            `Every ${Number(data.date) + 1}th at ${originTime - 24}:00`
    } else if (originTime < 0) {
        return language === 'zh' ?
            `每月${Number(data.date) - 1}日 ${originTime + 24}:00` :
            `Every ${Number(data.date) - 1}th at ${originTime + 24}:00`
    } else {
        return language === 'zh' ?
            `每月${data.date}日 ${originTime}:00` :
            `Every ${data.date}th at ${originTime}:00`
    }
}

// 將server拿到的raw data轉換成表格需要的型別與格式
export const customData = (data: IDevice, language: TLanguages) => {
    const result: IFormatedDevice = {
        deviceId: data.deviceId,
        connection: data.connection ? 'true' : 'false',
        InnerIP: data.innerIP,
        deviceName: data.deviceName,
        os: data.os,
        groups: convertToStringWithCommas(data.groups, language),
        detectionMode: data.detectionMode ? 'true' : 'false',
        scanSchedule: convertToStringArray(data.scanSchedule),
        scanFinishTime: customObjectTime(data.scanFinishTime, language),
        collectSchedule: formatObjectTime(data.collectSchedule, language),
        collectFinishTime: customObjectTime(data.collectFinishTime, language),
        fileDownloadDate: formatObjectTime(data.fileDownloadDate, language),
        fileFinishTime: customObjectTime(data.fileFinishTime, language),
        imageFinishTime: customObjectTime(data.imageFinishTime, language),
        yaraRuleFinishTime: customObjectTime(data.yaraRuleFinishTime, language),
        memoryTreeFinishTime: customObjectTime(data.memoryTreeFinishTime, language),
    }
    return result
}

export const customConnectedData = (data: IConnectStatus) => {
    const result: IFormtedConnectStatus = {
        deviceId: data.deviceId,
        connection: data.connection ? 'true' : 'false',
    }
    return result
}

export const fixPaginationNumber = (totalPages: number) => {
    if (totalPages === 0) {
        return []
    } else if (totalPages > 0 && totalPages < 50) {
        return [20]
    } else if (totalPages >= 50 && totalPages < 200) {
        return [20, 50]
    } else if (totalPages >= 200 && totalPages < 500) {
        return [20, 50, 200]
    } else if (totalPages >= 500) {
        return [20, 50, 200, 500]
    } else {
        return []
    }
}


export const matchDiffObjects = (
    originArray: IFormatedDevice[],
    newArray: IFormatedDevice[] | []
) => {
    if (originArray.length === 0) return []
    if (newArray.length === 0) return originArray

    const extractIds = newArray.map((obj) => obj.deviceId) // extractIds the same Ids
    const matchedList = originArray.map((obj) => {
        if (extractIds.includes(obj.deviceId)) {
            return newArray.find((item) => item.deviceId === obj.deviceId)
        }
        return obj
    })
    return matchedList
}

// 依照選取的id，立即更新grid的資料到初始值
export const updateGridInitial = (
    obj: IFormatedDevice,
    selectedHostsIDs: readonly string[],
    commandObj: Partial<IFormatedDevice> //
): IFormatedDevice => {
    if (selectedHostsIDs.includes(obj.deviceId)) {
        console.log({ ...obj, ...commandObj })
        return { ...obj, ...commandObj }
    }
    return obj
}

export const convertTimeStringsToString = (timeStrings: string[]): string => {
    const res = timeStrings
        .map((timeString) => Number(timeString.split(':')[0]))
        .sort((a, b) => a - b)
    return res.join(',')
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export type Order = 'asc' | 'desc'

export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export interface DynamicObject {
    [key: string]: any
}

// 判斷是否有時間資料在物件裡的一個函數  //number and length > 8
export const translateTime = (obj: DynamicObject) => {
    const newObj: DynamicObject = {}
    for (const key in obj) {
        if (key === "processCreateTime") {
            newObj["__pct"] = parseInt(obj[key]);
        }
        if (obj.hasOwnProperty(key)) {
            const value = obj[key]

            if (typeof value === 'number' && value.toString().length > 8) {
                newObj[key] = formatTimestamp(value) // 統一時間格式轉換
            } else {
                newObj[key] = value
            }
        }
    }

    return newObj
}
