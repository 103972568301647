import { ChangeEvent, useState } from "react"
import { BlackWhiteListData } from "../BlackListTableBox"
import { HackListData } from "../ToolSetting"
import { HeadCell } from "../index"
import { useAlert } from "hook/useAlert"
import { _language } from "redux/UtilSlice"
import { useSelector } from "react-redux"
import dic from "constant/dictionary"
import {
    Button,
    Dialog,
    DialogActions,
    IconButton,
    DialogContent,
    DialogContentText,
    TextField
} from "@mui/material"

interface IFormDialog {
    titleText: string
    listType: string
    icon: React.ReactNode
    visible: boolean
    rowData: BlackWhiteListData | HackListData
    updateBlackWhiteTableData?: (newData: BlackWhiteListData, isDelete?: boolean) => void
    updateHackTableData?: (newData: HackListData, isDelete?: boolean) => void
    isApplying: boolean
    headCells: readonly HeadCell[]
    checkBlackWhiteDuplicate?: (newData: BlackWhiteListData) => boolean
    checkHackDuplicate?: (newData: HackListData) => boolean
}

function FormDialog(props: IFormDialog) {
    const {
        titleText,
        listType,
        icon,
        visible,
        rowData,
        updateBlackWhiteTableData,
        updateHackTableData,
        isApplying,
        headCells,
        checkBlackWhiteDuplicate,
        checkHackDuplicate,
    } = props
    const [open, setOpen] = useState(false)
    const language = useSelector(_language)

    const [tempRowData, setTempRowData] = useState<BlackWhiteListData | HackListData>(rowData);
    const [hasChanged, setHasChanged] = useState(false)
    const isBlack = listType === dic[language]['setting_blacklist']
    const isWhite = listType === dic[language]['setting_whitelist']
    const isHack = listType === dic[language]['setting_hacker_tool']

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof BlackWhiteListData | keyof HackListData) => {
        if (e.target.value !== rowData[field]) {
            setHasChanged(true)
        } else {
            setHasChanged(false)
        }
        setTempRowData({
            ...tempRowData,
            [field]: field === 'addingPoint' ? Number(e.target.value) : e.target.value,
        })
    }

    const handleDelete = () => {
        setOpen(false)
        if (isHack) {
            if (updateHackTableData) {
                updateHackTableData(tempRowData as HackListData, true);
            }
        } else {
            if (updateBlackWhiteTableData) {
                updateBlackWhiteTableData(tempRowData as BlackWhiteListData, true);
            }
        }
    }

    const alert = useAlert().showAlert

    const handleConfirm = () => {
        if (isHack) {
            if (updateHackTableData && checkHackDuplicate) {
                if (checkHackDuplicate(tempRowData as HackListData) === false) {
                    setOpen(false)
                    updateHackTableData(tempRowData as HackListData);
                } else {
                    alert(dic[language]['input_repeated'])
                }
            }
        } else {
            if (updateBlackWhiteTableData && checkBlackWhiteDuplicate) {
                console.log('confirm')
                if (checkBlackWhiteDuplicate(tempRowData as BlackWhiteListData) === false) {
                    setOpen(false)
                    updateBlackWhiteTableData(tempRowData as BlackWhiteListData);
                } else {
                    alert(dic[language]['input_repeated'])
                }
            }
        }
    }

    return (
        <div>
            <IconButton
                onClick={() => {
                    setOpen(true)
                    setTempRowData(rowData)
                }}
                size="small"
                style={{ visibility: visible ? 'visible' : 'hidden' }}
            >
                {icon}
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <div
                    style={{
                        height: 35,
                        width: '100%',
                        backgroundColor: '#2196F3',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 16,
                        paddingLeft: 10,
                    }}
                >
                    {`${titleText}${listType}`}
                </div>

                <DialogContent sx={{ width: 350 }}>
                    {headCells.map((headCell, index) => {
                        return (
                            <TextField
                                key={index}
                                required={index === 0}
                                margin="dense"
                                id="name"
                                label={headCell.label}
                                type={headCell.numeric ? 'number' : 'text'}
                                fullWidth
                                variant="standard"
                                value={tempRowData[String(headCell.id)]}
                                onChange={(e) => {
                                    handleFieldChange(e, String(headCell.id))
                                }}
                            />
                        )
                    })}
                    {(((isBlack || isWhite) && tempRowData['filename'] === '') ||
                        (isHack && tempRowData['processName'] === '')) ? (
                        <DialogContentText style={{ color: 'gray' }}>
                            {dic[language]["no_input_data"]}
                        </DialogContentText>
                    ) : hasChanged === false ? (
                        <DialogContentText style={{ color: '#D32F2F' }}>
                            {dic[language]["data_not_change"]}
                        </DialogContentText>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    {titleText !== dic[language]["add"] && (
                        <Button
                            disabled={isApplying}
                            onClick={handleDelete}
                            sx={{ color: '#D32F2F' }}
                        >
                            {dic[language]["delete"]}
                        </Button>
                    )}
                    <Button
                        onClick={handleConfirm}
                        disabled={(((isBlack || isWhite) && tempRowData['filename'] === '') ||
                            (isHack && tempRowData['processName'] === '')) || hasChanged === false}
                    >
                        {titleText}
                    </Button>
                    <Button
                        onClick={() => setOpen(false)}
                        sx={{ color: 'gray' }}
                    >
                        {dic[language]["cancel"]}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FormDialog