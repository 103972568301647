import React, { useState, useEffect } from 'react'
import AnalysisTable, { Column } from "../AnalysisTable"
import { IpTimes, SelectedRow, EarthData, CountryEarthData } from "../Interfaces"
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { IDateRange } from 'interface'
import { sortRows, InitRequest } from '../functions'
import '../../index.scss'
import dic from 'constant/dictionary'
import { useDispatch, useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import { _dateModuleData, filterOtherIP, setDateRange } from 'redux/NetworkSlice'
import { useNavigate } from 'react-router'
import { useAlert } from 'hook/useAlert'

interface ProcessIPProps {
	dateModuleData: IDateRange
	setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
	selectedRow: SelectedRow
	setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
	setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const ProcessIP = (props: ProcessIPProps) => {
	const {
		dateModuleData,
		setEarthData,
		selectedRow,
		setSelectedRow,
		setOpenSnackBar,
	} = props
	const language = useSelector(_language);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const postMutation = usePost<IpTimes[]>();
	const earthMutation = usePost<EarthData>();
	const [ipTimes, setIpTimes] = useState<IpTimes[]>([]);
	const [fetchOrder, setFetchOrder] = useState<'desc' | 'asc'>('desc');
	const networkDateModuleData = useSelector(_dateModuleData);
	const { showAlert } = useAlert();

	const fetchData = () => {
		postMutation.mutate({
			root: `${urlRoot}`,
			route: `${API.processIP}/none/${fetchOrder}`,
			body: InitRequest(
				0, // 分析儀表板沒有用 cache 了
				dateModuleData.startTime,
				dateModuleData.endTime
			),
		}, {
			onSuccess: (res) => {
				setIpTimes(sortRows(res.data || [], 'times', fetchOrder));
			},
			onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.log(error.response?.data.message)
				}
			}
		})
	}

	useEffect(() => {
		fetchData()
	}, [fetchOrder, dateModuleData])

	const fetchAndSetEarthData = (rowData: IpTimes | null) => {
		if (!rowData) return
		earthMutation.mutate({
			root: `${urlRoot}`,
			route: `${API.processIP}/${rowData.ip}/${fetchOrder}`,
			body: InitRequest(
				0, // 分析儀表板沒有用 cache 了
				dateModuleData.startTime,
				dateModuleData.endTime
			),
		}, {
			onSuccess: (res) => {
				if (res.data) {
					setEarthData(res.data);
					if (res.data.connections === null || res.data.connections.length === 0) {
						setOpenSnackBar(true);
					}
				}
			},
			onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.log(error.response?.data.message)
				}
			}
		})
	}

	useEffect(() => {
		fetchAndSetEarthData(selectedRow.processIP)
	}, [selectedRow.processIP])

	const ipTimesColumns: Column<IpTimes, keyof IpTimes>[] = [
		{ key: 'ip', label: 'IP', minWidth: 150 },
		{ key: 'times', label: dic[language]['times'], minWidth: 85 },
	]

	return (
		<div className='tableContainer'>
			<AnalysisTable
				tableTitle={dic[language]['dashboard_table_process_ip']}
				lines={4}
				tableData={ipTimes}
				tableColumns={ipTimesColumns}
				sortBtn={true}
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				isLoading={postMutation.isLoading}
				setFetchOrder={setFetchOrder}
				showDirectBtn
				directBtnCallback={(row) => {
					dispatch(filterOtherIP((row as { ip: string }).ip));
					dispatch(setDateRange({
						...networkDateModuleData,
						startTime: dateModuleData.startTime,
						endTime: dateModuleData.endTime
					}))
					// dispatch(filterTimestamp({
					// 	from: dateModuleData.startTime,
					// 	to: dateModuleData.endTime,
					// }));
					navigate('/memory');
				}}
			/>
		</div>
	)
}

export default ProcessIP
