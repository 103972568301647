import { createSlice } from "@reduxjs/toolkit";
import { RootState } from './store'
import { QueryStatus } from "@tanstack/react-query";

export interface IMemoryTreeNode{
    processId: number
    parentProcessId: number      
    processName: string
    processCreateTime: number
    parentProcessName: string
    parentProcessCreateTime: number
    processPath: string
    userName: string
    isPacked: boolean
    dynamicCommand: string
    isHide: boolean
    child: string[]
    uuid: string
    agent: string
    agentIP: string
    agentName: string
    item_main: string
    date_main: number
    type_main: string
    etc_main: string
    gridRows: IMemoryTreeNode[],
    gridRowsStatus: QueryStatus
}

export interface IDllTreeNode{
    processId: number
    path: string
    processCreateTime: number
    item_main: string
    parentUUID: string
    agent: string
    agentIP: string
}

export interface IAgent{
    id: string
    ip: string
}
export interface memoryTreeState {
    selectedHost: IAgent | null
    isHostSelected: boolean
    selectedNode: IMemoryTreeNode | null
    isNodeSelected: boolean
}

const initialState: memoryTreeState = {
    selectedHost: null,
    isHostSelected: false,
    selectedNode: null,
    isNodeSelected: false
}

export const memoryTreeSlice = createSlice({
    name: 'memoryTree',
    initialState: initialState,
    reducers: {
        initialize: (state) => {
            return initialState;
        },
        setSelectedHost: (state, action) => {
            state.selectedHost = action.payload;
            state.isHostSelected = true;
            state.selectedNode = initialState.selectedNode;
            state.isNodeSelected = false;
        },
        setSelectedNode: (state, action) => {
            state.selectedNode = action.payload;
            state.isNodeSelected = true;
        },
        updateSelectedNodeChildren: (state, action) => {
            if (state.selectedNode !== null){
                state.selectedNode.gridRows = action.payload.gridRows;
                state.selectedNode.gridRowsStatus = action.payload.gridRowsStatus;
            }
        }
    }
});

export const { setSelectedHost, setSelectedNode, initialize, updateSelectedNodeChildren } = memoryTreeSlice.actions
export const _selectedHost = (state:RootState) => state.memoryTree.selectedHost;
export const _isHostSelected = (state:RootState) => state.memoryTree.isHostSelected;
export const _selectedNode = (state:RootState) => state.memoryTree.selectedNode;
export const _isNodeSelected = (state:RootState) => state.memoryTree.isNodeSelected;

export default memoryTreeSlice.reducer;
