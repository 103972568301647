import { BlackWhiteListData } from "../BlackListTableBox"
import { HackListData } from "../ToolSetting"
import { HeadCell } from "../index"
import { Order } from "util/index"
import { visuallyHidden } from '@mui/utils'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Box,
} from "@mui/material"

interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof BlackWhiteListData | keyof HackListData
    ) => void
    order: Order
    orderBy: keyof BlackWhiteListData | keyof HackListData
    headCells: readonly HeadCell[]
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        order,
        orderBy,
        onRequestSort,
        headCells,
    } = props
    const createSortHandler =
        (property: keyof BlackWhiteListData | keyof HackListData) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property)
        }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell
                        style={{ minWidth: headCell.minWidth }}
                        key={
                            index.toString() +
                            (Math.floor(Math.random() * 1000) + 1).toString()
                        }
                        align={'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            IconComponent={ArrowDropDownIcon}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => createSortHandler(String(headCell.id))}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default EnhancedTableHead
