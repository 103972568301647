
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { _language } from 'redux/UtilSlice';
import dic from 'constant/dictionary';

import NetworkConnectionPage from './subpage/NetworkConnectionPage';
import MemoryTreePage from './subpage/MemoryTreePage';
import useDenyIfNot from 'hook/useDenyIfNot';

import 'page/memory/index.scss';
import 'component/TreeNode/treepage.scss'

type tabName = 'network_connection'|'network_memory_list';

const MemoryPage = () => {

    useDenyIfNot('analysis');
    
    const [tabvalue, setTabvalue] = useState<tabName>('network_connection');

    const language = useSelector(_language);
    
    const pageRef = useRef(null);

    return <div className="page">
        <div id="networkContainer" ref={pageRef}>

            <TabContext value={tabvalue}>
                <TabList onChange={(event: React.SyntheticEvent, newValue: tabName) => {
                    setTabvalue(newValue);
                }}>
                    <Tab label={dic[language]['network_connection']} value='network_connection'></Tab>
                    <Tab label={dic[language]['network_memory_list']} value='network_memory_list'></Tab>
                </TabList>
                <TabPanel value="network_connection" className="network_connection">
                    <NetworkConnectionPage/>
                </TabPanel>
                <TabPanel value="network_memory_list" className="treepage network_memory_list">
                    <MemoryTreePage pageRef={pageRef}/>
                </TabPanel>
            </TabContext>
        </div>
    </div>
}

export default MemoryPage;