import React from 'react'
import {
    ActionPlugTitle,
    StraightSeparator,
} from '../../StyledComponents'
import RadioGroupEnd from '../../SettingBarItem/RadioGroupEnd'
import DateAndTimeSelector from '../../SettingBarItem/DateAndTimeSelector/DateAndTimeSelector'
import ActionButton from './CommonComponents/ActionButton'
import TimeMissionButton from './CommonComponents/TimeMissionButton'
import { IFormatedDevice } from 'interface'
import { useDispatch, useSelector } from 'react-redux'
import { _scheduleDownload, _scheduleDownloadTime, _scheduleForensics, _scheduleForensicsTime, setScheduleForensics, setScheduleForensicsTime } from 'redux/DetectSlice'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

interface ActionPlugCollectProps {
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
    ubuntuNumber: number
}

const ActionPlugCollect = (props: ActionPlugCollectProps) => {
    const { setData, data, ubuntuNumber } = props
    const dispatch = useDispatch()
    const language = useSelector(_language)

    return (
        <div className='actionPlugContainer'>
            <ActionPlugTitle>
                {dic[language]['detect_collect_schedule']}
            </ActionPlugTitle>
            <RadioGroupEnd setTrueOrFalse={input => dispatch(setScheduleForensics(input))} />
            <DateAndTimeSelector 
                timeSelector={_scheduleForensicsTime}
                timeSetterDispatcher={setScheduleForensicsTime}
            />
            <TimeMissionButton />
            <StraightSeparator />
            <ActionPlugTitle>
                {dic[language]['detect_task_type_collect']}
            </ActionPlugTitle>
            <ActionButton data={data} setData={setData} ubuntuNumber={ubuntuNumber} />
        </div>
    )
}

export default ActionPlugCollect
