import {
    Chip,
    Typography,
    Stack,
} from '@mui/material'
import { ChangeEvent, useState } from 'react'

import { IFormatedDevice } from 'interface'
import FilterDrop from '../FilterDrop.tsx/FilterDrop'
import SearchBar from '../../../../component/SearchBar'

import { SelectedBoxes } from 'interface'
import { useParams } from 'react-router-dom'
import {
    TCategory,
    TState,
    translateCategory,
    translateState,
} from '../../../home/subpage/detectdashboard/component/PointStatus'
import { _searchKeys, RemoveSearchKey, AddSearchKey } from 'redux/DetectSlice'
import { _language } from 'redux/UtilSlice'
import { useSelector, useDispatch } from 'react-redux'
import dic from 'constant/dictionary'

interface EnhancedTableToolbarProps {
    numSelected: number
    setDataQuery: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    searchRows: (key: string[]) => void
    originData: IFormatedDevice[]
    filter: SelectedBoxes
    setFilter: React.Dispatch<React.SetStateAction<SelectedBoxes>>
    isCondition: boolean
    setIsCondition: React.Dispatch<React.SetStateAction<boolean>>
}

export function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { filter, setFilter, isCondition, setIsCondition } =
        props
    const language = useSelector(_language)
    const [hint, setHint] = useState<string>(dic[language]['search'])
    const [key, setKey] = useState<string>('')
    const searchKeys = useSelector(_searchKeys)
    const dispatch = useDispatch()

    const params = useParams()
    const category = params.category as TCategory
    const state = params.state as TState

    const searchKeyWords = (key: string) => {
        if (key === '') return
        dispatch(AddSearchKey(key))
        setKey('')
    }

    const onChangeFunc = (e: ChangeEvent<HTMLInputElement>) => {
        setHint(dic[language]['search'])
        setKey(e.target.value)
    }

    const cancelQuery = () => {
        setIsCondition(false)
        props.setDataQuery(props.originData)
    }

    return (
        <div className='toolBar'>
            <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
            >
                <div
                    style={{
                        width: 500,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <FilterDrop filter={filter} setFilter={setFilter} />
                    {/* <FilterAltIcon style={{ color: '#BDBDBD', cursor: 'pointer', marginRight: 10 }} fontSize="large" /> */}
                    <SearchBar
                        labelValue={hint}
                        query={key}
                        setQuery={setKey}
                        onChangeFunc={onChangeFunc}
                        onSearchFunc={searchKeyWords}
                    />
                    {isCondition && (
                        <Chip
                            label={`${translateCategory[`${category}`]} - ${
                                translateState[`${state}`]
                            }`}
                            style={{ marginLeft: 20 }}
                            onDelete={cancelQuery}
                        />
                    )}
                    {searchKeys.length > 0 && (
                        <Stack direction="row" spacing={1}>
                            {searchKeys.map((key, index) => (
                                <Chip
                                    key={index}
                                    label={key}
                                    onDelete={() => {
                                        dispatch(RemoveSearchKey(key))
                                    }}
                                />
                            ))}
                        </Stack>
                    )}
                </div>
            </Typography>
        </div>
    )
}
