
import { useMutation } from '@tanstack/react-query'
import { _analysisData, analysisState, setDataFromTopAnalysisButton, setDateRange, setForensicsSelectedData, setGroups, setLeftList, setTemplateList } from 'redux/AnalysisSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import useElasticQueryFactory, { IMutationQuery } from './useElasticQueryFactory';
import { axiosElastic, axiosElasticNdjson } from '../../util';
import { elasticRoot } from 'constant/index';
import { addReportRow, clearReportRows } from 'redux/ReportSlice';
import { IAnalaysisIndexNode } from 'page/analysis/constant/indexTree';
import useFetchReportPreselect from './useFetchReportPreselect';
import { setElasticRestarting } from 'redux/UtilSlice';
import { SortModel } from 'redux/NetworkSlice';

const useFetchAnalysis = (factory: ReturnType<typeof useElasticQueryFactory>) => {
    
    const analysisData: analysisState = useSelector(_analysisData);
    const dispatch = useDispatch();
    const reportPreselect = useFetchReportPreselect();

    const {
        selectedHostsIDs,
        dateRange,
        mainKeyword,
    } = analysisData;

    const { generateChart, generateTable, generateLeftListCount, filteredIndexTree } = factory;
    
    // 取得下方表格資料
    const fetchTable = useMutation({
        mutationFn: (mutationData: IMutationQuery) => {
            return axiosElastic.post(mutationData.url, mutationData.body)
        },
        onSuccess: (res) => {
            handleElasticRestart(res.data);
        },
        retryDelay: 1000,
    })

    // 取得左方列表的數量
    const fetchListCount = useMutation({
        mutationFn: (mutationData: any) => {
            return axiosElasticNdjson.post(
                mutationData.url,
                mutationData.body
            )
        },
        onSuccess: (res) => {
            handleElasticRestart(res.data);
        },
        retryDelay: 1000,
    })

    // 取得中上圖表的資料
    const fetchChart = useMutation({
        mutationFn: (mutationData: any) => {
            return axiosElasticNdjson.post(
                mutationData.url,
                mutationData.body
            )
        },
        onSuccess: (res) => {
            handleElasticRestart(res.data);
        },
        retryDelay: 1000,
    })

    const mutateSelectedIndex = (indexList: IAnalaysisIndexNode[], sort: SortModel = {field: '', sort: 'asc'}) => {
        fetchTable.mutate(generateTable(indexList, undefined ,sort));
        fetchChart.mutate(generateChart(indexList));
    }

    const mutateAfter = (indexList: IAnalaysisIndexNode[], lastRow: {lastUUID: string, lastMainDate: number, lastSort?: any}, sort: SortModel = {field: '', sort: 'asc'}) => {
        fetchTable.mutate(generateTable(indexList, lastRow, sort));
    }

    const fullMutate = () => {
        try {
            // 發送取得中上圖表資料的請求
            fetchChart.mutate(generateChart())
            // 發送取得左方列表數量的請求
            fetchListCount.mutate(generateLeftListCount())
            // 發送取得下方 table 資料的請求
            fetchTable.mutate(generateTable())

            reportPreselect.mutate({
                startTime: dateRange.startTime,
                endTime: dateRange.endTime
            });

            // DEPRECATED CODE BELOW (not deleted in case there's something I missed to do while refactoring )
            // const afterResponseState = {
            //     mainKeyword: mainKeyword,
            //     subKeywordList: mainKeyword && mainKeyword !== "" ? [mainKeyword] : [], // 子搜尋關鍵字重製       //只要subSearchKeywordList有更動，就會觸發fetchElasticSearch
            //     fromIndex: 0, // 從第幾筆開始
            //     size: 999, // 一次取得幾筆
            //     selectedAgentId: [...selectedHostsIDs], // 所有被選取的id列表
            //     indexList: filteredIndexTree, // 所有index列表
            //     timeRange: {
            //         // 時間範圍
            //         startTime: dateRange.startTime,
            //         endTime: dateRange.endTime,
            //     },
            //     leftSelectedList: {
            //         // 左方列表
            //         analysisIndex: filteredIndexTree,
            //         selectedIndex: [],
            //     },
            //     chartData: 'responseFromElasticsearchForChart', // 圖表資料
            //     tableData: 'responseFromElasticsearchForTable', // 表格資料
            //     detailData: {
            //         // 詳細資料
            //     },
            // }
            // dispatch(setDataFromTopAnalysisButton(afterResponseState))
            dispatch(setLeftList({
                analysisIndex: factory.filteredIndexTree,
                selectedIndex: [],
            }));
            dispatch(setDateRange({
                startTime: dateRange.startTime,
                endTime: dateRange.endTime,
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const handleElasticRestart = (result: any) => {
        const restarting = !result.isSuccess && result.message === "Elastic is restarting";
        dispatch(setElasticRestarting(restarting));
    }

    return {
        fullMutate,
        mutateSelectedIndex,
        mutateAfter,
        fetchChart,
        fetchTable,
        fetchListCount
    }
}

export default useFetchAnalysis;