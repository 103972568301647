import React, { useEffect, useState } from 'react'
import AnalysisTable, { Column } from "../AnalysisTable"
import { IpNameProcess, EarthData, SelectedRow, RiskLevelCount, CountryEarthData } from "../Interfaces"
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { IDateRange } from 'interface'
import { InitRequest, InitRiskLevelCount } from '../functions'
import { processNumber } from 'util/index'
import '../../index.scss'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'
import { useDispatch, useSelector } from 'react-redux'
import { filterProcess } from 'redux/NetworkSlice'
import { useNavigate } from 'react-router'
import { useAlert } from 'hook/useAlert'


interface RiskLevelProps {
    dateModuleData: IDateRange
    setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const RiskLevel = (props: RiskLevelProps) => {
    const {
        dateModuleData,
        setEarthData,
        selectedRow,
        setSelectedRow,
        setOpenSnackBar,
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const language = useSelector(_language);
    const postMutation = usePost<IpNameProcess[]>();
    const countMutation = usePost<RiskLevelCount>();
    const earthMutation = usePost<EarthData>();
    const { showAlert } = useAlert();
    const [levelCount, setLevelCount] = useState<RiskLevelCount>(InitRiskLevelCount());
    const [ipDeviceProcess, setIpDeviceProcess] = useState<IpNameProcess[]>([]);
    const [level, setLevel] = useState('Level4');

    const fetchCountData = () => {
        countMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.riskLevel}/count/all`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                setLevelCount(Object.keys(res.data).length ? res.data : InitRiskLevelCount());
            },
            onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.error(error.response?.data.message)
				}
			}
        })
    }

    useEffect(() => {
        fetchCountData()
    }, [dateModuleData])

    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.riskLevel}/none/${level}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                setIpDeviceProcess(res.data || []);
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [level, dateModuleData])

    const fetchDetail = async (rowData: IpNameProcess | null) => {
        if (!rowData) return null;
        try {
            const response = await earthMutation.mutateAsync({
                root: `${urlRoot}`,
                route: `${API.riskLevel}/${rowData.id}/${level}`,
                body: InitRequest(
                    0, // 分析儀表板沒有用 cache 了
                    dateModuleData.startTime,
                    dateModuleData.endTime
                ),
            })
            return response;
        } catch (err) {
            console.log(err)
            return null;
        }
    }

    useEffect(() => {
        (async () => {
            const response = await fetchDetail(selectedRow.riskLevel)
            if (response) {
                if (response.data) {
                    setEarthData(response.data)
                    if (response.data.connections === null || response.data.connections.length === 0) {
                        setOpenSnackBar(true)
                    }
                }
            }
        })()
    }, [selectedRow.riskLevel])

    const IpDeviceProcessColumns: Column<IpNameProcess, keyof IpNameProcess>[] = [
        { key: 'ip', label: 'IP', minWidth: 110 },
        { key: 'name', label: dic[language]["agent_name"], minWidth: 85 },
        { key: 'process', label: dic[language]["highest_process"], minWidth: 120 },
    ]

    return (
        <>
            <div className='riskLevelCountContainer'>
                <RiskLevelBox pos={0} level={'LV.4'} count={levelCount.level4} />
                <RiskLevelBox pos={1} level={'LV.3'} count={levelCount.level3} />
                <RiskLevelBox pos={2} level={'LV.2'} count={levelCount.level2} />
            </div>
            <div className="tableContainer">
                <AnalysisTable
                    tableTitle={dic[language]["risk_level_4"]}
                    lines={6.5}
                    tableData={ipDeviceProcess}
                    tableColumns={IpDeviceProcessColumns}
                    level={level}
                    setLevel={setLevel}
                    isLoading={postMutation.isLoading}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    showDirectBtn
                    directBtnCallback={(row) => {
                        (async () => {
                            const response = await fetchDetail(row);
                            if (response === null) return;
                            if (response.data.connections === null || response.data.connections.length === 0) {
                                setOpenSnackBar(true)
                            } else {
                                dispatch(filterProcess({
                                    processId: row.processID,
                                    processCreateTime: row.processCreateTime,
                                    agent: row.id
                                }))
                                navigate('/memory');
                            }
                        })()
                    }}
                />
            </div>
        </>
    )
}

export default RiskLevel

interface RiskLevelBoxProps {
    pos: number
    level: string
    count: number
}

const RiskLevelBox = (props: RiskLevelBoxProps) => {
    const language = useSelector(_language);
    return (
        <Tooltip
            followCursor
            title={props.count}
        >
            <Paper
                square={false}
                elevation={1}
                className="riskLevelBox"
                sx={{
                    margin: props.pos === 1 ? '0px 8px' : '0px',
                }}
            >
                <Stack sx={{ margin: '8px' }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <WarningAmberIcon sx={{
                            color: 'rgba(190, 190, 190, 1)'
                        }} />
                        <span style={{
                            fontWeight: '900',
                            fontSize: '24px',
                            color: props.pos === 0 ? 'rgba(211, 47, 47, 1)' :
                                props.pos === 1 ? 'rgba(255, 160, 0, 1)' : 'rgba(56, 142, 60, 1)',
                            opacity: '0.6'
                        }}>
                            {props.level}
                        </span>
                    </Stack>
                    <span style={{
                        fontWeight: '900',
                        fontSize: '24px',
                        color: props.pos === 0 ? 'rgba(211, 47, 47, 1)' :
                            props.pos === 1 ? 'rgba(255, 160, 0, 1)' : 'rgba(56, 142, 60, 1)',
                    }}>
                        {processNumber(props.count)}
                    </span>
                    <span className='riskLevelBoxText'>
                        {dic[language]["agent_count"]}
                    </span>
                </Stack>
            </Paper>
        </Tooltip>
    )
}
