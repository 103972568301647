// TODO: move this to common components
import { Checkbox } from "@mui/material"

interface ICommonSelectForm {
    value: boolean
    title: string
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CheckInput = (props: ICommonSelectForm) => {
    const { value, title, handleChange } = props
    return (
        <div className='flex_align_items'>
            <Checkbox
                size="small"
                checked={value}
                onChange={handleChange}
            />
            <div>{title}</div>
        </div>
    )
}

export default CheckInput