import './style.scss'
import { MouseEvent, Ref, forwardRef, useEffect, useRef, useState } from 'react'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { _language } from 'redux/UtilSlice';
import { useSelector } from 'react-redux';
import dic from 'constant/dictionary';

interface IRightBarProps{
    children?: React.ReactNode
    closeButton?: React.ReactNode
    open: boolean
}

const RightBar = (props: IRightBarProps) => {
    const {children, open} = props;
    const language = useSelector(_language);

    let originalX = useRef<number>(0);
    const [deltaWidth, setDeltaWidth] = useState(0);

    const onDragStart = (e: MouseEvent<HTMLDivElement>) =>{
        originalX.current = e.clientX;
    }

    const onDragEnd = (e: MouseEvent<HTMLDivElement>) => {
        originalX.current = e.clientX;
    }

    const onDrag = (e: MouseEvent<HTMLDivElement>) => {
        if (e.clientX === 0) return;
        setDeltaWidth(originalX.current-e.clientX);
    }

    const defaultWidth = 300;
    const minWidth = 300;
    const maxWidth = 1000;

    return (
        <div id="rightBar" className={!open?"hidden":""} style={{width: ((deltaWidth+defaultWidth)<minWidth?minWidth:(deltaWidth+defaultWidth)>maxWidth?maxWidth:(deltaWidth+defaultWidth)) + "px"}}>
            <div className='resizeHandle' draggable onDragStart={onDragStart} onDrag={onDrag} onDragEnd={onDragEnd}/>
            <div className="container scrollbarY">
                <p className="title">
                    {props.closeButton} {dic[language]['right_bar_title']}
                </p>
                {children}
            </div>
        </div>
    )
}

export const RightBarCloseButton = (props:{onClick:any}) => {
    return <span onClick={props.onClick}><KeyboardDoubleArrowRightIcon /></span>
}

export default RightBar
