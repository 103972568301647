/** @format */

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import React, { useContext } from 'react'
//import { DetectContext } from '../../../AppContext/DetectProvider'
import ActionButton from './ActionPlug/CommonComponents/ActionButton'
import { IFormatedDevice } from 'interface'
import { useDispatch, useSelector } from 'react-redux'
import { _dialogOpen, setDialogOpen } from 'redux/DetectSlice'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

interface MissionAlertProps {
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
}

const MissionAlert = (props: MissionAlertProps) => {
    const { setData, data } = props
    // const { dialogOpen, setDialogOpen } = useContext(DetectContext)
    const dialogOpen = useSelector(_dialogOpen);
    const dispatch = useDispatch();
    const language = useSelector(_language)

    const handleClose = () => {
        dispatch(setDialogOpen(false));
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '444px',
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: '#D32F2F',
                    color: 'white',
                    padding: '5px 15px',
                    fontSize: '1rem',
                }}
            >
                {dic[language]['alert']}
            </DialogTitle>
            <DialogContent style={{ padding: '20px 10px 5px 10px' }}>
                <DialogContentText
                    style={{
                        color: 'black',
                        fontSize: '1rem',
                        padding: '5px 10px',
                    }}
                >
                    {dic[language]['detect_task_terminate_alert']}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ActionButton
                    data={data}
                    name={dic[language]['detect_task_terminate_confirm']}
                    isColor={true}
                    setData={setData}
                />{' '}
                {/* for stop mission button */}
                <Button
                    onClick={handleClose}
                    sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                >
                    {dic[language]['cancel']}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MissionAlert
