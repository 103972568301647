import { FormControl, FormLabel } from "@mui/material"
import TimezoneSelect, { ITimezoneOption } from "react-timezone-select"
import { _displayMode } from "redux/UtilSlice";
import { useSelector } from "react-redux";

interface TZSelectInterface {
    value: string
    title: string
    required: boolean
    handleChange: (timezone: ITimezoneOption) => void
}

const TZSelect = (props: TZSelectInterface) => {
    const { value, title, required, handleChange } = props;
    const displayMode = useSelector(_displayMode);

    const selectStyles = {
        dark: {
            control: (styles: any) => ({
                ...styles,
                backgroundColor: '#414141',
                borderColor: '#727272',
                color: '#fff',
            }),
            menuList: (styles: any) => ({
                ...styles,
                backgroundColor: '#414141',
                color: '#fff',
                "::-webkit-scrollbar": {
                    width: "8px",
                    height: "8px",
                    background: "transparent",
                },
                "::-webkit-scrollbar-button": {
                    background: 'transparent',
                },
                "::-webkit-scrollbar-track": {
                    backgroundColor: 'transparent',
                },
                "::-webkit-scrollbar-thumb": {
                    borderRadius: "8px",
                    margin: '0px 1rem',
                    backgroundColor: '#D9D9D9',
                    border: 'transparent 2px solid',
                },
                "::-webkit-scrollbar-corner": {
                    backgroundColor: 'transparent',
                },
            }),
            singleValue: (styles: any) => ({
                ...styles,
                color: '#fff',
            }),
            option: (styles: any, { isFocused, isSelected }: any) => ({
                ...styles,
                backgroundColor: isFocused
                    ? '#A9A9A9'
                    : isSelected
                        ? '#7D7D7D'
                        : '#414141',
                color: '#fff',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: '#7D7D7D',
                },
            }),
            input: (styles: any) => ({
                ...styles,
                color: '#fff',
            }),
            placeholder: (styles: any) => ({
                ...styles,
                color: '#aaa',
            }),
        },
        light: {
            control: (styles: any) => ({
                ...styles,
                backgroundColor: 'white',
                borderColor: '#ccc',
                color: '#333',
            }),
            menuList: (styles: any) => ({
                ...styles,
                backgroundColor: 'white',
                color: '#333',
            }),
            singleValue: (styles: any) => ({
                ...styles,
                color: '#333',
            }),
            option: (styles: any, { isFocused, isSelected }: any) => ({
                ...styles,
                backgroundColor: isFocused
                    ? '#eee'
                    : isSelected
                        ? '#ddd'
                        : 'white',
                color: '#333',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: '#ddd',
                },
            }),
            input: (styles: any) => ({
                ...styles,
                color: '#333',
            }),
            placeholder: (styles: any) => ({
                ...styles,
                color: '#999',
            }),
        },
    };

    return (
        <FormControl
            sx={{ width: '100%', margin: '0.5rem 0' }}
        >
            <FormLabel
                required={required}
                component="legend"
                sx={{ marginBottom: '0.3rem' }}
            >
                {title}
            </FormLabel>
            <TimezoneSelect
                value={value}
                onChange={handleChange}
                id="timezone-selector"
                styles={selectStyles[displayMode]}
                menuPortalTarget={document.getElementById("timezone-selector")}
                menuPosition="fixed"
                menuPlacement="auto"
            />
        </FormControl>
    )
}

export default TZSelect
