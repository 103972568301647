import ServerStatus from './component/ServerStatus'
import ConnectStatus from './component/ConnectStatus'
import OSCount from './component/OSCount'
import UserTask from './component/UserTask'
import BottomDashBoard from './component/BottomDashBoard'
import SwitchDashboardButton from "./component/SwitchDashboardButton"
import TopBarTitle from "./component/TopBarTitle"
import DarkModeBtn from 'component/DarkModeBtn'
import '../../index.scss'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import useDenyIfNot from 'hook/useDenyIfNot'

const DetectDashboard = () => {
    useDenyIfNot('dashboard');
    
    const language = useSelector(_language);

    return (
        <div id="DetectDashboard" className='dashboard'>
            <div className='topBarContainer'>
                <SwitchDashboardButton/>
                <div className="topBarTitleContainer">
                    <TopBarTitle title={dic[language]["detect_dashboard_title"]} />
                </div>
                <div className="detectDarkModeButtonContainer">
                    <DarkModeBtn />
                </div>
            </div>
            <ServerStatus/>
            <div className='firstLineContainer'>
                <ConnectStatus />
                <OSCount />
                <UserTask />
            </div>
            <BottomDashBoard />
        </div>
    )
}


export default DetectDashboard