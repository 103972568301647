/** @format */

import { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { login, _token, logout } from 'redux/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { API, urlRoot } from 'constant/index'
import { useQuery } from '@tanstack/react-query'
import CommonHeader from '../component/CommonHeader'
import { _translatedPermission } from 'redux/UtilSlice'
import DownloadManager from 'component/DownloadManager'

interface IFetchToken {
    success: boolean
    message: string
    user: {
        token: string
        username: string
    }
}

export const fetchToken = async (
    token: string | undefined
): Promise<IFetchToken> => {
    const res = await fetch(`${urlRoot}${API.loginWithToken}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token }), 
        signal: AbortSignal.timeout(2500)
    }).then((res) => res.json())
    .catch((err) => console.log(err))
    return res
}

const ProtectedRoutes = () => {
    const tokenAccess = useSelector(_token);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tokenQuery = useQuery({
        queryKey: ['verifyToken', tokenAccess],
        queryFn: () => {
            if (!tokenAccess) {
                return new Promise<IFetchToken>((resolve) => {
                    resolve({
                        success: false,
                        message: 'empty token',
                        user: {
                            token: '',
                            username: ''
                        }
                    })
                })
            }
            return fetchToken(tokenAccess)
        },
        retry: 1,
    })

    useEffect(() => {
        if (tokenQuery.data) {
            if (tokenQuery.isSuccess && tokenQuery.data.success) {
                dispatch(login(tokenAccess))
            } else {
                dispatch(logout())
                navigate('/login')
            }
        }
    }, [tokenAccess, tokenQuery.data])

    return tokenAccess ?
        <div style={{ height: '100vh' }}>
            <CommonHeader />
            <DownloadManager/>
            <Outlet />
        </div> : null
}

export default ProtectedRoutes
