/** @format */

import { useLocation, useNavigate } from 'react-router-dom'
import LogOutButton from './LogOutButton'
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, Switch } from '@mui/material'
import dic from 'constant/dictionary'
import { _language, _translatedPermission, _userInfo } from 'redux/UtilSlice'
import { _isGlobalDirty, setGlobalDirty } from 'redux/SettingSlice'
import { setDisplayMode, _displayMode } from 'redux/UtilSlice'
import { useSelector, useDispatch } from 'react-redux'
import { useAlert } from 'hook/useAlert'
import ComputerIcon from '@mui/icons-material/Computer';
import LanIcon from '@mui/icons-material/Lan';
import DiscFullIcon from '@mui/icons-material/DiscFull';
import BadgeIcon from '@mui/icons-material/Badge';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SettingsIcon from '@mui/icons-material/Settings';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FaceIcon from '@mui/icons-material/Face';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';

import { useState } from 'react'
import '../index.scss';
import { logout } from 'redux/AuthSlice'

interface routerObj {
    name: string
    path: string
    icon: React.ReactElement
}

const SettingSideBar = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const language = useSelector(_language);
    const isGlobalDirty = useSelector(_isGlobalDirty);
    const alert = useAlert().showAlert;
    const dispatch = useDispatch();
    const displayMode = useSelector(_displayMode);
    const userInfo = useSelector(_userInfo);
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const permission = useSelector(_translatedPermission);

    const handleLogOut = () => {
        alert(dic[language]["setting_logout_confirm"], () => {
            dispatch(logout());
        })
    }

    const routerList: routerObj[] = [
        {
            name: dic[language]["setting_agent_actions"],
            path: '/setting/SettingAgentActions',
            icon: <ComputerIcon />
        },
        ...(permission.includes('setting')?[
            {
                name: dic[language]["setting_group"],
                path: '/setting/SettingGroup',
                icon: <LanIcon />
            },
            {
                name: dic[language]["setting_image"],
                path: '/setting/SettingImage',
                icon: <DiscFullIcon />
            },
            {
                name: dic[language]["setting_white_black_keyword_list"],
                path: '/setting/SettingWhiteList',
                icon: <ChecklistIcon />
            },
            {
                name: 'IoC',
                path: '/setting/SettingIoC',
                icon: <DocumentScannerIcon />
            },
            {
                name: dic[language]['setting_dt_import'],
                path: '/setting/SettingDT',
                icon: <FileUploadIcon />
            },
            {
                name: dic[language]["setting_software"],
                path: '/setting/SettingSoftware',
                icon: <SettingsIcon />
            },
            // disabled pages
            // {
            //     name: dic[language]["setting_server"],
            //     path: '/setting/SettingServer',
            //     icon: <></>
            // },
            // {
            //     name: dic[language]["setting_system"],
            //     path: '/setting/SettingSystem',
            //     icon: <></>
            // },
        ]:[]),
        ...(permission.includes('user')?[
            {
                name: dic[language]["setting_user_permission"],
                path: '/setting/SettingUserPermission',
                icon: <BadgeIcon />
            }
        ]:[])
    ]

    return (
        <>
        <div id="settingNav">
            <List component="nav" sx={{ color: 'text.primary' }}>
                <ListItemButton onClick={(event) => {
                    setMenuOpen(true);
                    setAnchorEl(event.currentTarget);
                }}>
                    <ListItemAvatar>
                        <Avatar>
                            <FaceIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={userInfo.username} secondary={userInfo.email} />
                    <ExpandMoreIcon/>
                </ListItemButton>
                <Divider/>
                {routerList.map((item: routerObj, index: number) => {
                    return (
                        <ListItemButton
                            key={index}
                            selected={pathname === item.path}
                            onClick={() => {
                                if (isGlobalDirty) {
                                    alert(dic[language]['leave_alert_hint'], () => {
                                        dispatch(setGlobalDirty(false))
                                        navigate(item.path)
                                    })
                                } else {
                                    navigate(item.path)
                                }
                            }}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    )
                })}
            </List>
        </div>
            <Menu
                MenuListProps={{
                    id: "settingUserMenu"
                }}
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
            >
                <ListItemButton
                    onClick={() => dispatch(setDisplayMode(displayMode === 'dark' ? 'light' : 'dark'))}
                >
                    <ListItemIcon>
                        <DarkModeIcon />
                    </ListItemIcon>
                    <ListItemText primary={dic[language]['dark_mode']} />
                    <Switch checked={displayMode === 'dark'} />
                </ListItemButton>
                <ListItemButton onClick={() => {handleLogOut();}}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={dic[language]['setting_logout']} />
                </ListItemButton>
            </Menu>
        </>
    )
}

export default SettingSideBar
