import {
    Stack,
    Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useGet } from 'hook/useGet';
import { _language } from 'redux/UtilSlice';
import { useSelector } from 'react-redux';
import dic from 'constant/dictionary';
import { DataGridPro, GridColDef, GridRowSelectionModel, gridClasses } from '@mui/x-data-grid-pro';
import { API, urlRoot } from 'constant/index';
import KeywordFormDialog from './KeywordFormDialog';
import TableToolbar from './TableToolBar';

export interface KeywordData {
    id: string
    keyword: string
    reason: string
}

export default function KeywordList() {
    const [selectedIDs, setSelectedIDs] = useState<readonly string[]>([]);
    const [searchKeywordList, setSearchKeywordList] = useState<string[]>([]);
    const [rows, setRows] = useState<KeywordData[]>([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const language = useSelector(_language);

    const {
        data: defaultKeywordsList,
        refetch: refetchDefaultKeywordsList,
        isSuccess: isDefaultKeywordsListSuccess,
    } = useGet<KeywordData[]>({
        query: 'getDefaultKeywordsList',
        root: `${urlRoot}`,
        route: `${API.keywordlist}`,
    })

    useEffect(() => {
        if (isDefaultKeywordsListSuccess && defaultKeywordsList.data) {
            if (searchKeywordList.length === 0) {
                setRows(defaultKeywordsList.data);
            } else {
                // filter out the rows that don't contain all the keywords
                const filteredRows = defaultKeywordsList.data.filter((row) => {
                    return searchKeywordList.every((keyword) => {
                        return row.keyword.toLowerCase().includes(keyword.toLowerCase()) ||
                            row.reason.toLowerCase().includes(keyword.toLowerCase());
                    })
                })
                setRows(filteredRows);
            }
        }
    }, [isDefaultKeywordsListSuccess, defaultKeywordsList, searchKeywordList])

    const keywordColumns: GridColDef[] = [
        { field: 'keyword', headerName: dic[language]['setting_keyword_list_name'], flex: 1 },
        {
            field: 'reason', headerName: dic[language]['setting_keyword_list_reason'], flex: 1,
            renderCell: ({ value, row }) => {
                return (
                    <Stack direction="row" justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                        <div>{value}</div>
                        <KeywordFormDialog
                            titleText={dic[language]['update']}
                            inputLableList={[dic[language]['setting_keyword_list_name'], dic[language]['setting_keyword_list_reason']]}
                            refetchDefaultKeywordsList={refetchDefaultKeywordsList}
                            tableData={row}
                            rows={rows}
                        />
                    </Stack>
                )
            }
        },
    ]

    return (
        <Box className='Box'>
            <TableToolbar
                selectedList={selectedIDs}
                searchKeywordList={searchKeywordList}
                setSearchKeywordList={setSearchKeywordList}
                refetchDefaultKeywordsList={refetchDefaultKeywordsList}
                rows={rows}
            />
            <DataGridPro
                rows={rows}
                rowHeight={34}
                columnHeaderHeight={42}
                columns={keywordColumns}
                disableRowSelectionOnClick
                hideFooterSelectedRowCount
                checkboxSelection
                pagination
                initialState={{ pagination: { paginationModel: { pageSize: 25, page: 0 } } }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                    setSelectedIDs(newRowSelectionModel as string[])
                }}
                rowSelectionModel={rowSelectionModel}
                sx={{
                    maxHeight: 'calc(100% - 4rem)',
                    bgcolor: 'background.default',
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .actionButton": {
                        opacity: 0
                    },
                    [`& .${gridClasses.row}:hover`]: {
                        ".actionButton": {
                            opacity: 1
                        }
                    }
                }}
            />
        </Box>
    )
}
