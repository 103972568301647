import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimeClock } from '@mui/x-date-pickers/TimeClock'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Dayjs } from 'dayjs'
import './TimeSelector.css'

interface ChildProps {
    onParamChange: (param: number) => void
    childProps: number
}

const TimeSelector: React.FC<ChildProps> = ({
    childProps,
    onParamChange,
}) => {
    // 時間1~24
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'end',
                padding: '0px 15px',
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimeClock
                    ampmInClock
                    views={['hours']}
                    onChange={
                        (newValue: Dayjs | null) => {
                            onParamChange(newValue?.hour() || 0)
                        }
                    }
                    sx={{ width: '252px' }}
                />
            </LocalizationProvider>
        </div>
    )
}

export default TimeSelector
