import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {RootState} from './store'
import {IAllFilesDropDownData, IForensicDropDownData, IDateRange, IGenerateGroup, IMemoryDropDownData} from 'interface'
import { IelasticQuery } from "../page/analysis/component/ElasticGrid/util";
import dayjs from 'dayjs'
import { IAnalaysisIndexNode } from "page/analysis/constant/indexTree";
import { SortModel } from "./NetworkSlice";
import TDictionaryKeys from "constant/dict.type";
import edTableGroup from "constant/edTableGroup";

export interface analysisState {
    selectedHostsIDs: string[]
    templateList: IForensicDropDownData[]
    selectedTemplate: IForensicDropDownData | null
    memoryDropDownData: IMemoryDropDownData
    memoryDropDownCheckboxEnabled: (keyof IMemoryDropDownData)[]
    forensicDropDownData: IForensicDropDownData
    allFilesDropDownData: IAllFilesDropDownData
    dateRange: IDateRange
    dateSelectIndex: number,
    mainKeyword: string // 主搜尋關鍵字
    subKeywordList: string[]
    groups: IGenerateGroup[]
    rightBar: {
        uuid: string,
        tab: 'detail' | 'chatgpt'
    }
    selectedAgentId: string[] // 所有被選取的主機列表
    indexList: IAnalaysisIndexNode[] // 所有index列表
    leftSelectedList: {
        // 左方列表
        analysisIndex: IAnalaysisIndexNode[] // 所有index列表
        selectedIndex: edTableGroup[] // 所有被選取的index列表
    }
    chartData: any // 圖表資料
    tableData: {
        // 表格資料
        // ...
    }
    detailData: {
        // 詳細資料
    },
    selectedHostGroup: string[],
}


const initMemoryDropDownData: IMemoryDropDownData = {
    isMemoryGroupSelected: true,
    processName: '',
    processConnectIP: '',
    dynamicCommand: '',
    processMD5: '',
    processPath: '',
    parentProcessPath: '',
    digitalSign: '',
    importOtherDLL: '',
    processId: '',
    riskLevel: [3, 2],
    injectActive: ['0,1', '0,0'],
    processBeInjected: [0],
    boot: ['0,1', '0,0'],
    hook: ['*'],
    hide: ['0,1', '0,0'],
}
const initForensicDropDownData: IForensicDropDownData = {
    isForensicsSelected: true,
    templateId: '',
    templateName: '',
    keywordType: 'keywordType',
    keyword: 'keyword',
    
    group_browsing_history_and_bookmarks: true,
    group_cache_and_cookie_history: true,
    ed_chromehistory: true,
    ed_chromedownload: true,
    ed_chromekeywordsearch: true,
    ed_chromelogin: true,
    ed_chromebookmarks: true,
    ed_chromecache: true,
    ed_chromecookies: true,
    ed_edgehistory: true,
    ed_edgecache: true,
    ed_edgecookies: true,
    ed_edgelogin: true,
    ed_edgebookmarks: true,
    ed_iehistory: true,
    ed_iecache: true,
    ed_ielogin: true,
    ed_firefoxhistory: true,
    ed_firefoxlogin: true,
    ed_firefoxbookmarks: true,
    ed_firefoxcache: true,
    ed_firefoxcookies: true,

    group_current_network_connection_history: true,
    ed_netadapters: true,
    ed_wireless: true,
    ed_networkresources: true,

    group_recently_executed_software_log: true,
    ed_installedsoftware: true,
    ed_baseservice: true,
    ed_remotedesktoplogin: true,
    ed_systeminfo: true,
    ed_prefetch: true,
    ed_taskschedule: true,
    ed_networkdatausagemonitor: true,
    ed_dnsinfo: true,
    ed_service: true,
    ed_startrun: true,
    ed_jumplist: true,
    ed_muicache: true,
    ed_eventapplication: true,
    ed_userassist: true,
    ed_appresourceusagemonitor: true,

    group_evanescent_records: true,
    ed_process: true,
    ed_openedfiles: true,
    ed_network: true,
    ed_arpcache: true,
    group_recently_opened_file_history: true,
    ed_shortcuts: true,
    ed_userprofiles: true,
    ed_windowsactivity: true,
    ed_shellbags: true,
    ed_recentfile: true,

    group_usb_usage_record: true,
    ed_usbdevices: true,
    ed_eventsystem: true,
    ed_eventsecurity: true,
    
    group_email_list_records: true,
    ed_emailpath: true,
    ed_email: true,
}
const initAllFilesDropDownData: IAllFilesDropDownData = {
    isAllFilesSelected: true,
    keyPath: '',
    keywordType: '影音檔',
    keywordFileName: '',
    keywordContent: '',
    fileSize: 'min',
    fileSizeValue: 0,
    fileUnit: 'MB',
    onlySearchDeletedFile: false,
}
const initDateRange: IDateRange = {
    startTime: dayjs().valueOf(),
    endTime: dayjs().valueOf(),
}

const initialState: analysisState = {
    selectedHostsIDs: [],
    templateList: [],
    selectedTemplate: null,
    memoryDropDownData: initMemoryDropDownData,
    memoryDropDownCheckboxEnabled: [],
    forensicDropDownData: initForensicDropDownData,
    allFilesDropDownData: initAllFilesDropDownData,
    dateRange: initDateRange,
    mainKeyword: '',
    subKeywordList: [],
    groups: [],
    rightBar: {
        uuid: '',
        tab: 'detail',
    },
    selectedAgentId: [],
    indexList: [],
    leftSelectedList: {
        analysisIndex: [],
        selectedIndex: [],
    },
    chartData: [],
    tableData: {},
    detailData: {},
    selectedHostGroup: [],
    dateSelectIndex: 3
}

export const analysisSlice = createSlice({
    name: 'analysis',
    initialState: initialState,
    reducers: {
        setDataFromTopAnalysisButton: (state:analysisState, action: PayloadAction<any>) => {
            // 更改 1.左方列表 2.中上圖表 3.中下表格 4.右方詳細資料 , 5.儲存所選取的主機們、時間範圍
            // console.log("更改 1.左方列表 2.中上圖表 3.中下表格 4.右方詳細資料 5.儲存所選取的主機們、時間範圍")
            const data = action.payload;
            return {
                ...state,
                mainKeyword: data.mainKeyword,
                subKeywordList: data.subKeywordList, // 子搜尋關鍵字重製
                selectedAgentId: data.selectedAgentId, //儲存所選取的agent id們
                indexList: data.indexList, // 儲存index們
                leftSelectedList: {
                    selectedIndex: [], //左方列表
                    analysisIndex: data.leftSelectedList.analysisIndex, //左方列表
                },
                tableData: data.tableData, //中下表格
                chartData: data.chartData, //中上圖表
            }
        },
        setDataFromLeftSelectedList: (state:analysisState, action: PayloadAction<{
            leftSelectedList: {
                selectedIndex: edTableGroup[],
            },
            chartData: any,
            tableData: any,
            detailData: {},
        }>) => {
            const data = action.payload;
            // 更改 1.中上圖表 2.中下表格 3.右方詳細資料
            return {
                ...state,
                subKeywordList: [], // 子搜尋關鍵字重製
                fromIndex: 0, // 4.累計比數歸零
                leftSelectedList: {
                    ...state.leftSelectedList,
                    selectedIndex: data.leftSelectedList.selectedIndex, //5.紀錄被點選到的左方index
                },
                chartData: data.chartData, //1.中上圖表
                tableData: data.tableData, //2.中下表格
                detailData: {}, //3.右方詳細資料
            }
        },
        setSubKeyword:  (state:analysisState, action: PayloadAction<string[]>) => {
            state.subKeywordList = action.payload;
        },
        setSelectedId: (state:analysisState, action: PayloadAction<string[]>) => {
            state.selectedHostsIDs = action.payload;
        },
        setTemplateList: (state:analysisState, action: PayloadAction<IForensicDropDownData[]>) => {
            state.templateList = action.payload;
        },
        setSelectedTemplate: (state:analysisState, action: PayloadAction<IForensicDropDownData | null>) => {
            state.selectedTemplate = action.payload;
        },
        setMemoryDropDownSelected: (state:analysisState, action: PayloadAction<IMemoryDropDownData>) => {
            state.memoryDropDownData = action.payload
        },
        setForensicsSelectedData: (state:analysisState, action: PayloadAction<IForensicDropDownData>) => {
            state.forensicDropDownData = action.payload;
        },
        setallFilesDropDownData: (state:analysisState, action: PayloadAction<IAllFilesDropDownData>) => {
            state.allFilesDropDownData = action.payload
        },
        setDateRange: (state:analysisState, action: PayloadAction<IDateRange>) => {
            state.dateRange = action.payload
        },
        setDateSelectIndex:  (state:analysisState, action: PayloadAction<number>) => {
            state.dateSelectIndex = action.payload;
        },
        setMainKeyword: (state:analysisState, action: PayloadAction<string>) => {
            state.mainKeyword = action.payload
        },
        setGroups: (state:analysisState, action: PayloadAction<IGenerateGroup[]>) => {
            state.groups = action.payload
        },
        setRightBar: (state:analysisState, action: PayloadAction<{
            uuid?: string,
            tab?: 'detail' | 'chatgpt'
        }>) => {
            state.rightBar = {
                ...state.rightBar,
                ...action.payload
            }
        },
        setLeftList: (state:analysisState, action: PayloadAction<{ analysisIndex: IAnalaysisIndexNode[], selectedIndex: edTableGroup[] }>) => {
            state.leftSelectedList = action.payload;
        },
        changeMemoryDropDownSelected: (state: analysisState, action: PayloadAction<{
            title: keyof IMemoryDropDownData | 'deleteAll', value?: string
        }>) => {
            const {title, value} = action.payload;
            switch (title) {
                case 'processName':
                case 'processConnectIP':
                case 'dynamicCommand':
                case 'processMD5':
                case 'processPath':
                case 'parentProcessPath':
                case 'digitalSign':
                case 'importOtherDLL':
                case 'processId':
                    state.memoryDropDownData[title] = value;
                    return;
                case 'injectActive':
                case 'processBeInjected':
                case 'boot':
                case 'hook':
                case 'hide':
                case 'riskLevel':
                    let list = state.memoryDropDownData[title];
                    //@ts-ignore
                    if (list.filter((item) => item === value).length === 0) {
                        //@ts-ignore
                        list.push(value)
                    } else {
                        //@ts-ignore
                        state.memoryDropDownData[title] = list.filter((item) => item !== value)
                    }
                    return;
                case 'deleteAll':
                    state.memoryDropDownData = {
                        isMemoryGroupSelected: state.memoryDropDownData.isMemoryGroupSelected,
                        processName: '',
                        processConnectIP: '',
                        dynamicCommand:'',
                        processMD5: '',
                        processPath: '',
                        parentProcessPath:'',
                        digitalSign: '',
                        importOtherDLL:'',
                        processId: '',
                        riskLevel: [],
                        injectActive: [],
                        processBeInjected: [],
                        boot: [],
                        hook: [],
                        hide: []
                    }
                    return;
            }
        },
        toggleMemoryDropDownCheckbox: (state: analysisState, action: PayloadAction<{
            title: keyof IMemoryDropDownData,
            state: boolean
        }>) => {
            let _set = new Set(state.memoryDropDownCheckboxEnabled);
            if (action.payload.state)
                _set.add(action.payload.title)
            else
                _set.delete(action.payload.title)
            state.memoryDropDownCheckboxEnabled = Array.from(_set);
        },
        reset: (state: analysisState) => {
            state = initialState;
        },
    }
});

export const { 
    setDataFromTopAnalysisButton, setDataFromLeftSelectedList, setSubKeyword, setSelectedId, setTemplateList,
    setSelectedTemplate, setMemoryDropDownSelected, setForensicsSelectedData,
    setallFilesDropDownData, setDateRange, setMainKeyword, setGroups, setRightBar,
    setDateSelectIndex, changeMemoryDropDownSelected, setLeftList,
    toggleMemoryDropDownCheckbox
} = analysisSlice.actions

export const _analysisData = (state:RootState) => state.analysis;

export default analysisSlice.reducer;