import React, { useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'
import SortBtn from './SortBtn'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useSelector } from 'react-redux'
import {
    SelectedRow,
    IpNameTimes,
    IpTimes,
    IpNameProcess,
    ProcessTimesScore,
    ProcessTimes,
    VirusTotalData,
} from './Interfaces'
import { InitSelectedRow } from './functions'
import '../index.scss'
import { IconButton } from '@mui/material'
import dic from 'constant/dictionary'
import { _language, _displayMode } from 'redux/UtilSlice'

export type Column<T, K extends keyof T> = {
    key: K
    label: string
    minWidth: number
    align?: 'right'
}

interface AnalysisTableProps<T, K extends keyof T> {
    tableTitle: string,
    lines: number,
    tableData: T[],
    tableColumns: readonly Column<T, K>[],
    level?: string,
    setLevel?: React.Dispatch<React.SetStateAction<string>>,
    isLoading?: boolean,
    sortBtn?: boolean,
    toggleBtn?: React.ReactNode,
    selectedRow?: SelectedRow,
    setSelectedRow?: React.Dispatch<React.SetStateAction<SelectedRow>>,
    setFetchOrder?: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>,
    showDirectBtn?: boolean
    directBtnCallback?: (row:any) => void
}

const AnalysisTable = <T, K extends keyof T>(props: AnalysisTableProps<T, K>) => {
    const {
        tableTitle,
        lines,
        tableData,
        tableColumns,
        level,
        setLevel,
        isLoading,
        sortBtn,
        toggleBtn,
        selectedRow,
        setSelectedRow,
        setFetchOrder,
        showDirectBtn,
        directBtnCallback
    } = props
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);
    const [rows, setRows] = useState(tableData)
    const [hoveredRow, setHoveredRow] = useState<number | null>(null)
    const sortColumn = useRef<string | null>(null)
    const order = useRef<'asc' | 'desc'>('asc')

    useEffect(() => {
        setRows(tableData)
    }, [isLoading, tableData])

    const handleColumnSort = (columnId: string) => {
        if (sortColumn.current === columnId) {
            if (order.current === 'asc') {
                order.current = 'desc'
                setRows(sortRows(columnId))
            } else {
                order.current = 'asc'
                setRows(sortRows(columnId))
            }
        } else {
            order.current = 'asc'
            sortColumn.current = columnId
            setRows(sortRows(columnId))
        }
    }

    const handleSortBtn = (sortOrder: 'asc' | 'desc') => {
        setFetchOrder && setFetchOrder(sortOrder)
    }

    const sortRows = (columnId: string) => {
        if (rows === null) return []
        return [...rows].sort((a, b) => {
            if (sortColumn) {
                const valueA = a[columnId as K]
                const valueB = b[columnId as K]

                if (order.current === 'asc') {
                    if (typeof valueA === 'number' && typeof valueB === 'number') {
                        return valueA - valueB
                    }
                    return String(a[columnId as K]).localeCompare(String(b[columnId as K]))
                } else {
                    if (typeof valueA === 'number' && typeof valueB === 'number') {
                        return valueB - valueA
                    }
                    return String(b[columnId as K]).localeCompare(String(a[columnId as K]))
                }
            }

            return 0
        })
    }

    const handleRowClick = (rowData: T) => {
        if (setSelectedRow) {
            const initSelectedRow = InitSelectedRow()
            if (tableTitle === dic["en"]["dashboard_table_ip_connect"] || tableTitle === dic["zh"]["dashboard_table_ip_connect"]) {
                initSelectedRow.ipConnect = rowData as IpNameTimes
                setSelectedRow(initSelectedRow)
            } else if (tableTitle === dic["en"]["dashboard_table_process_ip"] || tableTitle === dic["zh"]["dashboard_table_process_ip"]) {
                initSelectedRow.processIP = rowData as IpTimes
                setSelectedRow(initSelectedRow)
            } else if (tableTitle === dic["en"]["dashboard_table_login_failure"] || tableTitle === dic["zh"]["dashboard_table_login_failure"]) {
                initSelectedRow.loginFailure = rowData as IpNameTimes
                setSelectedRow(initSelectedRow)
            } else if (tableTitle === dic["en"]["dashboard_top_bar_level3_process_count"] || tableTitle === dic["zh"]["dashboard_top_bar_level3_process_count"]) {
                initSelectedRow.highRiskProcess = rowData as ProcessTimesScore
                setSelectedRow(initSelectedRow)
            } else if (tableTitle === dic["en"]["dashboard_table_no_sign_process"] || tableTitle === dic["zh"]["dashboard_table_no_sign_process"]) {
                initSelectedRow.noSignProcess = rowData as ProcessTimes
                setSelectedRow(initSelectedRow)
            } else if (tableTitle === 'VirusTotal') {
                initSelectedRow.virusTotal = rowData as VirusTotalData
                setSelectedRow(initSelectedRow)
            } else {
                console.log("tableTitle", tableTitle)
                initSelectedRow.riskLevel = rowData as IpNameProcess
                setSelectedRow(initSelectedRow)
            }
        }
    }

    return (
        <div>
            <Paper square={false} className='tablePaper'>
                <div className='tableToolBar'>
                    {level ? (
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={level}
                            label="Level"
                            onChange={(e) => {
                                if (setLevel) setLevel(e.target.value)
                            }}
                            sx={{
                                height: '24px',
                                margin: '5px 0px',
                                color: displayMode === 'dark' ? 'var(--gray-100)' : 'var(--gray-900)',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderStyle: 'none'
                                },
                                '& .MuiSvgIcon-root': {
                                    color: 'var(--gray-400)',
                                },
                            }}
                        >
                            <MenuItem selected value="Level4">{dic[language]["risk_level_4"]}</MenuItem>
                            <MenuItem value="Level3">{dic[language]["risk_level_3"]}</MenuItem>
                            <MenuItem value="Level2">{dic[language]["risk_level_2"]}</MenuItem>
                        </Select>
                    ) : (
                        <Typography variant="body1" sx={{
                            margin: '5px 0px',
                        }}>
                            {tableTitle}
                        </Typography>
                    )}
                    {
                        sortBtn === true ?
                            <SortBtn handleSortBtn={handleSortBtn} /> : null
                    }
                    {toggleBtn}
                </div>
                <TableContainer
                    className='container'
                    sx={{
                        maxHeight:
                            lines === 6.5 ? '256px' :
                                lines === 6 ? '220px' :
                                    lines === 5 ? '190px' : '160px',
                        height:
                            lines === 6.5 ? '256px' :
                                lines === 6 ? '220px' :
                                    lines === 5 ? '190px' : '160px',
                    }}
                >
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        size="small"
                    >
                        <TableHead>
                            <TableRow>
                                {tableColumns.map((column, index) => (
                                    <TableCell
                                        className='row'
                                        key={`headCell-${index}`}
                                        align={column.align}
                                        sx={{
                                            minWidth: column.minWidth,
                                            maxWidth: '150px',
                                            padding: '5px 6px',
                                        }}
                                        onClick={() =>
                                            handleColumnSort(column.key as string)
                                        }
                                    >
                                        {column.label}
                                        <TableSortLabel
                                            className='cell'
                                            active={sortColumn.current === column.key}
                                            direction={'asc'}
                                            IconComponent={order.current === 'asc' ? ArrowDropDownIcon : ArrowDropUpIcon}
                                        />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows ? rows.map((row, rowIndex) => (
                                <TableRow
                                    key={rowIndex}
                                    onClick={() => handleRowClick(row)}
                                    onMouseEnter={() => setHoveredRow(rowIndex)}
                                    onMouseLeave={() => setHoveredRow(null)}
                                    sx={{
                                        cursor: 'pointer',
                                        position: 'relative',
                                        background: selectedRow?.ipConnect === row ||
                                            selectedRow?.processIP === row ||
                                            selectedRow?.riskLevel === row ||
                                            selectedRow?.loginFailure === row ||
                                            selectedRow?.highRiskProcess === row ||
                                            selectedRow?.noSignProcess === row ?
                                            'rgba(255, 152, 0, 1)' :
                                            (displayMode === 'dark' ? 'var(--gray-1000)' : 'var(--gray-0)'),
                                        "&:hover": {
                                            backgroundColor: "rgb(255, 160, 0)",

                                            "& .MuiTableCell-root": {
                                                color: displayMode === 'dark' ? 'var(--gray-1000)' : 'var(--gray-0)',
                                            }
                                        }
                                    }}
                                >
                                    {tableColumns.map((column, index) => (
                                        <Tooltip
                                            key={`row-${index}`}
                                            followCursor
                                            title={String(row[column.key])}
                                        >
                                            <TableCell
                                                align={column.align}
                                                sx={{
                                                    maxWidth: column.minWidth,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    padding: '5px 6px',
                                                    color: displayMode === 'dark' ? 'var(--gray-0)' : 'var(--gray-1000)',
                                                }}
                                            >
                                                {String(row[column.key])}
                                            </TableCell>
                                        </Tooltip>
                                    ))}
                                    {showDirectBtn ? (
                                        <TableCell className='navigateBtn' style={hoveredRow === rowIndex?undefined:{display: "none"}}>
                                            <IconButton
                                                className='button'
                                                size='small'
                                                onClick={(e) => {if (directBtnCallback) directBtnCallback(row);}}>
                                                <ExitToAppRoundedIcon fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                    {rows && rows.length ? null : (
                        <div className='circularProgressContainer'>
                            <div>{dic[language]["no_data"]}</div>
                        </div>
                    )}
                    {isLoading ? (
                        <div className='circularProgressContainer'>
                            <CircularProgress disableShrink />
                        </div>
                    ) : null}
                </TableContainer>
            </Paper>
        </div>
    )
}

export default AnalysisTable
