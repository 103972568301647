import GroupTable from '../../component/GroupTable'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { API, urlRoot } from 'constant/index'
import { useMutation } from '@tanstack/react-query'
import { useAlert } from 'hook/useAlert'
import { axiosClient } from 'util/index'
import { useToast } from 'hook/useToast'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import { useGet } from 'hook/useGet'
import AgentTable, { deviceInfo } from '../../component/AgentTable'
import { IRawGroupData } from 'interface'

export interface IGroupData {
    name: string
    id: number
    amount: number
    color: string
}

interface IChangeAgentGroupMutation {
    url: string
    isAdd: boolean
    requestBody: {
        groups: number[]
        devices: string[]
    }
}

const TableBox = () => {
    const language = useSelector(_language)
    const alert = useAlert().showAlert
    const toast = useToast()

    const [selectedIDs, setSelectedIDs] = useState<string[]>([])
    const [selectedGroupList, setSelectedGroupList] = useState<number[]>([])
    const [tableData, setTableData] = useState<deviceInfo[]>([])
    const [groupTableData, setGroupTableData] = useState<IGroupData[]>([])

    const {
        data: defaultAgentData,
        refetch: refetchAgentData,
        isSuccess: isDefaultAgentDataSuccess,
    } = useGet<deviceInfo[]>({
        query: 'AllAgentDetail',
        root: `${urlRoot}`,
        route: `${API.agentInfo}`,
    })

    useEffect(() => {
        if (isDefaultAgentDataSuccess && defaultAgentData.data) {
            setTableData(defaultAgentData.data)
        }
    }, [isDefaultAgentDataSuccess, defaultAgentData])

    const {
        data: defaultGroupData,
        refetch: refetchGroupData,
        isSuccess: isDefaultGroupDataSuccess,
    } = useGet<IRawGroupData[]>({
        query: 'AllGroupDetail',
        root: `${urlRoot}`,
        route: `${API.group}`,
    })

    useEffect(() => {
        if (isDefaultGroupDataSuccess && defaultGroupData.data) {
            setGroupTableData(defaultGroupData.data.map((group) => {
                return {
                    name: group.name,
                    id: group.id,
                    amount: group.devices.length,
                    color: group.color,
                }
            }))
        }
    }, [isDefaultGroupDataSuccess, defaultGroupData])

    const fetchChangeAgentGroup = useMutation({
        mutationFn: (mutationData: IChangeAgentGroupMutation) => {
            if (mutationData.isAdd) {
                return axiosClient.post(
                    mutationData.url,
                    mutationData.requestBody
                )
            } else {
                return axiosClient.delete(mutationData.url, {
                    data: mutationData.requestBody,
                })
            }
        },
        onSuccess: () => {
            toast.show({content: dic[language]['save_success']});
            setSelectedIDs([]);
            setSelectedGroupList([]);
            refetchAgentData();
            refetchGroupData();
        },
        onError: () => {
            console.log(dic[language]['setting_error']);
        },
        retryDelay: 1000,
    })

    const handleAgntAddOrRemoveFromGroup = async (isAdd: boolean) => {
        if (selectedGroupList.length !== 0 && selectedIDs.length !== 0) {
            fetchChangeAgentGroup.mutate({
                url: `${urlRoot}${API.groupDevice}`,
                requestBody: {
                    groups: selectedGroupList,
                    devices: selectedIDs,
                },
                isAdd: isAdd,
            })
        } else {
            alert(dic[language]['please_select_group_or_device'])
        }
    }

    const ToolBarButtons = (
        <Stack
            direction="row"
            spacing={2}
            justifyContent={'right'}
            height={40}
            sx={{ marginLeft: 'auto' }}
        >
            <Button
                onClick={() => handleAgntAddOrRemoveFromGroup(true)}
                variant="contained"
                startIcon={<NavigateNextIcon />}
                sx={{ textTransform: 'none' }}
            >
                {dic[language]['add_to_selected_group']}
            </Button>
            <Button
                color="error"
                onClick={() => handleAgntAddOrRemoveFromGroup(false)}
                variant="contained"
                endIcon={<NavigateBeforeIcon />}
                sx={{ textTransform: 'none' }}
            >
                {dic[language]['remove_from_selected_group']}
            </Button>
        </Stack>
    )

    return (
        <div className='ChildInnerWhiteContainer'>
            <p className='SettingSubTitle'>
                {dic[language]['setting_group_statistics']}: Agent {tableData?.length} {dic[language]['group']} {groupTableData?.length}
            </p>

            <Stack direction="row" sx={{ height: 'calc(100% - 3rem)' }}>
                <AgentTable
                    ToolBarButtons={ToolBarButtons}
                    defaultDeviceList={tableData}
                    selectedIDs={selectedIDs}
                    setSelectedIDs={setSelectedIDs}
                    height='100%'
                />
                <GroupTable
                    tableData={groupTableData}
                    selectedHostsIDs={selectedGroupList}
                    setSelectedId={setSelectedGroupList}
                    refetchGroupData={refetchGroupData}
                />
            </Stack>
        </div>
    )
}

export default TableBox
