import { IFormatedDevice } from 'interface'
import {
    ActionPlugTitle,
} from '../../StyledComponents'
import ActionButton from './CommonComponents/ActionButton'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

interface ActionYaraProps {
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
}

const ActionYara = (props: ActionYaraProps) => {
    const { setData, data } = props
    const language = useSelector(_language)

    return (
        <div className='actionPlugContainer'>
            <ActionPlugTitle>Yara Rule</ActionPlugTitle>
            <ActionButton data={data} setData={setData} name={dic[language]['detect_task_memory_action']} />
        </div>
    )
}

export default ActionYara
