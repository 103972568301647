import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { IForensicDropDownData, AnalysisTemplate } from 'interface'
import { API, urlRoot } from 'constant/index'
import { usePost } from 'hook/usePost'

interface TemplateDialogProps {
    template: IForensicDropDownData
    dialogOpen: boolean
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
    setRefreshTemplate: React.Dispatch<React.SetStateAction<boolean>>
}

const TemplateDialog = (props: TemplateDialogProps) => {
    const { template, dialogOpen, setDialogOpen, setRefreshTemplate } = props
    const [name, setName] = useState(template.templateName)
    const mutation = usePost()

    const convertTemplate = (template: IForensicDropDownData) => {
        const templateForAPI: AnalysisTemplate = {
            template_id: template.templateId,
            template_name: template.templateName,
            work: "collect",
            keyword_type: template.keywordType,
            keyword: template.keyword,
            history_and_bookmark: {
                chrome_browsing_history: template.ed_chromehistory,
                chrome_download_history: template.ed_chromedownload,
                chrome_keyword_search: template.ed_chromekeywordsearch,
                chrome_login_info: template.ed_chromelogin,
                chrome_bookmark: template.ed_chromebookmarks,
                edge_browsing_history: template.ed_edgehistory,
                ie_browsing_history: template.ed_iehistory,
                edge_login_info: template.ed_edgelogin,
                ie_login_info: template.ed_ielogin,
                edge_bookmark: template.ed_edgebookmarks,
                firefox_browsing_history: template.ed_firefoxhistory,
                firefox_login_info: template.ed_firefoxlogin,
                firefox_bookmark: template.ed_firefoxbookmarks,
            },
            cookie_and_cache: {
                chrome_cache: template.ed_chromecache,
                chrome_cookie: template.ed_chromecookies,
                edge_cache: template.ed_edgecache,
                edge_cookie: template.ed_edgecookies,
                ie_cache: template.ed_iecache,
                firefox_cache: template.ed_firefoxcache,
                firefox_cookie: template.ed_firefoxcookies,
            },
            connection_history: {
                network_info: template.ed_netadapters,
                wireless_info: template.ed_wireless,
                network_resource: template.ed_networkresources,
            },
            process_history: {
                installed_software: template.ed_installedsoftware,
                detailed_system_service: template.ed_baseservice,
                remote_desktop_info: template.ed_remotedesktoplogin,
                system_info: template.ed_systeminfo,
                ed_prefetch: template.ed_prefetch,
                scheduled_task: template.ed_taskschedule,
                network_traffic: template.ed_networkdatausagemonitor,
                dns_info: template.ed_dnsinfo,
                general_system_service: template.ed_service,
                bootup_program: template.ed_startrun,
                jumplist: template.ed_jumplist,
                mui_cache: template.ed_muicache,
                machine_code_history: template.ed_userassist,
                program_read_write_history: template.ed_appresourceusagemonitor,
            },
            vanishing_history: {
                process: template.ed_process,
                opened_file: template.ed_openedfiles,
                connection_info: template.ed_network,
                arp_cache: template.ed_arpcache,
            },
            recent_opening: {
                related_shortcut: template.ed_shortcuts,
                user_info: template.ed_userprofiles,
                windows_activity: template.ed_windowsactivity,
                opened_path: template.ed_shellbags,
                opened_file: template.ed_recentfile,
            },
            usb_history: {
                usb_info: template.ed_usbdevices,
                system_log_file: template.ed_eventsystem,
                security_log_file: template.ed_eventsecurity,
            },
            email_history: {
                email_path: template.ed_emailpath,
                email_list: template.ed_email,
            },
        }

        return templateForAPI
    }

    const handleClose = () => {
        setDialogOpen(false)
    }

    const handleConfirm = async () => {
        if (template.templateName !== name) {
            const data: AnalysisTemplate = {
                ...convertTemplate(template),
                template_name: name,
            }

            try {
                await mutation.mutateAsync({
                    method: 'post',
                    root: `${urlRoot}`,
                    route: `${API.template}`,
                    body: data,
                })

                setRefreshTemplate(true)
            } catch (err) {
                console.log(err)
            }
        } else {
            const data: AnalysisTemplate = {
                ...convertTemplate(template),
            }
    
            try {
                await mutation.mutateAsync({
                    method: 'put',
                    root: `${urlRoot}`,
                    route: `${API.template}/${template.templateId}`,
                    body: data,
                })
                setRefreshTemplate(true)
            } catch (err) {
                console.log(err)
            }
        }

        setDialogOpen(false)
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    width: '444px',
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'rgba(33, 150, 243, 1)',
                    color: 'white',
                    padding: '5px 15px',
                    fontSize: '1rem',
                }}
            >
                {'快選名稱'}
            </DialogTitle>
            <DialogContent style={{ padding: '20px 10px 5px 10px' }}>
                <TextField
                    variant="outlined"
                    size='small'
                    fullWidth
                    value={name}
                    sx={{
                        padding: '0 1rem'
                    }}
                    onChange={(e) => setName(e.target.value)}
                />
                {
                    name ? null : (
                        <p style={{
                            color: 'rgba(211, 47, 47, 1)',
                            fontSize: '0.8rem',
                            margin: '0.5rem 0 0 1rem'
                        }}>
                            欄位不可空白
                        </p>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleConfirm}
                    disabled={!name}
                    sx={{ color: 'rgba(33, 150, 243, 1)' }}
                >
                    確定
                </Button>
                <Button
                    onClick={handleClose}
                    sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                >
                    取消
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TemplateDialog
