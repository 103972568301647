import { Checkbox, Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { IGroupCount } from './GroupDropDown'
import { areArraysHasOne, areArraysIncluding } from '..'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setSelectedId } from 'redux/AnalysisSlice'
import { _displayMode, _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

interface GroupNavProps {
    groupCount: IGroupCount[]
    setGroupCount: React.Dispatch<React.SetStateAction<IGroupCount[]>>
    selectedHost: string
    setSelectedHost: React.Dispatch<React.SetStateAction<string>>
}

const GroupNav = (props: GroupNavProps) => {
    const {
        groupCount,
        setGroupCount,
        selectedHost,
        setSelectedHost
    } = props
    
    const analysisData = useSelector(_analysisData);
    const { groups, selectedHostsIDs } = analysisData
    const dispatch = useDispatch();
    const language = useSelector(_language);

    useEffect(() => {
        const allFalse = groups.map((item) => {
            return { group: item.group, state: false }
        })
        setGroupCount(allFalse)
    }, [])

    return (
        <div style={{ width: '20%', height: '100%' }} className="scrollbarY">
            <Stack key={`checkbox_-1`} style={{ width: '100%' }}>
                <CheckTitle
                    id={-1}
                    text={dic[language]['all']}
                    groupCount={groupCount}
                    setGroupCount={setGroupCount}
                    selectedHost={selectedHost}
                    setSelectedHost={setSelectedHost}
                    all={true}
                />
            </Stack>
            {groups.map((item, index) => {
                return (
                    <Stack key={`checkbox_${index}`} style={{ width: '100%' }}>
                        <CheckTitle
                            id={index}
                            text={item.group}
                            groupCount={groupCount}
                            setGroupCount={setGroupCount}
                            selectedHost={selectedHost}
                            setSelectedHost={setSelectedHost}
                        />
                    </Stack>
                )
            })}
        </div>
    )
}

export default GroupNav

interface CheckTitleProps {
    id: number
    text: string
    groupCount: IGroupCount[]
    setGroupCount: React.Dispatch<React.SetStateAction<IGroupCount[]>>
    selectedHost: string
    setSelectedHost: React.Dispatch<React.SetStateAction<string>>,
    all?: boolean
}

const CheckTitle = (props: CheckTitleProps) => {
    const {
        id,
        text,
        groupCount,
        setGroupCount,
        selectedHost,
        setSelectedHost,
        all
    } = props

    const displayMode = useSelector(_displayMode);
    const analysisData = useSelector(_analysisData);
    const { groups, selectedHostsIDs } = analysisData
    const dispatch = useDispatch();
    const language = useSelector(_language);

    const [checked, setChecked] = useState<boolean>(false) // checked icon
    const [minusCheck, setMinusCheck] = useState<boolean>(false) // minus icon

    const groupData = !all?
                        groups.find((item) => item.group === text):
                        {
                            group: text,
                            devices: groups.map((item) => item.devices).flat()
                        }
    const groupIds = groupData?.devices.map((item) => item.id) // 這個群組下的所有id

    const handleAllCheck = () => {
        // confirm condition
        // all check
        if (checked) {
            const filterSelect: readonly string[] = selectedHostsIDs.filter(
                (item) => groupIds?.includes(item) === false
            )
            dispatch(setSelectedId([...filterSelect]))
        }
        // minus check
        else if (minusCheck) {
            const concatSelect: readonly string[] = selectedHostsIDs.concat(
                groupIds as string[]
            )
            const concatSet = new Set(concatSelect)
            const transformArray = Array.from(concatSet) // 處理set轉array的問題，js 會有跨版本
            dispatch(setSelectedId([...transformArray]))
        }
        // canceled
        else if (!checked && !minusCheck) {
            dispatch(setSelectedId([...selectedHostsIDs, ...(groupIds as string[])]))
        }
    }

    //根據groupCount調整此checkbox的checked/minusCheck states
    useEffect(() => {
        const compareResult = areArraysIncluding(selectedHostsIDs, groupIds)
        setChecked(compareResult)
        if (compareResult) {
            const result = groupCount.map((item, idx) => {
                if (idx === id) {
                    item.state = true
                    return { ...item }
                }
                return { ...item }
            })
            setGroupCount(result)
        }

        const compareMinus = areArraysHasOne(
            selectedHostsIDs,
            groupIds,
            compareResult
        ) //  只要有一個就回傳true，但不能被全選
        setMinusCheck(compareMinus)
        if (compareMinus) {
            const result = groupCount.map((item, idx) => {
                if (idx === id) {
                    item.state = false
                    return { ...item }
                }
                return { ...item }
            })
            setGroupCount(result)
        }

        if (!compareResult && !compareMinus) {
            const result = groupCount.map((item, idx) => {
                if (idx === id) {
                    item.state = false
                    return { ...item }
                }
                return { ...item }
            })
            setGroupCount(result)
        }
    }, [selectedHostsIDs])

    return (
        <div
            style={{
                width: '93%',
                backgroundColor:
                    (selectedHost === text || all && selectedHost==='all') ? displayMode === 'dark' ? 'var(--gray-800)' : '#E1F5FE' : 'transparent',
                cursor: 'pointer',
                margin: '3px auto',
                display: 'flex',
                alignItems: 'center',
            }}
            onClick={() => {
                all? setSelectedHost('all'):setSelectedHost(text);
            }}
        >
            <Checkbox
                color="primary"
                indeterminate={minusCheck}
                checked={checked}
                onChange={handleAllCheck}
            />
            <span
                style={{
                    width: '100%',
                    maxHeight: 45,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {text === '' ? dic[language]['no_group'] : text}
            </span>
        </div>
    )
}
