import React, { useEffect, useState } from 'react'
import AnalysisTable, { Column } from "../AnalysisTable"
import { IpNameTimes, SelectedRow, EarthData, CountryEarthData } from "../Interfaces"
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { IDateRange } from 'interface'
import { sortRows, InitRequest } from '../functions'
import '../../index.scss'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { _dateModuleData, filterSearchIP, setDateRange } from 'redux/NetworkSlice'
import { useAlert } from 'hook/useAlert'

interface IPConnectProps {
    dateModuleData: IDateRange
    setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const IPConnect = (props: IPConnectProps) => {
    const {
        dateModuleData,
        setEarthData,
        selectedRow,
        setSelectedRow,
        setOpenSnackBar,
    } = props
    const language = useSelector(_language);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const postMutation = usePost<IpNameTimes[]>();
    const earthMutation = usePost<EarthData>();
    const [IpConnectData, setIpConnectData] = useState<IpNameTimes[]>([]);
    const [fetchOrder, setFetchOrder] = useState<'desc' | 'asc'>('desc');
    const networkDateModuleData = useSelector(_dateModuleData);
    const { showAlert } = useAlert();

    // fetch table data
    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.ipConnect}/agent/none/${fetchOrder}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                setIpConnectData(sortRows(res.data || [], 'times', fetchOrder));
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchData();
    }, [fetchOrder, dateModuleData])

    // fetch earth data
    const fetchAndSetEarthData = (rowData: IpNameTimes | null) => {
        if (!rowData) return
        earthMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.ipConnect}/agent/${rowData.id}/all`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                setEarthData(res.data);
                if (res.data.connections === null || res.data.connections.length === 0) {
                    setOpenSnackBar(true);
                }
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchAndSetEarthData(selectedRow.ipConnect)
    }, [selectedRow.ipConnect])

    const IpDeviceTimesColumns: Column<IpNameTimes, keyof IpNameTimes>[] = [
        { key: 'ip', label: 'IP', minWidth: 100 },
        { key: 'name', label: dic[language]["agent_name"], minWidth: 85 },
        { key: 'times', label: dic[language]["times"], minWidth: 85 },
    ]

    return (
        <div className='tableContainer'>
            <AnalysisTable
                tableTitle={dic[language]["dashboard_table_ip_connect"]}
                lines={6}
                tableData={IpConnectData}
                tableColumns={IpDeviceTimesColumns}
                sortBtn={true}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                isLoading={postMutation.isLoading}
                setFetchOrder={setFetchOrder}
                showDirectBtn
                directBtnCallback={(row) => {
                    dispatch(filterSearchIP((row as { ip: string }).ip))
                    dispatch(setDateRange({
                        ...networkDateModuleData,
                        startTime: dateModuleData.startTime,
                        endTime: dateModuleData.endTime
                    }))
                    // dispatch(filterTimestamp({
                    //     from: dateModuleData.startTime,
                    //     to: dateModuleData.endTime,
                    // }))
                    navigate('/memory');
                }}
            />
        </div>
    )
}

export default IPConnect
