import { useEffect, useState } from 'react'
import { useAlert } from 'hook/useAlert'
import '../../index.scss'
import dic from "constant/dictionary";
import { useSelector } from 'react-redux';
import { _language } from 'redux/UtilSlice';
import { Stack, Button, Divider, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Tabs, Tab } from '@mui/material';
import { useGet } from 'hook/useGet';
import { API, urlRoot } from 'constant/index';
import { usePost } from 'hook/usePost';
import { useToast } from 'hook/useToast';
import AgentTable, { deviceInfo } from '../../component/AgentTable';
import LoadingButton from '@mui/lab/LoadingButton';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import useDenyIfNot from 'hook/useDenyIfNot';

export interface VersionListData {
    linux: string[];
    windows: string[];
}

const AgentUpdate = () => {
    const language = useSelector(_language);
    const { showAlert } = useAlert();
    const toast = useToast();

    const [defaultDeviceList, setDefaultDeviceList] = useState<deviceInfo[]>([]);
    const [windowsDeviceList, setWindowsDeviceList] = useState<deviceInfo[]>([]);
    const [linuxDeviceList, setLinuxDeviceList] = useState<deviceInfo[]>([]);
    const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
    const [file, setFile] = useState<File | null>(null);
    const [key, setKey] = useState<File | null>(null);
    const [selectedVersion, setSelectedVersion] = useState<string>('');
    const [versionList, setVersionList] = useState<VersionListData | null>(null);
    const [osVersionList, setOSVersionList] = useState<string[]>([]);
    const [selectedOS, setSelectedOS] = useState<string>('windows');
    const [os, setOS] = useState(0);
    const { mutate: uploadMutate, isLoading: isUploadLoading } = usePost();
    const updateMutation = usePost();
    useDenyIfNot('setting');

    const {
        data: versionListData,
        refetch: refetchVersionList,
        isSuccess: isVersionListSuccess,
    } = useGet<VersionListData>({
        query: 'getVersionList',
        root: `${urlRoot}`,
        route: `${API.agentVersion}`,
    })

    useEffect(() => {
        if (isVersionListSuccess && versionListData.data) {
            setVersionList(versionListData.data);
            setOSVersionList(versionListData.data.windows);
        }
    }, [isVersionListSuccess, versionListData])

    const {
        data: allDeviceInfo,
        refetch: refetchDevices,
        isSuccess: isAllDeviceInfoSuccess,
    } = useGet<deviceInfo[]>({
        query: 'getAllDeviceInfo',
        root: `${urlRoot}`,
        route: `${API.agentInfo}`,
    })

    useEffect(() => {
        if (isAllDeviceInfoSuccess && allDeviceInfo.data) {
            setDefaultDeviceList(allDeviceInfo.data);
            setWindowsDeviceList(allDeviceInfo.data.filter((device) => device.os.includes('Windows')));
            setLinuxDeviceList(allDeviceInfo.data.filter((device) => device.os.includes('Ubuntu')));
        }
    }, [isAllDeviceInfoSuccess, allDeviceInfo])

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            showAlert(dic[language]['setting_yara_choose_file_first']);
        } else if (e.target.files[0].type !== 'application/x-msdownload') {
            showAlert(dic[language]['setting_agent_update_choose_file_error']);
        } else {
            setFile(e.target.files[0]);
        }
    }

    const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) {
            showAlert(dic[language]['setting_yara_choose_file_first']);
        } else if (e.target.files[0].name.slice(-4) !== '.key') {
            showAlert(dic[language]['setting_agent_update_choose_file_error']);
        } else {
            setKey(e.target.files[0]);
        }
    }

    const handleVerify = () => {
        if (!file || !key) return
        const formData = new FormData();
        formData.append('file', file);
        formData.append('key', key);

        uploadMutate({
            root: `${urlRoot}`,
            route: `${API.agent}/${selectedOS}`,
            body: formData,
            contentType: 'multipart/form-data',
        }, {
            onSuccess: () => {
                refetchVersionList();
                toast.show({content: dic[language]['verify_success']});
                setFile(null);
                setKey(null);
                setSelectedOS('windows');
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response?.data.message || "Failed");
                } else {
                    console.error(error.response?.data.message || "Failed");
                }
            }
        })
    }

    const handleUpdate = () => {
        for (let id of selectedIDs) {
            const device = defaultDeviceList.find((device) => device.id === id);
            if (device && device.version.split(',')[0] >= selectedVersion) {
                showAlert(dic[language]['setting_agent_update_older_version_error']);
                return;
            }
        }

        updateMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.agent}`,
            method: 'put',
            body: {
                devices: selectedIDs,
                version: selectedVersion,
                os: os === 0 ? 'windows' : 'linux',
            },
        }, {
            onSuccess: () => {
                toast.show({content: dic[language]['update_success']});
                refetchDevices();
                setSelectedIDs([]);
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response?.data.message || "Failed");
                } else {
                    console.error(error.response?.data.message || "Failed");
                }
            }
        })
    }

    const handleOSChange = (_: React.SyntheticEvent, newValue: number) => {
        setOS(newValue);
        setSelectedIDs([]);
        if (newValue === 0) {
            setOSVersionList(versionList?.windows || []);
        } else {
            setOSVersionList(versionList?.linux || []);
        }
    };

    const ToolBarButtons = (
        <Stack
            direction='row'
            spacing={2}
            sx={{ marginTop: '1rem' }}
        >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="select-version">Version</InputLabel>
                <Select
                    labelId="select-version"
                    id="select-agent-version"
                    value={selectedVersion}
                    label="Version"
                    onChange={(e: SelectChangeEvent) => setSelectedVersion(e.target.value as string)}
                >
                    {osVersionList.map((version, index) => (
                        <MenuItem key={index} value={version}>{version}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                color='warning'
                variant="contained"
                sx={{ textTransform: 'none' }}
                disabled={selectedIDs.length === 0 || selectedVersion === ''}
                onClick={handleUpdate}
            >
                {dic[language]['update']}
            </Button>
        </Stack>
    )

    return (
        <div className='NavigationBodyNoScroll'>
            <Stack
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{ margin: '1rem 0' }}
            >
                <div>{dic[language]["setting_system_agent_program"]}:</div>
                <Button
                    variant="contained"
                    component="label"
                    sx={{ textTransform: 'none' }}
                >
                    {dic[language]['setting_yara_choose_file']}
                    <input type="file" hidden onChange={handleFileChange} />
                </Button>
                {file && <div>{dic[language]['setting_yara_choose_file']}: {file.name}</div>}
                <div>{dic[language]["setting_system_upload_key"]}:</div>
                <Button
                    variant="contained"
                    component="label"
                    sx={{ textTransform: 'none' }}
                >
                    {dic[language]['setting_yara_choose_file']}
                    <input type="file" hidden onChange={handleKeyChange} />
                </Button>
                {key && <div>{dic[language]['setting_yara_choose_file']}: {key.name}</div>}
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="select-os-label">OS</InputLabel>
                    <Select
                        id="select-os"
                        labelId="select-os-label"
                        value={selectedOS}
                        label="OS"
                        onChange={(e: SelectChangeEvent) => setSelectedOS(e.target.value as string)}
                    >
                        <MenuItem value="windows">Windows</MenuItem>
                        <MenuItem value="linux">Linux</MenuItem>
                    </Select>
                </FormControl>
                <LoadingButton
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<FileUploadRoundedIcon />}
                    onClick={handleVerify}
                    loading={isUploadLoading}
                    disabled={!file || !key}
                >
                    <span>{dic[language]['upload']}</span>
                </LoadingButton>
            </Stack>

            <Divider sx={{ margin: '1% 0 0' }} />

            <Tabs
                value={os}
                onChange={handleOSChange}
                aria-label="agent-os"
            >
                <Tab
                    label="Windows"
                    sx={{ textTransform: 'none' }}
                />
                <Tab
                    label='Linux'
                    sx={{ textTransform: 'none' }}
                />
            </Tabs>

            {os === 0 && (
                <AgentTable
                    ToolBarButtons={ToolBarButtons}
                    defaultDeviceList={windowsDeviceList}
                    selectedIDs={selectedIDs}
                    setSelectedIDs={setSelectedIDs}
                    // blockSelection
                />
            )}
            {os === 1 && (
                <AgentTable
                    ToolBarButtons={ToolBarButtons}
                    defaultDeviceList={linuxDeviceList}
                    selectedIDs={selectedIDs}
                    setSelectedIDs={setSelectedIDs}
                    // blockSelection
                />
            )}
        </div>
    )
}

export default AgentUpdate
