import { ReactComponent as eDetctorLogo } from './img/eDetctorLogo.svg'
import { ReactComponent as UnCheck } from './img/UnCheck.svg'

import { ReactComponent as StopIcon } from './img/stopIcon.svg'
import { ReactComponent as IsoIcon } from './img/isoIcon.svg'
import { ReactComponent as FileIcon } from './img/fileIcon.svg'
import { ReactComponent as EyeIcon } from './img/eyeIcon.svg'
import { ReactComponent as MemoryIcon } from './img/memoryIcon.svg'
import { ReactComponent as logo } from './img/logo.svg'
import { ReactComponent as logotext } from './img/logotext.svg'
import { ReactComponent as slidePic } from './img/slidePic.svg'
import { ReactComponent as loginLeftBackground } from './img/loginLeftBackground.svg'
import { ReactComponent as homeISO } from './img/homeISO.svg'
import { ReactComponent as dashboardTitleBackground } from './img/dashboardTitleBackground.svg'
import { ReactComponent as server } from './img/server.svg'
import { ReactComponent as LoginWorld } from './img/loginWorld.svg'
import { ReactComponent as newLogo } from './img/newLogo.svg'
import { ReactComponent as yaraRuleIcon } from './img/yaraRuleIcon.svg'
import { ReactComponent as ServerImage } from './img/ServerImage.svg'
import { ReactComponent as Elastic } from './img/Elastic.svg'
import { ReactComponent as Queue } from './img/Queue.svg'
import { ReactComponent as Agent } from './img/Agent.svg'
import { ReactComponent as Tree } from './img/Tree.svg'

const assets = {
    newLogo,
    LoginWorld,
    eDetctorLogo,
    UnCheck,
    StopIcon,
    IsoIcon,
    FileIcon,
    EyeIcon,
    MemoryIcon,
    logo,
    logotext,
    slidePic,
    loginLeftBackground,
    homeISO,
    dashboardTitleBackground,
    server,
    yaraRuleIcon,
    ServerImage,
    Elastic,
    Queue,
    Agent,
    Tree,
}

export default assets
