// TODO: refactor this !!!!! -> use data grid and common components
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { TableCellComponentDense } from '../../../../component/FormInput/FormTable/EnhancedTableElement'
import { useGet } from 'hook/useGet'
import { usePost } from 'hook/usePost'
import {
    fixPaginationNumber,
    Order, getComparator,
    stableSort,
} from 'util/index'
import { useDispatch } from 'react-redux'
import {
    setGlobalDirty,
    _isApplyingHackList,
    setApplyingHackList,
} from 'redux/SettingSlice'
import FormDialog from './commonComponents/FormDialog'
import EnhancedTableToolbar from './commonComponents/EnhancedTableToolbar'
import EnhancedTableHead from './commonComponents/EnhancedTableHead'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { HeadCell } from './index'
import { useToast } from 'hook/useToast'

export interface HackListData {
    [key: string]: string | number;
    id: number
    processName: string
    cmd: string
    path: string
    addingPoint: number
}

interface ListProps {
    root: string
    route: string
}

export default function ToolSetting(props: ListProps) {
    const { root, route } = props
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<keyof HackListData>('processName')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [searchKeywordList, setSearchKeywordList] = useState<string[]>(
        []
    )
    const [rows, setRows] = useState<HackListData[]>([])
    const [onHoveredIndex, setOnHoveredIndex] = useState<number>(-1)
    const [visibleRows, setVisibleRows] = useState<HackListData[]>([])
    const dispatch = useDispatch()
    const isApplying = useSelector(_isApplyingHackList);
    const language = useSelector(_language)
    const toast = useToast()

    useEffect(() => {
        setPage(0)
    }, [searchKeywordList])

    const {
        data: hackList,
        isSuccess: isHackListSuccess,
    } = useGet<HackListData[]>({
        query: `${route}-HackListTableBox`,
        root: root,
        route: route,
    })

    useEffect(() => {
        if (isHackListSuccess && hackList.data) {
            setRows(hackList.data || [])
            setVisibleRows(hackList.data || [])
        }
    }, [isHackListSuccess, hackList])

    const {
        data: updateListStatus,
        dataUpdatedAt,
    } = useGet<boolean>({
        query: 'updateListStatus',
        root: root,
        route: 'setting/updateListStatus/hack',
        refetchInterval: 5000,
        enabled: isApplying,
    })

    useEffect(() => {
        if (updateListStatus && updateListStatus.data === true && isApplying) {
            console.log('got true from updateListStatus')
            dispatch(setApplyingHackList(false))
            dispatch(setGlobalDirty(false))
            toast.show({content: dic[language]['apply_success']})
        } else {
            console.log('got false from updateListStatus')
        }
    }, [updateListStatus, dataUpdatedAt, dispatch, isApplying, language, toast])

    const { mutate } = usePost()

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof HackListData
    ) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    useEffect(() => {
        const handleFilter = () => {
            const generateFiltedList = (rows: HackListData[], searchKeywordList: string[]) => {
                let filtedList: HackListData[] = []
                if (searchKeywordList.length === 0) {
                    filtedList = rows
                } else {
                    filtedList = rows.filter((row: HackListData) => {
                        let matchCount = 0
                        searchKeywordList.forEach((keyword: string) => {
                            if (
                                row.processName.includes(keyword) ||
                                row.path.includes(keyword) ||
                                row.addingPoint === Number(keyword) ||
                                row.cmd.includes(keyword)
                            ) {
                                matchCount++
                            }
                        })
                        return matchCount === searchKeywordList.length
                    })
                }
                return filtedList
            }

            const filtedList = generateFiltedList(rows, searchKeywordList)

            if (rowsPerPage === -1) {
                setVisibleRows(stableSort(filtedList, getComparator(order, orderBy)))
            } else {
                setVisibleRows(
                    stableSort(filtedList, getComparator(order, orderBy)).slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                    )
                )
            }
        }

        handleFilter()
    }, [order, orderBy, page, rowsPerPage, searchKeywordList, rows])

    const handleApply = () => {
        mutate({
            root: root,
            route: route,
            body: { data: rows },
            method: 'patch',
        }, {
            onSuccess: () => {
                dispatch(setApplyingHackList(true))
                toast.show({content: dic[language]['applying']})
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    toast.show({content: dic[language]['apply_fail']})
                } else {
                    console.log(error.response?.data.message)
                }
            }
        })
    }

    const updateTableData = (newData: HackListData, isDelete?: boolean) => {
        dispatch(setGlobalDirty(true))
        if (isDelete === true) { // 刪除
            const newRows = rows.filter((row: HackListData) => row.id !== newData.id)
            setRows(newRows)
            setVisibleRows(newRows)
        } else if (newData.id === -1) { // 新增
            setRows([...rows, newData])
            setVisibleRows([...rows, newData])
        } else { // 更新
            const newRows = rows.map((row: HackListData) => {
                if (row.id === newData.id) {
                    return newData
                } else {
                    return row
                }
            })
            setRows(newRows)
            setVisibleRows(newRows)
        }
    }

    const checkDuplicate = (newData: HackListData) => {
        const isDuplicate = rows.some((row: HackListData) => {
            return (
                row.processName === newData.processName &&
                row.cmd === newData.cmd &&
                row.path === newData.path &&
                row.addingPoint === newData.addingPoint
            )
        })
        return isDuplicate
    }

    const initListData: HackListData = {
        id: -1,
        processName: '',
        cmd: '',
        path: '',
        addingPoint: 0,
    }

    const headCells: readonly HeadCell[] = [
        {
            id: 'processName',
            numeric: false,
            label: dic[language]['setting_hacklist_process_name'],
            minWidth: 200,
        },
        {
            id: 'cmd',
            numeric: false,
            label: dic[language]['setting_hacklist_cmd'],
            minWidth: 200,
        },
        {
            id: 'path',
            numeric: false,
            label: dic[language]['setting_hacklist_path'],
            minWidth: 200,
        },
        {
            id: 'addingPoint',
            numeric: true,
            label: dic[language]['setting_hacklist_adding_point'],
            minWidth: 200,
        },
    ]

    return (
        <Box className='Box'>
            <EnhancedTableToolbar
                searchKeywordList={searchKeywordList}
                setSearchKeywordList={setSearchKeywordList}
                handleApply={handleApply}
                isApplying={isApplying}
            />
            <TableContainer className='BlackWhiteToolKeywordListBox'>
                <Table
                    aria-labelledby="tableTitle"
                    size='small'
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                    sx={{ cursor: 'pointer' }}
                                    onMouseEnter={() => setOnHoveredIndex(index)}
                                    onMouseLeave={() => setOnHoveredIndex(-1)}
                                >
                                    <TableCell
                                        component="th"
                                        id={labelId}
                                        scope="row"
                                    >
                                        {row.processName}
                                    </TableCell>
                                    <TableCellComponentDense
                                        align="left"
                                        minWidth="150px"
                                    >
                                        {row.cmd}
                                    </TableCellComponentDense>
                                    <TableCellComponentDense
                                        align="left"
                                        minWidth="150px"
                                    >
                                        {row.path}
                                    </TableCellComponentDense>
                                    <TableCellComponentDense
                                        align="left"
                                        minWidth="150px"
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {row.addingPoint}
                                            <FormDialog
                                                titleText={`${dic[language]["update"]}${language === 'en' ? ' ' : ''}`}
                                                listType={dic[language]["setting_hacker_tool"]}
                                                icon={<EditIcon />}
                                                visible={onHoveredIndex === index}
                                                rowData={row}
                                                updateHackTableData={updateTableData}
                                                isApplying={isApplying}
                                                headCells={headCells}
                                                checkHackDuplicate={checkDuplicate}
                                            />
                                        </Box>
                                    </TableCellComponentDense>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                    <TableFooter>
                        <tr>
                            <td colSpan={6} style={{ textAlign: 'center', height: '80px' }}>
                                <FormDialog
                                    titleText={`${dic[language]["add"]}${language === 'en' ? ' ' : ''}`}
                                    listType={dic[language]["setting_hacker_tool"]}
                                    icon={<AddCircleOutlineRoundedIcon color="disabled" />}
                                    visible={true}
                                    rowData={initListData}
                                    updateHackTableData={updateTableData}
                                    isApplying={isApplying}
                                    headCells={headCells}
                                    checkHackDuplicate={checkDuplicate}
                                />
                            </td>
                        </tr>
                    </TableFooter>
                </Table>
            </TableContainer>
            <TablePagination
                className='TablePagination'
                rowsPerPageOptions={[
                    ...fixPaginationNumber(rows.length),
                    { label: dic[language]["all"], value: -1 },
                ]}
                component="div"
                count={visibleRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}
