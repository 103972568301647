import { useState, useEffect, useMemo, useRef } from 'react'
import Button from '@mui/material/Button'
import { API, urlRoot } from 'constant/index'
import { useGet } from 'hook/useGet'
import '../../index.scss'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import SearchBarWithChips from '../../../../component/SearchBarWithChips'
import { Stack, Tooltip } from '@mui/material'

interface Log {
    id: number
    level: string
    service: string
    content: string
    timestamp: number
}

function GET_COLUMN_DEF (language: keyof typeof dic):GridColDef[] { 
    return [
        {
            field: 'timestamp', headerName: dic[language]['setting_software_logs_time'], flex: 1,
            valueGetter: (params) => { return new Date(params.row.timestamp * 1000).toLocaleString('zh-CN', { hour12: false }) }
        },
        { field: 'level', headerName: dic[language]['setting_software_logs_level'], flex: 1 },
        { field: 'service', headerName: dic[language]['setting_software_logs_source'], flex: 1 },
        {
            field: 'content', headerName: dic[language]['setting_software_logs_content'], flex: 5,
            renderCell: (params) => {
                return (
                    <Tooltip
                        arrow
                        followCursor
                        title={params.value}
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: '0.875rem',
                                },
                            },
                        }}
                    >
                        {params.value}
                    </Tooltip>
                )
            }
        },
    ]
}

const DailyLogs = () => {
    const [rows, setRows] = useState<Log[]>([])
    const [searchKeywordList, setSearchKeywordList] = useState<string[]>([])
    const language = useSelector(_language)

    const { 
        data: logData,
        isSuccess: isLogDataSuccess,
    } = useGet<Log[]>({
        query: 'logData',
        root: `${urlRoot}`,
        route: `${API.logs}`,
    })

    const logColumns = useRef<GridColDef[]>([]);
    const [_, rerender4colDef] = useState(false);
    useEffect(() => {
        logColumns.current = GET_COLUMN_DEF(language);
        rerender4colDef(!_);
    }, [])

    useEffect(() => {
        if (isLogDataSuccess && logData.data) {
            if (searchKeywordList.length === 0) {
                setRows(logData.data);
            } else {
                const filteredRows = logData.data.filter((row) => {
                    return searchKeywordList.every((keyword) => {
                        return Object.values(row).some((value) => {
                            const strValue = typeof value === 'number' ? new Date(value * 1000).toLocaleString('zh-CN', { hour12: false }) : value.toString();
                            return strValue.toLowerCase().includes(keyword.toLowerCase())
                        })
                    })
                })
                setRows(filteredRows)
            }
        }
    }, [isLogDataSuccess, logData, searchKeywordList])

    return (
        <div className='BoxSmall'>
            <Stack direction="row">
                <SearchBarWithChips
                    searchKeywordList={searchKeywordList}
                    setSearchKeywordList={setSearchKeywordList}
                    chipLength={5}
                />
                <Button
                    variant="contained"
                    sx={{ margin: '0.75rem 0' }}
                >
                    {dic[language]['setting_software_logs_detail']}
                </Button>
            </Stack>

            {/* one of the weirdest bug I have ever encountered,
            have to set the parent container to display: grid,
            otherwise the datagrid will keep expanding its width
            https://github.com/mui/mui-x/issues/8447 */}
            <div style={{ height: 'calc(100% - 5rem)', display: 'grid', width: '100%'}}>
                <DataGridPro
                    rows={rows}
                    rowHeight={34}
                    columnHeaderHeight={42}
                    columns={logColumns.current}
                    hideFooterSelectedRowCount
                    disableRowSelectionOnClick
                    pagination
                    sx={{
                        bgcolor: 'background.default',
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default DailyLogs
