import { useContext, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import Checkbox from '@mui/material/Checkbox';
import InboxIcon from '@mui/icons-material/MoveToInbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { UseMutationResult } from '@tanstack/react-query'
import { useRef, useEffect } from 'react'
import { IMemoryDropDownData } from 'interface'
import { AxiosResponse } from 'axios'
import { IAnalaysisIndexNode, indexTree } from 'page/analysis/constant/indexTree'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setDataFromLeftSelectedList } from 'redux/AnalysisSlice'
import { TreeView, TreeItem } from '@mui/x-tree-view'
import { processNumber } from 'util/index'
import { _language } from 'redux/UtilSlice';
import dic from 'constant/dictionary';
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext';
import { CircularProgress, Skeleton } from '@mui/material';
import TDictionaryKeys from 'constant/dict.type';
import edTableGroup from 'constant/edTableGroup';

interface SearchResult {
    // 定義你期望的搜尋結果型別
    // 這裡使用 any 作為示例
    data: any
}
interface IIndexWithTotal {
    index: string
    total: number
}
interface IIndexForLeftList {
    index: string
    total: number
    type: 'group' | 'item'
    children: IIndexForLeftList[]
}

const LeftSelectList = () => {

    const state = useSelector(_analysisData);
    const language = useSelector(_language);
    const dispatch = useDispatch();

    const [selectedIndex, setSelectedIndex] = useState<string[]>([])
    const [autoSelectedAll, setAutoSelectedAll] = useState<boolean>(false)
    const [index2Total, setIndex2Total] = useState<Map<string, number>>(new Map());
    const [selectedItemName, setSelectedItemName] = useState<string[]>([])
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const groupSelected = useRef<boolean>(false);

    const controller = (useContext(AnalysisController) as TAnalysisController);

    function generateInitIndexArrTableName (
        groupList: IAnalaysisIndexNode[],
        nowList: string[] = []
    ) {
        groupList.map((item) => {
            item.type === 'group'
                ? generateInitIndexArrTableName(item.children, nowList)
                : nowList.push(item.name.tableName)
        })
        return nowList
    }

    // 點選左方清單時，延遲三秒後發送請求，如果點選內容有更換則3秒內重新計時
    // generateIndexWithTotalList(fetchElasticSearchForLeftListCount)
    useEffect(() => {
        if (selectedItemName.length !== 0) {
            if (selectedItemName.length === 1 || groupSelected.current) {
                selectedListSendRequest()
                return;
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            // 改變狀態後延遲三秒
            timeoutRef.current = setTimeout(() => {
                selectedListSendRequest()
            }, 1000)
        }
    }, [selectedItemName])

    useEffect(() => {
        if (!controller.fetchListCount.isSuccess) return;
        let tempIndex2Total: Map<string, number> = new Map();
        let responseList = controller.fetchListCount.data?.data.responses
        let total = 0
        // 整理出total不為0的index
        if (responseList) {
            responseList.map((item: any) => {
                try {
                    total = total + item.hits.total.value;
                    tempIndex2Total.set(
                        'ed_' + item.hits.hits[0]._source.category,
                        item.hits.total.value
                    )
                } catch {
                    // console.log("error")
                }
            })
        }
        //traverse tree[] to get count of the groups.
        getNodeWithTableName(state.leftSelectedList.analysisIndex, tempIndex2Total);

        //set the total amount
        tempIndex2Total.set("__total", total);
        setIndex2Total(tempIndex2Total);
        

        function getNodeWithTableName(
            indexTree: IAnalaysisIndexNode[],
            tableName: Map<string, number>
        ) {
            let nameList: IIndexForLeftList[] = []
            for (let i = 0; i < indexTree.length; i++) {
                if (indexTree[i].type === 'group') {
                    let children = getNodeWithTableName(
                        indexTree[i].children,
                        tableName
                    )
                    let tempGroup: IIndexForLeftList = {
                        total: 0,
                        index: indexTree[i].name.groupName,
                        type: 'group',
                        children: children,
                    }
                    let groupTotal = 0;
                    if (children?.length !== 0) {
                        children.forEach(child => {
                            total += child.total;
                            groupTotal += child.total;
                        })
                        tempGroup.total = groupTotal;
                        nameList.push(tempGroup)
                    }
                    tempIndex2Total.set(indexTree[i].name.groupName, groupTotal);
                } else {
                    let subTotal = tableName.get(indexTree[i].name.tableName);
                    if (subTotal !== undefined) {
                        let temp: IIndexForLeftList = {
                            total: subTotal,
                            index: indexTree[i].name.tableName,
                            type: 'item',
                            children: [],
                        }
                        nameList.push(temp)
                    }
                }
            }
            return nameList
        }
    }, [controller.fetchListCount])

    // 存放左方欄位要顯示的清單
    // const [groupList, setGroupList] = React.useState<oneNode[]>(initGroupList)
    // 控制item被選取
    const handleListItemClick = (event: any, index: string, name: string | string[], isGroup?: boolean) => {
    // const handleListItemClick = (event: any, index: string, name: string) => {
        //根據所選取的index(所選主機、時間不變)，重新發送請求，並更換chart、table的資料，根據所選取的index
        groupSelected.current = isGroup||false;
        if (isGroup) {
            setSelectedIndex([index])
            setSelectedItemName((name as string[]));
            return;
        }
        if (event.ctrlKey) {
            // 控制畫面是否被點選
            // 如果 total result 有被選的話，取消total result的選取
            if (!selectedIndex.includes(index)) {
                selectedIndex.includes('__total')
                    ? setSelectedIndex([index])
                    : setSelectedIndex([...selectedIndex, index])
            } else {
                setSelectedIndex(selectedIndex.filter((item) => item !== index))
            }

            // 控制送出請求內容，如果全部沒選，送出原先全選的請求，如果有選，送出選取的請求
            if (!selectedItemName.includes((name as string))) {
                //@ts-ignore
                setSelectedItemName((selectedItemName) => [
                    ...selectedItemName,
                    name,
                ])
            } else {
                if (selectedItemName.filter((item) => item !== name).length === 0) {
                    let allIndexNameList = generateInitIndexArrTableName(state.leftSelectedList.analysisIndex)
                    setSelectedItemName(allIndexNameList)
                    setAutoSelectedAll(true)
                } else {
                    if (autoSelectedAll) {
                        setSelectedItemName([(name as string)])
                        setAutoSelectedAll(false)
                    } else {
                        setSelectedItemName(
                            selectedItemName.filter((item) => item !== name)
                        )
                    }
                }
            }
        } else {
            setSelectedIndex([index])
            setSelectedItemName((selectedItemName) => [(name as string)])
        }
    }

    // 根據左方所選取的index,生成mutationDataForTable，並發送請求，並更換chart、table的資料，根據所選取的index
    function selectedListSendRequest() {
        try {
            const indexList: IAnalaysisIndexNode[] = []
            selectedItemName.map((item) => {
                indexList.push({
                    name: {
                        tableName: item as edTableGroup,
                        groupName: '',
                        importTimeColumnName: ''
                    },
                    type: 'item',
                    children: [],
                    fourImportantColumnNames: {type: '', item: '', date: '', etc: ''}
                });
            })
            controller.mutateSelectedIndex(indexList);
            const afterSelectedLeftListResponseState = {
                leftSelectedList: {
                    selectedIndex: selectedItemName as edTableGroup[],
                },
                chartData: [],
                tableData: 'responseFromElasticsearchForTable',
                detailData: {},
            }
            dispatch(setDataFromLeftSelectedList(afterSelectedLeftListResponseState))
        } catch (err) {
            let afterSelectedLeftListResponseState = {
                leftSelectedList: {
                    selectedIndex: selectedItemName as edTableGroup[],
                },
                chartData: [],
                tableData: [],
                detailData: {},
            }
            dispatch(setDataFromLeftSelectedList(afterSelectedLeftListResponseState))
        }
    }

    const getItemID = (item:IAnalaysisIndexNode, idx?: number) => {
        let key = `${item.type}-${item.name.tableName+item.name.groupName}-${idx}`;
        return key;
    }

    const renderTree = (tree: IAnalaysisIndexNode[]) => {
        return tree.map((item: IAnalaysisIndexNode, idx) => {
            const key = getItemID(item, idx);

            if (item.type === 'group') {
                return (
                    <TreeItem
                        nodeId={key}
                        key={key}
                        onClick={(event) =>{
                            const tableNameList:string[] = [];
                            const traverse = (tree:IAnalaysisIndexNode) => {
                                if (tree.type === "item") tableNameList.push(tree.name.tableName);
                                else tree.children.forEach(child => traverse(child));
                            }
                            traverse(item);
                            handleListItemClick(
                                event,
                                key,
                                tableNameList,
                                true
                            )
                        }}
                        label={
                            <div className="itemLabel">
                                {/* <Checkbox checked/> */}
                                <span className="text">{dic[language][item.name.groupName as edTableGroup]}</span>
                                {
                                    index2Total.get(item.name.groupName) === undefined? null:
                                    <span className="info">{processNumber(index2Total.get(item.name.groupName) || 0)}</span>
                                }
                            </div>
                        }
                    >
                        {renderTree(item.children)}
                    </TreeItem>
                )
            } else {
                // 產生item
                return (
                    <TreeItem
                        nodeId={key}
                        key={key}
                        label={
                            <div className="itemLabel">
                                <DraftsIcon />
                                <span className="text">{dic[language][item.name.tableName as edTableGroup]}</span>
                                {
                                    controller.fetchListCount.isSuccess?
                                        index2Total.get(item.name.tableName) === undefined?
                                            <span className="info">0</span>:
                                            <span className="info">{processNumber(index2Total.get(item.name.tableName) || 0)}</span>:
                                        null
                                }
                            </div>
                        }
                        onClick={(event) =>{
                            handleListItemClick(
                                event,
                                key,
                                item.name.tableName
                            )
                        }
                    }/>
                )
            }
        })
    }

    return (
        <TreeView 
            className='leftBar'
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
            selected={selectedIndex}
        >
            <div className="header">CATEGORY</div>
            {
                // controller.fetchListCount.isLoading? (
                //     <div className="center-inner" style={{height: "80%"}}>
                //         <CircularProgress/>
                //     </div>
                // ):
                // controller.fetchListCount.isError? (
                //     <span>{dic[language]['database_refresh_fail']}</span>
                // ):
                state.leftSelectedList.analysisIndex !== undefined && state.leftSelectedList.analysisIndex.length > 0?
                (
                    <>
                    <TreeItem
                        nodeId={'__total'}
                        key={'__total'}
                        onClick={() => {
                            setSelectedIndex(['__total'])
                            let allIndexNameList = generateInitIndexArrTableName(state.leftSelectedList.analysisIndex)
                            setSelectedItemName(allIndexNameList)
                            setAutoSelectedAll(true)
                        }}
                        label={
                            <div className="itemLabel">
                                <DraftsIcon />
                                <span className="text">{dic[language]['__total']}</span>
                                {
                                    index2Total.get('__total') === undefined? null:
                                    <span className="info">{processNumber(index2Total.get('__total') || 0)}</span>
                                }
                            </div>
                        }
                    />
                    {
                        renderTree(state.leftSelectedList.analysisIndex)
                    }
                    </>
                ) : null
            }
        </TreeView>
    )
}

export default LeftSelectList
