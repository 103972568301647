import styled from 'styled-components'

export const StraightSeparator = styled.div`
    width: 1px;
    height: 30px;
    border-right: solid black 1px;
    margin-left: 5px;
    margin-right: 5px;
`

export const ActionPlugTitle = styled.span`
    margin-left: 10px;
    margin-right: 10px;
`
