import { API, elasticRoot, urlRoot, WEBSOCKET_STATUS } from 'constant/index'
import { useMutation, useQuery } from '@tanstack/react-query'
import { IAgent } from 'redux/ViewDetailSlice'
import { _token } from 'redux/AuthSlice'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { _selectedHost } from 'redux/MemoryTreeSlice'
import { axiosClient } from 'util/index'
import useSocketHandler from 'hook/useSocketHandler'
import { useAlert } from 'hook/useAlert'

export interface IAgentStatus extends IAgent {
    connection: boolean
    os: string
    memory: {
        timestamp: number
        status: number
        failed: string
    }
}

const useFetchMemoryTreeAgents = () => {
    const alert = useAlert();
    const token = useSelector(_token);
    const selectedHost = useSelector(_selectedHost);
    const [rootsList, setRootsList] = useState<Map<string, any[]>>(new Map());
    const [agentList, setAgentList] = useState<IAgentStatus[]>([]);

    // fetch agentlist, in order to know agent's status
    const detectAgentQuery = useQuery({
        queryKey: ['detectAgent'],
        queryFn: async () => {
            const res = await fetch(`${urlRoot}${API.agentAll}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                }
            })
            const json = await res.json();
            return json;
        }
    })

    //fetch rootslist, every memory treenode data start fetching from here
    const rootQuery = useQuery({
        queryKey: ['memoryTreeRoots'],
        queryFn: async () => {
            const res = await fetch(`${elasticRoot}ed_memory/_search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                },
                body: JSON.stringify({"size":10000,"query":{"term":{"memory_tree.isRoot":true}}})
            })
            const json = await res.json();
            return json;
        }
    });

    //fire detect/mission to scan memory tree of selected host. and set agent object to loading (status =2 )
    const fireDetectMission = () => {
        const hostId = selectedHost?.id;
        if (selectedHost === null || !hostId ) return;
        if (!agentList.find(agent => agent.id === selectedHost.id)?.connection) {
            alert.showAlert('device is offline');
            return;
        }
        axiosClient.post(`${urlRoot}${API.detectMission}`, {
            action: 'StartMemoryTree',
            deviceId: [hostId],
            filter: null,
        }).catch(err => {
            setAgentList((prev) => prev.map(agent => {
                if (agent.id === hostId) {
                    return {
                        ...agent,
                        status: 6
                    }
                }
                return agent;
            }))
            alert.showAlert(err.response.data.message+ '. Trying to fetch the previous snapshot of memory tree.');
            rootQuery.refetch();
        })
        const newList = JSON.parse(JSON.stringify(agentList)).map((agent:IAgentStatus) => {
            if (agent.id === hostId) {
                agent.memory.status = 2;
            }
            return agent;
        })
        setAgentList(newList);
    }

    //receive websocket message after detect/mission is complete, translate the data into agentList and refetch rootslist
    useSocketHandler(WEBSOCKET_STATUS.DETECT_UPDATE, (message, list:IAgentStatus[]) => { 
        let modified = false;
        message.data.forEach((data:any) => {
            const id = data.deviceId;
            let isLoading = false;
            list.forEach(agent => {
                if (agent.id === id && agent.memory.status === 2) isLoading = true;
            })

            if (!isLoading) return;
            const newList = JSON.parse(JSON.stringify(agentList)).map((agent:IAgentStatus) => {
                if (agent.id === id) {
                    agent.memory.status = data.memoryTreeFinishTime.status

                    // scan memorytree complete
                    if (data.memoryTreeFinishTime.status === 3) {
                        modified = true;
                        agent.memory.timestamp = data.memoryTreeFinishTime.finishTime;

                    // scan memorytree error
                    } else if (data.memoryTreeFinishTime.status === 6) {
                        agent.memory.failed = data.memoryTreeFinishTime.message;
                    }
                }
                return agent;
            })
            setAgentList(newList);
        });
        if (modified) rootQuery.refetch();
    }, agentList)

    //inital data fetching, translate data into roots and agent list
    useEffect(() => {
        if (rootQuery.status !== "success") return;
        const newRootsList = new Map<string, []>();
        const newAgentList:IAgentStatus[] = JSON.parse(JSON.stringify(agentList));
        rootQuery.data.hits.hits.forEach((hit:any) => {

            //check if agent already added to the list
            let added = false;
            newAgentList.forEach(agent => {if (agent.ip === hit._source.agentIP) added = true})
            if (!added) {
                newAgentList.push({
                    ip: hit._source.agentIP, 
                    id: hit._source.agent, 
                    connection: true,
                    os: "Unknown",
                    memory: {status: 3, failed: "", timestamp: 0}
                });
            }

            if (newRootsList.get(hit._source.agent) === undefined) newRootsList.set(hit._source.agent, []);
            const rootsList = (newRootsList.get(hit._source.agent) as any[]);
            rootsList.push(hit)
        })
        setRootsList(newRootsList);
        setAgentList(newAgentList);
    }, [rootQuery.data, rootQuery.status])

    useEffect(() => {
        if (detectAgentQuery.status !== 'success') return;
        const newAgentList:IAgentStatus[] = JSON.parse(JSON.stringify(agentList));
        detectAgentQuery.data.data.forEach((agent:any) => {
            const idx = newAgentList.findIndex(_agent => _agent.id === agent.deviceId);
            if (idx === -1) {
                newAgentList.push({
                    id: agent.deviceId,
                    ip: agent.innerIP,
                    connection: agent.connection,
                    os: agent.os,
                    memory: {
                        timestamp: agent.memoryTreeFinishTime.finishTime,
                        status: agent.memoryTreeFinishTime.status,
                        failed: agent.memoryTreeFinishTime.message === "nil"? "":agent.memoryTreeFinishTime.message
                    }
                })
            } else {
                newAgentList[idx] = {
                    ...newAgentList[idx],
                    connection: agent.connection,
                    memory: {
                        timestamp: agent.memoryTreeFinishTime.finishTime,
                        status: agent.memoryTreeFinishTime.status,
                        failed: agent.memoryTreeFinishTime.message === "nil"? "":agent.memoryTreeFinishTime.message
                    }
                }
            }
        })
        setAgentList(newAgentList)
    }, [detectAgentQuery.data, detectAgentQuery.status])

    return { rootsList: rootsList, agentList: agentList, status: rootQuery.status, refetch: rootQuery.refetch, fireDetectMission};
}

export default useFetchMemoryTreeAgents;