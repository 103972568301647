/** @format */

import { useForm } from 'react-hook-form'
import {
    HookFormSubmit,
    HookFormSubmitContainer,
} from '../../../../../component/FormInput/StyledComponent'
import FormInputByHooks from '../../../../../component/FormInput/FormInputByHooks'
import {
    CommonHoriLine,
    CommonSpaceGap,
    CommonTitle,
} from '../../../../../component/CommonStyledComponent'
import CheckInputByHooks from '../../../../../component/FormInput/CheckInputByHooks'
import SelectInputByHooks from '../../../../../component/FormInput/SelectInputByHooks'
import { MenuItem } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { usePost } from 'hook/usePost'
import { useGet } from 'hook/useGet'
import { API, urlRoot } from 'constant/index'
import FullLoading from '../../../../../component/FullLoading'
import { useToast } from 'hook/useToast'
import { useDispatch } from 'react-redux'
import { setGlobalDirty } from 'redux/SettingSlice'
import '../../../index.scss'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'

interface EmailNotification {
    on: boolean
    riskLevel: string
    recepientEmail: string
}

interface Report {
    on: boolean
    generateTime: string
    riskLevel: string
    recepientEmail: string
}

interface AutoDefense {
    on: boolean
    riskLevel: string
    action: string
    memoryDump: boolean
    dumpUploadVirusTotalAnalysis: boolean
    dumpUploadHybridAnalysis: boolean
    sampleDownload: boolean
    sampleUploadVirusTotalAnalysis: boolean
    sampleUploadHybridAnalysis: boolean
}

export interface ICommonFuncData {
    emailNotification: EmailNotification
    report: Report
    autoDefense: AutoDefense
}
// 時間選項的迴圈
const dayOfTime = Array.from({ length: 25 }, (_, i) =>
    i < 10 ? `0${i}:00` : `${i}:00`
)

const defaultValues: ICommonFuncData = {
    emailNotification: {
        on: true,
        riskLevel: '',
        recepientEmail: 'test@example.com',
    },
    report: {
        on: true,
        generateTime: '22:00',
        riskLevel: 'level4',
        recepientEmail: 'test@example.com',
    },
    autoDefense: {
        on: true,
        riskLevel: 'level4',
        action: 'action',
        memoryDump: true,
        dumpUploadVirusTotalAnalysis: true,
        dumpUploadHybridAnalysis: true,
        sampleDownload: true,
        sampleUploadVirusTotalAnalysis: true,
        sampleUploadHybridAnalysis: true,
    },
}

const Child_CommonFunc = () => {
    const toast = useToast()
    const dispatch = useDispatch();
    const language = useSelector(_language)
    const [pageData, setPageData] = useState<ICommonFuncData>(defaultValues)

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isSubmitting, isDirty, isValid, isSubmitSuccessful },
    } = useForm<ICommonFuncData>({ defaultValues: pageData })

    const { mutate, isSuccess, isLoading: postLoading } = usePost()
    const { 
        data: defaultData, 
        isLoading: getLoading,
        isSuccess: isGetDefaultSuccess,
    } = useGet({
        query: 'CommonFunc',
        root: `${urlRoot}`,
        route: `${API.commonFunc}`,
        state: { data: isSubmitSuccessful },
    })

    useEffect(() => {
        if (isGetDefaultSuccess && defaultData.data) {
            const formData = defaultData.data as ICommonFuncData
            setPageData(formData)
            reset(formData)
        }
    }, [isGetDefaultSuccess, reset, defaultData])

    const onSubmit = (result: ICommonFuncData) => {
        console.log('onSubmit', result)
        mutate({
            root: `${urlRoot}`,
            route: `${API.commonFunc}`,
            body: result,
        })
    }

    useEffect(() => {
        if (isDirty) {
            dispatch(setGlobalDirty(true))
        }
        return () => {
            dispatch(setGlobalDirty(false))
        }
    }, [isDirty])

    useEffect(() => {
        if (isSuccess) {
            toast.show({content: dic[language]["save_success"]})
        }
    }, [isSuccess])

    return (
        <div className='NavigationBody'>
            <FullLoading open={postLoading || getLoading} />
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: 'flex', flexWrap: 'wrap' }}
            >
                <CommonTitle>
                    {dic[language]["setting_system_server"]}
                </CommonTitle>

                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_mail_notificaiton"]}
                    name="emailNotification.on"
                    watch={watch('emailNotification.on')}
                />
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_mail_encryption"]}
                    name="emailNotification.riskLevel"
                    label="emailEncryption"
                    formControlWidth={170}
                    marginGap={170}
                >
                    <MenuItem value="non white list">
                        {dic[language]["setting_system_non_whitelist_program"]}
                    </MenuItem>
                    <MenuItem value="black list">
                        {dic[language]["setting_system_blacklist_program"]}
                    </MenuItem>
                </SelectInputByHooks>
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_recevier_mail"]}
                    name="emailNotification.recepientEmail"
                    marginGap={170}
                />

                <CommonSpaceGap />

                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_report_generate_time"]}
                    name="report.on"
                    watch={watch('report.on')}
                />
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_daily_report_time"]}
                    name="report.generateTime"
                    label="generateTime"
                    formControlWidth={150}
                    marginGap={170}
                >
                    {dayOfTime.map((item, index) => (
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                    ))}
                </SelectInputByHooks>
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_risk_level_bigger_than"]}
                    name="report.riskLevel"
                    label="riskLevel"
                    formControlWidth={150}
                    marginGap={170}
                >
                    <MenuItem value="level4">{dic[language]["risk_level_4"]}</MenuItem>
                    <MenuItem value="level3">{dic[language]["risk_level_3"]}</MenuItem>
                    <MenuItem value="level2">{dic[language]["risk_level_2"]}</MenuItem>
                    <MenuItem value="level1">{dic[language]["risk_level_1"]}</MenuItem>
                </SelectInputByHooks>
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_recevier_mail"]}
                    name="report.recepientEmail"
                    marginGap={170}
                />

                <CommonSpaceGap />

                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_auto_defense"]}
                    name="autoDefense.on"
                    watch={watch('autoDefense.on')}
                />
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_risk_level_bigger_than"]}
                    name="autoDefense.riskLevel"
                    label="riskLevel"
                    formControlWidth={150}
                    marginGap={170}
                >
                    <MenuItem value="level4">{dic[language]["risk_level_4"]}</MenuItem>
                    <MenuItem value="level3">{dic[language]["risk_level_3"]}</MenuItem>
                    <MenuItem value="level2">{dic[language]["risk_level_2"]}</MenuItem>
                    <MenuItem value="level1">{dic[language]["risk_level_1"]}</MenuItem>
                </SelectInputByHooks>
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_risk_process"]}
                    name="autoDefense.action"
                    label="action"
                    formControlWidth={150}
                    marginGap={170}
                >
                    <MenuItem value="no-action">{dic[language]["no_action"]}</MenuItem>
                    <MenuItem value="action">{dic[language]["have_action"]}</MenuItem>
                </SelectInputByHooks>

                <CommonSpaceGap />

                <CheckInputByHooks
                    control={control}
                    title=""
                    name="autoDefense.memoryDump"
                    subTitle={dic[language]["setting_system_memory_dump"]}
                    watch={watch('autoDefense.memoryDump')}
                />
                <CheckInputByHooks
                    control={control}
                    title=""
                    name="autoDefense.dumpUploadVirusTotalAnalysis"
                    subTitle={dic[language]["setting_system_dump_to_virustotal"]}
                    watch={watch('autoDefense.dumpUploadVirusTotalAnalysis')}
                />
                <CheckInputByHooks
                    control={control}
                    title=""
                    name="autoDefense.dumpUploadHybridAnalysis"
                    subTitle={dic[language]["setting_system_dump_to_hybrid_analysis"]}
                    watch={watch('autoDefense.dumpUploadHybridAnalysis')}
                />

                <CommonSpaceGap />

                <CheckInputByHooks
                    control={control}
                    title=""
                    name="autoDefense.sampleDownload"
                    subTitle={dic[language]["setting_system_sample_download"]}
                    watch={watch('autoDefense.sampleDownload')}
                />
                <CheckInputByHooks
                    control={control}
                    title=""
                    name="autoDefense.sampleUploadVirusTotalAnalysis"
                    subTitle={dic[language]["setting_system_sample_download_to_virustotal"]}
                    watch={watch('autoDefense.sampleUploadVirusTotalAnalysis')}
                />
                <CheckInputByHooks
                    control={control}
                    title=""
                    name="autoDefense.sampleUploadHybridAnalysis"
                    subTitle={dic[language]["setting_system_sample_download_to_hybrid_analysis"]}
                    watch={watch('autoDefense.sampleUploadHybridAnalysis')}
                />

                <CommonHoriLine />

                <HookFormSubmitContainer>
                    <HookFormSubmit
                        type="submit"
                        value={dic[language]["submit"]}
                        disabled={!isDirty || !isValid || isSubmitting}
                    />
                </HookFormSubmitContainer>
            </form>
        </div>
    )
}
export default Child_CommonFunc
