import Earth from "./Earth"
import ConnectCountry from "./ConnectCountry"
import ServerStatus from "../../../detectdashboard/component/ServerStatus"
import ChangeBox from "./ChangeBox"
import KeywordBarChart from "./KeywordBarChart"
import LineChart from "./LineChart"
import { EarthData, CountryEarthData, SelectedRow, KeywordHitData, LineChartData } from "../Interfaces"
import { IDateRange } from 'interface'
import './MiddleBar.scss'

interface MiddleBarProps {
    dateModuleData: IDateRange
    earthData: EarthData | CountryEarthData
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
    displayChart: 'earth' | 'bar' | 'line'
    barChartData: KeywordHitData[]
    lineChartData: LineChartData
    openSnackBar: boolean
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const MiddleBar = (props: MiddleBarProps) => {
    const {
        dateModuleData,
        earthData,
        selectedRow,
        setSelectedRow,
        setEarthData,
        displayChart,
        barChartData,
        lineChartData,
        openSnackBar,
        setOpenSnackBar,
    } = props

    return (
        <div className="middleBarContainer">
            <ChangeBox dateModuleData={dateModuleData} />
            {
                displayChart === 'earth' ? (
                    <Earth data={earthData}
                        openSnackBar={openSnackBar}
                        setOpenSnackBar={setOpenSnackBar}
                    />
                ) : displayChart === 'bar' ? (
                    <KeywordBarChart data={barChartData} />
                ) : (
                    <LineChart data={lineChartData} />
                )
            }
            <ConnectCountry
                dateModuleData={dateModuleData}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setEarthData={setEarthData}
                setOpenSnackBar={setOpenSnackBar}
            />
            <ServerStatus />
        </div>
    )
}

export default MiddleBar
