import { createSlice } from "@reduxjs/toolkit";
import { RootState } from './store'
import { QueryStatus } from "@tanstack/react-query";

export interface IFileData{
    disk: string;
    fileName: string
    isDeleted: boolean
    isDirectory: boolean
    createTime: number
    writeTime: number
    accessTime: number
    entryModifiedTime: number 
    dataLen: number
    uuid: string
    agent: string
    agentIP: string
    agentName: string
    item_main: string
    date_main: number
    type_main: string
    etc_main: string
    path: string
    fileId: string
    yaraRuleHitCount: number
    yaraRuleHit: string
    child: string[]
}

export interface IAgent{
    id: string
    ip: string
}

export interface IFileNode extends IFileData{
    children: IFileData[],
    childrenStatus: QueryStatus
}

export type INode = IFileNode | null

export interface viewDetailState {
    selectedHost: IAgent | null
    isHostSelected: boolean
    selectedNode: INode
    isNodeSelected: boolean
}

const initialState: viewDetailState = {
    selectedHost: null,
    isHostSelected: false,
    selectedNode: null,
    isNodeSelected: false
}

export const viewDetailSlice = createSlice({
    name: 'viewDetail',
    initialState: initialState,
    reducers: {
        initialize: (state) => {
            return initialState;
        },
        setSelectedHost: (state, action) => {
            state.selectedHost = action.payload;
            state.isHostSelected = true;
            state.selectedNode = initialState.selectedNode;
            state.isNodeSelected = false;
        },
        setSelectedNode: (state, action) => {
            state.selectedNode = action.payload;
            state.isNodeSelected = true;
        },
        updateSelectedNodeChildren: (state, action) => {
            if (state.selectedNode !== null){
                state.selectedNode.children = action.payload.children;
                state.selectedNode.childrenStatus = action.payload.childrenStatus;
            }
        }
    }
});

export const { setSelectedHost, setSelectedNode, initialize, updateSelectedNodeChildren } = viewDetailSlice.actions
export const _selectedHost = (state:RootState) => state.viewDetail.selectedHost;
export const _isHostSelected = (state:RootState) => state.viewDetail.isHostSelected;
export const _selectedNode = (state:RootState) => state.viewDetail.selectedNode;
export const _isNodeSelected = (state:RootState) => state.viewDetail.isNodeSelected;

export default viewDetailSlice.reducer;
