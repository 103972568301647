import { useState } from 'react'
import ItemComponent from './ItemComponent'
import FullLoading from '../../../../../component/FullLoading'
import { useToast } from 'hook/useToast'
import { useAlert } from 'hook/useAlert'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'

const MainComponent = () => {
    const [isLoading, setIsLoading] = useState(false)
    const alert = useAlert().showAlert
    const toast = useToast()
    const [isToastOrAlarm, setIsToastOrAlarm] = useState(true)
    const language = useSelector(_language)

    const showAlert = (message: string) => {
        alert(message)
    }

    const showToast = (message: string) => {
        toast.show({
            content: message,
            type: isToastOrAlarm? undefined: 'error'
        })
        setIsToastOrAlarm(!isToastOrAlarm)
    }

    return (
        <div>
            <FullLoading open={isLoading} />
            <p className='SettingSubTitle'>
                {dic[language]['setting_select_functionality']}
            </p>
            <ItemComponent
                label={dic[language]['setting_server_install_start']}
                buttonLabel={dic[language]['start']}
                onClick={setIsLoading.bind(null, true)}
            />
            <ItemComponent
                label={dic[language]['setting_server_restart']}
                buttonLabel={dic[language]['start']}
                onClick={showAlert.bind(null, dic[language]['setting_server_restart'])}
            />
            <ItemComponent
                label={dic[language]['setting_server_remove']}
                buttonLabel={dic[language]['start']}
                onClick={showToast.bind(null, dic[language]['setting_server_remove'])}
            />
        </div>
    )
}
export default MainComponent
