/** @format */

import { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { login, _token, logout } from 'redux/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { API, urlRoot } from 'constant/index'
import { useQuery } from '@tanstack/react-query'
import CommonHeader from '../component/CommonHeader'
import { _translatedPermission, setUserInfo } from 'redux/UtilSlice'
import { axiosClient } from 'util/index'

interface IFetchToken {
    success: boolean
    message: string
    user: {
        token: string
        username: string
    }
}

export const fetchToken = async (
    token: string | undefined
): Promise<IFetchToken> => {
    const res = await fetch(`${urlRoot}${API.loginWithToken}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: token }),
    }).then((res) => res.json())
    return res
}

const UnAuth = () => {
    const tokenAccess = useSelector(_token);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const permission = useSelector(_translatedPermission);

    const tokenQuery = useQuery({
        queryKey: ['verifyToken', tokenAccess],
        queryFn: () => fetchToken(tokenAccess),
        retry: 1,
    })

    useEffect(() => {
        if (tokenQuery.data) {
            if (tokenQuery.data.success) {
                dispatch(login(tokenAccess))
                
                axiosClient.get(`${urlRoot}${API.userInfo}`, {
                    headers: {
                        Authorization: `Bearer ${tokenAccess}`
                    }
                })
                .then((res:any) => {
                    dispatch(setUserInfo(res.data.data));
                    if (permission.includes("dashboard")){
                        navigate('/');
                    } else {
                        navigate('/setting/SettingAgentActions');
                    }
                });
            } else {
                dispatch(logout())
            }
        }
    }, [tokenAccess, tokenQuery.data, permission])

    return !tokenAccess ?
        <div style={{ height: '100vh' }}>
            <Outlet />
        </div> : null
}

export default UnAuth
