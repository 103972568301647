import { DialogContent, IconButton, TableCell as TableCell_MUI, TableCellProps } from '@mui/material'
import './TableCell.css'
import ProgressBar from 'component/ProgressBar'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { _language } from 'redux/UtilSlice';
import dic from 'constant/dictionary';

interface TableCellComponentProps extends TableCellProps {
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
    children: React.ReactNode
    display?: boolean
    isWidth?: boolean
    maxWidth?: number
    isHead?: boolean
}

// ! max height -> 41px

export function TableCellDense({
    align = 'left',
    children,
    display = true,
    isWidth = true,
    maxWidth = 200,
    isHead = false,
    ...other
}: TableCellComponentProps) {
    if (!display) return null
    return (
        <TableCell_MUI
            align={align}
            {...other}
            sx={{
                whiteSpace: 'pre-line',
                minWidth: isWidth ? '120px' : '100px',
                maxWidth: maxWidth,
                maxHeight: isHead ? "80px" : '41px',
                paddingTop: '0px',
                paddingBottom: '0px',
            }}
        >
            <span style={{
                padding: '0px',
                margin: '0px',
                maxHeight: isHead ? "80px" : '41px',
                width: '100%',
                lineHeight: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: "nowrap"
            }}>
                {children}
            </span>
        </TableCell_MUI>
    )
}

export function TableCell({
    align = 'left',
    children,
    display = true,
    isWidth = true,
    ...other
}: TableCellComponentProps) {
    if (!display) return null
    return (
        <TableCell_MUI
            // style={{ 
            //     whiteSpace: 'pre-line' 
            // }}
            align={align}
            {...other}
            sx={{
                whiteSpace: 'pre-line',
                minWidth: isWidth ? '120px' : '80px',
                maxWidth: 200,
                maxHeight: '41px',
            }}
        >
            <span style={{
                display: 'inline-block',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',

            }}>
                {children}
            </span>
        </TableCell_MUI>
    )
}

interface TableStatusomponentProps extends TableCellProps {
    index: string
    children: React.ReactNode
}

// * only for "連線狀態", with fade in animation
export function TableStatus({
    children,
    index,
    ...other
}: TableStatusomponentProps) {
    return (
        <TableCell_MUI
            align="left"
            sx={{ paddingLeft: '0', paddingRight: '0' }}
            {...other}
        >
            {children}
        </TableCell_MUI>
    )
}

interface ConditionCellcomponentProps extends TableCellProps {
    value: string | number
    display?: boolean
}

// * only for installing progress, with progress bar
export function ConditionCell({
    value,
    display = true,
}: ConditionCellcomponentProps) {
    if (!display) return null
    return typeof value === 'string' ? (
        <TableCellDense>{value}</TableCellDense>
    ) : (
        <TableCellDense>
            <ProgressBar progress={value} />
        </TableCellDense>
    )
}

interface DownloadCellComponentProps extends TableCellProps {
    value: string | number
    display: boolean
    handleDownload: () => void
}

export const DownloadCell = ({
    value,
    display,
    handleDownload,
}: DownloadCellComponentProps) => {
    if (!display) return null
    const timePattern = /^\d{4}\/\d{1,2}\/\d{1,2} \d{2}:\d{2}:\d{2}$/;

    return typeof value === 'string' ? (
        <TableCellDense>
            {value}
            {timePattern.test(value) && (
                <IconButton onClick={handleDownload} size="small" aria-label='image-download'>
                    <DownloadRoundedIcon fontSize="inherit" />
                </IconButton>
            )}
        </TableCellDense>
    ) : (
        <TableCellDense>
            <ProgressBar progress={value} />
        </TableCellDense>
    )
}

interface CollectCellComponentProps extends TableCellProps {
    value: string | number
    display: boolean
}

export const CollectCell = ({
    value,
    display,
}: CollectCellComponentProps) => {
    const [open, setOpen] = useState(false);
    const lanugage = useSelector(_language);

    if (!display) return null;

    const openFailedTableDialog = () => {
        setOpen(true);
    }

    const closeFailedTableDialog = () => {
        setOpen(false);
    }

    if (typeof value === 'string') {
        const values = value.split('|');
        const failedTableList = values.slice(1).map((table, index) => {
            return { id: index, table: table }
        });

        const failedTableListColumn: GridColDef[] = [
            { field: 'table', headerName: dic[lanugage]['detect_collect_table'], flex: 1 },
        ];

        return (
            <TableCellDense>
                {values.length > 1 ? values[0] : value}
                {values.length > 1 && (
                    <>
                        <IconButton onClick={openFailedTableDialog} size="small" aria-label='image-download'>
                            <ErrorRoundedIcon fontSize="inherit" color={values.length > 5 ? 'error' : 'warning'} />
                        </IconButton>
                        <Dialog
                            open={open}
                            onClose={closeFailedTableDialog}
                            fullWidth={true}
                            maxWidth='sm'
                            aria-labelledby="collect-failed-tables"
                        >
                            <DialogTitle
                                sx={{
                                    backgroundColor: 'rgba(33, 150, 243, 1)',
                                    color: 'white',
                                    padding: '5px 15px',
                                    fontSize: '1rem',
                                }}
                            >
                                {dic[lanugage]['detect_collect_failed_tables']}
                            </DialogTitle>
                            <DialogContent>
                                <DataGridPro
                                    rows={failedTableList}
                                    rowHeight={34}
                                    columnHeaderHeight={42}
                                    columns={failedTableListColumn}
                                    sx={{
                                        marginTop: '1rem',
                                        maxHeight: 'calc(100% - 4rem)',
                                        bgcolor: 'background.default',
                                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                            outline: "none !important",
                                        },
                                    }}
                                />
                            </DialogContent>
                        </Dialog>
                    </>
                )}
            </TableCellDense>
        )
    } else {
        return (
            <TableCellDense>
                <ProgressBar progress={value} />
            </TableCellDense>
        )
    }
}