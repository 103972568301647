import assets from "assets"
import './topBarTitle.scss'

const TopBarTitle = (props: {title: string}) => 
    <div className="topBarTitle">
        <assets.dashboardTitleBackground />
        <div className="titleText">
            {props.title}
        </div>
    </div>


export default TopBarTitle