/** @format */

import { useForm } from 'react-hook-form'
import {
    CommonHoriLine,
    CommonTitle,
} from '../../../../../component/CommonStyledComponent'
import {
    HookFormSubmit,
    HookFormSubmitContainer,
} from '../../../../../component/FormInput/StyledComponent'
import CheckInputByHooks from '../../../../../component/FormInput/CheckInputByHooks'
import {
    MenuItem,
} from '@mui/material'
import SelectInputByHooks from '../../../../../component/FormInput/SelectInputByHooks'
import FormInputByHooks from '../../../../../component/FormInput/FormInputByHooks'

import { API, urlRoot } from 'constant/index'
import { usePost } from 'hook/usePost'
import { useGet } from 'hook/useGet'
import { useEffect, useState } from 'react'
import FullLoading from '../../../../../component/FullLoading'
import { useToast } from 'hook/useToast'
import { setGlobalDirty } from 'redux/SettingSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { _displayMode } from 'redux/UtilSlice'
import { _language } from 'redux/UtilSlice'
import dic from "constant/dictionary";

export interface InterfaceSettingType {
    cefLog: boolean
    riskLevel: string
    ip: string
    port: number
    signatureID: string
    vtAPIKey: string
}

const defaultValues: InterfaceSettingType = {
    cefLog: false,
    riskLevel: 'level4',
    ip: '',
    port: 0,
    signatureID: '',
    vtAPIKey: '',
}

// 轉換型別規則
const handleType = (data: InterfaceSettingType) => {
    return {
        ...data,
        port: Number(data.port),
    }
}

const Child_InterfaceSetting = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const [pageData, setPageData] =
        useState<InterfaceSettingType>(defaultValues)
    const language = useSelector(_language)

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isSubmitting, isDirty, isValid, isSubmitSuccessful },
    } = useForm<InterfaceSettingType>({ defaultValues: pageData })

    const { mutate, isSuccess, isLoading: postLoading } = usePost()
    const { 
        data: defaultData, 
        isLoading: getLoading,
        isSuccess: isGetDefaultSuccess,
    } = useGet({
        query: 'interfaceSetting',
        root: `${urlRoot}`,
        route: `${API.interfaceSetting}`,
        state: { data: isSubmitSuccessful },
    })

    useEffect(() => {
        if (isGetDefaultSuccess && defaultData.data) {
            const reTypeData = defaultData.data as InterfaceSettingType
            setPageData(reTypeData)
            reset(reTypeData)
        }
    }, [isGetDefaultSuccess, reset, defaultData])

    // request server date
    const onSubmit = (result: InterfaceSettingType) => {
        const reTypeResult = handleType(result)
        mutate({
            root: `${urlRoot}`,
            route: `${API.interfaceSetting}`,
            body: reTypeResult,
        })
    }

    useEffect(() => {
        if (isDirty) {
            dispatch(setGlobalDirty(true))
        }
        return () => {
            dispatch(setGlobalDirty(false))
        }
    }, [isDirty])

    useEffect(() => {
        if (isSuccess) {
            toast.show({content: dic[language]['save_success']})
        }
    }, [isSuccess])

    return (
        <div>
            <FullLoading open={postLoading || getLoading} />
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: 'flex', flexWrap: 'wrap' }}
            >
                <CommonTitle>
                    {dic[language]['setting_system_tab_interface']}
                </CommonTitle>
                <CheckInputByHooks
                    control={control}
                    title="CEF log"
                    name="cefLog"
                    watch={watch('cefLog')}
                    defaultChecked={pageData.cefLog}
                />
                <SelectInputByHooks
                    control={control}
                    title={dic[language]['setting_system_risk_level_bigger_than']}
                    name="riskLevel"
                    label="settingInterfaceRiskLevel"
                    formControlWidth={150}
                // marginGap={170}
                >
                    <MenuItem value="level4">{dic[language]['risk_level_4']}</MenuItem>
                    <MenuItem value="level3">{dic[language]['risk_level_3']}</MenuItem>
                    <MenuItem value="level2">{dic[language]['risk_level_2']}</MenuItem>
                    <MenuItem value="level1">{dic[language]['risk_level_1']}</MenuItem>
                </SelectInputByHooks>

                <FormInputByHooks
                    control={control}
                    title="IP"
                    name="ip"
                    defaultType="string"
                />
                <FormInputByHooks
                    control={control}
                    title="Port"
                    name="port"
                    defaultType="number"
                />
                <FormInputByHooks
                    control={control}
                    title="Signature ID"
                    name="signatureID"
                    defaultType="string"
                />

                <CommonHoriLine />
                <HookFormSubmitContainer>
                    <HookFormSubmit
                        type="submit"
                        disabled={!isDirty || !isValid || isSubmitting}
                        value={isSubmitting ? dic[language]['saving'] : dic[language]['save']}
                    />
                </HookFormSubmitContainer>
            </form>
        </div>
    )
}

export default Child_InterfaceSetting
