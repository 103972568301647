import { elasticRoot } from 'constant/index'
import { useQuery } from '@tanstack/react-query'
import { IAgent, IFileData } from 'redux/ViewDetailSlice'
import { _token } from 'redux/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setElasticRestarting } from 'redux/UtilSlice'

const useFetchExplorerAgents = () => {
    const token = useSelector(_token);
    const dispatch = useDispatch();

    const { status, data } = useQuery({
        queryKey: ['explorerRoots'],
        queryFn: async () => {
            const res = await fetch(`${elasticRoot}ed_explorer/_search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`
                },
                body: JSON.stringify({"size":10000,"query":{"term":{"explorer.isRoot":true}}})
            })
            const json = await res.json();
            return json;
        }
    });

    const [rootsList, setRootsList] = useState<Map<string, IFileData[]>>(new Map());
    const [agentList, setAgentList] = useState<IAgent[]>([]);

    useEffect(() => {
        if (status !== "success") return;
        if (data.message === "Elastic is restarting"){
            dispatch(setElasticRestarting(true));
            return;
        }
        dispatch(setElasticRestarting(false));
        const newRootsList = new Map<string, IFileData[]>();
        const newAgentList:IAgent[] = [];
        data.hits.hits.forEach((hit:any) => {

            //check if agent already added to the list
            let added = false;
            newAgentList.forEach(agent => {if (agent.ip === hit._source.agentIP) added = true})
            if (!added) newAgentList.push({ip: hit._source.agentIP, id: hit._source.agent});

            if (newRootsList.get(hit._source.agentIP) === undefined) newRootsList.set(hit._source.agentIP, []);
            const rootsList = (newRootsList.get(hit._source.agentIP) as IFileData[]);
            rootsList.push(hit)
        })
        setRootsList(newRootsList);
        setAgentList(newAgentList);
    }, [data, status])

    return { rootsList, agentList, status};
}

export default useFetchExplorerAgents;  