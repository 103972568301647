import { useLocation, useNavigate } from 'react-router-dom'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import './SwitchDashboardButton.scss'

const SwitchDashboardButton = () => {
    const language = useSelector(_language);
    const location = useLocation();
    const navigate = useNavigate();

    const onChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
        navigate(value);
    }

    return (
        <ToggleButtonGroup
            exclusive
            color="primary"
            value={location.pathname}
            id="switchDashboardButtonGroup"
            onChange={onChange}>
            <ToggleButton
                className="switchDashboardButton"
                value={'/'}
            >
                {dic[language]["dashboard_switch_btn_detect"]}
            </ToggleButton>
            <ToggleButton
                className="switchDashboardButton"
                value={'/analysisDashboard'}
            >
                {dic[language]["dashboard_switch_btn_analysis"]}
            </ToggleButton>
        </ToggleButtonGroup>
    )
}

export default SwitchDashboardButton;