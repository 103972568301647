import { useMutation, UseMutationResult } from '@tanstack/react-query'
import GridFooter from './GridFooter'
import GridHeader from './GridHeader'
import { AxiosResponse } from 'axios'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { formatTimestamp } from 'util/index'
import { API, elasticRoot, urlRoot } from 'constant/index'
import {
    DynamicObject,
    excludingList,
    filterExcludingList,
    filterExcludingObj,
    combineIndexIntoSource,
    stringArray2IAnalaysisNodeArray,
} from './util'
import { axiosElastic, extractDoc } from 'util/index';
import { DataGridPro, GridColDef, GridSortModel } from '@mui/x-data-grid-pro'
import { CircularProgress, ClickAwayListener, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Popover, Portal } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setRightBar } from 'redux/AnalysisSlice'
import { ContentCopy, Chat } from '@mui/icons-material'
import { _uuidList, addReportRow, removeReportRow } from 'redux/ReportSlice'
import { _displayMode } from 'redux/UtilSlice'
import { usePost } from 'hook/usePost'
import {CellCheckBox, HeaderCheckBox} from './GridCheckBox'
import dayjs from 'dayjs'
import useRerender from 'hook/useRerender'
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext'
import { SortModel } from 'redux/NetworkSlice'

export interface IElasticGridProps {
    rightBar: JSX.Element
    setRightBarOpen: (_: boolean) => void
}

export interface ILastDataInEachPage {
    uuid: string
    date_main: number
}

const timeFields = ['date_main', 'last_modified', 'visit_time', 'last_visit_time',
'processCreateTime', 'installdate', 'installfoldercreatedtime',
'installfoldermodifiedtime', 'registrytime',
'createtime', 'accesstime', 'modifiedtime', 'recordtime',
"createTime", "writeTime", "accessTime", "entryModifiedTime",
'lastruntime', 'foldercreatedtime', 'foldermodifiedtime',
'createdsystemtime', 'nextruntime', 'modifytime', 'timestamp',
'date_added', 'date_modified', 'date', 'expires', 'creation_utc',
'expires_utc', 'last_access_utc', 'start_time', 'end_time', 'last_access_time',
'last_modified', 'visit_time', 'last_visit_time', 'date_created', 'dateadded',
'lastmodified', 'url_last_visit_date', 'last_fetched', 'lastaccessed', 'creationtime',
'visit_date', 'last_visit_date', 'expirestime', 'lastupdatedtime', 'visitedtime',
'processcreatetime', 'install_date', 'first_install_date', 'last_arrival_date', 'last_removal_date',
'lastlogontime', 'creation_time', 'delivery_time', 'task_timestamp']

const ElasticGrid = (props: IElasticGridProps) => {
    const {
        setRightBarOpen,
        rightBar
    } = props;

    const {rerender} = useRerender();
    const displayMode = useSelector(_displayMode);;
    const analysisData = useSelector(_analysisData);
    const reportUUIDList = useSelector(_uuidList); 
    const headerCheckboxRef = useRef<HTMLDivElement>(null);
    const { fetchTable, mutateSelectedIndex } = (useContext(AnalysisController) as TAnalysisController);
    const dispatch = useDispatch();
    const [columnName, setColumnName] = useState<string[]>([]) //store column array
    const [gridData, setGridData] = useState<DynamicObject[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [sort, _setSort] = useState<SortModel>(null);


    // todo 紀錄頁數，紀錄每頁最後一筆資料的uuid
    const [lastDataInEachPage, setLastDataInEachPage] = useState<any[]>([])
    const [pageNumber, setPageNumber] = useState<number>(0)
    const [lastSelectedID, setLastSelectedID] = useState<string>();

    const dataGridColumn = useRef<GridColDef[]>([]);
    const gridContainer = useRef(null);

    const setSort = (_s: SortModel) => {
        if (JSON.stringify(_s) !== JSON.stringify(sort)){
            setPageInit();
            _setSort(_s);
        }
    }

    const setPageInit = () => {
        setPageNumber(0)
        setLastDataInEachPage([])
        setLastSelectedID("");
    }

    useEffect(() => {
        const selectedItemName = analysisData.leftSelectedList.selectedIndex;
        let indexList = stringArray2IAnalaysisNodeArray(selectedItemName);
        if (indexList.length === 0) {
            indexList = analysisData.indexList;
        }
        mutateSelectedIndex(indexList,sort);
    }, [sort])

    const findReport = useMemo(() => {
        return (uuid: string) => {
            const found = reportUUIDList.find(_uuid => _uuid === uuid)
            return found !== undefined
        }
    }, [reportUUIDList])

    useEffect(() => {
        handleBody();
    }, [reportUUIDList]);

    const handleBody = () => {
        if (JSON.stringify(fetchTable.data?.data.hits.hits) === undefined) return
        const arrList: DynamicObject[] = JSON.parse(JSON.stringify(fetchTable.data?.data.hits.hits)); // parse(stringify())for deep cloning
        // 將_index放入item._source內
        let putIndexInto_source = arrList.map((item) =>
            combineIndexIntoSource(item)
        )   
        const filterRes = putIndexInto_source.map((item) =>
            filterExcludingObj(item._source, excludingList)
        )
        let count = 0
        const transDateRes = filterRes.map((item) => {
            const inReport = findReport(item.uuid)
            if (inReport) count++;
            if (typeof item.date === 'number') {
                item.date = formatTimestamp(item.date)
                return { ...item, selected: inReport }
            } else if (
                typeof item.date === 'string' &&
                item.date.length > 6
            ) {
                const timstamp = Number(item.date)
                item.date = formatTimestamp(timstamp)
                return { ...item, selected: inReport }
            }
            return { ...item, selected: inReport }
        })
        const flattened = transDateRes.map((item:any) => {
            const nested = JSON.parse(JSON.stringify(item[item.category]));
            item[item.category] = undefined;
            return { ...item, ...nested};
        })
        if (transDateRes.length !== 0) setGridData(flattened)
    }

    useEffect(() => {
        if (fetchTable.data && fetchTable.data.data.hits.hits.length !== 0) {
            const handleHeader = () => {
                const arrList: string[] = Object.keys(fetchTable.data?.data.hits.hits[0]._source)
                                        .concat(Object.keys(extractDoc(fetchTable.data?.data.hits.hits[0])))
                                        .filter(item => item !== fetchTable.data?.data.hits.hits[0]._source.category)
                const filterRes = filterExcludingList(arrList, excludingList)
                setColumnName(filterRes)
                // total Number
                const allCounts =
                    fetchTable.data?.data.hits.total.value &&
                        typeof fetchTable.data?.data.hits.total.value === 'number'
                        ? fetchTable.data?.data.hits.total.value
                        : 0
                setTotalCount(allCounts)
            }
            handleHeader()
            handleBody()
        } else {
            setGridData([])
            setTotalCount(0)
        }
    }, [fetchTable])

    useEffect(() => {
        const exclude = [
            'uuid',
            'index',
            'item_main',
            'date_main',
            'type_main',
            'etc_main',
            'task_id',
            'category'
        ]
        let _col = [];
        if (analysisData.leftSelectedList.selectedIndex.length === 1) {
            _col = columnName.map(col => {
                if (!exclude.includes(col)) return col
                return ""
            }).filter(str => str !== "")
            _col.unshift(' ')
        } else {
            _col = [
                ' ',
                'item_main',
                'date_main',
                'type_main',
                'etc_main',
                'agentIP',
                'agentName',
            ];
        }

        // PM wants these columns to be in the front of memory analysis grid
        _col.sort((a, b) => {
            const order: Record<string, number> = { ' ': 1, 'processName': 2, 'processCreateTime': 3, 'riskLevel': 4, 'riskScore': 5 };
          
            const orderA = order[a];
            const orderB = order[b];
          
            // Handle cases where a or b is not in the order object
            if (orderA === undefined && orderB === undefined) {
              return 0; // Leave them in the current order
            } else if (orderA === undefined) {
              return 1; // Move 'a' to the end
            } else if (orderB === undefined) {
              return -1; // Move 'b' to the end
            }
          
            return orderA - orderB;
          });

          let newCol:GridColDef[] = _col.map(__col => {
            if (__col === ' ') return {
                field: __col,
                sortable: false,
                minWidth: 38,
                width: 38,
                align: 'center',
                renderCell: ({ row }:{row:any}) => (
                    <CellCheckBox uuid={row.uuid}/>
                ),
                headerClassName: 'checkboxHeader',
                renderHeader: () => (
                    //ref for portal
                    //Unable to use HeaderChe2ckBox component directly due to the nature of renderCell/renderHeader
                    <div ref={headerCheckboxRef}></div>
                )
            };
            const commonField: GridColDef = { field: __col, sortable: timeFields.includes(__col), minWidth: 200, flex: 1 };
            if (timeFields.includes(__col)) return {
                ...commonField,
                renderCell: ({ row }:{row:any}) => dayjs.unix(row[__col]).format("YYYY-MM-DD HH:mm:s")
            }
            if (__col === 'timestamp') return {
                ...commonField,
                renderCell: ({ row }:{row:any}) => `${row[__col]}(${dayjs.unix(row[__col]).format("YYYY-MM-DD HH:mm:s")})`
            }
            return commonField;
        });
        
        //change only if there's a difference, otherwise width of the column will reset
        if (JSON.stringify(dataGridColumn.current) !== JSON.stringify(newCol)){
            dataGridColumn.current = newCol;
            rerender();
        }
    }, [columnName, analysisData.leftSelectedList.selectedIndex.length])


    return (
        <div id="gridContainer" ref={gridContainer}>   
            <GridHeader
                totalCount={totalCount}
                setPageInit={setPageInit}
            />
            <ClickAwayListener onClickAway={() => { setRightBarOpen(false) }}>
                <div>
                    <Portal container={headerCheckboxRef.current}>
                        <HeaderCheckBox count={totalCount} pageRows={gridData}/>
                    </Portal>
                    <Portal container={gridContainer.current}>
                        {dataGridColumn.current !== null? 
                        <DataGridPro
                            sx={{
                                backgroundColor: displayMode === 'dark' ? 'black' : 'white',
                                borderRadius: '3px 3px 0 0'
                            }}
                            loading={fetchTable.isLoading}
                            columns={dataGridColumn.current}
                            rows={gridData}
                            getRowId={(row) => row.uuid}
                            rowCount={totalCount}
                            rowHeight={32}
                            disableColumnFilter
                            hideFooter
                            disableColumnMenu
                            paginationMode='server'
                            sortingMode="server"
                            onSortModelChange={(sortModel: GridSortModel) => {
                                const filteredModel = sortModel.filter(_ => _.sort !== undefined);
                                if (filteredModel.length === 0) {
                                    setSort(null)
                                } else {
                                    let field = filteredModel[0].field
                                    if (!['date_main'].includes(filteredModel[0].field)){
                                        field = analysisData.leftSelectedList.selectedIndex[0].replace('ed_','')+'.'+field
                                    }
                                    setSort({
                                        sort: (filteredModel[0].sort as ("asc" | "desc")),
                                        field: field
                                    })
                                }
                            }}
                            onRowClick={(params, event) => {
                                if ((event.target as HTMLInputElement).type === 'checkbox') return;
                                const item = params.row;
                                const uid = item.uuid
                                const index = item.index !== undefined ? item.index : '';

                                //avoid refetch the same row
                                if (lastSelectedID !== `${uid}-${index}`) {
                                    setLastSelectedID(`${uid}-${index}`)
                                }

                                setRightBarOpen(true);
                                dispatch(setRightBar({ uuid: uid }))
                            }}
                        />: null}
                        
                    </Portal>
                    {rightBar}
                </div>
            </ClickAwayListener>
            {totalCount !== 0 ? (
                <GridFooter
                    gridData={gridData}
                    lastDataInEachPage={lastDataInEachPage}
                    setLastDataInEachPage={setLastDataInEachPage}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalCount={totalCount}
                    sort={sort}
                />
            ) : null}
        </div>
    )
}

export default ElasticGrid