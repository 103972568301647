import { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { API, urlRoot } from 'constant/index'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { useGet } from 'hook/useGet'
import { useToast } from 'hook/useToast'
import { usePost } from 'hook/usePost'
import { useAlert } from 'hook/useAlert'
import AgentTable, { deviceInfo } from '../../component/AgentTable'
import useDenyIfNot from 'hook/useDenyIfNot'

const AgentRemove = () => {
    const [defaultDeviceList, setDefaultDeviceList] = useState<deviceInfo[]>([]);
    const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
    const language = useSelector(_language);
    const deleteMutation = usePost();
    const toast = useToast();
    const { showAlert } = useAlert();
    useDenyIfNot('setting');

    const {
        data: allDeviceInfo,
        refetch,
        isSuccess: isAllDeviceInfoSuccess,
    } = useGet<deviceInfo[]>({
        query: 'getAllDeviceInfo',
        root: `${urlRoot}`,
        route: `${API.agentInfo}`,
    })

    useEffect(() => {
        if (isAllDeviceInfoSuccess && allDeviceInfo.data) {
            setDefaultDeviceList(allDeviceInfo.data)
        }
    }, [isAllDeviceInfoSuccess, allDeviceInfo])

    const removeSelectedAgent = () => {
        if (selectedIDs.length === 0) {
            alert(dic[language]['setting_agent_remove_empty']);
            return;
        }

        showAlert(dic[language]['setting_agent_remove_hint'], () => {
            deleteMutation.mutate({
                root: `${urlRoot}`,
                route: `${API.agent}`,
                method: 'delete',
                body: {
                    devices: selectedIDs,
                },
            }, {
                onSuccess: () => {
                    ;
                    setSelectedIDs([]);
                    refetch();
                },
                onError: (error) => {
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        showAlert(error.response.data.message || dic[language]['setting_agent_remove_error']);
                    } else {
                        console.log(error.response?.data.message);
                    }
                }
            })
        })
    }

    const ToolBarButtons = (
        <Button
            color="error"
            onClick={removeSelectedAgent}
            variant="contained"
        >
            {dic[language]['setting_agent_remove_btn']}
        </Button>
    )

    return (
        <>
            <p className='SettingSubTitle'>
                {dic[language]['setting_select_functionality']}
            </p>

            <AgentTable
                ToolBarButtons={ToolBarButtons}
                defaultDeviceList={defaultDeviceList}
                selectedIDs={selectedIDs}
                setSelectedIDs={setSelectedIDs}
                blockSelection
            />
        </>
    )
}

export default AgentRemove
