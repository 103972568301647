import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DataGridPro } from '@mui/x-data-grid-pro'
import { CircularProgress, ClickAwayListener, Portal,} from '@mui/material'

import TreeNode from '../../component/TreeNode'
import HostTreeNode from '../../component/TreeNode/HostTreeNode'
import FileTreeNode from './component/FileTreeNode'
import useFetchExplorerAgents from 'page/explorer/useFetchExplorerAgents'
import { IAgent, IFileData, _isHostSelected, _selectedHost, _selectedNode, setSelectedHost } from 'redux/ViewDetailSlice'
import RightBar, { RightBarCloseButton } from '../../component/RightBar'
import { ViewDetailRightBarRowFactory } from '../../component/RightBar/RightBarRowFactory'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import dayjs from 'dayjs'
import { GridColDef } from '@mui/x-data-grid'
import { flattenDoc, processFileSize } from 'util/index'
import { processTimeField } from 'util/index'
import { _elasticRestarting, _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { useAlert } from 'hook/useAlert'
import useDenyIfNot from 'hook/useDenyIfNot'
import TreePageContextMenu, { TContextMenu } from '../../component/TreePageContextMenu';
import DownloadContext from 'context/DownloadContext'
import './index.scss'
import 'component/TreeNode/treepage.scss'

const ExplorerPage = () => {

    useDenyIfNot('analysis');
    
    const isHostSelected = useSelector(_isHostSelected);
    const selectedHost = useSelector(_selectedHost);
    const selectedNode = useSelector(_selectedNode);
    const elasticRestarting = useSelector(_elasticRestarting);
    const language = useSelector(_language);
    const dispatch = useDispatch();

    const alert = useAlert();
    const {addItem} = useContext(DownloadContext);

    const [rightBarData, setRightBarData] = useState({});
    const [rightBarOpen, setRightBarOpen] = useState(false);

    let { rootsList, agentList, status } = useFetchExplorerAgents();

    const viewDetailContainer = useRef(null);
    const clostButtonContainer = useRef(null);

    useEffect(() => {
        if (elasticRestarting && status === "success") {
            alert.showAlert('Elasticsearch is restarting, please retry after a while');
        }
    },[elasticRestarting, rightBarData, rightBarOpen, status, rootsList, agentList])

    const fileProperties: GridColDef[] = [
        { field: 'fileName', headerName: dic[language]['view_detail_file_name'], flex: 1 },
        { field: 'dataLen', headerName: dic[language]['view_detail_file_size'], flex: 1, renderCell: ({ row }) => processFileSize(row.dataLen) + 'B' },
        { field: 'createTime', headerName: dic[language]['view_detail_file_create_time'], flex: 1, renderCell: ({ row }) => dayjs.unix(row.createTime).format("YYYY-MM-DD HH:mm:s") },
        { field: 'entryModifiedTime', headerName: dic[language]['view_detail_file_modify_time'], flex: 1, renderCell: ({ row }) => {
            if (row.entryModifiedTime === 0) return '- -'
            else return dayjs.unix(row.entryModifiedTime).format("YYYY-MM-DD HH:mm:s")
        }},
    ];

    const [contextMenu, setContextMenu] = useState<TContextMenu | null>(null);

    const contextOnDownload = (node: any) => {
        addItem({
            action: 'StartDumpDrive',
            name: `${node.agentIP}-${node.item_main}.zip`,
            deviceId: node.agent,
            filter: {
                path: node.path,
                fileId: node.fileId?.toString()
            }
        })
    }

    const handleGridContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        const row = Number(event.currentTarget.getAttribute("data-rowindex"));

        const node = selectedNode?.children? selectedNode.children[row] : null;

        setContextMenu(contextMenu === null? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
                node: node,
                name: node === null?'':node.fileName,
                onDownload: contextOnDownload,
                downloadEnabled: Boolean(node && node.agent && node.fileId !== undefined && node.path !== undefined)
            } : null,
        );
    };

    const handleTreeContextMenu = (event: React.MouseEvent, data:IFileData) => {
        event.preventDefault();

        setContextMenu(contextMenu === null? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
                node: data,
                name: data.fileName,
                onDownload: contextOnDownload,
                downloadEnabled: data.fileId !== undefined && data.path !== undefined && data.agent !== undefined
            } : null,
        );
    }

    return (
        <div className="viewDetailContainer treepage page" ref={viewDetailContainer}>
            <TreePageContextMenu contextMenu={contextMenu} handleClose={() => {setContextMenu(null)}}/>
            <Portal container={clostButtonContainer.current}><RightBarCloseButton onClick={(e: any) => { setRightBarOpen(false) }} /></Portal>
            <PanelGroup direction="horizontal">
                <Panel className="hostList treeView" id="hostTree" minSizePixels={250} defaultSizePixels={250}>
                    <div id="hostListContainer">
                    {
                        status === 'success' && !elasticRestarting ?
                            <TreeNode defaultOpen type="pc-group" label={dic[language]['view_detail_agent_list']} root lastRoot={true}>
                                {
                                    agentList.map((agent: IAgent) => <HostTreeNode selected={selectedHost?.id===agent.id} agent={agent} key={agent.id} onClick={()=>{
                                        dispatch(setSelectedHost(agent));
                                    }}></HostTreeNode>)
                                }
                            </TreeNode>
                            : null
                    }
                    {
                        status === 'loading' ?
                            <CircularProgress /> : null
                    }
                    {
                        elasticRestarting ? "Elastic Restarting" : null
                    }
                    </div>
                </Panel>
                <PanelResizeHandle className='resizeHandle' />
                <Panel>
                <PanelGroup direction='horizontal' className="window">
                    <Panel className="detailList treeView detailTree" minSizePixels={300} defaultSizePixels={300}>
                        <div id="detailTreeContainer">
                            {
                                status === "success" && !elasticRestarting && isHostSelected ?
                                    rootsList.get((selectedHost?.ip as string))?.map((root, idx: number) => {
                                        const data = flattenDoc(root);
                                        return (
                                            <FileTreeNode
                                                data={data}
                                                root
                                                key={data.uuid}
                                                lastRoot={idx === (rootsList.get((selectedHost?.ip as string))?.length as number) - 1}
                                                onContextMenu={handleTreeContextMenu}
                                            />)
                                    })
                                    : null
                            }
                            {
                                elasticRestarting ? "Elastic Restarting" : null
                            }
                        </div>
                    </Panel>
                    <PanelResizeHandle className='resizeHandle' />
                    <Panel className="" id="detailGridContainer">
                        {
                            !elasticRestarting && status === "success"?
                            (
                                <ClickAwayListener onClickAway={() => {
                                    setRightBarOpen(false);
                                }}>
                                    <div className='fullSizeContainer'>
                                        <DataGridPro
                                            rowHeight={32}
                                            className="table"
                                            columns={fileProperties}
                                            rows={selectedNode?.children ? selectedNode.children : []}
                                            getRowId={data => data.uuid}
                                            loading={selectedNode?.childrenStatus === 'loading'}
                                            onRowClick={(params) => {
                                                setRightBarData(processTimeField(params.row));
                                                setRightBarOpen(true);
                                            }}
                                            slotProps={{
                                            row: {
                                                onContextMenu: handleGridContextMenu,
                                                style: { cursor: "context-menu" }
                                            }
                                            }}
                                        />
                                        <Portal container={viewDetailContainer.current}>
                                            <RightBar open={rightBarOpen} closeButton={<span ref={clostButtonContainer}></span>}>
                                                <ViewDetailRightBarRowFactory rightBarData={rightBarData} />
                                            </RightBar>
                                        </Portal>
                                    </div>

                                </ClickAwayListener>
                            ) : null
                        }
                        {
                            elasticRestarting? "Elastic Restarting": null
                        }
                    </Panel>
                </PanelGroup>
                </Panel>
            </PanelGroup>
        </div>
    )
}

export default ExplorerPage
