import { useState, useEffect } from 'react'
import PointStatus from '../PointStatus'
import { IData, DonutChart } from '../Pie'
import GroupTable, {
    TableTitleDetail,
    ConnectData,
} from '../commonComponent/GroupTable'
import { API, urlRoot, NO_GROUP_KEYWORD } from 'constant'
import { useGet } from 'hook/useGet'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'

interface AgentData {
    id: number,
    name: string,
    connected: number,
    disconnected: number,
    color: string,
}

interface AgentConnection {
    total_connected: number,
    total_disconnected: number,
    data: AgentData[],
}

const ConnectStatus = () => {
    const language = useSelector(_language);
    const [totalConnect, setTotalConnect] = useState<number>(0)
    const [totalDisconnect, setTotalDisconnect] = useState<number>(0)
    const [tableConnectData, setTableConnectData] = useState<ConnectData[]>([])
    const [pieData, setPieData] = useState<IData[]>([])
    const [tableTitleDetail, setTableTitleDetail] = useState<TableTitleDetail>({
        groupCount: 0,
        deviceCount: 0,
        done: 0,
        unknown: 0,
    })

    const {
        data: agentConnection,
        isSuccess: isAgentConnectionSuccess,
    } = useGet<AgentConnection>({
        query: 'agentConnection',
        root: `${urlRoot}`,
        route: `${API.agentConnection}`,
    })

    useEffect(() => {
        if (!agentConnection || !agentConnection.data) return;
        const ac = agentConnection.data;
        ac.data.forEach((item) => {
            if (item.name === NO_GROUP_KEYWORD) item.name = dic[language]["no_group"];
        })
        if (isAgentConnectionSuccess) {
            setTotalConnect(ac.total_connected)
            setTotalDisconnect(ac.total_disconnected)
            setTableTitleDetail({
                groupCount: ac.data.length,
                deviceCount: ac.total_connected + ac.total_disconnected,
                done: ac.total_connected,
                unknown: ac.total_disconnected,
            })
            setTableConnectData(ac.data.map((item) => {
                return {
                    id: item.id,
                    name: item.name,
                    count: item.connected + item.disconnected,
                    connected: item.connected,
                    disconnected: item.disconnected,
                }
            }))
            setPieData(ac.data.map((item) => {
                return {
                    name: item.name,
                    amount: item.connected + item.disconnected,
                    good: item.connected,
                    bad: item.disconnected,
                    color: item.color,
                }
            }))
        }
    }, [isAgentConnectionSuccess, agentConnection, language])

    return (
        <div className='connectStatusContainer'>
            <div style={{ display: 'flex' }}>
                <div className='statusContainer'>
                    <p className='dashBoardP'>
                        {dic[language]["dashboard_connection_status"]}
                    </p>
                    <PointStatus
                        point={calculatePercentage(totalConnect, totalConnect + totalDisconnect)}
                        state="connection"
                        category="connection"
                    />
                    <PointStatus
                        point={calculatePercentage(totalDisconnect, totalConnect + totalDisconnect)}
                        state="non-connection"
                        category="connection"
                    />
                </div>

                <div className='pieContainer'>
                    <p className='dashBoardP'>
                        {dic[language]["dashboard_connection_rate"]}
                    </p>
                    <DonutChart data={pieData} />
                </div>
            </div>

            <GroupTable
                tableTitle={dic[language]["dashboard_table_title_detect_group"]}
                tableTitleDetail={tableTitleDetail}
                connectData={tableConnectData}
            />
        </div>
    )
}

export default ConnectStatus

const calculatePercentage = (numerator: number, denominator: number): number => {
    if (denominator === 0) {
        return 0;
    }
    const result = (numerator / denominator) * 100;
    return Math.round(result * 10) / 10;
};
