import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import {
    IForensicDropDownData,
} from 'interface'
import ForensicsDropSelector from './ForensicsDropSelector'
import MemoryDropDownSelector from '../MemoryDropDownSelector/MemoryDropDownSelector'
import AllFilesDropDownSelector from '../AllFilesDropDownSelector/AllFilesDropDownSelector'
import { _analysisData, setForensicsSelectedData, setMemoryDropDownSelected, setallFilesDropDownData } from 'redux/AnalysisSlice'
import { useDispatch, useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { _displayMode, _language } from 'redux/UtilSlice'

interface ChildProps {
    setRefreshTemplate: React.Dispatch<React.SetStateAction<boolean>>
}

const AnalysisDataDropSelector: React.FC<ChildProps> = (props: ChildProps) => {
    const {
        setRefreshTemplate,
    } = props

    const displayMode = useSelector(_displayMode);
    const language = useSelector(_language);
    const analysisData = useSelector(_analysisData);
    const dispatch = useDispatch();

    const {
        memoryDropDownData,
        forensicDropDownData,
        allFilesDropDownData
    } = analysisData;

    const [selectedIndex, setSelectedIndex] = React.useState(0) // 選擇哪個頁面3
    const [selectedMainSelectorName, setSelectedMainSelectorName] =
        React.useState<string>(dic[language]['analysis_filter_memory']) // 選擇哪個頁面3
    const handleMainListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ) => {
        setSelectedIndex(index)
    }

    function changeIsMemoryDropDownSelected(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        dispatch(setMemoryDropDownSelected({
            ...memoryDropDownData,
            isMemoryGroupSelected: e.target.checked,
        }))
    }
    function isSelectAll() {
        const keysToChange = Object.keys(forensicDropDownData).filter(
            (key) =>
                key !== 'keyword' &&
                key !== 'keywordType' &&
                key !== 'isForensicsSelected' &&
                key !== 'templateId' &&
                key !== 'templateName'
        )
        let count = 0
        for (const key of keysToChange) {
            if (forensicDropDownData[key] === true) {
                count++
            }
        }
        if (count === keysToChange.length) {
            return true
        }

        return false
    }

    function isSelectPart() {
        const keysToChange = Object.keys(forensicDropDownData).filter(
            (key) =>
                key !== 'keyword' &&
                key !== 'keywordType' &&
                key !== 'isForensicsSelected'
        )
        let count = 0
        for (const key of keysToChange) {
            if (forensicDropDownData[key] === true) {
                count++
            }
        }
        if ((count === keysToChange.length && count !== 0) || count === 0) {
            return false
        }

        return true
    }
    // 控制是否選取記憶體
    function handleForensicsDropDownSelected(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        // 除了keyword 和keywordType 其他都要改成 e.target.checked
        const newForensicSelectedData: IForensicDropDownData = {
            ...forensicDropDownData,
        }

        const keysToChange = Object.keys(newForensicSelectedData).filter(
            (key) => key !== 'keyword' && key !== 'keywordType'
        )
        for (const key of keysToChange) {
            newForensicSelectedData[key] = e.target.checked
        }

        dispatch(setForensicsSelectedData(newForensicSelectedData))
    }
    function changeMemoryDropDownSelected(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        dispatch(setallFilesDropDownData({
            ...allFilesDropDownData,
            isAllFilesSelected: e.target.checked,
        }))
    }

    const MainSelectorList = () => {
        const mainSelectorNameList = [
            dic[language]['analysis_filter_memory'], 
            dic[language]['analysis_filter_collect'], 
            dic[language]['analysis_filter_drive']
        ]
        return (
            <List component="nav" aria-label="main mailbox folders">
                {mainSelectorNameList.map((item, i) => {
                    return (
                        <ListItemButton
                            defaultValue=""
                            style={{ paddingRight: '5px', marginRight: '0px' }}
                            selected={selectedIndex === i}
                            key={`${item}${i}-SelectorList`}
                            onClick={(event) => {
                                handleMainListItemClick(event, i)
                                setSelectedMainSelectorName(item)
                            }}
                        >
                            {item === dic[language]['analysis_filter_memory'] && (
                                <CheckboxList
                                    checked={
                                        memoryDropDownData.isMemoryGroupSelected
                                    }
                                    changeSelectedFunc={
                                        changeIsMemoryDropDownSelected
                                    }
                                    item={item}
                                />
                            )}
                            {item === dic[language]['analysis_filter_collect'] && (
                                <CheckboxList
                                    checked={isSelectAll()}
                                    indeterminate={isSelectPart() && !isSelectAll()}
                                    changeSelectedFunc={
                                        handleForensicsDropDownSelected
                                    }
                                    item={item}
                                />
                            )}
                            {item === dic[language]['analysis_filter_drive'] && (
                                <CheckboxList
                                    checked={
                                        allFilesDropDownData.isAllFilesSelected
                                    }
                                    changeSelectedFunc={
                                        changeMemoryDropDownSelected
                                    }
                                    item={item}
                                />
                            )}
                        </ListItemButton>
                    )
                })}
            </List>
        )
    }

    const selectRenderValue = () => {
        let ret = ''
        if (memoryDropDownData.isMemoryGroupSelected) {
            ret += dic[language]['analysis_filter_memory']
        }
        if (isSelectPart() || isSelectAll()) {
            ret += ret.slice(-1) === '' ? 
                dic[language]['analysis_filter_collect'] :
                `,${dic[language]['analysis_filter_collect']}`
        }
        if (allFilesDropDownData.isAllFilesSelected) {
            ret += ret.slice(-1) === '' ?
                dic[language]['analysis_filter_drive']
                : `,${dic[language]['analysis_filter_drive']}`
        }

        return ret === '' ? dic[language]["no_select"] : ret
    }

    return (
        <div className='optionsContainer'>
            <span>{dic[language]['analysis_data']}</span>

            <FormControl
                sx={{
                    m: 1,
                    width: 200,
                    backgroundColor: displayMode === 'dark' ? '' : 'white',
                    height: 'min-content',
                }}
                size="small"
            >
                <Select
                    multiple
                    value={['126', 'fepfl']}
                    renderValue={() => <em>{selectRenderValue()}</em>}
                >
                    <div
                        style={{
                            minWidth: '300px',
                            minHeight: 400,
                            display: 'flex',
                            overflow: 'hidden',
                        }}
                    >
                        <div style={{ width: 200, height: '100%' }}>
                            <MainSelectorList />
                        </div>
                        <div style={{ width: 800, height: '100%' }}>
                            {selectedMainSelectorName === dic[language]['analysis_filter_memory'] && (
                                <MemoryDropDownSelector />
                            )}
                            {selectedMainSelectorName === dic[language]['analysis_filter_collect'] && (
                                <ForensicsDropSelector setRefreshTemplate={setRefreshTemplate} />
                            )}
                            {selectedMainSelectorName === dic[language]['analysis_filter_drive'] && (
                                <AllFilesDropDownSelector />
                            )}
                        </div>
                    </div>
                </Select>
            </FormControl>
        </div>
    )
}

export default AnalysisDataDropSelector

interface ICheckedList {
    checked?: boolean
    indeterminate?: boolean
    changeSelectedFunc(e: React.ChangeEvent<HTMLInputElement>): void
    item: string
}

const CheckboxList = (props: ICheckedList) => {
    const { checked, indeterminate, item, changeSelectedFunc } = props
    return (
        <div>
            <Checkbox
                sx={{
                    padding: '0px',
                    marginRight: '3px',
                }}
                // checked={forensicDropDownData.isForensicsSelected}
                checked={checked}
                indeterminate={indeterminate || false}
                onChange={(e) => changeSelectedFunc(e)}
            />
            <span>{item}</span>
        </div>
    )
}
