/** @format */

import {
    IFormatedDevice,
    SelectedBoxes,
} from 'interface'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TvIcon from '@mui/icons-material/Tv'
import {
    TableStatus,
    TableCellDense,
    ConditionCell,
    DownloadCell,
    CollectCell,
} from './TableCell'
import { useEffect, useMemo, useState } from 'react'
import {
    fixPaginationNumber,
    formatTimestamp,
} from 'util/index'
import { EnhancedTableToolbar } from '../EnhancedTableToolbar'
import EnhancedTableHead from './EnhancedTableHead'
import assets from 'assets'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
    addSelectedID,
    removeID,
    clearIDList,
    setSelectedIDList,
    _selectedID,
} from 'redux/DetectSlice'
import { TCategory, TState } from 'page/home/subpage/detectdashboard/component/PointStatus'
import { Params, useParams } from 'react-router-dom'
import { _displayMode } from 'redux/UtilSlice'
import { DetectGrid_HeadCell } from 'interface'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { _searchKeys } from 'redux/DetectSlice'
import useDownloadFile from 'hook/useDownloadFile'
import { axiosClient } from 'util/index'
import { useAlert } from 'hook/useAlert'
import { AxiosError, isAxiosError } from 'axios'
import { ErrorResponse } from 'hook/usePost'
import { API, NO_GROUP_KEYWORD, urlRoot } from 'constant/index'
import { store } from 'redux/store'
import { useToast } from 'hook/useToast'

interface GridTableProps {
    data: IFormatedDevice[]
    route?: {
        state: TState
        category: TCategory
    }
    ubuntuNumber: number
    setUbuntuNumber: React.Dispatch<React.SetStateAction<number>>
}

type Order = 'asc' | 'desc'

const CheckIcon = (
    <div style={{ width: 22, height: 22 }}>
        <CheckCircleIcon
            sx={{ width: '100%', height: '100%' }}
            style={{ color: '#2E7D32' }}
        />
    </div>
)
const UnCheckIcon = (
    <div style={{ width: 20, height: 20 }}>
        {/* <img style={{ width: '100%' }} src={<assets.UnCheck/>} alt="UnCheck" /> */}
        <assets.UnCheck style={{ width: '100%', height: '100%' }} />
    </div>
)
// table sort function : asc or desc
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

// get comparator
function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// stable sort
function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const emptyIntoUnGroup = (data: string[]) => {
    const resultArray = R.map((str) => {
        return R.when(
            R.pipe(R.trim, R.isEmpty || R.equals(NO_GROUP_KEYWORD)), // 检查是否为空字符串 || @@no-group@@
            R.always(dic[store.getState().util.language]["no_group"]),
            str // 如果不是空字符串，返回原字符串
        )
    }, data);
    return resultArray;
}

// 處理群組分類 - 因為拿到的資料是字串，所以要轉換成string[]
const pipeAllGroups = (data: IFormatedDevice[]) => {
    return R.pipe(
        R.map((obj: IFormatedDevice) => obj.groups), // 取出所有 分組
        emptyIntoUnGroup, // ! 空字串轉換成 "未分類群組" error handling
        R.map((str: string) => str.split(', ')),
        R.flatten,
        R.uniq
    )(data)
}

const doesAnyElementExistInArray = (
    arr1: string[],
    arr2: string[]
): boolean => {
    const res = R.any(R.includes(R.__, arr2), arr1)
    // console.log('log --doesAnyElementExistInArray', res)
    return res
}

const reformCommaStringToArray = (str: string): string[] => {
    return str.split(', ')
}

const SelectGroups = (data: IFormatedDevice[], groups: string[]) => {
    const elementInGroups = data.filter((obj) =>
        doesAnyElementExistInArray(reformCommaStringToArray(obj.groups), groups)
    )
    return elementInGroups
}

const filterDataByCategoryAndState = (
    params: Readonly<Params<string>>,
    data: IFormatedDevice[]
) => {
    const isNumberString = (str: string | number) => {
        if (typeof str === 'number') {
            return false
        }

        const reg = new RegExp('^[0-9]*$')
        const testString0 = str[0]
        const testString1 = str[1]
        return reg.test(testString0) && reg.test(testString1) //if all true, it is a number string
    }

    if (!params) {
        return []
    }

    const getCategoryFilter = (category: TCategory, state: TState) => {
        switch (category) {
            case 'connection':
                return (obj: IFormatedDevice) =>
                    obj[category] ===
                    (state === 'connection' ? 'true' : 'false')
            default:
                if (state === 'working') {
                    return (obj: IFormatedDevice) =>
                        typeof obj[category] === 'number' || obj[category] === 'Sending...'
                }
                if (state === 'non-working') {
                    return (obj: IFormatedDevice) =>
                        typeof obj[category] === 'string' &&
                        (obj[category] === '- -' || obj[category] === 'Pending...')
                }
                if (state === 'done') {
                    return (obj: IFormatedDevice) =>
                        typeof obj[category] === 'string' &&
                        obj[category] !== '- -'
                }
                if (state === 'fail') {
                    return (obj: IFormatedDevice) =>
                        typeof obj[category] === 'string' &&
                        !isNumberString(obj[category]) &&
                        obj[category] !== '- -'
                }
                if (state === 'success') {
                    return (obj: IFormatedDevice) =>
                        typeof obj[category] === 'string' &&
                        isNumberString(obj[category])
                }
                return () => false // 預設情況下返回一個永遠不會匹配的過濾器
        }
    }

    const category = params.category as TCategory
    const state = params.state as TState
    const categoryFilter = getCategoryFilter(category, state)
    const filteredData = data.filter(categoryFilter)

    return filteredData
}

const GridTable = ({ data, route, ubuntuNumber, setUbuntuNumber }: GridTableProps) => {

    const dispatch = useDispatch()
    const ids = useSelector(_selectedID)
    const displayMode = useSelector(_displayMode);
    const language = useSelector(_language);
    const searchKeys = useSelector(_searchKeys);
    const { showAlert } = useAlert();
    const toast = useToast();

    const [order, setOrder] = useState<Order>('desc')
    const [orderBy, setOrderBy] = useState<keyof IFormatedDevice>('connection')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)

    const [dataQuery, setDataQuery] = useState<IFormatedDevice[]>([]) // dataQuery 為當前顯示在表格中的資料
    const [groups, setGroups] = useState<string[]>([])
    const [groupsMenu, setGroupsMenu] = useState<string[]>([])

    const [hoverRow, setHoverRow] = useState<string>('')
    const [isCondition, setIsCondition] = useState<boolean>(false)
    const [selectedGroupsInFilter, setSelectedGroupsInFilter] = useState<
        string[]
    >([])

    // initialize table
    useEffect(() => {
        if (data.length > 0) {
            const res = pipeAllGroups(data) // 群組分類
            setGroups(res)
            setGroupsMenu(res)
        }
    }, [data, language])

    // useEffect(() => {
    //     if (groups.length === 0) {
    //         setDataQuery(data) //if groups is empty or check no options, show all data
    //     } else {
    //         setDataQuery(SelectGroups(data, groups))
    //     }
    // }, [data, groups])

    // 處理 dashboard 畫面的標籤
    // 如果有標籤，就顯示標籤，如果沒有標籤，就顯示全部
    // 直接從 redux 拿被過濾完的Id資料，再從 data 中找出對應的資料

    const params = useParams()
    // const location = useLocation();
    // http://localhost:3000/detect?category=memory&state=working

    useEffect(() => {
        if (params.category) {
            setIsCondition(true)
        } else {
            setIsCondition(false)
        }
    }, [params])

    const handleRequestSort = (
        //排序方法
        event: React.MouseEvent<unknown>,
        property: keyof IFormatedDevice
    ) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            let ubuntu = 0
            visibleRows.map((n) => {
                if (n.os.search('Windows') === -1) {
                    ubuntu++
                }
            })
            setUbuntuNumber(ubuntu)
            const newSelected = visibleRows.map((n) => n.deviceId)
            dispatch(setSelectedIDList(newSelected))
            return
        }
        setUbuntuNumber(0)
        dispatch(clearIDList())
    }

    const handleClick = (event: React.MouseEvent<unknown>, id: string, os: string) => {
        //表格單列排序
        const selectedIndex = ids.indexOf(id)
        // let newSelected: readonly string[] = []

        // if (selectedIndex === -1) {
        //     newSelected = newSelected.concat(selectedHostsIDs, name)

        // } else if (selectedIndex === 0) {
        //     newSelected = newSelected.concat(selectedHostsIDs.slice(1))
        // } else if (selectedIndex === selectedHostsIDs.length - 1) {
        //     newSelected = newSelected.concat(selectedHostsIDs.slice(0, -1))
        // } else if (selectedIndex > 0) {
        //     newSelected = newSelected.concat(
        //         selectedHostsIDs.slice(0, selectedIndex),
        //         selectedHostsIDs.slice(selectedIndex + 1)
        //     )
        // }
        // setSelectedId(newSelected)
        if (selectedIndex === -1) {
            dispatch(addSelectedID(id))
            if (os.search('Windows') === -1) {
                setUbuntuNumber(ubuntuNumber + 1)
            }
        } else {
            dispatch(removeID(id))
            if (os.search('Windows') === -1) {
                setUbuntuNumber(ubuntuNumber - 1)
            }
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const pagNumArray = fixPaginationNumber(dataQuery.length)

    const isSelected = (name: string) => ids.indexOf(name) !== -1

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0


    const searchRows = (searchedVals: string[]) => {
        if (searchedVals.length === 0) {
            setDataQuery(data);
            return;
        }
        // 表格關鍵字搜索
        const filteredRows = data.filter((row) => {
            return searchedVals.every((searchedVal) => {
                return Object.entries(row).some(([key, value]) => {
                    if (
                        key === 'tableFinishTime' ||
                        key === 'ImageFinishTime' ||
                        key === 'scanFinishTime' ||
                        key === 'traceFinishTime'
                    ) {
                        return formatTimestamp(value).includes(searchedVal);
                    } else if (key === 'detectionMode') { // 特殊判斷 -> 偵測模式
                        return value === 'true' ? 'on'.includes(searchedVal.toLowerCase()) : 'off'.includes(searchedVal.toLowerCase());
                    }

                    // 不看不在表格中的欄位
                    if (key === 'deviceId' || key === 'os') return false;

                    return value.toString().toLowerCase().includes(searchedVal.toLowerCase());
                });
            });
        });
        setDataQuery(filteredRows);
    };

    useEffect(() => {
        searchRows(searchKeys);
    }, [data, searchKeys]);

    const visibleRows = useMemo(() => {

        const filtedByGroupsDataQuery =
            selectedGroupsInFilter.length === 0
                ? dataQuery
                : SelectGroups(dataQuery, selectedGroupsInFilter)

        if (rowsPerPage === -1) {
            if (isCondition) {
                const res = filterDataByCategoryAndState(
                    params,
                    filtedByGroupsDataQuery
                )
                const res2 = stableSort(
                    res ? res : filtedByGroupsDataQuery,
                    getComparator(order, orderBy)
                )
                return res2
            } else {
                const res = stableSort(
                    filtedByGroupsDataQuery,
                    getComparator(order, orderBy)
                )
                return res
            }
        } else {
            if (isCondition) {
                const res = filterDataByCategoryAndState(
                    params,
                    filtedByGroupsDataQuery
                )
                const res2 = stableSort(
                    res ? res : filtedByGroupsDataQuery,
                    getComparator(order, orderBy)
                ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                return res2
            } else {
                const res = stableSort(
                    filtedByGroupsDataQuery,
                    getComparator(order, orderBy)
                ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                return res
            }
        }
    }, [
        order,
        orderBy,
        page,
        rowsPerPage,
        dataQuery,
        isCondition,
        selectedGroupsInFilter,
    ])

    const [filter, setFilter] = useState<SelectedBoxes>({
        memory: true,
        detectionMode: true,
        scanSchedule: true,
        scanFinishTime: true,
        collect: true,
        collectSchedule: true,
        collectFinishTime: true,
        file: true,
        fileDownloadDate: true,
        fileFinishTime: true,
        imageFinishTime: true,
        yaraRuleFinishTime: true,
        memoryTreeFinishTime: true,
    })

    const stickyColumnBackground = (deviceId: string, rowSelected: boolean) => {
        if (displayMode === 'dark') {
            if (hoverRow === deviceId) {
                if (rowSelected) {
                    return { background: '#40515E' }
                } else {
                    return { background: '#383838' }
                }
            } else {
                if (rowSelected) {
                    return { background: '#36414B' }
                } else {
                    return { background: '#222222' }
                }
            }
        } else {
            if (hoverRow === deviceId) {
                if (rowSelected) {
                    return { background: '#E7EFFA' }
                } else {
                    return { background: '#F5F5F5' }
                }
            } else {
                if (rowSelected) {
                    return { background: '#EFF5FC' }
                } else {
                    return { background: '#FFFFFF' }
                }
            }
        }
    }

    const { download } = useDownloadFile({
        preDownloading: () => {
            toast.show({content: `${dic[language]['start_download']} ...`})
        },
        postDownloading: () => {
            toast.show({content: `${dic[language]['download_success']}!`})
        },
        apiDefinition: (apiRoute: string) => {
            return axiosClient.get(apiRoute, { responseType: 'blob' })
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                const axiosError = error as AxiosError<ErrorResponse>
                showAlert(axiosError.response?.data.message || axiosError.message)
            } else {
                showAlert(dic[language]['setting_yara_download_error'])
            }
        }
    });

    const handleImageDownload = (ip: string) => {
        download(`${urlRoot}${API.detectImage}/${ip}`, `image_${ip}.zip`)
    }

    const headCells: readonly DetectGrid_HeadCell[] = [
        {
            id: 'connection',
            numeric: false,
            disablePadding: false,
            label: dic[language]['detect_table_connection'],
            width: '55px'
        },
        {
            id: 'InnerIP',
            numeric: false,
            disablePadding: true,
            label: dic[language]['detect_table_device_ip'],
            width: '140px'
        },
        {
            id: 'deviceName',
            numeric: false,
            disablePadding: true,
            label: dic[language]['detect_table_device_name'],
            width: '200px'
        },
        {
            id: 'groups',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_group'],
            width: '130px'
        },
        {
            id: 'detectionMode',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_detect_mode'],
            width: '120px'
        },
        {
            id: 'scanSchedule',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_scan_schedule'],
            width: language === 'zh' ? '150px' : '155px'
        },
        {
            id: 'scanFinishTime',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_scan_finish_time'],
            width: '220px'
        },
        {
            id: 'collectSchedule',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_collect_schedule'],
            width: language === 'zh' ? '160px' : '170px'
        },
        {
            id: 'collectFinishTime',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_collect_finish_time'],
            width: language === 'zh' ? '200px' : '220px'
        },
        {
            id: 'fileDownloadDate',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_drive_schedule'],
            width: '180px'
        },
        {
            id: 'fileFinishTime',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_drive_finish_time'],
            width: '200px'
        },
        {
            id: 'imageFinishTime',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_image_finish_time'],
            width: language === 'zh' ? '200px' : '220px'
        },
        {
            id: 'yaraRuleFinishTime',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_yara_finish_time'],
            width: language === 'zh' ? '200px' : '240px'
        },
        {
            id: 'memoryTreeFinishTime',
            numeric: true,
            disablePadding: true,
            label: dic[language]['detect_table_memory_tree_finish_time'],
            width: language === 'zh' ? '240px' : '280px'
        }
    ]

    return (
        <div className='gridContainer'>
            <Paper
                elevation={1}
                sx={{
                    width: '100%',
                    height: '99.7%',
                    mb: 2
                }}
            >
                <EnhancedTableToolbar
                    numSelected={ids.length}
                    setDataQuery={setDataQuery}
                    searchRows={searchRows}
                    originData={data}
                    filter={filter}
                    setFilter={setFilter}
                    isCondition={isCondition}
                    setIsCondition={setIsCondition}
                />
                <TableContainer
                    className={displayMode === 'dark' ? 'scrollbarYDark' : 'scrollbarY'}
                    sx={{ height: "calc(100% - 6.5rem)" }}
                >
                    <Table
                        stickyHeader
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            numSelected={ids.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={visibleRows.length}
                            groups={groups}
                            setGroups={setGroups}
                            groupsMenu={groupsMenu}
                            filter={filter}
                            selectedGroupsInFilter={selectedGroupsInFilter}
                            setSelectedGroupsInFilter={
                                setSelectedGroupsInFilter
                            }
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row.deviceId)
                                const labelId = `enhanced-table-checkbox-${index}`

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) =>
                                            handleClick(event, row.deviceId, row.os)
                                        }
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.deviceId}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                        onMouseEnter={() => setHoverRow(row.deviceId)}
                                        onMouseLeave={() => setHoverRow('')}
                                    >
                                        <TableCell
                                            padding="checkbox"
                                            sx={{
                                                position: 'sticky', left: '0px', zIndex: 1,
                                                ...stickyColumnBackground(row.deviceId, isItemSelected)
                                            }}
                                        >
                                            <Checkbox
                                                size='small'
                                                sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableStatus
                                            index={`${index}${row.connection}`}
                                            style={{ position: 'sticky', left: '48px', zIndex: 1, ...stickyColumnBackground(row.deviceId, isItemSelected) }}
                                        >
                                            {row.connection === 'true'
                                                ? CheckIcon
                                                : UnCheckIcon}
                                        </TableStatus>
                                        <TableCellDense align="left"
                                            style={{ position: 'sticky', left: '103px', zIndex: 1, ...stickyColumnBackground(row.deviceId, isItemSelected) }}
                                        >
                                            {row.InnerIP}
                                        </TableCellDense>
                                        <TableCellDense
                                            style={{ position: 'sticky', left: '243px', zIndex: 1, ...stickyColumnBackground(row.deviceId, isItemSelected) }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                <TvIcon
                                                    style={{
                                                        color: '#29B6F6',
                                                        marginRight: 5,
                                                    }}
                                                    fontSize="small"
                                                />
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        width: '100%',
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {row.deviceName}
                                                </span>
                                            </div>
                                        </TableCellDense>
                                        <TableCellDense
                                            maxWidth={150}
                                            style={{ position: 'sticky', left: '443px', zIndex: 1, ...stickyColumnBackground(row.deviceId, isItemSelected) }}
                                        >
                                            {row.groups}
                                        </TableCellDense>
                                        <TableCellDense
                                            display={filter.detectionMode}
                                            isWidth={false}
                                        >
                                            {row.detectionMode === 'true'
                                                ? 'On'
                                                : 'Off'}
                                        </TableCellDense>
                                        <TableCellDense
                                            display={filter.scanSchedule}
                                        >
                                            {row.scanSchedule}
                                        </TableCellDense>
                                        <ConditionCell
                                            value={row.scanFinishTime}
                                            display={filter.scanFinishTime}
                                        />
                                        <TableCellDense
                                            display={filter.collectSchedule}
                                        >
                                            {row.collectSchedule}
                                        </TableCellDense>
                                        <CollectCell
                                            display={filter.collectFinishTime}
                                            value={row.collectFinishTime}
                                        />
                                        <TableCellDense
                                            display={filter.fileDownloadDate}
                                        >
                                            {row.fileDownloadDate}
                                        </TableCellDense>
                                        <ConditionCell
                                            display={filter.fileFinishTime}
                                            value={row.fileFinishTime}
                                        />
                                        <DownloadCell
                                            display={filter.imageFinishTime}
                                            value={row.imageFinishTime}
                                            handleDownload={() => {
                                                handleImageDownload(`${row.InnerIP}_${row.deviceId}`)
                                            }}
                                        />
                                        <ConditionCell
                                            display={filter.yaraRuleFinishTime}
                                            value={row.yaraRuleFinishTime}
                                        />
                                        <ConditionCell
                                            display={filter.memoryTreeFinishTime}
                                            value={row.memoryTreeFinishTime}
                                        />
                                    </TableRow>
                                )
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 40 * emptyRows }}>
                                    <TableCell colSpan={visibleRows.length} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[...pagNumArray]}
                    component="div"
                    count={dataQuery.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}

export default GridTable
