import React from 'react'
import {
    ActionPlugTitle,
    StraightSeparator,
} from '../../StyledComponents'
import RadioGroupEnd from '../../SettingBarItem/RadioGroupEnd'
import DateAndTimeSelector from '../../SettingBarItem/DateAndTimeSelector/DateAndTimeSelector'
import ActionButton from './CommonComponents/ActionButton'
import TimeMissionButton from './CommonComponents/TimeMissionButton'
import { IFormatedDevice } from 'interface'
import { useDispatch, useSelector } from 'react-redux'
import { _scheduleDownloadTime, setScheduleDownload, setScheduleDownloadTime } from 'redux/DetectSlice'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

interface ActionPlugFileProps {
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
}

const ActionPlugFile = (props: ActionPlugFileProps) => {
    const { setData, data } = props
    const dispatch = useDispatch()
    const language = useSelector(_language)

    return (
        <div className='actionPlugContainer'>
            <ActionPlugTitle>
                {dic[language]['detect_file_schedule']}
            </ActionPlugTitle>
            <RadioGroupEnd setTrueOrFalse={input => dispatch(setScheduleDownload(input))} />
            <DateAndTimeSelector
                timeSetterDispatcher={setScheduleDownloadTime}
                timeSelector={_scheduleDownloadTime}
            />
            <TimeMissionButton />
            <StraightSeparator />
            <ActionPlugTitle>
                {dic[language]['detect_task_type_drive']}
            </ActionPlugTitle>
            <ActionButton data={data} setData={setData} />
        </div>
    )
}

export default ActionPlugFile
