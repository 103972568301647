import { Stack, Tabs, Tab } from '@mui/material'
import { useEffect, useState } from 'react'
import { useGet } from 'hook/useGet'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import FormDialog from './FormDialog'
import TableToolbar from './TableToolBar'
import { DataGridPro, GridColDef, GridRowSelectionModel, gridClasses } from '@mui/x-data-grid-pro'

export interface Data {
    type: string
    apptype: string
    path: string
    keyword: string
    id: string
    os: string
}

interface IFilterDrop {
    root: string
    route: string
    isEditable: boolean
}

const TableBox = (props: IFilterDrop) => {
    const { root, route, isEditable } = props;
    const [searchKeywordList, setSearchKeywordList] = useState<string[]>([]);
    const [rows, setRows] = useState<Data[]>([]);
    const language = useSelector(_language);
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
    const [tabValue, setTabValue] = useState(0);

    const {
        data: defaultImage,
        refetch: refetchImageTable,
        isSuccess: isDefaultImageSuccess,
    } = useGet<Data[]>({
        query: 'settingImage',
        root: root,
        route: route,
    })

    useEffect(() => {
        if (isDefaultImageSuccess) {
            if (!defaultImage.data) {
                setRows([]);
            } else if (searchKeywordList.length === 0) {
                if (tabValue === 0) {
                    // filter os === windows
                    setRows(defaultImage.data.filter((row) => row.os === 'windows'))
                } else {
                    // filter os === ubuntu
                    setRows(defaultImage.data.filter((row) => row.os === 'ubuntu'))
                }
            } else {
                // filter out the rows that don't contain all the keywords
                const filteredRows = defaultImage.data.filter((row) => {
                    return searchKeywordList.every((keyword) => {
                        return row.path.toLowerCase().includes(keyword.toLowerCase()) ||
                            row.keyword.toLowerCase().includes(keyword.toLowerCase())
                    })
                })
                setRows(filteredRows)
            }
        }
    }, [isDefaultImageSuccess, defaultImage, searchKeywordList, tabValue])

    const imageColumns: GridColDef[] = [
        { field: 'path', headerName: dic[language]['setting_image_path'], flex: 1 },
        {
            field: 'keyword', headerName: dic[language]['setting_image_keyword'], flex: 1,
            renderCell: ({ value, row }) => {
                return (
                    <Stack direction="row" justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                        <div>{value}</div>
                        {isEditable && (
                            <FormDialog
                                titleText={dic[language]['update']}
                                confirmText={dic[language]['confirm']}
                                inputLableList={[
                                    dic[language]['path'],
                                    dic[language]['keyword'],
                                ]}
                                row={row}
                                rows={rows}
                                refetchImageTable={refetchImageTable}
                            />
                        )}
                    </Stack>
                )
            }
        },
    ]

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    return (
        <>
            <Tabs value={tabValue} onChange={handleChange} aria-label="linux-windows-tabs">
                <Tab label={"Windows"} sx={{ textTransform: 'none' }} />
                <Tab label={"Linux"} sx={{ textTransform: 'none' }} />
            </Tabs>
            <TableToolbar
                selectedRows={selectedRows as readonly string[]}
                numSelected={selectedRows.length}
                searchKeywordList={searchKeywordList}
                setSearchKeywordList={setSearchKeywordList}
                rows={rows}
                isEditable={isEditable}
                refetchImageTable={refetchImageTable}
            />
            <DataGridPro
                rows={rows}
                rowHeight={34}
                columnHeaderHeight={42}
                columns={imageColumns}
                hideFooterSelectedRowCount
                disableRowSelectionOnClick
                checkboxSelection={isEditable}
                pagination
                initialState={{ pagination: { paginationModel: { pageSize: 25, page: 0 } } }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRows}
                sx={{
                    maxHeight: 'calc(100% - 8rem)',
                    bgcolor: 'background.default',
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .actionButton": {
                        opacity: 0
                    },
                    [`& .${gridClasses.row}:hover`]: {
                        ".actionButton": {
                            opacity: 1
                        }
                    }
                }}
            />
        </>
    )
}

export default TableBox;
