import edTableGroup from "constant/edTableGroup"

export interface IAnalaysisIndexNode {
    name: {
        tableName: edTableGroup | ""
        groupName: edTableGroup | ""
        importTimeColumnName: string
    }
    type: 'item' | 'group'
    fourImportantColumnNames: {
        type: string
        item: string
        date: string
        etc: string
    }
    children: IAnalaysisIndexNode[]
}

export const indexTree: IAnalaysisIndexNode[] = [
    {
        name: {
            tableName: 'ed_memory',
            groupName: '',
            importTimeColumnName: 'processCreateTime',
        },
        fourImportantColumnNames: {
            //等elasticsearch建好對表
            item: 'processName',
            date: 'processDate',
            type: 'memory',
            etc: '',
        },
        type: 'item',
        children: [],
    },
    {
        name: {
            groupName: 'ed_collection',
            tableName: 'ed_collection',
            importTimeColumnName: '',
        },
        fourImportantColumnNames: {
            item: '',
            date: '',
            type: '',
            etc: '',
        },
        type: 'group',
        children: [
            {
                name: {
                    tableName: '',
                    groupName: 'group_browsing_history_and_bookmarks',
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                type: 'group',
                children: [
                    {
                        name: {
                            tableName: 'ed_chromehistory',
                            groupName: '',
                            importTimeColumnName: 'visit_time',
                        },
                        fourImportantColumnNames: {
                            item: 'url',
                            date: 'visit_time',
                            type: 'website_bookmark',
                            etc: 'title',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_chromedownload',
                            groupName: '',
                            importTimeColumnName: 'start_time',
                        },
                        fourImportantColumnNames: {
                            item: 'download_url',
                            date: 'start_time',
                            type: 'website_bookmark',
                            etc: 'target_path',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_chromekeywordsearch',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'term',
                            date: '',
                            type: 'website_bookmark',
                            etc: 'title',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_chromelogin',
                            groupName: '',
                            importTimeColumnName: 'date_created',
                        },
                        fourImportantColumnNames: {
                            item: 'origin_url',
                            date: 'date_created',
                            type: 'website_bookmark',
                            etc: 'username_value',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_chromebookmarks',
                            groupName: '',
                            importTimeColumnName: 'date_added',
                        },
                        fourImportantColumnNames: {
                            item: 'url',
                            date: 'date_added',
                            type: 'website_bookmark',
                            etc: 'name',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_edgehistory',
                            groupName: '',
                            importTimeColumnName: 'last_visit_time',
                        },
                        fourImportantColumnNames: {
                            item: 'url',
                            date: 'last_visit_time',
                            type: 'website_bookmark',
                            etc: 'title',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_iehistory',
                            groupName: '',
                            importTimeColumnName: 'visitedtime',
                        },
                        fourImportantColumnNames: {
                            item: 'url',
                            date: 'visitedtime',
                            type: 'website_bookmark',
                            etc: 'title',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_edgelogin',
                            groupName: '',
                            importTimeColumnName: 'date_created',
                        },
                        fourImportantColumnNames: {
                            item: 'origin_url',
                            date: 'date_created',
                            type: 'website_bookmark',
                            etc: 'username_value',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_ielogin',
                            groupName: '',
                            importTimeColumnName: 'last_written',
                        },
                        fourImportantColumnNames: {
                            item: 'url',
                            date: 'last_written',
                            type: 'website_bookmark',
                            etc: 'username',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_edgebookmarks',
                            groupName: '',
                            importTimeColumnName: 'date_added',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: 'date_added',
                            type: 'website_bookmark',
                            etc: 'url',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_firefoxhistory',
                            groupName: '',
                            importTimeColumnName: 'last_visit_date',
                        },
                        fourImportantColumnNames: {
                            item: 'url',
                            date: 'last_visit_date',
                            type: 'website_bookmark',
                            etc: 'title',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_firefoxlogin',
                            groupName: '',
                            importTimeColumnName: 'timelastused',
                        },
                        fourImportantColumnNames: {
                            item: 'hostname',
                            date: 'timelastused',
                            type: 'website_bookmark',
                            etc: 'username',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_firefoxbookmarks',
                            groupName: '',
                            importTimeColumnName: 'lastModified',
                        },
                        fourImportantColumnNames: {
                            item: 'url',
                            date: 'lastModified',
                            type: 'website_bookmark',
                            etc: 'title',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
            {
                name: {
                    tableName: '',
                    groupName: 'group_cache_and_cookie_history',
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                type: 'group',
                children: [
                    {
                        name: {
                            tableName: 'ed_chromecookies',
                            groupName: '',
                            importTimeColumnName: 'last_access_utc',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: 'last_access_utc',
                            type: 'cookie_cache',
                            etc: 'host_key',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_chromecache',
                            groupName: '',
                            importTimeColumnName: 'date',
                        },
                        fourImportantColumnNames: {
                            item: 'URL',
                            date: 'date',
                            type: 'cookie_cache',
                            etc: 'web_site',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_edgecookies',
                            groupName: '',
                            importTimeColumnName: 'last_access_utc',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: 'last_access_utc',
                            type: 'cookie_cache',
                            etc: 'host_key',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_edgecache',
                            groupName: '',
                            importTimeColumnName: 'last_modified',
                        },
                        fourImportantColumnNames: {
                            item: 'URL',
                            date: 'last_modified',
                            type: 'cookie_cache',
                            etc: 'web_site',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_firefoxcookies',
                            groupName: '',
                            importTimeColumnName: 'lastAccessed',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: 'lastAccessed',
                            type: 'cookie_cache',
                            etc: 'host',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_firefoxcache',
                            groupName: '',
                            importTimeColumnName: 'last_fetched',
                        },
                        fourImportantColumnNames: {
                            item: 'URL',
                            date: 'last_fetched',
                            type: 'cookie_cache',
                            etc: 'server_name',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_iecache',
                            groupName: '',
                            importTimeColumnName: 'lastaccesstime',
                        },
                        fourImportantColumnNames: {
                            item: 'sourceurlname',
                            date: 'lastaccesstime',
                            type: 'cookie_cache',
                            etc: 'localfilename',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
            {
                name: {
                    tableName: '',
                    groupName: 'group_current_network_connection_history',
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                type: 'group',
                children: [
                    {
                        name: {
                            tableName: 'ed_netadapters',
                            groupName: '',
                            importTimeColumnName: 'valid_lifetime',
                        },
                        fourImportantColumnNames: {
                            item: 'description',
                            date: 'valid_lifetime',
                            type: 'network_record',
                            etc: 'ip',
                        },
                        type: 'item',
                        children: [],
                    },
                    //
                    {
                        name: {
                            tableName: 'ed_networkresources',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'resourcesname',
                            date: '',
                            type: 'network_record',
                            etc: 'ipaddress',
                        },
                        type: 'item',
                        children: [],
                    },
                    //
                    {
                        name: {
                            tableName: 'ed_wireless',
                            groupName: '',
                            importTimeColumnName: 'lastmodifiedtime',
                        },
                        fourImportantColumnNames: {
                            item: 'profilename',
                            date: 'lastmodifiedtime',
                            type: 'network_record',
                            etc: 'authentication',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
            {
                name: {
                    tableName: '',
                    groupName: 'group_recently_executed_software_log',
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                type: 'group',
                children: [
                    //
                    {
                        name: {
                            tableName: 'ed_installedsoftware',
                            groupName: '',
                            importTimeColumnName: 'registrytime',
                        },
                        fourImportantColumnNames: {
                            item: 'displayname',
                            date: 'registrytime',
                            type: 'software',
                            etc: 'publisher',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_service',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: '',
                            type: 'software',
                            etc: 'pathname',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_baseservice',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: '',
                            type: 'software',
                            etc: 'pathname',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_startrun',
                            groupName: "",
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: '',
                            type: 'software',
                            etc: 'command',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_remotedesktoplogin',
                            groupName: '',
                            importTimeColumnName: 'lastWritten',
                        },
                        fourImportantColumnNames: {
                            item: 'targetName',
                            date: 'lastWritten',
                            type: 'software',
                            etc: 'userName',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_jumplist',
                            groupName: '',
                            importTimeColumnName: 'recordtime',
                        },
                        fourImportantColumnNames: {
                            item: 'fullpath',
                            date: 'recordtime',
                            type: 'software',
                            etc: 'application_id',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_systeminfo',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'hotfix',
                            date: '',
                            type: 'software',
                            etc: 'os',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_muicache',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'applicationpath',
                            date: '',
                            type: 'software',
                            etc: 'applicationname',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_prefetch',
                            groupName: '',
                            importTimeColumnName: 'lastruntime',
                        },
                        fourImportantColumnNames: {
                            item: 'processname',
                            date: 'lastruntime',
                            type: 'software',
                            etc: 'processpath',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_eventapplication',
                            groupName: '',
                            importTimeColumnName: 'createdsystemtime',
                        },
                        fourImportantColumnNames: {
                            item: 'eventid',
                            date: 'createdsystemtime',
                            type: 'software',
                            etc: 'evtrenderdata',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_taskschedule',
                            groupName: '',
                            importTimeColumnName: 'lastruntime',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: 'lastruntime',
                            type: 'software',
                            etc: 'path',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_userassist',
                            groupName: '',
                            importTimeColumnName: 'modifiedtime',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: 'modifiedtime',
                            type: 'software',
                            etc: 'of_times_executed',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_networkdatausagemonitor',
                            groupName: '',
                            importTimeColumnName: 'timestamp',
                        },
                        fourImportantColumnNames: {
                            item: 'app_name',
                            date: 'timestamp',
                            type: 'software',
                            etc: 'bytes_sent',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_appresourceusagemonitor',
                            groupName: '',
                            importTimeColumnName: 'timestamp',
                        },
                        fourImportantColumnNames: {
                            item: 'app_name',
                            date: 'timestamp',
                            type: 'software',
                            etc: 'backgroundbyteswritten',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_dnsinfo',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'Name',
                            date: '',
                            type: 'software',
                            etc: 'Data',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
            {
                name: {
                    groupName: 'group_evanescent_records',
                    tableName: '',
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                type: 'group',
                children: [
                    {
                        name: {
                            tableName: 'ed_process',
                            groupName: '',
                            importTimeColumnName: 'processcreatetime',
                        },
                        fourImportantColumnNames: {
                            item: 'process_name',
                            date: 'processcreatetime',
                            type: 'volatile',
                            etc: 'process_path',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_network',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'processname',
                            date: '',
                            type: 'volatile',
                            etc: 'remoteaddress',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_openedfiles',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'processname',
                            date: '',
                            type: 'volatile',
                            etc: 'processid',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_arpcache',
                            groupName: '',
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'internetaddress',
                            date: '',
                            type: 'volatile',
                            etc: 'physicaladdress',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
            {
                name: {
                    groupName: 'group_recently_opened_file_history',
                    tableName: '',
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                // 相關捷徑 開啟的資料夾路徑  主機用戶資料 最近開啟檔案 Windows Activity
                type: 'group',
                children: [
                    {
                        name: {
                            tableName: 'ed_shortcuts',
                            groupName: "",
                            importTimeColumnName: 'modifytime',
                        },
                        fourImportantColumnNames: {
                            item: 'shortcutname',
                            date: 'modifytime',
                            type: 'document',
                            etc: 'linkto',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_shellbags',
                            groupName: "",
                            importTimeColumnName: 'accesstime',
                        },
                        fourImportantColumnNames: {
                            item: 'path',
                            date: 'accesstime',
                            type: 'document',
                            etc: 'slotpath',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_userprofiles',
                            groupName: "",
                            importTimeColumnName: 'lastlogontime',
                        },
                        fourImportantColumnNames: {
                            item: 'username',
                            date: 'lastlogontime',
                            type: 'document',
                            etc: 'usersid',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_recentfile',
                            groupName: "",
                            importTimeColumnName: 'accesstime',
                        },
                        fourImportantColumnNames: {
                            item: 'name',
                            date: 'accesstime',
                            type: 'document',
                            etc: 'fullpath',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_windowsactivity',
                            groupName: "",
                            importTimeColumnName: 'last_modified_on_client',
                        },
                        fourImportantColumnNames: {
                            item: 'app_id',
                            date: 'last_modified_on_client',
                            type: 'document',
                            etc: 'activity_type',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
            {
                name: {
                    tableName: '',
                    groupName: "group_usb_usage_record",
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                // USB 裝置資訊  安全性日誌檔   系統日誌檔
                type: 'group',
                children: [
                    {
                        name: {
                            tableName: 'ed_usbdevices',
                            groupName: "",
                            importTimeColumnName: 'last_arrival_date',
                        },
                        fourImportantColumnNames: {
                            item: 'device_description',
                            date: 'last_arrival_date',
                            type: 'usb',
                            etc: 'device_letter',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_eventsecurity',
                            groupName: "",
                            importTimeColumnName: 'createdsystemtime',
                        },
                        fourImportantColumnNames: {
                            item: 'eventid',
                            date: 'createdsystemtime',
                            type: 'usb',
                            etc: 'evtrenderdata',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_eventsystem',
                            groupName: "",
                            importTimeColumnName: 'createdsystemtime',
                        },
                        fourImportantColumnNames: {
                            item: 'eventid',
                            date: 'createdsystemtime',
                            type: 'usb',
                            etc: 'evtrenderdata',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
            {
                name: {
                    groupName: "group_email_list_records",
                    tableName: '',
                    importTimeColumnName: '',
                },
                fourImportantColumnNames: {
                    item: '',
                    date: '',
                    type: '',
                    etc: '',
                },
                // 電子郵件路徑  電子郵件清單紀錄
                type: 'group',
                children: [
                    {
                        name: {
                            tableName: 'ed_emailpath',
                            groupName: "",
                            importTimeColumnName: '',
                        },
                        fourImportantColumnNames: {
                            item: 'Path',
                            date: '',
                            type: 'emails',
                            etc: '',
                        },
                        type: 'item',
                        children: [],
                    },
                    {
                        name: {
                            tableName: 'ed_email',
                            groupName: "",
                            importTimeColumnName: 'Delivery_time',
                        },
                        fourImportantColumnNames: {
                            item: 'Subject',
                            date: 'Delivery_time',
                            type: 'emails',
                            etc: 'Sent_Representing_name',
                        },
                        type: 'item',
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        name: {
            tableName:'ed_explorer',
            groupName: "",
            importTimeColumnName: '',
        },
        fourImportantColumnNames: {
            item: '',
            date: '',
            type: '',
            etc: '',
        },
        type: 'item',
        children: [],
    },
]