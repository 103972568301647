import React, { useState, useEffect, useContext } from 'react'
import AnalysisTable, { Column } from "../AnalysisTable"
import { ProcessTimes, SelectedRow, LineChartData } from "../Interfaces"
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { IForensicDropDownData, IDateRange } from 'interface'
import { InitRequest } from '../functions'
import '../../index.scss'
import dic from 'constant/dictionary'
import { useDispatch, useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import { useNavigate } from 'react-router'
import { _analysisData, setDateRange, setallFilesDropDownData, setForensicsSelectedData, setMemoryDropDownSelected, setSelectedId } from 'redux/AnalysisSlice';
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext'
import { useAlert } from 'hook/useAlert'

interface NoSignProcessProps {
	dateModuleData: IDateRange
	selectedRow: SelectedRow
	setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
	setLineChartData: React.Dispatch<React.SetStateAction<LineChartData>>
}

const NoSignProcess = (props: NoSignProcessProps) => {
	const {
		dateModuleData,
		selectedRow,
		setSelectedRow,
		setLineChartData,
	} = props;
	const language = useSelector(_language);
	const postMutation = usePost<ProcessTimes[]>();
	const lineChartMutation = usePost<LineChartData>();
	const [processTimes, setProcessTimes] = useState<ProcessTimes[]>([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const analysisPageData = useSelector(_analysisData);
	const { showAlert } = useAlert();

	const fetchData = () => {
		postMutation.mutate({
			root: `${urlRoot}`,
			route: `${API.noSignProcess}/none`,
			body: InitRequest(
				0, // 分析儀表板沒有用 cache 了
				dateModuleData.startTime,
				dateModuleData.endTime
			),
		}, {
			onSuccess: (res) => {
				setProcessTimes(res.data || []);
			},
			onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.error(error.response?.data.message)
				}
			}
		})
	}

	const controller = (useContext(AnalysisController) as TAnalysisController);

	useEffect(() => {
		fetchData()
	}, [dateModuleData])

	const fetchAndSetLineChartData = (rowData: ProcessTimes | null) => {
		if (!rowData) return
		lineChartMutation.mutate({
			root: `${urlRoot}`,
			route: `${API.noSignProcess}/${rowData.process}`,
			body: InitRequest(
				0, // 分析儀表板沒有用 cache 了
				dateModuleData.startTime,
				dateModuleData.endTime
			),
		}, {
			onSuccess: (res) => {
				if (res.data) {
					setLineChartData({
						title: dic[language]["dashboard_table_no_sign_process"],
						timestamps: res.data.timestamps,
						dots: res.data.dots,
						leftLabel: dic[language]["process_name"],
						leftValue: rowData.process,
					});
				}
			},
			onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.error(error.response?.data.message)
				}
			}
		})
	}

	useEffect(() => {
		fetchAndSetLineChartData(selectedRow.noSignProcess)
	}, [selectedRow.noSignProcess])

	const processTimesColumns: Column<ProcessTimes, keyof ProcessTimes>[] = [
		{ key: 'process', label: dic[language]["process_name"], minWidth: 150 },
		{ key: 'times', label: dic[language]["times"], minWidth: 85 },
	]

	return (
		<div className='tableContainer'>
			<AnalysisTable
				tableTitle={dic[language]["dashboard_table_no_sign_process"]}
				lines={4}
				tableData={processTimes}
				tableColumns={processTimesColumns}
				isLoading={postMutation.isLoading}
				selectedRow={selectedRow}
				setSelectedRow={setSelectedRow}
				showDirectBtn
				directBtnCallback={(row) => {
					dispatch(setDateRange({
						...analysisPageData.dateRange,
						cache: 4,
						startTime: dateModuleData.startTime,
						endTime: dateModuleData.endTime
					}))
					let allID: string[] = [];
					analysisPageData.groups.forEach(group => {
						group.devices.forEach(device => allID.push(device.id));
					})
					dispatch(setSelectedId(allID));
					dispatch(setMemoryDropDownSelected({
						...analysisPageData.memoryDropDownData,
						isMemoryGroupSelected: true,
						processName: row.process
					}))
					dispatch(setallFilesDropDownData({
						...analysisPageData.allFilesDropDownData,
						isAllFilesSelected: false,
					}))
					let newForensicData: IForensicDropDownData = JSON.parse(JSON.stringify(analysisPageData.forensicDropDownData));
					for (var data in newForensicData) {
						if (newForensicData[data] === true) newForensicData[data] = false
					}
					dispatch(setForensicsSelectedData(newForensicData))
					navigate('/analysis');
					controller.fullMutate();
				}}
			/>
		</div>
	)
}

export default NoSignProcess
