import { useContext, useEffect, useState } from "react";
import { CircularProgress, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DownloadIcon from '@mui/icons-material/Download';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CancelIcon from '@mui/icons-material/Cancel';

import { _token } from "redux/AuthSlice";
import { AxiosError, isAxiosError } from "axios";
import { API, urlRoot } from "constant";

import DownloadContext, { DownloadData } from "context/DownloadContext";
import useDownloadFile from "hook/useDownloadFile";
import { useAlert } from "hook/useAlert";
import { ErrorResponse } from "hook/usePost";
import { axiosClient } from "util/index";
import './style.scss'

const FILE_ITEM_HEIGHT = 3;
const HEADER_HEIGHT = 3;

const DownloadManager = () => {
    const {files, deleteItem} = useContext(DownloadContext);
    const hidden = !files.length;
    const [minimized, setMinimize] = useState(false);
    const alert = useAlert();

    const { download } = useDownloadFile({
        apiDefinition: (apiRoute: string) => {
            return axiosClient.get(apiRoute, { responseType: 'blob' })
        },
        onError: (error, param: DownloadData) => {
            if (isAxiosError(error)) {
                const axiosError = error as AxiosError<ErrorResponse>
                if (axiosError.response?.status === 404){
                    alert.showAlert(`File ${param.filename} is already expired`, () => {
                        deleteItem(param.uuid);
                    })
                } else {
                    alert.showAlert(`Error occured while downloading file ${param.filename}: ${axiosError.message}`)
                }
            }
        },
        postDownloading: (param: DownloadData) => {
            deleteItem(param.uuid);
        }
    });

    const getFile = (file:DownloadData) => {
        if (file.progress < 100) return;
        download(`${urlRoot}${API.detectDump}/${file.uuid}`,file.filename, file);
    }

    useEffect(() => {
        files.forEach(file => {
            if (file.progress >= 100){
                getFile(file);
            }
        })
    }, [files])

    return (
        <div className={`downloader ${minimized?'minimized':''}`} style={{
            height: files.length*FILE_ITEM_HEIGHT+HEADER_HEIGHT+'rem',
            display: !hidden?'block':'none'
            }}>
            <h3 className="header" style={{height: HEADER_HEIGHT+'rem'}}>Preparing to download</h3>
            <IconButton aria-label="hide" className="hideBtn" onClick={() => setMinimize(!minimized)}>
                <KeyboardArrowDownIcon />
            </IconButton>
            <Divider/>
            <List className="content">
                {
                    files
                        .sort((fileA, fileB) => fileA.timestamp-fileB.timestamp)
                        .map(file => (
                        <ListItem
                            key={file.filename+file.timestamp}
                            className={`fileItem ${file.progress>=100?'ready':''}`}
                            style={{height: FILE_ITEM_HEIGHT+'rem'}}
                            secondaryAction={
                                <>
                                    <CircularProgress
                                        variant="determinate"
                                        className="progressBackground"
                                        thickness={6}
                                        style={{width: '1.25rem', height: '1.25rem'}}
                                        value={100}
                                    />
                                    <CircularProgress 
                                        className="progress"
                                        variant="determinate"
                                        value={file.progress}
                                        thickness={6}
                                        style={{width: '1.25rem', height: '1.25rem'}}
                                    />
                                    <IconButton className="cancelIcon" onClick={() => deleteItem(file.uuid)}>
                                        <CancelIcon />
                                    </IconButton>
                                    <IconButton className="downloadIcon" onClick={() => {
                                        getFile(file);
                                    }}>
                                        <DownloadIcon />
                                    </IconButton>
                                </>
                            }>
                            <ListItemIcon className="fileIcon" onClick={(e) => getFile(file)}>
                                {
                                    file.failed === ""?
                                        <InsertDriveFileIcon />
                                        :<ReportProblemIcon />
                                }
                            </ListItemIcon>
                            <ListItemText onClick={(e) => getFile(file)}>{file.filename}</ListItemText>
                        </ListItem>
                    ))
                }
            </List>
        </div>
    )
}

export default DownloadManager;