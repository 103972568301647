import { ChangeEvent, useEffect, useState } from 'react'
import { oneHostData } from 'interface'
import SearchBar from 'component/SearchBar'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'

interface EnhancedTableToolbarProps {
    selectedHost: string
    selectedHostsIDs: readonly string[]
    pageData: oneHostData[]
    search: string
    setSearch: (key: string) => void
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { selectedHost, selectedHostsIDs, pageData, search, setSearch } = props;
    const language = useSelector(_language)
    const [includeIds, setIncludeIds] = useState<string[]>([])

    useEffect(() => {
        const groupIds = pageData.map((item) => item.id)
        const includeId = selectedHostsIDs.filter((item) => groupIds.includes(item))
        setIncludeIds(includeId)
    }, [selectedHostsIDs, pageData])

    return (
        <div
            style={{
                width: '93%',
                margin: '8px auto',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <p
                    style={{
                        width: '80%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {selectedHost === '' ? dic[language]['no_group'] : selectedHost}
                </p>
                <p>
                    {includeIds.length}/{pageData.length}
                </p>
            </div>
            <div
                style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <SearchBar
                    labelValue={dic[language]['search']}
                    query={search}
                    setQuery={() => null}
                    onChangeFunc={(e) => setSearch(e.target.value)}
                    onSearchFunc={() => null}
                />
            </div>
        </div>
    )
}

export default EnhancedTableToolbar
