import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import FullLoading from 'component/FullLoading'
import TextInput from '../FormComponent/TextInput'
import CheckInput from '../FormComponent/CheckInput'
import RadioInput from '../FormComponent/RadioInput'
import TZSelect from '../FormComponent/TimezoneSelect'
import MultiSelect from '../FormComponent/MultiSelect'
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { useToast } from 'hook/useToast'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { PermissionData } from '../..'

interface ICusDialog {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    groupList: string[]
    data?: PermissionData
    listRefetchFn: () => void
}

const CusDialog = (props: ICusDialog) => {
    const { data, open, setOpen, groupList, listRefetchFn } = props;
    const toast = useToast();
    const language = useSelector(_language);
    const [department, setDepartment] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [status, setStatus] = useState<boolean>(false);
    const [permission, setPermission] = useState<number>(31);
    const [timezone, setTimezone] = useState<string>('Asia/Taipei');
    const [groups, setGroups] = useState<string[]>([]);

    useEffect(() => {
        if (data) {
            setDepartment(data.department);
            setUsername(data.username);
            setEmail(data.email);
            setPassword(data.password);
            setStatus(data.status);
            setTimezone(data.timezone);
            setPermission(data.permission);
            setGroups(data.isroot ? data.groups.concat('All') : data.groups);
        }
    }, [data]);

    const {
        mutate: addMutate,
        isLoading: isAddLoading,
    } = usePost();

    const {
        mutate: editMutate,
        isLoading: isEditLoading,
    } = usePost();

    const handleSubmit = () => {
        if (data) {
            // 編輯
            editMutate({
                root: `${urlRoot}`,
                route: `${API.user}`,
                body: {
                    userID: data.userID,
                    department,
                    username,
                    email,
                    password: password === '        ' ? "" : password,
                    status,
                    permission,
                    timezone,
                    groups: groups.filter((g) => g !== 'All'),
                    isroot: groups.includes('All'),
                },
                method: 'put',
            }, {
                onSuccess: () => {
                    handleClose()
                    toast.show({content: dic[language]['edit_success']})
                    listRefetchFn()
                },
                onError: (error) => {
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        toast.show({
                            content: dic[language]['edit_fail'],
                            type: 'error'
                        })
                    } else {
                        console.log(error.response?.data.message)
                    }
                }
            })
        } else {
            // 新增
            addMutate({
                root: `${urlRoot}`,
                route: `${API.user}`,
                body: {
                    department,
                    username,
                    email,
                    password,
                    status,
                    permission,
                    timezone,
                    groups: groups.filter((g) => g !== 'All'),
                    isroot: groups.includes('All'),
                },
                method: 'post',
            }, {
                onSuccess: () => {
                    handleClose()
                    listRefetchFn()
                    toast.show({content: dic[language]['add_success']})
                },
                onError: (error) => {
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        toast.show({
                            type: 'error',
                            content: dic[language]['add_fail']
                        })
                    } else {
                        console.log(error.response?.data.message)
                    }
                }
            })
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <FullLoading open={isAddLoading || isEditLoading} />
            <DialogTitle
                sx={{
                    backgroundColor: '#2196F3',
                    color: 'white',
                    padding: '5px 15px',
                    fontSize: '1rem',
                }}
            >
                {dic[language]['setting_user_add']}
            </DialogTitle>
            <DialogContent sx={{ width: 600 }}>
                <TextInput
                    value={department}
                    title={dic[language]['setting_user_department']}
                    handleChange={(e) => setDepartment(e.target.value)}
                    required
                />
                <TextInput
                    value={username}
                    title={dic[language]['setting_user_username']}
                    handleChange={(e) => setUsername(e.target.value)}
                    required
                />
                <TextInput
                    value={email}
                    title={dic[language]['setting_user_email']}
                    handleChange={(e) => setEmail(e.target.value)}
                    required={false}
                />
                <TextInput
                    value={password}
                    title={dic[language]['setting_user_password']}
                    handleChange={(e) => setPassword(e.target.value)}
                    isPWD
                    required
                />

                <TZSelect
                    value={timezone}
                    title={dic[language]['setting_user_timezone']}
                    handleChange={(timezone) => setTimezone(timezone.value)}
                    required
                />

                <MultiSelect
                    value={groups}
                    selectOptions={groupList}
                    handleDelete={(group: string) => { setGroups(groups.filter((g) => g !== group)) }}
                    title={dic[language]['setting_user_permission_user']}
                    handleChange={(e) => {
                        const { target: { value } } = e;
                        setGroups(typeof value === 'string' ? value.split(',') : value);
                    }}
                />

                <div
                    style={{
                        display: 'flex',
                        margin: '15px auto',
                        flexWrap: 'wrap',
                    }}
                >
                    <FormLabel required component="legend" sx={{ width: '100%' }}>
                        {dic[language]['setting_user_permission_header']}
                    </FormLabel>
                    <CheckInput
                        value={(permission & 16) === 16}
                        title={dic[language]['setting_user_permission_setting']}
                        handleChange={() => setPermission(permission ^ 16)}
                    />
                    <CheckInput
                        value={(permission & 8) === 8}
                        title={dic[language]['setting_user_permission_user']}
                        handleChange={() => setPermission(permission ^ 8)}
                    />
                    <CheckInput
                        value={(permission & 4) === 4}
                        title={dic[language]['setting_user_permission_detect']}
                        handleChange={() => setPermission(permission ^ 4)}
                    />
                    <CheckInput
                        value={(permission & 2) === 2}
                        title={dic[language]['setting_user_permission_analysis']}
                        handleChange={() => setPermission(permission ^ 2)}
                    />
                    <CheckInput
                        value={(permission & 1) === 1}
                        title={dic[language]['setting_user_permission_dashboard']}
                        handleChange={() => setPermission(permission ^ 1)}
                    />
                </div>

                <RadioInput
                    value={status}
                    title={dic[language]['setting_user_status']}
                    onChange={(e) => setStatus(e.target.value === 'true' ? true : false)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isAddLoading || isEditLoading || !department || !username || !password || !timezone || !permission}
                    onClick={() => handleSubmit()}
                >
                    {dic[language]['confirm']}
                </Button>
                <Button
                    onClick={handleClose}
                    sx={{ color: 'gray' }}
                >
                    {dic[language]["cancel"]}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CusDialog
