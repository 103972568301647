import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ITimeForm } from 'interface'
import { TLanguages } from 'constant/dictionary';
import dic from 'constant/dictionary'

export interface detectState {
    selectedIDList: string[]
    selectedOption: string
    scheduleScan: boolean
    scanModule: boolean
    scheduleForensics: boolean
    scheduleDownload: boolean
    scheduleForensicsTime: ITimeForm
    scheduleDownloadTime: ITimeForm
    dialogOpen: boolean
    searchKeys: string[]
}

const initialState: detectState = {
    selectedIDList: [],
    selectedOption: '',
    scheduleScan: false,
    scanModule: false,
    scheduleForensics: false,
    scheduleDownload: false,
    scheduleForensicsTime: {
        date: 30,
        time: 12,
    },
    scheduleDownloadTime: {
        date: 30,
        time: 12,
    },
    dialogOpen: false,
    searchKeys: []
}

export const title2name = (title: string, language: TLanguages) => {
    if (title === dic[language]['detect_task_type_memory']) {
        return { buttonTitle: dic[language]['detect_task_memory_action'], axiosTitle: 'StartScan' }
    } else if (title === dic[language]['detect_task_type_collect']) {
        return { buttonTitle: dic[language]['detect_task_collect_action'], axiosTitle: 'StartCollect' }
    } else if (title === dic[language]['detect_task_type_drive']) {
        return { buttonTitle: dic[language]['detect_task_drive_action'], axiosTitle: 'StartGetDrive' }
    } else if (title === dic[language]['detect_task_type_image']) {
        return { buttonTitle: dic[language]['detect_task_start_making_image'], axiosTitle: 'StartGetImage' }
    } else if (title === dic[language]['detect_task_type_yara']) {
        return { buttonTitle: dic[language]['detect_task_yara_action'], axiosTitle: 'StartYaraRule' }
    } else if (title === dic[language]['detect_task_type_memory_tree']) {
        return { buttonTitle: dic[language]['detect_task_memory_tree_action'], axiosTitle: 'StartMemoryTree' }
    } else if (title === dic[language]['detect_task_terminate']) {
        return { buttonTitle: dic[language]['detect_task_terminate_action'], axiosTitle: 'Terminate' }
    } else {
        return { buttonTitle: dic[language]['error'], axiosTitle: 'error' }
    }
}

export const detectSlice = createSlice({
    name: 'detect',
    initialState: initialState,
    reducers: {
        addSelectedID: (state, action: PayloadAction<string>) => {
            state.selectedIDList.push(action.payload);
        },
        setSelectedIDList: (state, action: PayloadAction<string[]>) => {
            state.selectedIDList = action.payload;
        },
        removeID: (state, action: PayloadAction<string>) => {
            state.selectedIDList = state.selectedIDList.filter(e => !action.payload.includes(e));
        },
        clearIDList: (state) => {
            state.selectedIDList = [];
        },
        setOption: (state, action: PayloadAction<string>) => {
            state.selectedOption = action.payload;
        },
        setScheduleScan: (state, action: PayloadAction<boolean>) => {
            state.scheduleScan = action.payload;
        },
        setScanModule: (state, action: PayloadAction<boolean>) => {
            state.scanModule = action.payload;
        },
        setScheduleForensics: (state, action: PayloadAction<boolean>) => {
            state.scheduleForensics = action.payload;
        },
        setScheduleDownload: (state, action: PayloadAction<boolean>) => {
            state.scheduleDownload = action.payload;
        },
        setScheduleForensicsTime: (state, action: PayloadAction<ITimeForm>) => {
            state.scheduleForensicsTime = action.payload;
        },
        setScheduleDownloadTime: (state, action: PayloadAction<ITimeForm>) => {
            state.scheduleDownloadTime = action.payload;
        },
        setDialogOpen: (state, action: PayloadAction<boolean>) => {
            state.dialogOpen = action.payload;
        },
        AddSearchKey: (state, action: PayloadAction<string>) => {
            if (!state.searchKeys.includes(action.payload)) state.searchKeys.push(action.payload)
        },
        RemoveSearchKey: (state, action: PayloadAction<string>) => {
            state.searchKeys = state.searchKeys.filter(e => e !== action.payload)
        },
    }
});

export const { addSelectedID, setSelectedIDList,
    removeID, clearIDList, setOption,
    setScheduleScan, setScanModule, setScheduleForensics,
    setScheduleDownload, setScheduleForensicsTime, setScheduleDownloadTime,
    setDialogOpen, AddSearchKey, RemoveSearchKey
} = detectSlice.actions;
export const _selectedID = (state: RootState) => state.detect.selectedIDList;
export const _selectedOption = (state: RootState) => state.detect.selectedOption;
export const _scheduleScan = (state: RootState) => state.detect.scheduleScan
export const _scanModule = (state: RootState) => state.detect.scanModule
export const _scheduleForensics = (state: RootState) => state.detect.scheduleForensics
export const _scheduleDownload = (state: RootState) => state.detect.scheduleDownload
export const _scheduleForensicsTime = (state: RootState) => state.detect.scheduleForensicsTime
export const _scheduleDownloadTime = (state: RootState) => state.detect.scheduleDownloadTime
export const _dialogOpen = (state: RootState) => state.detect.dialogOpen
export const _searchKeys = (state: RootState) => state.detect.searchKeys


export default detectSlice.reducer;