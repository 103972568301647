import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import SortBtn from '../SortBtn'
import { countryData } from './Earth/countryData'
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { CountryConnection, SelectedRow, CountryEarthResponse, CountryEarthData, EarthData } from '../Interfaces'
import { IDateRange } from 'interface'
import { InitSelectedRow, InitRequest } from '../functions'
import { processNumber } from 'util/index'
import './MiddleBar.scss'
import dic from 'constant/dictionary'
import { _language, _displayMode } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import { useAlert } from 'hook/useAlert'

interface ConnectCountryProps {
    dateModuleData: IDateRange
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setEarthData: React.Dispatch<React.SetStateAction<EarthData | CountryEarthData>>
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const ConnectCountry = (props: ConnectCountryProps) => {
    const {
        dateModuleData,
        selectedRow,
        setSelectedRow,
        setEarthData,
        setOpenSnackBar,
    } = props
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);
    const postMutation = usePost<CountryConnection[]>();
    const earthMutation = usePost<CountryEarthResponse>();
    const [connectCountry, setConnectCountry] = useState<CountryConnection[]>([]);
    const [fetchOrder, setFetchOrder] = useState<'asc' | 'desc'>('desc');
    const { showAlert } = useAlert();

    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.ipConnect}/country/none/${fetchOrder}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                setConnectCountry(sortData(res.data || []));
            },
            onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.error(error.response?.data.message)
				}
			}
        })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [fetchOrder, dateModuleData])

    const fetchAndSetEarthData = (rowData: CountryConnection | null) => {
        if (!rowData) return
        earthMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.ipConnect}/country/${rowData.country}/${fetchOrder}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                if (res.data) {
                    setEarthData({
                        country: rowData.country,
                        connections: res.data.connections,
                    });
                    if (res.data.connections === null || res.data.connections.length === 0) {
                        setOpenSnackBar(true);
                    }
                }
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchAndSetEarthData(selectedRow.connectCountry)
        // eslint-disable-next-line
    }, [selectedRow.connectCountry])

    const sortData = (data: CountryConnection[]) => {
        const convertedData: CountryConnection[] = data.map((item) => {
            return {
                country: item.country,
                name: language === 'en' || !countryData[item.country] ?
                    item.country : countryData[item.country].name,
                times: item.times,
            }
        })

        const sortedData = [...convertedData].sort((a, b) => {
            if (fetchOrder === 'asc') {
                return a.times - b.times
            } else {
                return b.times - a.times
            }
        })

        return sortedData.slice(0, 8)
    }

    return (
        <Stack spacing={2} sx={{
            height: '100px',
            margin: '10px 20px',
        }}>
            <div className='countryContainer'>
                <Typography variant="body1" sx={{
                    margin: '5px 0px',
                }}>
                    {dic[language]["dashboard_table_country_connection"]}
                </Typography>
                <SortBtn setOrder={setFetchOrder} />
            </div>
            <div className='countryContainer'>
                {connectCountry.length > 0 ? (
                    connectCountry.map((item, index) => {
                        return (
                            <Tooltip
                                key={index}
                                followCursor
                                title={item.times}
                            >
                                <Stack
                                    spacing={1}
                                    onClick={() => {
                                        const initSelectedRow = InitSelectedRow()
                                        initSelectedRow.connectCountry = {
                                            country: item.country,
                                            name: item.name,
                                            times: item.times,
                                        }
                                        setSelectedRow(initSelectedRow)
                                    }}
                                    sx={{
                                        margin: '0px 5px',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        color: selectedRow.connectCountry?.country === item.country &&
                                            displayMode === 'dark' ? "var(--gray-900)" : undefined,
                                        background: selectedRow.connectCountry?.country === item.country ?
                                            'rgba(255, 152, 0, 1)' : 'transparent',
                                    }}
                                >
                                    <div className='countryValue'>
                                        <span className='countryValueHighlight'>
                                            {processNumber(item.times)}
                                        </span> {dic[language]["simple_times"]}
                                    </div>
                                    <div className='countryLabel'>
                                        {item.name}
                                    </div>
                                </Stack>
                            </Tooltip>
                        )
                    })
                ) : (
                    <div style={{ marginLeft: '45%' }}>
                        {dic[language]["no_data"]}
                    </div>
                )}
            </div>
        </Stack>
    )
}

export default ConnectCountry
