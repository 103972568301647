import AgentRemove from './AgentRemove'
import AgentUpdate from './AgentUpdate'
import AgentGenerate from './AgentDownload'
import '../../index.scss'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { useState } from 'react'
import HeaderNavigation from '../../component/HeaderNavigation'
import { Paper } from '@mui/material'

const SettingAgentActions = () => {

    const language = useSelector(_language);
    const headerList: string[] = [
        dic[language]['setting_agent_generate'],
        dic[language]['setting_agent_remove'],
        dic[language]['setting_system_agent_update'],
    ];
    const [header, setHeader] = useState<string>(headerList[0]);

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>
                {dic[language]['setting_agent_actions']}
            </h2>
            <HeaderNavigation
                header={header}
                setHeader={setHeader}
                HeaderList={headerList}
            />
            <Paper elevation={0} className='ChildInnerWhiteContainerScroll'>
                {header === dic[language]['setting_agent_generate'] && <AgentGenerate />}
                {header === dic[language]['setting_agent_remove'] && <AgentRemove />}
                {header === dic[language]['setting_system_agent_update'] && <AgentUpdate />}
            </Paper>
        </div>
    )
}

export default SettingAgentActions
