import { InputAdornment, TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { IMemoryDropDownData } from 'interface'

// todo : 
// 1. filter * (do not use *)
// -> if the user input * , then do not search 
// -> jump the alert message, and clear the input
// 2. identify the specific chart
// 3. timeStamp used or not
//

interface SearchBarProps {
    width?: number
    labelValue: string
    query: string
    setQuery: React.Dispatch<React.SetStateAction<string>>
    onChangeFunc: (e: ChangeEvent<HTMLInputElement>) => void
    onSearchFunc: (key: string) => void
    dataForDisplay?: any
    setDataForDisplay?: React.Dispatch<any>
    memoryDropDownData?: IMemoryDropDownData,
    id?: string
}

const SearchBar = (props: SearchBarProps) => {
    const { labelValue, onChangeFunc, onSearchFunc, query, setQuery, width, id } = props
    const InputWidth = width ? width : 250

    return (
        <TextField
            sx={{ width: InputWidth, marginRight: '1rem' }}
            id={ id ? id : 'searchBar' }
            label={labelValue}
            size="small"
            value={query}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeFunc(e)
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSearchFunc(query)
                    setQuery('')
                }
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon
                            cursor="pointer"
                            onClick={() => {
                                onSearchFunc(query)
                            }}
                        />
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default SearchBar
