import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { IForensicDropDownData } from 'interface'
import ItemContainerInForensicsDropSelector, { forensicDropdownOption } from './ItemContainerInForensicsDropSelector'
import { useState } from 'react'
import TemplateDialog from './TemplateDialog'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setForensicsSelectedData, setMemoryDropDownSelected, setSelectedTemplate } from 'redux/AnalysisSlice'
import { _displayMode, _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import TDictionaryKeys from 'constant/dict.type'

interface ChildProps {
    setRefreshTemplate: React.Dispatch<React.SetStateAction<boolean>>
}

const ForensicsDropSelector = (props: ChildProps) => {
    const displayMode = useSelector(_displayMode);
    const analysisData = useSelector(_analysisData);
    const language = useSelector(_language);
    const dispatch = useDispatch();
    const {
        forensicDropDownData,
        templateList
    } = analysisData;

    const allList = {
        group_browsing_history_and_bookmarks: [
            forensicDropDownData.ed_chromehistory,
            forensicDropDownData.ed_chromedownload,
            forensicDropDownData.ed_chromekeywordsearch,
            forensicDropDownData.ed_chromelogin,
            forensicDropDownData.ed_chromebookmarks,
            forensicDropDownData.ed_edgehistory,
            forensicDropDownData.ed_iehistory,
            forensicDropDownData.ed_edgelogin,
            forensicDropDownData.ed_ielogin,
            forensicDropDownData.ed_edgebookmarks,
            forensicDropDownData.ed_firefoxhistory,
            forensicDropDownData.ed_firefoxlogin,
            forensicDropDownData.ed_firefoxbookmarks,
        ],
        group_cache_and_cookie_history: [
            forensicDropDownData.ed_chromecache,
            forensicDropDownData.ed_chromecookies,
            forensicDropDownData.ed_edgecache,
            forensicDropDownData.ed_edgecookies,
            forensicDropDownData.ed_iecache,
            forensicDropDownData.ed_firefoxcache,
            forensicDropDownData.ed_firefoxcookies,
        ],
        group_current_network_connection_history: [
            forensicDropDownData.ed_netadapters,
            forensicDropDownData.ed_wireless,
            forensicDropDownData.ed_networkresources,
        ],
        group_recently_executed_software_log: [
            forensicDropDownData.ed_installedsoftware,
            forensicDropDownData.ed_service,
            forensicDropDownData.ed_baseservice,
            forensicDropDownData.ed_startrun,
            forensicDropDownData.ed_remotedesktoplogin,
            forensicDropDownData.ed_jumplist,
            forensicDropDownData.ed_systeminfo,
            forensicDropDownData.ed_muicache,
            forensicDropDownData.ed_prefetch,
            forensicDropDownData.ed_eventapplication,
            forensicDropDownData.ed_taskschedule,
            forensicDropDownData.ed_userassist,
            forensicDropDownData.ed_networkdatausagemonitor,
            forensicDropDownData.ed_appresourceusagemonitor,
            forensicDropDownData.ed_dnsinfo,
        ],
        group_evanescent_records: [
            forensicDropDownData.ed_process,
            forensicDropDownData.ed_openedfiles,
            forensicDropDownData.ed_network,
            forensicDropDownData.ed_arpcache,
        ],
        group_recently_opened_file_history: [
            forensicDropDownData.ed_shortcuts,
            forensicDropDownData.ed_userprofiles,
            forensicDropDownData.ed_windowsactivity,
            forensicDropDownData.ed_shellbags,
            forensicDropDownData.ed_recentfile,
        ],
        group_usb_usage_record: [
            forensicDropDownData.ed_usbdevices,
            forensicDropDownData.ed_eventsystem,
            forensicDropDownData.ed_eventsecurity,
        ],
        group_email_list_records: [
            forensicDropDownData.ed_emailpath,
            forensicDropDownData.ed_email,
        ],
    }

    const [selectedPage, setSelectedPage] = useState<forensicDropdownOption>('group_browsing_history_and_bookmarks')
    const [selectedIndex, setSelectedIndex] = useState(0) // 選擇哪個頁面
    // 關鍵字
    const SearchKeyword = () => {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto 1fr',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    關鍵字 :
                </span>
                <TextField
                    id="outlined-basic"
                    size="small"
                    onChange={(e) => console.log(e.target.value)}
                />
                <div></div>
            </div>
        )
    }
    // 控制顯示的子頁面

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ) => {
        setSelectedIndex(index)
    }

    const handleChange = (event: SelectChangeEvent) => {
        const _selectedTemplate = (templateList.find((item: IForensicDropDownData) => item.templateId === event.target.value) as IForensicDropDownData);
        dispatch(setSelectedTemplate(_selectedTemplate || null));
        dispatch(setForensicsSelectedData(_selectedTemplate));
    }

    const QuickSelect = () => {
        const [dialogOpen, setDialogOpen] = useState(false)
        const analysisData = useSelector(_analysisData);
        const {selectedTemplate} = analysisData;

        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto auto 1fr',
                    marginTop: '10px',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    {dic[language]["quick_select"]}
                </span>
                <Box sx={{ minWidth: 120, marginRight: '10px' }}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="template-select">
                            {dic[language]["select_template"]}
                        </InputLabel>
                        <Select
                            labelId="template-select"
                            value={selectedTemplate?.templateName}
                            label={dic[language]["select_template"]}
                            onChange={handleChange}
                        >
                            {
                                templateList.map((item, i) => {
                                    return (
                                        <MenuItem key={i} value={item.templateId?.toString()}>{item.templateName}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
                <Button
                    variant="contained"
                    disabled={JSON.stringify(selectedTemplate) === JSON.stringify(forensicDropDownData)}
                    onClick={() => { setDialogOpen(true) }}
                >
                    {dic[language]["save_template"]}
                </Button>
                <TemplateDialog
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                    template={forensicDropDownData}
                    setRefreshTemplate={props.setRefreshTemplate}
                />
            </div>
        )
    }
    // 生成選擇頁面清單
    const SelectPageList = () => {
        // groupList 清單
        const groupList: forensicDropdownOption[] = [
            'group_browsing_history_and_bookmarks',
            'group_cache_and_cookie_history',
            'group_current_network_connection_history',
            'group_recently_executed_software_log',
            'group_evanescent_records',
            'group_recently_opened_file_history',
            'group_usb_usage_record',
            'group_email_list_records',
        ]
        // 從group checkbox 控制小項目
        function controlAGroupOfLittleElement(
            e: React.ChangeEvent<HTMLInputElement>
        ) {
            switch (e.target.name) {
                case 'group_browsing_history_and_bookmarks':
                    dispatch(setForensicsSelectedData({
                        ...forensicDropDownData,
                        group_browsing_history_and_bookmarks: e.target.checked,
                        ed_chromehistory: e.target.checked,
                        ed_chromedownload: e.target.checked,
                        ed_chromekeywordsearch: e.target.checked,
                        ed_chromelogin: e.target.checked,
                        ed_chromebookmarks: e.target.checked,
                        ed_edgehistory: e.target.checked,
                        ed_iehistory: e.target.checked,
                        ed_edgelogin: e.target.checked,
                        ed_ielogin: e.target.checked,
                        ed_edgebookmarks: e.target.checked,
                        ed_firefoxhistory: e.target.checked,
                        ed_firefoxlogin: e.target.checked,
                        ed_firefoxbookmarks: e.target.checked,
                    }))
                    break;
                case 'group_cache_and_cookie_history':
                    dispatch(setForensicsSelectedData( {
                        ...forensicDropDownData,
                        group_cache_and_cookie_history: e.target.checked,
                        ed_chromecache: e.target.checked,
                        ed_chromecookies: e.target.checked,
                        ed_edgecache: e.target.checked,
                        ed_edgecookies: e.target.checked,
                        ed_iecache: e.target.checked,
                        ed_firefoxcache: e.target.checked,
                        ed_firefoxcookies: e.target.checked,
                    }))
                    break;
                case 'group_current_network_connection_history':
                    dispatch(setForensicsSelectedData( {
                        ...forensicDropDownData,
                        group_current_network_connection_history: e.target.checked,
                        ed_netadapters: e.target.checked,
                        ed_wireless: e.target.checked,
                        ed_networkresources: e.target.checked,
                    }))
                    break;
                case 'group_recently_executed_software_log':
                    dispatch(setForensicsSelectedData( {
                        ...forensicDropDownData,
                        group_recently_executed_software_log: e.target.checked,
                        ed_installedsoftware: e.target.checked,
                        ed_service: e.target.checked,
                        ed_baseservice: e.target.checked,
                        ed_startrun: e.target.checked,
                        ed_remotedesktoplogin: e.target.checked,
                        ed_jumplist: e.target.checked,
                        ed_systeminfo: e.target.checked,
                        ed_muicache: e.target.checked,
                        ed_prefetch: e.target.checked,
                        ed_eventapplication: e.target.checked,
                        ed_taskschedule: e.target.checked,
                        ed_userassist: e.target.checked,
                        ed_networkdatausagemonitor: e.target.checked,
                        ed_appresourceusagemonitor: e.target.checked,
                        ed_dnsinfo: e.target.checked,
                    }))
                    break;
                case 'group_evanescent_records':
                    dispatch(setForensicsSelectedData( {
                        ...forensicDropDownData,
                        group_evanescent_records: e.target.checked,
                        ed_process: e.target.checked,
                        ed_openedfiles: e.target.checked,
                        ed_network: e.target.checked,
                        ed_arpcache: e.target.checked,
                    }))
                    break;
                case 'group_recently_opened_file_history':
                    dispatch(setForensicsSelectedData( {
                        ...forensicDropDownData,
                        group_recently_opened_file_history: e.target.checked,
                        ed_shortcuts: e.target.checked,
                        ed_userprofiles: e.target.checked,
                        ed_windowsactivity: e.target.checked,
                        ed_shellbags: e.target.checked,
                        ed_recentfile: e.target.checked,
                    }))
                    break;
                case 'group_usb_usage_record':
                    dispatch(setForensicsSelectedData( {
                        ...forensicDropDownData,
                        group_usb_usage_record: e.target.checked,
                        ed_usbdevices: e.target.checked,
                        ed_eventsystem: e.target.checked,
                        ed_eventsecurity: e.target.checked,
                    }))
                    break;
                case 'group_email_list_records':
                    dispatch(setForensicsSelectedData( {
                        ...forensicDropDownData,
                        group_email_list_records: e.target.checked,
                        ed_emailpath: e.target.checked,
                        ed_email: e.target.checked,
                    }))
                    break;
                default:
                    break
            }
        }
        // 決定group是否只選部分
        function decideSelectAllOrNot(groupName: string): boolean | undefined {
            //@ts-ignore
            let selectedGroup = (allList[groupName] as boolean[]);

            let checkedInSelectedGroup = selectedGroup.filter(
                (item) => item === true
            )
            if (
                checkedInSelectedGroup.length !== 0 &&
                checkedInSelectedGroup.length !== selectedGroup.length
            ) {
                return true
            }
            return undefined
        }

        // 決定group是否選取
        function decideCheckBoxOfGroupIsChecked(groupName: string) {
            //@ts-ignore
            let selectedGroup = (allList[groupName] as boolean[]);
            let checkedInSelectedGroup = selectedGroup.filter(
                (item) => item === true
            )
            if (checkedInSelectedGroup.length === 0) {
                return false
            } else if (checkedInSelectedGroup.length === selectedGroup.length) {
                return true
            } else {
                return undefined
            }
        }

        return (
            <List
                component="nav"
                aria-label="main mailbox folders"
                style={{ top: '-8px' }}
            >
                {groupList.map((item: forensicDropdownOption, i) => {
                    return (
                        <ListItemButton
                            defaultValue=""
                            style={{ padding: '0px', margin: '0px' }}
                            selected={selectedIndex === i}
                            key={i}
                            onClick={(event) => {
                                handleListItemClick(event, i)
                                setSelectedPage(item)
                            }}
                        >
                            <Checkbox
                                checked={
                                    decideCheckBoxOfGroupIsChecked(item) ||
                                    false
                                }
                                indeterminate={decideSelectAllOrNot(item)} //當checkbox加上indeterminate屬性時會啟用選取部分功能   true: 有些選有些不選, 會覆蓋掉checked的值狀態
                                onChange={controlAGroupOfLittleElement}
                                name={item}
                            ></Checkbox>
                            <span>{dic[language][item]}</span>
                        </ListItemButton>
                    )
                })}
            </List>
        )
    }
    // 顯示選擇的頁面
    function showSelectedPage() {
        return (
            <ItemContainerInForensicsDropSelector
                containerName={selectedPage}
            />
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
                width: '750px',
            }}
        >
            <div style={{ minWidth: '300px', height: '599px' }}>
                <div
                    style={{
                        position: 'relative',
                        height: '599px',
                        width: '760px',
                        zIndex: '100',
                        paddingLeft: '20px',
                    }}
                >
                    <div style={{ height: '600px' }}>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr  auto',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {dic[language]["ed_collection"]}
                            </div>
                            <QuickSelect/>
                        </div>
                        <hr />
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 2fr',
                                height: '450px',
                            }}
                        >
                            {SelectPageList()}
                            <div
                                style={{
                                    backgroundColor: displayMode === 'dark' ? 'var(--gray-900)' : 'var(--gray-100)',
                                    padding: '20px',
                                }}
                            >
                                {showSelectedPage()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForensicsDropSelector

// 區塊加上全選 ？
