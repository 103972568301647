import styled from 'styled-components'

export const CommonTitle = styled.p`
    font-size: 25px;
    font-weight: bold;
`

export const CommonHoriLine = styled.div`
    width: 100%;
    height: 1px;
    margin: 10px 0px;
    border-top: 1px solid #e0e0e0;
`

export const CommonSpaceGap = styled.div`
    width: 100%;
    height: 1px;
    margin: 10px 20px;
`

export const StyledTitle = styled.div`
    font-size: 1.3rem;
    margin: 1rem 0;
`

export const StyledSpan = styled.span`
    margin-left: 5rem;
    color: #2196F3;
    font-size: 1.1rem;
`

export const StyledSpan1 = styled.span`
    margin-left: 4rem;
    color: #2196F3;
    font-size: 1.1rem;
`
export const StyledDivInSettingV = styled.div`
    margin : 1rem 0rem;
`
