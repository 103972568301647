/** @format */

import { useMutation } from '@tanstack/react-query'
import { API, urlRoot } from 'constant/index'
import { Button } from '@mui/material'
import { useAlert } from 'hook/useAlert'
import { useSelector } from 'react-redux'
import { _selectedOption, _selectedID, _scheduleForensicsTime, _scheduleForensics, _scheduleDownload, _scheduleDownloadTime  } from 'redux/DetectSlice'
import { axiosClient } from 'util/index'
import { ITimeForm } from 'interface'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

const TimeMissionButton = () => {
    const scheduleForensicsTime = useSelector(_scheduleForensicsTime)
    const scheduleForensics = useSelector(_scheduleForensics)
    const scheduleDownload = useSelector(_scheduleDownload)
    const scheduleDownloadTime = useSelector(_scheduleDownloadTime)
    const language = useSelector(_language)

    const convertToUTC = (schedule: ITimeForm): ITimeForm => {
        const { date: scheduleDate, time: scheduleTime } = schedule
        const offset = new Date(`01/01/2024 00:00`).getTimezoneOffset() / 60
        const UTCTime = scheduleTime + offset
        if (UTCTime < 0) {
            return { date: scheduleDate - 1, time: UTCTime + 24 }
        } else if (UTCTime > 24) {
            return { date: scheduleDate + 1, time: UTCTime - 24 }
        } else {
            return { date: scheduleDate, time: UTCTime }
        }
    }

    const option = useSelector(_selectedOption)
    const ids = useSelector(_selectedID)

    const scheduledForensicsRequest = useMutation({
        mutationKey: ['scheduledForensicsRequest'],
        mutationFn: () => {
            convertToUTC(scheduleForensicsTime)
            return axiosClient.post(
                `${urlRoot}${API.schedule}/collect`,
                {
                    mode: scheduleForensics,
                    date: convertToUTC(scheduleForensicsTime).date,
                    time: convertToUTC(scheduleForensicsTime).time,
                    devices: ids,
                }
            )
        },
        onError: (error) => {
            console.log('onError', error)
        }
    })

    const scheduledDownloadRequest = useMutation({
        mutationKey: ['scheduledDownloadRequest'],
        mutationFn: () => {
            return axiosClient.post(
                `${urlRoot}${API.schedule}/explorer`,
                {
                    mode: scheduleDownload,
                    date: convertToUTC(scheduleDownloadTime).date,
                    time: convertToUTC(scheduleDownloadTime).time,
                    devices: ids,
                }
            )
        },
        onError: (error) => {
            console.log('onError', error)
        }
    })

    return (
        <Button
            variant="contained"
            style={{ marginLeft: 10, marginRight: 10 }}
            disabled={ids.length === 0}
            onClick={() => {
                if (option === dic[language]['detect_task_type_collect']) {
                    scheduledForensicsRequest.mutate()
                } else if (option === dic[language]['detect_task_type_drive']) {
                    scheduledDownloadRequest.mutate()
                }
            }}
        >
            {dic[language]['apply']}
        </Button>
    )
}

export default TimeMissionButton
