import { Dialog, DialogTitle, DialogContent } from "@mui/material"
import { Stack, Typography, IconButton } from "@mui/material"
import dic from 'constant/dictionary'
import { _language, _displayMode } from "redux/UtilSlice"
import { useSelector } from "react-redux"
import ServerStatus from "./ServerStatus"
import assets from "assets"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './statusDiagram.scss'

interface StatusDiagramProps {
    open: boolean
    onClose: () => void
    serverCPU: number
    elasticMemory: number
    elasticCapacity: number
    rabbitMessages: number
    agentCount: number
}

const StatusDiagram = (props: StatusDiagramProps) => {
    const {
        open,
        onClose,
        serverCPU,
        elasticMemory,
        elasticCapacity,
        rabbitMessages,
        agentCount
    } = props
    const language = useSelector(_language)
    const displayMode = useSelector(_displayMode)

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth='xl'
            aria-labelledby="server-status"
        >
            <DialogTitle
                sx={{
                    backgroundColor: '#2196F3',
                    color: 'white',
                    fontSize: '1rem',
                    padding: '0.25rem 1rem',
                }}
            >
                {dic[language]['right_bar_title']}
                <IconButton
                    sx={{ marginLeft: 'calc(100% - 65px - 40px)' }}
                    onClick={onClose}
                >
                    <CloseRoundedIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ height: '65vh', background: displayMode === 'dark' ? 'black' : 'white' }}>
                <div style={{ paddingTop: '1rem', height: '100%' }}>
                    <ServerStatus canOpen={false} />
                    <Stack direction='column' sx={{ marginTop: '1rem', height: 'calc(100% - 4rem)' }}>
                        <Stack direction='row' justifyContent='space-between' sx={{ height: '45%' }}>
                            <Card
                                isServer={true}
                                title={dic[language]['server']}
                                svgImage={<assets.ServerImage />}
                                hasStatus={true}
                                statusColor={serverCPU < 50 ? '#A5D6A7' : serverCPU < 80 ? '#FFE082' : '#EF9A9A'}
                                statusTitle1={dic[language]['cpu']}
                                statusValue1={`${serverCPU} %`}
                            />
                            <div className="horizontalBar"/>
                            <Card
                                isServer={false}
                                title={dic[language]['number_of_messages']}
                                svgImage={<assets.Queue />}
                                hasStatus={true}
                                statusColor={rabbitMessages < 100 ? '#A5D6A7' : rabbitMessages < 500 ? '#FFE082' : '#EF9A9A'}
                                statusTitle1={dic[language]['number']}
                                statusValue1={`${rabbitMessages}${language === 'zh' ? ' 筆' : ''}`}
                            />
                            <div className="horizontalBar"/>
                            <Card
                                isServer={false}
                                title='Elastic'
                                svgImage={<assets.Elastic />}
                                hasStatus={true}
                                statusColor={elasticMemory > 95 || elasticCapacity > 95 ? '#EF9A9A' : elasticMemory > 75 || elasticCapacity > 90 ? '#FFE082' : '#A5D6A7'}
                                statusTitle1={dic[language]['memory']}
                                statusValue1={`${elasticMemory} %`}
                                statusTitle2={dic[language]['storage']}
                                statusValue2={`${elasticCapacity} %`}
                            />
                        </Stack>
                        <div className='verticalBar'/>
                        <Stack direction='row' justifyContent='space-between' sx={{ height: '45%' }}>
                            <Card
                                isServer={false}
                                title={dic[language]['agent']}
                                svgImage={<assets.Agent />}
                                hasStatus={false}
                                statusTitle1={dic[language]['number']}
                                statusValue1={`${agentCount}`}
                            />
                        </Stack>
                    </Stack>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default StatusDiagram

interface CardProps {
    isServer: boolean
    title: string
    svgImage: any
    hasStatus: boolean
    statusColor?: string
    statusTitle1: string
    statusValue1: string
    statusTitle2?: string
    statusValue2?: string
}

const Card = (props: CardProps) => {
    const {
        isServer,
        title,
        svgImage,
        hasStatus,
        statusColor,
        statusTitle1,
        statusValue1,
        statusTitle2,
        statusValue2
    } = props
    const displayMode = useSelector(_displayMode)
    const language = useSelector(_language)
    const smallBoxBackground = displayMode === 'dark' ? '#434343' : '#FFFFFF'

    return (
        <Stack
            direction='column'
            alignItems='flex-start'
            justifyContent='center'
            sx={{
                border: `1px solid ${displayMode === 'dark' ? '#0277BD' : '#81D4FA'}`,
                width: '32%',
                borderRadius: '4px',
                backgroundColor: isServer ?
                    (displayMode === 'dark' ? '#01579B' : '#B3E5FC') :
                    (displayMode === 'dark' ? '#1F1F1F' : '#F7F7F7'),
            }}
        >
            <Typography variant='h6' sx={{ margin: '0.5rem' }}>{title}</Typography>
            <Stack direction='row' alignItems='center' justifyContent='center' sx={{ width: '100%', flex: 1 }}>
                {svgImage}
                <Stack direction={'column'} alignItems='center' justifyContent='center' sx={{ flex: 1 }}>
                    {hasStatus && (
                        <Stack direction='row' alignItems='center' justifyContent='space-between'
                            sx={{ width: '80%', background: smallBoxBackground, padding: '0.25rem 0.5rem' }}
                        >
                            <Typography variant='body1'>{dic[language]['setting_user_status']}</Typography>
                            <div style={{ width: '4rem', height: '1.5rem', background: statusColor }}></div>
                        </Stack>
                    )}
                    <Stack direction='row' alignItems='center' justifyContent='space-between'
                        sx={{ width: '80%', background: smallBoxBackground, padding: '0.25rem 0.5rem', margin: '1rem' }}
                    >
                        <Typography variant='body1'>{statusTitle1}</Typography>
                        <Typography variant='body1'>{statusValue1}</Typography>
                    </Stack>
                    {statusTitle2 && statusValue2 && (
                        <Stack direction='row' alignItems='center' justifyContent='space-between'
                            sx={{ width: '80%', background: smallBoxBackground, padding: '0.25rem 0.5rem' }}
                        >
                            <Typography variant='body1'>{statusTitle2}</Typography>
                            <Typography variant='body1'>{statusValue2}</Typography>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    )
}