export const socketRoute = {
    local: 'wss://localhost:6969',
    dev: 'ws://192.168.200.161:5050/ws',
}

export const API = {
    // ! login
    login: 'login',
    loginWithToken: 'loginWithToken',

    // ! user
    user: 'user',
    userInfo: 'user/info',

    // ! agent
    agent: 'agent',
    agentCount: 'agent/count',
    agentAll: 'agent/all',
    agentInfo: 'agent/info',
    agentVersion: 'agent/version',

    // ! group
    group: 'group',
    groupDevice: 'group/device',

    // ! detect page
    detectDump: 'detect/dump',
    detectMission: 'detect/mission',
    detectMode: 'detect/detectmode',
    detectImage: 'detect/keyimage',
    schedule: 'detect/schedule',

    //! analysis page
    gptAnalysis: 'analysis/gptAnalysis',
    template: 'analysis/template',

    // ! setting page
    interfaceSetting: 'setting/system/Interface',
    agentSetting: 'setting/system/Agent',
    commonFunc: 'setting/system/Function',
    serverAndEmail: 'setting/system/ServerAndEmail',

    whiteList: 'setting/whitelist',
    blackList: 'setting/blacklist',
    hackList: 'setting/hacklist',
    keywordlist: 'setting/keywordlist',

    keyImage: 'setting/keyImage',
    keyImageDefault: 'setting/keyImage/default',
    keyImageAdvanced: 'setting/keyImage/advanced',
    keyImageCustomized: 'setting/keyImage/customized',

    virusTotalKey: 'setting/vtkey',
    virulTotalVerify: 'setting/vtkey/validate',
    virusTotalStore: 'setting/vtkey/store',

    yaraRule: 'setting/yara',
    license: 'setting/license',
    logs: 'setting/logs',

    dtImport: 'setting/dtImport',

    // ! detect dashboard
    serverStatus: 'dashboard/server/status',
    agentConnection: 'dashboard/agent/connection',
    osCount: 'dashboard/osCount',
    timeline: 'dashboard/task/timeline',
    taskStatus: 'dashboard/task/status',

    // ! analysis dashboard
    ipConnect: 'analysisDashboard/connectionRank',
    riskLevel: 'analysisDashboard/riskLevel',
    loginFailure: 'analysisDashboard/loginFailure',
    noSignProcess: 'analysisDashboard/noSignProcess',
    processIP: 'analysisDashboard/processIP',
    highRiskProcess: 'analysisDashboard/highRiskProcess',
    levelChange: 'analysisDashboard/levelChange',
    keywordHit: 'analysisDashboard/keywordHit',
    virusTotal: 'analysisDashboard/virustotal',
}

//@ts-ignore
export const env = { ...process.env, ...window.env }

export const urlRoot = env.REACT_APP_URL;
export const VERSION = env.REACT_APP_VERSION;
export const elasticRoot = env.REACT_APP_ELASTIC_URL;
export const IS_PRODUCTION = env.REACT_APP_IS_PRODUCTION;
export const socketRoot = env.REACT_APP_WS_URL ?
    env.REACT_APP_WS_URL
    : socketRoute.dev;

//analysis page
export const CSV_REPORT_LIMIT = 100000;

//network page
export const NETWORK_REFETCH_INTERVAL = 10000;

export const NO_GROUP_KEYWORD = "@@no-group@@";

// task status code
export const TASK_STATUS = {
    'unknown': -1, 
    'pending': 0,
    'sending': 1,
    'working': 2,
    'finished': 3,
    'terminated': 4,
    'terminating': 5,
    'internalServerError': 6,
    'lostConnection': 7,
    'waitingForExplorer': 10,
}

export enum WEBSOCKET_STATUS {
    AUTHORIZE_SUCCESS = 0,
    DETECT_UPDATE = 1,
    SERVER_STATUS_UPDATE = 2,
    HEARTBEAT = 3,
    ERROR = 4,
    DUPLICATE_CONNECTION_ERROR = 5,
    LOAD_DLL = 6,
    ACTION_PROGRESS_UPDATE = 7,
}