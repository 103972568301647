import { memo } from 'react'
import './index.css'
import { useSpring, animated } from '@react-spring/web'

interface ProgressBarProps {
    progress: number
}

const ProgressBar = memo((props: ProgressBarProps) => {
    const { progress } = props
    // 確保 progressState 不會超過 progress

    const durationTimer = 30000

    // progress bar animation
    const loadingProps = useSpring({
        from: { width: `${progress}%` },
        to: { width: `${progress}%` },
        config: { duration: durationTimer },
    })

    return (
        <div>
            <div className="loading progress-label">
                {Math.round(progress)}
            </div>

            <div className="progress-bar-container" >
                <animated.div className="filler" style={loadingProps} />
            </div>
        </div>
    )
})

export default ProgressBar

// todo 時間loading 對不準 bar running
