import { useState, useEffect, useContext } from "react"
import { usePost } from "hook/usePost"
import { API, urlRoot } from 'constant/index'
import AnalysisTable, { Column } from "../AnalysisTable"
import { ProcessTimesScore, SelectedRow, LineChartData } from "../Interfaces"
import { IDateRange } from 'interface'
import { InitRequest } from '../functions'
import '../../index.scss'
import dic from 'constant/dictionary'
import { _language } from "redux/UtilSlice"
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { _analysisData, setDateRange, setallFilesDropDownData, setForensicsSelectedData, changeMemoryDropDownSelected } from 'redux/AnalysisSlice';
import AnalysisController, { TAnalysisController } from "context/AnalysisControllerContext"
import { useAlert } from "hook/useAlert"

interface HighRiskProcessProps {
    dateModuleData: IDateRange
    selectedRow: SelectedRow
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setLineChartData: React.Dispatch<React.SetStateAction<LineChartData>>
}

const HighRiskProcess = (props: HighRiskProcessProps) => {
    const {
        dateModuleData,
        selectedRow,
        setSelectedRow,
        setLineChartData,
    } = props;
    const language = useSelector(_language);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const analysisPageData = useSelector(_analysisData);
    const postMutation = usePost<ProcessTimesScore[]>();
    const lineChartMutation = usePost<LineChartData>();
    const [processTimesScore, setProcessTimesScore] = useState<ProcessTimesScore[]>([]);
    const { showAlert } = useAlert();
    const controller = (useContext(AnalysisController) as TAnalysisController);

    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.highRiskProcess}/none`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                setProcessTimesScore(res.data || []);
            },
            onError: (error) => {
				// 4xx error -> alert
				// 5xx error -> console.log
				if (error.response && error.response.status < 500) {
					showAlert(error.response.data.message)
				} else {
					console.error(error.response?.data.message)
				}
			}
        })
    }

    useEffect(() => {
        fetchData()
    }, [dateModuleData])

    const fetchAndSetLineChartData = (rowData: ProcessTimesScore | null) => {
        if (!rowData) return
        lineChartMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.highRiskProcess}/${rowData.process}|${rowData.score}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                if (res.data) {
                    setLineChartData({
                        title: dic[language]["dashboard_top_bar_level3_process_count"],
                        timestamps: res.data.timestamps,
                        dots: res.data.dots,
                        leftLabel: dic[language]["process_name"],
                        leftValue: rowData.process,
                        rightLabel: dic[language]["score"],
                        rightValue: rowData.score.toString(),
                    });
                }
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchAndSetLineChartData(selectedRow.highRiskProcess)
    }, [selectedRow.highRiskProcess])

    const processTimesScoreColumns: Column<ProcessTimesScore, keyof ProcessTimesScore>[] = [
        { key: 'process', label: dic[language]["process_name"], minWidth: 150 },
        { key: 'times', label: dic[language]["times"], minWidth: 65 },
        { key: 'score', label: dic[language]["score"], minWidth: 65 },
    ]

    return (
        <div className="tableContainer">
            <AnalysisTable
                tableTitle={dic[language]["dashboard_top_bar_level3_process_count"]}
                lines={5}
                tableData={processTimesScore}
                tableColumns={processTimesScoreColumns}
                isLoading={postMutation.isLoading}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                showDirectBtn
                directBtnCallback={(row) => {
                    dispatch(setDateRange({
                        ...analysisPageData.dateRange,
                        cache: 4,
                        startTime: dateModuleData.startTime,
                        endTime: dateModuleData.endTime
                    }))
                    dispatch(changeMemoryDropDownSelected({
                        title: 'processName',
                        value: row.process
                    }))
                    dispatch(setallFilesDropDownData({
                        ...analysisPageData.allFilesDropDownData,
                        isAllFilesSelected: false,
                    }))
                    dispatch(setForensicsSelectedData({
                        ...analysisPageData.forensicDropDownData,
                        isForensicsSelected: false,
                    }))
                    controller.fullMutate();
                    navigate('/analysis');
                }}
            />
        </div>
    )
}

export default HighRiskProcess
