import AddIcon from '@mui/icons-material/Add'
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete'
import { Card, CardActions, CardContent, Chip, CircularProgress, IconButton, LinearProgress, linearProgressClasses, styled } from '@mui/material'
import { API, urlRoot, WEBSOCKET_STATUS } from 'constant'
import dic from 'constant/dictionary'
import useDenyIfNot from 'hook/useDenyIfNot'
import { useGet } from 'hook/useGet'
import useSocketHandler from 'hook/useSocketHandler'
import JSZip from 'jszip'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import { axiosClient, formatTimestamp } from 'util/index'
import '../../index.scss'
import './index.scss'

type dtDBMetaData = {
    actionId: string
    deviceIP: string
    deviceName: string
    timestamp: number
    uploader: string
    progress: number,
    actionType: 'explorer' | 'collection'
    failure?: string
}

//copied from MUI documentation
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    transform: 'translateY(-50%)',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

const DTDBCard = (props: {
    metadata: dtDBMetaData
    refetch: () => void
}) => {
    const {metadata, refetch} = props;
    const [dotCount, setDotCount] = useState(1);
    useEffect(() => {
        let timer:(NodeJS.Timer|null) = null;
        if (metadata.progress < 100) {
            timer = setInterval(() => {
                setDotCount((prev) => (prev%5)+1);
            }, 500);
        }
        return () => {
            if (timer !== null)clearInterval(timer);
        }
    },[metadata.progress])

    return (
        <Card variant="outlined" className="dtCard">
            {
                metadata.progress < 100 && metadata.progress !== -1?
                    <BorderLinearProgress variant="determinate" value={metadata.progress} />
                : null
            }
            <CardContent>
                <span className="smallText">{
                    metadata.progress === 100?formatTimestamp(metadata.timestamp)
                    :metadata.failure?metadata.failure
                    :('server processing'+ [...Array(dotCount)].map(_ => '.').join(''))
                }</span>
                <div style={{height: '1rem'}}></div>
                <h3>{metadata.failure?<WarningIcon/>:null}{metadata.deviceName}</h3>
                <span className="smallText">{metadata.deviceIP}</span>
            </CardContent>
            <CardActions className="cardAction">
                <Chip label={metadata.actionType} color={metadata.actionType==='collection'?"primary":'secondary'} variant="outlined" />
                <IconButton onClick={() => {
                    axiosClient .delete(`${urlRoot}${API.dtImport}/${metadata.actionId}`)
                        .then(res => {
                            refetch();
                        })
                }}>
                    <DeleteIcon/>
                </IconButton>
            </CardActions>
        </Card>
    )
}

const SettingDT = () => {
    useDenyIfNot('setting');
    const language = useSelector(_language);

    const dbListQuery = useGet<dtDBMetaData[]>({
        query: 'dtImportGet', 
        root: urlRoot,
        route: API.dtImport,
    });
    const [dbList, setDBList] = useState<dtDBMetaData[]>([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(-2); // -2: compressing; -1: preparing upload

    useEffect(() => {
        if (dbListQuery.status === 'success' && dbListQuery.data) {
            setDBList(dbListQuery.data?.data)
        }
    }, [dbListQuery.status, dbListQuery.data])

    useSocketHandler(WEBSOCKET_STATUS.ACTION_PROGRESS_UPDATE, (message, param) => {
        if (message.action !== "DT") return;
        setDBList((prev: dtDBMetaData[]) => {
            return prev.map(db => {
                if (db.actionId === message.uuid){
                    let __db = JSON.parse(JSON.stringify(db));
                    __db.progress = message.progress;
                    return __db;
                }
                return db;
            })
        })
    })

    const formatBytes = (bytes: number, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        );
    };

    const handleUploadFile = async (file: File | null) => {
        if (!file) return;
        setUploading(true);

        const fileData = Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        });
        
        const zip = new JSZip();
        zip.file(`${fileData.name}`, fileData);
   
        const zipContent = await zip.generateAsync({ type: "blob", compression: 'DEFLATE' });

        try {
            const formData = new FormData();
            formData.append("file", zipContent, `${fileData.name}.zip`);
            setUploadProgress(-1);
            const res = await axiosClient
                .post(`${urlRoot}${API.dtImport}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (event) => {
                        if (!event.loaded) return;
                        setUploadProgress((event.progress??0) * 100);
                    }
                });
            dbListQuery.refetch();
            setUploading(false);
            setUploadProgress(-2);
        } catch (error) {
            setUploading(false);
            setUploadProgress(-2);
        }
    };

    return (
        <div className='ChildContainer scrollbarY' style={{overflowY: 'scroll'}}>
            <h2 className='ChildContainerTitle'>{dic[language]["setting_dt_import"]}</h2>
            <div className="dtSettingPage">
                {
                    dbListQuery.isSuccess && dbList ?
                        dbList.map((metadata) => (
                            <DTDBCard metadata={metadata} refetch={dbListQuery.refetch}/>
                        ))
                    :null
                }
                <label htmlFor="upload" >
                    <Card variant="outlined" className="dtCard add">
                        {
                            !uploading?
                            <>
                                <AddIcon className="addIcon"/>
                                <span className="addSubtitle">
                                    Add DT database
                                </span>
                                <input type="file" id="upload" style={{display:"none"}} onChange={(e)=>handleUploadFile(e.target.files ? e.target.files[0] : null)}/>
                            </>
                            :
                            <>
                                <div className="addIcon">
                                    <CircularProgress className="DTprogressBackground" variant="determinate" value={100} size={16*3} 
                                        sx={{
                                            color: (theme) =>
                                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                                        }}
                                    />
                                    <CircularProgress className="DTprogress" variant={(uploadProgress===-2||uploadProgress===1)?"indeterminate":"determinate"} value={uploadProgress} size={16*3}/>
                                </div>
                                <span className="addSubtitle">
                                    {uploadProgress===-2?'Compressing file...':null}
                                    {uploadProgress!==-2&&uploadProgress!==1?'Uploading file...':null}
                                    {uploadProgress===1?'Server Processing..':null}
                                </span>
                            </>
                        }
                    </Card>
                </label>
                
            </div>
        </div>
    )
}

export default SettingDT
