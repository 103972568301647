import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import { _language } from 'redux/UtilSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
    _selectedOption,
    setOption,
    _selectedID,
    setDialogOpen
} from 'redux/DetectSlice'
import dic from 'constant/dictionary'

export interface IIconSelectorProps {
    Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string | undefined
        }
    >
    title: string
}

// "#2196F3" // be clicked
// "#BDBDBD" // default
// "#757575" // can be used
// "#D32F2F" // stop action

type TbuttonTheme = '#2196F3' | '#BDBDBD' | '#757575' | '#D32F2F'

const IconSelector = (props: IIconSelectorProps) => {
    const { Icon, title } = props
    // const { setDialogOpen } = useContext(DetectContext)
    const dispatch = useDispatch()
    const language = useSelector(_language)

    const [buttonTheme, setButtonTheme] = useState<TbuttonTheme>('#757575')

    const option = useSelector(_selectedOption)
    const ids = useSelector(_selectedID)

    useEffect(() => {
        const handleTheme = () => {
            if (title === option && title !== dic[language]['detect_task_terminate']) {
                setButtonTheme('#2196F3')
            } else if (ids.length === 0) {
                setButtonTheme('#757575')
            } else if (title === dic[language]['detect_task_terminate']) {
                setButtonTheme('#D32F2F')
            } else {
                setButtonTheme('#BDBDBD')
            }
        }
        handleTheme()
    }, [option, ids])

    const handleClick = (title: string) => {
        handleClickStatus(title)
        handleStopAction(title)
    }

    const handleClickStatus = (title: string) => {
        // The following is commented out for a bug by Mars, but I don't know if it's necessary.        
        // if (option === title) {
        //     dispatch(setOption(''))
        //     return
        // }
        dispatch(setOption(title))
    }

    const handleStopAction = (title: string) => {
        if (ids.length === 0) return
        if (title === dic[language]['detect_task_terminate'] && ids.length !== 0) {
            dispatch(setDialogOpen(true))
        }
    }

    return (
        <Tooltip title={title} arrow>
            <div
                onClick={() => {
                    handleClick(title)
                }}
            >
                <div style={{ width: 35, height: 35, cursor: 'pointer' }}>
                    <Icon style={{ width: '100%', fill: buttonTheme }} />
                </div>
            </div>
        </Tooltip>
    )
}

export default IconSelector
