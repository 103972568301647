import { useLocation } from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/Settings'
import { ReactComponent as SvgLogo } from 'assets/img/eDetctorLogo.svg'
import dic from 'constant/dictionary'
import { useDispatch, useSelector } from 'react-redux'
import { _language, _translatedPermission } from 'redux/UtilSlice'
import { _isGlobalDirty, setGlobalDirty } from 'redux/SettingSlice'
import { useAlert } from 'hook/useAlert'
import { useNavigate } from 'react-router-dom'
import { _userInfo } from 'redux/UtilSlice'
import './index.scss'
import { Stack, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

//TODO:
//no link should appear when 404
const CommonHeader = () => {
    const loc = useLocation()
    const navigate = useNavigate()
    const language = useSelector(_language);
    const dispatch = useDispatch();
    const alert = useAlert().showAlert;
    const isGlobalDirty = useSelector(_isGlobalDirty);
    const userInfo = useSelector(_userInfo);
    const permission = useSelector(_translatedPermission);

    const handleOnClick = (to: string) => {
        if (isGlobalDirty) {
            alert(dic[language]['leave_alert_hint'], () => {
                dispatch(setGlobalDirty(false))
                navigate(to)
            })
        } else {
            navigate(to)
        }
    }

    const HeaderLink = (props: { to: string, displayName: string }) => {
        const location = useLocation()

        if (props.to === '/' && location.pathname === '/analysisDashboard') {
            return (
                <div onClick={() => handleOnClick(props.to)}>
                    <div className="link selected">
                        {props.displayName}
                    </div>
                </div>
            )
        }

        return (
            <div onClick={() => handleOnClick(props.to)}>
                <div className={"link " + (location.pathname === props.to ? 'selected' : '')}>
                    {props.displayName}
                </div>
            </div>
        )
    }

    return (
        <header>
            <div onClick={() => handleOnClick('/')}>
                <SvgLogo className='logoText' />
            </div>
            {
                permission.includes("dashboard")?<HeaderLink to="/" displayName={dic[language]["common_header_dashboard"]} />:null
            }
            {
                permission.includes("detect")?<HeaderLink to="/detect" displayName={dic[language]["common_header_detect"]} />:null
            }
            {
                permission.includes("analysis")?<HeaderLink to="/analysis" displayName={dic[language]["common_header_analysis"]} />:null
            }
            {
                permission.includes("analysis")?<HeaderLink to="/memory" displayName={dic[language]["common_header_memory"]} />:null
            }
            {
                permission.includes("analysis")?<HeaderLink to="/explorer" displayName={dic[language]["common_header_detail"]} />:null
            }
            <Stack direction="row" spacing={1} className="timezone" alignItems="center">
                <AccessTimeIcon />
                <Typography>{userInfo.timezone}</Typography>
            </Stack>
            <div
                onClick={() => handleOnClick("/setting/SettingAgentActions")}
                className={"settingButton" + (loc.pathname.includes('/setting') ? 'Selected' : '')}
            >
                <SettingsIcon className='setting-icon' />
            </div>
        </header>
    )
}

export default CommonHeader
