import { useQuery } from '@tanstack/react-query'
import { axiosClient } from '../util'
import { AxiosError, isAxiosError } from 'axios'

interface IuseFetch {
    root: string
    route: string
}

interface IResponse<T> {
    data: T
}

interface ErrorResponse {
    message: string
}

interface IQueryParams<T> extends IuseFetch {
    query: string
    refetchInterval?: number
    state?: queryKey
    isRefetchOnWindowFocus?: boolean
    enabled?: boolean
}

interface queryKey {
    data: string | boolean | number
}

const FetchData = async (props: IuseFetch) => {
    try {
        const { root, route } = props
        const { data } = await axiosClient.get(`${root}${route}`)
        return data
    } catch (error) {
        if (isAxiosError(error)) {
            const err = error as AxiosError<ErrorResponse>
            // handle error: 4xx -> alert, 5xx -> console.log
            if (err.response?.status && err.response.status < 500) {
                // alert(err.response.data.message)
                throw error;
            } else {
                console.log(err.response?.data.message)
            }
        }
    }
}

export const useGet = <T>(params: IQueryParams<T>) => {
    const {
        query,
        root,
        route,
        refetchInterval,
        state,
        isRefetchOnWindowFocus,
        enabled,
    } = params;
    // const alert = useAlert().showAlert;
    return useQuery<IResponse<T>, AxiosError<ErrorResponse>>(
        [query, state],
        () => FetchData({ root, route }),
        {
            refetchInterval: refetchInterval,
            refetchOnWindowFocus: isRefetchOnWindowFocus,
            enabled: enabled,
        }
    )
}
