import '../../index.scss'
import TableBox from './TableBox'
import { API, urlRoot } from 'constant/index'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import useDenyIfNot from 'hook/useDenyIfNot'

const SettingImage = () => {
    const language = useSelector(_language);
    useDenyIfNot('setting');

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>
                {dic[language]['setting_image']}
            </h2>
            <div className='ChildInnerWhiteContainer'>
                <div className='ImageTable'>
                    <p className='SettingSubTitle'>
                        {dic[language]['setting_image_path_keyword']}
                    </p>
                    <div className='TableBackground'>
                        <TableBox
                            isEditable={true}
                            root={`${urlRoot}`}
                            route={`${API.keyImageCustomized}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingImage
