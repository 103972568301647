import { ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';

export type TContextMenu = {
    mouseX: number
    mouseY: number
    node: any
    name: string,
    downloadEnabled: boolean
    loadDllEnabled?: boolean
    onDownload?: (node: any) => void
    onLoadDLL?: (node: any) => void
}

type TreePageContextMenuProps = {
    contextMenu: TContextMenu | null,
    handleClose: () => void
}

const TreePageContextMenu = (props: TreePageContextMenuProps) => {

    const {contextMenu, handleClose} = props;
    
    const copyJSON = () => {
        navigator.clipboard.writeText(JSON.stringify(contextMenu?.node));
    }

    return (
        
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            componentsProps={{
            root: {
                onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
                }
            }
            }}
            >
            <ListItem divider dense>
                <ListItemText>{contextMenu?.name}</ListItemText>
            </ListItem>
            {
                contextMenu && contextMenu.onDownload?
                    <MenuItem dense disabled={!props.contextMenu?.downloadEnabled} onClick={() => {
                        handleClose();
                        if (contextMenu.onDownload) contextMenu.onDownload(contextMenu.node);
                    }}>
                        <ListItemIcon>
                            <DownloadIcon />
                        </ListItemIcon>
                        <ListItemText>Download File</ListItemText>
                    </MenuItem>
                    :null
            }
            {
                contextMenu && contextMenu.onLoadDLL?
                    <MenuItem dense disabled={!props.contextMenu?.loadDllEnabled} onClick={() => {
                        handleClose();
                        if (contextMenu.onLoadDLL) contextMenu.onLoadDLL(contextMenu.node);
                    }}>
                        <ListItemIcon>
                            <PlayForWorkIcon />
                        </ListItemIcon>
                        <ListItemText>Load DLL</ListItemText>
                    </MenuItem>
                    :null
            }
            <MenuItem dense onClick={() => {
                copyJSON();
                handleClose();
            }}>
                <ListItemIcon>
                    <ContentCopyIcon />
                </ListItemIcon>
                <ListItemText>Copy as JSON</ListItemText>
            </MenuItem>
        </Menu>
    )
}

export default TreePageContextMenu;