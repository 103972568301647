import { useSelector } from "react-redux"
import { Data } from "./TableBox"
import { _language } from "redux/UtilSlice"
import { usePost } from "hook/usePost"
import { Button, Stack, Typography } from "@mui/material"
import dic from "constant/dictionary"
import { API, urlRoot } from "constant"
import FormDialog from "./FormDialog"
import SearchBarWithChips from "../../../../component/SearchBarWithChips"
import { useToast } from "hook/useToast"

interface EnhancedTableToolbarProps {
    selectedRows: readonly string[]
    searchKeywordList: string[]
    setSearchKeywordList: React.Dispatch<React.SetStateAction<string[]>>
    numSelected: number
    rows: Data[]
    isEditable: boolean
    refetchImageTable: () => void
}

function TableToolbar(props: EnhancedTableToolbarProps) {
    const {
        searchKeywordList,
        setSearchKeywordList,
        selectedRows,
        rows,
        isEditable,
        refetchImageTable,
    } = props;
    const language = useSelector(_language);
    const toast = useToast();
    const deleteMutation = usePost();

    const handleDelete = () => {
        deleteMutation.mutate({
            method: 'delete',
            root: `${urlRoot}`,
            route: `${API.keyImage}`,
            body: { ids: selectedRows },
        }, {
            onSuccess: () => {
                refetchImageTable();
                toast.show({content: dic[language]['delete_success']});
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    toast.show({content: dic[language]['delete_fail'] || error.response?.data.message, type: 'error'});
                } else {
                    console.log(error.response?.data.message)
                }
            }
        })
    }

    return (
        <Stack direction='row' alignItems='center' spacing={2}>
            {isEditable && (
                <Typography variant='body1'>
                    {dic[language]['checked']} {selectedRows.length}
                </Typography>
            )}

            <SearchBarWithChips
                searchKeywordList={searchKeywordList}
                setSearchKeywordList={setSearchKeywordList}
                chipLength={4}
            />
            {isEditable && (
                <>
                    <FormDialog
                        titleText={dic[language]['add']}
                        inputLableList={[dic[language]['setting_image_path'], dic[language]['setting_image_keyword']]}
                        confirmText={dic[language]['submit']}
                        rows={rows}
                        refetchImageTable={refetchImageTable}
                    />
                    <Button
                        color="error"
                        onClick={handleDelete}
                        variant="contained"
                    >
                        {dic[language]['delete']}
                    </Button>
                </>
            )}
        </Stack>
    )
}

export default TableToolbar