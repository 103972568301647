import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux"
import { axiosElasticNdjson, extractDoc, flattenDoc } from "../../util";
import { elasticRoot } from "constant";
import { addReportRow, clearReportRows } from "redux/ReportSlice";

const useFetchReportPreselect = () => {

    const dispatch = useDispatch();
    
    const fetchHighRiskNetworkData = useMutation({
        mutationFn: (mutationData: any) => {
            return axiosElasticNdjson.post(
                `${elasticRoot}ed_memory/_msearch`,
                mutationData
            )
        },
        onSuccess: (result) => {
            if (result && result.data && result.data.responses) {
                result.data.responses.forEach((response: any) => {
                    if (response.hits.total.value <= 0) return
                    response.hits.hits.forEach((hit: any) => {
                        dispatch(addReportRow(flattenDoc(hit).uuid))
                    })
                })
            }
        },
        retryDelay: 1000,
    })

    const fetchHighRiskData = useMutation({
        mutationFn: (mutationData: { startTime: number, endTime: number }) => {
            const startTimestamp = Math.floor(mutationData.startTime / 1000)
            const endTimestamp = Math.floor(mutationData.endTime / 1000)
            return axiosElasticNdjson.post(
                `${elasticRoot}_msearch`,
                '{"index": "ed_memory"}\n' + 
                `{"track_total_hits": true,"size": 10000,"query": {"bool": {"filter": [{"range": {"memory.malicious": {"gt": 4}}}, {"range": {"memory.timestamp": {"gte": ${startTimestamp}, "lte": ${endTimestamp}}}}]}}}` + "\n" +
                '{"index": "ed_memory"}\n' +
                `{"track_total_hits": true,"size": 10000,"query": {"bool": {"filter": [{"range": {"memory_network.malicious": {"gt": 4}}}, {"range": {"memory_network.timestamp": {"gte": ${startTimestamp}, "lte": ${endTimestamp}}}}]}}}` + "\n" +
                '{"index": "ed_memory"}\n' +
                `{"track_total_hits": true,"size": 10000,"query": {"bool": {"filter": [{"range": {"memory.riskLevel": {"gt": 1}}}, {"range": {"memory.timestamp": {"gte": ${startTimestamp}, "lte": ${endTimestamp}}}}]}}}` + "\n" +
                '{"index": "ed_explorer"}\n' +
                `{"track_total_hits": true,"size": 10000,"query": {"bool": {"filter": [{"range": {"explorer.yaraRuleHitCount": {"gt": 0}}}, {"range": {"explorer.timestamp": {"gte": ${startTimestamp}, "lte": ${endTimestamp}}}}]}}}` + "\n"
            )
        },
        onSuccess: (result) => {
            if (result && result.data && result.data.responses) {
                dispatch(clearReportRows())
                result.data.responses.forEach((response: any) => {
                    if (response.hits.total.value <= 0) return;
                    if (response.hits.hits[0]._source.category === "memory_network") {
                        console.log(response);
                        let body = ''
                        response.hits.hits.forEach((hit: any) => {
                            const doc = flattenDoc(hit);
                            const temp = `{
                                        "track_total_hits": true,
                                        "size": 10000,
                                        "query": {
                                            "bool" :{
                                                "must": [
                                                    {"term":{"memory.processId":${doc.processId}}},
                                                    {"match":{"agent":"${doc.agent}"}},
                                                    {"term":{"memory.processCreateTime":${doc.processCreateTime}}}
                                                ],
                                                "must_not": [
                                                    {"match":{"memory.mode":"detectNetwork"}}
                                                ]
                                            }
                                        }
                                    }`
                            body += '{}\n' + temp.replaceAll("\n", "").replaceAll(" ", "") + "\n"
                        })
                        fetchHighRiskNetworkData.mutate(body)
                        return;
                    }
                    response.hits.hits.forEach((hit: any) => {
                        dispatch(addReportRow(flattenDoc(hit).uuid))
                    })
                })
            }
        },
        retryDelay: 1000,
    })

    return fetchHighRiskData;

}

export default useFetchReportPreselect;
