import React, { useState } from 'react'
import {
    Box,
    Stack,
    Avatar,
    IconButton,
} from '@mui/material'
import GroupDialog from './GroupDialog'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useAlert } from 'hook/useAlert'
import { useToast } from 'hook/useToast'
import { axiosClient } from 'util/index'
import { urlRoot, API } from 'constant/index'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { DataGridPro, GridColDef, GridRowSelectionModel, gridClasses } from '@mui/x-data-grid-pro'
import { IGroupData } from '../subpage/group/TableBox'

interface IGroupTable {
    tableData: IGroupData[]
    selectedHostsIDs: number[]
    setSelectedId: React.Dispatch<React.SetStateAction<number[]>>
    refetchGroupData: () => void
}

const GroupTable = (props: IGroupTable) => {
    const alert = useAlert().showAlert
    const toast = useToast()
    const {
        tableData,
        selectedHostsIDs,
        setSelectedId,
        refetchGroupData,
    } = props;
    const language = useSelector(_language);
    const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

    const addGroup = async (input: string, color: string) => {
        let isGroupNameRepeat = false
        tableData.forEach((item) => {
            if (item.name === input) {
                isGroupNameRepeat = true
                return
            }
        })
        if (isGroupNameRepeat) {
            alert(dic[language]['setting_group_name_repeat'])
        } else {
            await axiosClient
                .post(`${urlRoot}${API.group}`, {
                    name: input,
                    color: color,
                })
                .then(() => {
                    toast.show({content: dic[language]['add_success']})
                    refetchGroupData()
                })
        }
    }

    const handleDelete = () => {
        alert(dic[language]['setting_group_delete_group_confirm'], async () => {
            const deleteRequests = selectedHostsIDs.map(id =>
                axiosClient.delete(`${urlRoot}${API.group}/${id}`)
            );

            await Promise.all(deleteRequests)
                .then(() => {
                    setSelectedId([]);
                    refetchGroupData();
                    toast.show({
                        content: 
                            dic[language]['setting_group_delete'] +
                            selectedHostsIDs.length +
                            dic[language]['setting_group_delete_unit'],
                    });
                })
                .catch(error => {
                    alert(dic[language]['setting_group_delete_fail'] + error.message);
                });
        })
    }

    // todo : fix width
    const editGroup = async (
        input: string,
        color: string,
        selectedHostsIDs?: number
    ) => {
        selectedHostsIDs
            ? await axiosClient
                .put(`${urlRoot}${API.group}/${selectedHostsIDs}`, {
                    name: input,
                    color: color,
                })
                .then((res) => {
                    refetchGroupData()
                })
            : alert(dic[language]['please_select_group'])
    }

    const groupColumns: GridColDef[] = [
        {
            field: 'name', headerName: dic[language]['group_name'], flex: 1,
            renderCell: ({ value, row }) => {
                return (
                    <Stack direction="row" alignItems='center' spacing={1}>
                        <Avatar
                            sx={{
                                bgcolor: row.color,
                                width: '0.75rem',
                                height: '0.75rem',
                            }}
                        >
                            {' '}
                        </Avatar>
                        <div>{value}</div>
                    </Stack>
                )
            }
        },
        {
            field: 'amount', headerName: dic[language]['agent_count'], flex: 1,
            renderCell: ({ value, row }) => {
                return (
                    <Stack direction="row" justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                        <div>{value}</div>
                        <GroupDialog
                            titleText={dic[language]['setting_group_update_name']}
                            icon={<EditIcon />}
                            confirmAction={editGroup}
                            group={row}
                        />
                    </Stack>
                )
            }
        },
    ]

    return (
        <Box className="GroupTableBox">
            <div className="FlexToolBar">
                <span style={{ fontSize: '16px' }}>
                    {dic[language]['setting_group_list']}
                </span>
                <div style={{ display: 'flex', float: 'right' }}>
                    <GroupDialog
                        titleText={dic[language]['setting_group_add']}
                        icon={<AddIcon />}
                        confirmAction={addGroup}
                    />
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <DataGridPro
                rows={tableData}
                rowHeight={34}
                columnHeaderHeight={42}
                columns={groupColumns}
                hideFooter
                disableRowSelectionOnClick
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setSelectedRows(newRowSelectionModel);
                    setSelectedId(newRowSelectionModel as number[]);
                }}
                rowSelectionModel={selectedRows}
                sx={{
                    maxHeight: 'calc(100% - 4rem)',
                    bgcolor: 'background.default',
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .actionButton": {
                        opacity: 0
                    },
                    [`& .${gridClasses.row}:hover`]: {
                        ".actionButton": {
                            opacity: 1
                        }
                    }
                }}
            />
        </Box>
    )
}

export default GroupTable
