import { IMemoryDropDownData } from 'interface'
import { elasticRoot } from 'constant/index'
import { IAnalaysisIndexNode, indexTree } from 'page/analysis/constant/indexTree'
import { useSelector } from 'react-redux'
import { _analysisData, analysisState } from 'redux/AnalysisSlice'
import { useEffect, useState } from 'react'
import { SortModel } from 'redux/NetworkSlice'

export interface IMutationQuery {
    url: string
    body: any
}

const INTEGER_TYPES = ["pid","parent_pid","processid","startboundary","endboundary","estimatedsize","runcount","eventrecordid","eventid","version","task","opcode","executionprocessid","executionthreadid","of_times_executed","focus_count","slotnum","visit_count","received_bytes","total_bytes","creation_utc","expires_utc","last_access_utc","source_port","parent","content_length","usage_counter","reuse_counter","syncStatus","fetch_count","frequency","table_id","filesize","entry_id","priority","is_local_only","etag","created_in_cloud","record_id","app_id","bytes_sent","bytes_recvd","interfaceluid","TimeToLive","InstanceId","DataLength","Section","Status","Type","dhcp_enabled","ddns_enabled"]

const useElasticQueryFactory = () => {
    
    const analysisData = (useSelector(_analysisData) as analysisState);

    const {
        memoryDropDownData,
        memoryDropDownCheckboxEnabled,
        allFilesDropDownData,
        forensicDropDownData,
        selectedHostsIDs,
        dateRange,
        mainKeyword,
        subKeywordList,
    } = analysisData;

    const [filteredIndexTree, setFilteredIndexTree] = useState<IAnalaysisIndexNode[]>([]);

    const generateChart = (indexList: IAnalaysisIndexNode[] = filteredIndexTree) => {
        let requests = [{}]
        let timeListForChart = generateTimeListForChart(dateRange.startTime, dateRange.endTime)
        let chartUrl = '';
        for (let i = 0; i < timeListForChart.length; i++) {
            let timeSpanQuery = generate(
                indexList,
                timeListForChart[i].startTime,
                timeListForChart[i].endTime,
            )
            chartUrl = timeSpanQuery.url.msearch;
            requests.push(timeSpanQuery.body)
            requests.push({})
        }
        if (timeListForChart.length === 0) {
            let timeSpanQuery = generate(
                indexList,
                dateRange.startTime,
                dateRange.endTime,
            )
            chartUrl = timeSpanQuery.url.msearch;
            requests.push(timeSpanQuery.body)
            requests.push({})
        }            
        return {
            url: chartUrl,
            body: requests.map((req) => JSON.stringify(req)).join('\n') + '\n',
        }
    }

    const generateLeftListCount = () => {
        let requests = [{}]
        let indexList = flattenIndexTreeArray(filteredIndexTree)
        indexList.map((index) => {
            let mutationDataForOneNodeInChart = generate([index])
            // 這邊不適用mutationDataForOneNodeInChart內帶的 url ， 因為搜尋邏輯不同
            requests.push({
                index: mutationDataForOneNodeInChart.url.msearch.split('/')[(elasticRoot as string).split('/').length - 1],
                ignore_unavailable: true,
            })
            requests.push(mutationDataForOneNodeInChart.body)
        })
        requests.push({})
        requests.shift()

        return {
            body: requests.map((req) => JSON.stringify(req)).join('\n') + '\n',
            url: `${elasticRoot}_msearch`,
        }
    }

    const generateTable = (
        indexList: IAnalaysisIndexNode[] = filteredIndexTree,
        lastRow: {lastUUID: string, lastMainDate: number} = {
                lastUUID: '',
                lastMainDate: 0,
            },        
        sort: SortModel = {field: '', sort: 'asc'},
        ) => {
        const content = generate(
            indexList,
            dateRange.startTime,
            dateRange.endTime,
            lastRow,
            sort);
        return {
            url: content.url.search,
            body: content.body
        }
    };

    const generate = (
        filteredIndexTree: IAnalaysisIndexNode[], 
        startTime: number = dateRange.startTime, 
        endTime: number = dateRange.endTime,
        lastRow: {lastUUID: string, lastMainDate: number, lastSort?: any} = {
                lastUUID: '',
                lastMainDate: 0,
            },
        sort: SortModel = {field: '', sort: 'asc'},
        ) => {

        const {lastUUID, lastMainDate} = lastRow;
        
        const indexArr = indexTree2IndexArr(filteredIndexTree);

        const urlIndices = [memoryDropDownData.isMemoryGroupSelected?'ed_memory':undefined,
                            allFilesDropDownData.isAllFilesSelected?'ed_explorer':undefined,
                            forensicDropDownData.isForensicsSelected?'ed_collection':undefined]
                            .filter(index => index !== undefined).join(',');

        const mainSearchKeywordQuery = (function(keyword: string){
            if (!keyword) return [];
            const query = [];
            const numberFilterQuery: any[] = []
            if (parseFloat(keyword)) {
                INTEGER_TYPES.map((item) => {
                    numberFilterQuery.push({
                        term: {
                            [item]: `${keyword}`,
                        },
                    })
                })
            }
            query.push({
                bool: {
                    should: [
                        ...numberFilterQuery,
                        {
                            query_string: {
                                query: `*${keyword}*`,
                                fuzziness: 'AUTO',
                            }
                        },
                    ],
                },
            })
            return query
        })(mainKeyword);

        const subSearchKeywordQuery = (function(keywordList: string[]){
            if (keywordList.length === 0) return [];
            const query = []
            for (let i = 0; i < keywordList.length; i++) {
                const numberFilterQuery: any[] = []
                const stringFilterQuery: any[] = []
                if (parseFloat(keywordList[i])) {
                    INTEGER_TYPES.map((item) => {
                        numberFilterQuery.push({
                            term: {
                                [item]: `${keywordList[i]}`,
                            },
                        })
                    })
                }

                stringFilterQuery.push({
                    query_string: {
                        query: `*${keywordList[i]}*`
                    },
                })

                query.push({
                    bool: {
                        should: [
                            ...numberFilterQuery,
                            ...stringFilterQuery,
                        ],
                    },
                })
            }
            return query
        })(subKeywordList)

        const explorer_filesize_multiplier = allFilesDropDownData.fileUnit === "MB"? 1024*1024 : 1024*1024*1024;

        const forensicQuery = (forensicDropDownData.isForensicsSelected &&
                              indexArr.filter(str => (str!=='ed_memory' && str!=='ed_explorer')).length!==0)?{
            bool: {
                must: [
                    {
                        query_string: {
                            fields: ['_index'],
                            query: 'ed_collection',
                        },
                    },
                    {
                        // 選定的index
                        query_string: {
                            fields: ['category'],
                            query: `${indexArr.map(index => index.replaceAll('ed_', '')).join(' OR ')}`,
                        },
                    }
                ],
            },
        }:undefined;

        const explorerQuery = (allFilesDropDownData.isAllFilesSelected && indexArr.indexOf('ed_explorer') !== -1)?{
            bool: {
                must: [
                    {
                        query_string: {
                            fields: ['_index'],
                            query: `ed_explorer`,
                        },
                    },
                    {
                        query_string: {
                            fields: ['category'],
                            query: `explorer`,
                        },
                    },
                    ...(allFilesDropDownData.fileSize === 'min' && allFilesDropDownData.fileSizeValue === 0? [] : [
                        {
                            query_string: {
                                fields: ['explorer.dataLen'],
                                query: allFilesDropDownData.fileSize === 'min' ?
                                    `[${allFilesDropDownData.fileSizeValue*explorer_filesize_multiplier} TO *]`:
                                    `[* TO ${allFilesDropDownData.fileSizeValue*explorer_filesize_multiplier}]`
                            },
                        },
                    ]),
                    ...(!allFilesDropDownData.onlySearchDeletedFile? [] : [
                        {
                            query_string: {
                                fields: ['explorer.isDeleted'],
                                query: true
                            },
                        },
                    ]),
                ]
            }
        }:undefined;

        const memoryQuery = (memoryDropDownData.isMemoryGroupSelected && indexArr.indexOf('ed_memory') !== -1)?{
            bool: {
                must: [
                    {
                        query_string: {
                            fields: ['_index'],
                            query: 'ed_memory',
                        },
                    },
                    {
                        query_string: {
                            fields: ['category'],
                            query: 'memory',
                        },
                    },
                    ...(memoryDropDownData.processName === ''? []:[{
                        query_string: {
                            fields: ['memory.processName'],
                            query: `*${memoryDropDownData.processName}*`,
                        },
                    }]),
                    ...(memoryDropDownData.processConnectIP === ''? []:[{
                        query_string: {
                            fields: ['memory.processConnectIP'],
                            query: `*${memoryDropDownData.processConnectIP}*`,
                        },
                    }]),
                    ...(memoryDropDownData.dynamicCommand === ''? []:[{
                        query_string: {
                            fields: ['memory.dynamicCommand'],
                            query: `*${memoryDropDownData.dynamicCommand}*`,
                        },
                    }]),
                    ...(memoryDropDownData.processMD5 === ''? []:[{
                        query_string: {
                            fields: ['memory.processMD5'],
                            query: `*${memoryDropDownData.processMD5}*`,
                        },
                    }]),
                    ...(memoryDropDownData.processPath === ''? []:[{
                        query_string: {
                            fields: ['memory.processPath'],
                            query: memoryDropDownData.processPath !== "" && memoryDropDownData.processPath !== undefined ?
                                    `"${memoryDropDownData.processPath.replaceAll('\\','\\\\')}"`:
                                    "**",
                        },
                    }]),
                    ...(memoryDropDownData.parentProcessPath === ''? []:[{
                        query_string: {
                            fields: ['memory.parentProcessPath'],
                            query: `*${memoryDropDownData.parentProcessPath}*`,
                        },
                    }]),
                    ...(memoryDropDownData.digitalSign === ''? []:[{
                        query_string: {
                            fields: ['memory.digitalSign'],
                            query: `*${memoryDropDownData.digitalSign}*`,
                        },
                    }]),
                    ...(memoryDropDownData.importOtherDLL === ''? []:[{
                        query_string: {
                            fields: ['memory.importOtherDLL'],
                            query: `*${memoryDropDownData.importOtherDLL}*`,
                        },
                    }]),
                    ...(memoryDropDownData.processId === ''? []:[{
                        query_string: {
                            fields: ['memory.processId'],
                            query: `${memoryDropDownData.processId}`,
                        },
                    }]),
                    ...memoryDropDownCheckboxEnabled
                        .map((ele:(keyof IMemoryDropDownData)) => {
                            if (memoryDropDownCheckboxEnabled.includes(ele)) {
                                return {
                                    query_string: {
                                        fields: ["memory."+ele],
                                        //@ts-ignore
                                        query: `${memoryDropDownData[ele].join(' OR ')}`,
                                    },
                                }
                            }
                            return null;
                        })
                        .filter(ele => ele !== null)
                ],
            },
        }:undefined;

        const isSorting = !sort||sort.field===''||!sort.field;

        return{
            url: {
                search: `${elasticRoot}${urlIndices}/_search`,
                msearch: `${elasticRoot}${urlIndices}/_msearch`
            },
            body: {
                track_total_hits: true,
                size: 1000,
                query: {
                    bool: {
                        must: [
                            {
                                // 選定日期
                                query_string: {
                                    fields: ['date_main'],
                                    query: `[${startTime / 1000 } TO ${endTime / 1000 }] OR 0`,
                                },
                            },
                            {
                                // 選定主機
                                query_string: {
                                    fields: ['agent'],
                                    query: `${selectedHostsIDs.join(' OR ')}`,
                                },
                            },
                            // 加入全域搜尋主關鍵字
                            ...mainSearchKeywordQuery,
                            // 加入全域搜尋子關鍵字
                            ...subSearchKeywordQuery,
                            {
                                bool: {
                                    should: [forensicQuery, memoryQuery, explorerQuery].filter(ele => ele !== undefined)
                                }
                            }
                        ]
                    }
                },
                search_after: lastUUID === ''?undefined:[
                    ...(isSorting?[]: [lastRow.lastSort]),
                    lastMainDate,lastUUID
                ],
                sort: [
                    ...(isSorting?[]: [{[sort.field]: sort.sort}]),
                    {date_main: "desc"},{uuid: "desc"}
                ]
            }
        }
    }

    useEffect(() => {
        filterSelectedIndex();
    }, [
        memoryDropDownData,
        memoryDropDownCheckboxEnabled,
        allFilesDropDownData,
        forensicDropDownData
    ])

    const filterSelectedIndex = () => {
    
        let filterdIndexTree:IAnalaysisIndexNode[] = [];
        if (memoryDropDownData.isMemoryGroupSelected) filterdIndexTree.push((JSON.parse(JSON.stringify(indexTree[0])) as IAnalaysisIndexNode));
        if (allFilesDropDownData.isAllFilesSelected) filterdIndexTree.push((JSON.parse(JSON.stringify(indexTree[2])) as IAnalaysisIndexNode));
        
        if (forensicDropDownData.isForensicsSelected) {
    
            const forensicTree = indexTree[1];
            const filterSelectedForensicNode = (node: IAnalaysisIndexNode) => {
                const clonedNode:IAnalaysisIndexNode = JSON.parse(JSON.stringify(node));
                clonedNode.children = [];
                for (let _node of node.children) {
                    const _nodeName = _node.type === 'item' ? _node.name.tableName: _node.name.groupName;
                    if (forensicDropDownData[_nodeName]){
                        clonedNode.children.push(filterSelectedForensicNode(_node));
                    }
                }
                return clonedNode;
            }
            filterdIndexTree.push(filterSelectedForensicNode(forensicTree));
        }
        
        setFilteredIndexTree(filterdIndexTree);
    }

    return { generateChart, generateTable, generateLeftListCount, filteredIndexTree }
}

export default useElasticQueryFactory;

function indexTree2IndexArr(_indexTree: IAnalaysisIndexNode[]):string[] {
    let res:string[] = [];
    for (let i = 0; i < _indexTree.length; i++) {
        let node = _indexTree[i];
        if (node.type === 'item') {
            res.push(node.name.tableName)
        } else {
            res = res.concat(indexTree2IndexArr(node.children))
        }
    }
    return res;
}

function flattenIndexTreeArray(_indexTree: IAnalaysisIndexNode[]):IAnalaysisIndexNode[] {
    let res:IAnalaysisIndexNode[] = [];
    for (let i = 0; i < _indexTree.length; i++) {
        let node = _indexTree[i];
        if (node.type === 'item') {
            res.push(node)
        } else {
            res = res.concat(flattenIndexTreeArray(node.children))
        }
    }
    return res;
}

interface ITimeNode {
    startTime: number
    endTime: number
}

export function generateTimeListForChart(
    startTime: number,
    endTime: number
): ITimeNode[] {
    const h16 = 16 * 60 * 60;

    const range = endTime - startTime
    const timeNodeArr: ITimeNode[] = []
    let len = 0;
    if (range <= h16) {
        len = 60 * 60;
    } else {
        len = range / 16;
    }
    for (let i = 0; i < range; i += len) {
        const pointer = startTime + i
        const pointerEnd = Math.min(pointer + len, endTime)
        const timeNode: ITimeNode = {
            startTime: Math.floor(pointer),
            endTime: Math.floor(pointerEnd),
        }

        timeNodeArr.push(timeNode)

        if (pointerEnd === endTime) {
            break
        }
    }

    return timeNodeArr
}