import { useState } from "react";

const useRerender = () => {
    const [RR, SRR] = useState(false);

    return {
        rerender: () => SRR(!RR)
    }
}

export default useRerender;