import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from './store'

export interface authState {
    token: string,
    isLogin: boolean
}

const initialState: authState = {
    token: '',
    isLogin: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        login: (state: authState, action: PayloadAction<string>) => {
            return {
                token: action.payload,
                isLogin: true
            }
        },
        // actual logout logic is written in store.ts
        logout: (state: authState) => {
            return state;
        }
    }
});

export const { login, logout } = authSlice.actions
export const _token = (state: RootState) => state.auth.token;
export const _isLogin = (state: RootState) => state.auth.isLogin;

export default authSlice.reducer;
