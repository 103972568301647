import { useSelector } from 'react-redux'
import './ForensicsDropSelector.css'
import ItemInForensicsDropSelector from './ItemInForensicsDropSelector'
import { _analysisData } from 'redux/AnalysisSlice'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import TDictionaryKeys from 'constant/dict.type'
import edTableGroup, { edGroup } from 'constant/edTableGroup'

export type forensicDropdownOption = Exclude<edGroup, 'ed_collection'>

export const itemList:{[key in forensicDropdownOption]: edTableGroup[][]} = {
    group_browsing_history_and_bookmarks: [
        [
            'ed_chromehistory',
            'ed_chromedownload',
            'ed_chromekeywordsearch',
            'ed_chromelogin',
            'ed_chromebookmarks',
        ],
        [
            'ed_edgehistory',
            'ed_iehistory',
            'ed_edgelogin',
            'ed_ielogin',
            'ed_edgebookmarks',
        ],
        [
            'ed_firefoxhistory',
            'ed_firefoxlogin',
            'ed_firefoxbookmarks',
        ],
    ],
    group_cache_and_cookie_history: [
        ['ed_chromecache', 'ed_chromecookies'],
        ['ed_edgecache', 'ed_edgecookies'],
        ['ed_iecache'],
        ['ed_firefoxcache', 'ed_firefoxcookies'],
    ],
    group_current_network_connection_history: [
        [
            'ed_netadapters',
            'ed_wireless',
            'ed_networkresources',
        ],
    ],
    group_recently_executed_software_log:[
        [
            'ed_installedsoftware',
            'ed_service',
            'ed_baseservice',
            'ed_startrun',
            'ed_remotedesktoplogin',
            'ed_jumplist',
            'ed_systeminfo',
            'ed_muicache',
            'ed_prefetch',
            'ed_eventapplication',
            'ed_taskschedule',
            'ed_userassist',
            'ed_networkdatausagemonitor',
            'ed_appresourceusagemonitor',
            'ed_dnsinfo',
        ],
    ],
    group_evanescent_records: [
        [
            'ed_process',
            'ed_openedfiles',
            'ed_network',
            'ed_arpcache',
        ],
    ],
    group_recently_opened_file_history: [
        [
            'ed_shortcuts',
            'ed_userprofiles',
            'ed_windowsactivity',
            'ed_shellbags',
            'ed_recentfile',
        ],
    ],
    group_usb_usage_record: [['ed_usbdevices', 'ed_eventsystem', 'ed_eventsecurity']],
    group_email_list_records: [['ed_emailpath', 'ed_email']]
}

const ItemContainerInForensicsDropSelector= (props: {containerName: edGroup}) => {

    const language = useSelector(_language);

    let map = itemList[(props.containerName as keyof typeof itemList)];
    return (
        <div>
            <div>{dic[language][props.containerName]}</div>
            {map.map((item, i) => {
                return (
                    <div className="forensicsSelectPage" key={i}>
                        {item.map((cell) => {
                            return (
                                <ItemInForensicsDropSelector
                                    key={cell}
                                    parentName={props.containerName}
                                    title={cell}
                                />
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}
export default ItemContainerInForensicsDropSelector
