import Checkbox from '@mui/material/Checkbox'
import './MemoryDropDownSelector.scss'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, changeMemoryDropDownSelected, toggleMemoryDropDownCheckbox } from 'redux/AnalysisSlice'
import { Accordion, AccordionSummary, AccordionDetails, TextField, Switch } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IMemoryDropDownData } from 'interface'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

const MemoryDropDownSelectorInputField = (props:{
    label: string,
    title: keyof IMemoryDropDownData,
}) => {
    const {label, title} = props;
    const dispatch = useDispatch();
    const { memoryDropDownData } = useSelector(_analysisData);

    return (
    <div className="memoryDropDownSelectorInputField">
        <span>{label} :</span>
        <TextField
            size="small"
            //@ts-ignore
            value={memoryDropDownData[title]}
            onChange={(e) => {
                dispatch(changeMemoryDropDownSelected({
                    title: title,
                    value: e.target.value
                }))
            }}
        />
    </div>
    )
}

const MemoryDropDownSelectorMultiSelect = (props:{
    label: string,
    title: keyof IMemoryDropDownData,
    options: Array<{
        label: string,
        value: string | number | null
    }>
}) => {

    const {label, title, options} = props;
    const dispatch = useDispatch();
    const language = useSelector(_language);
    const { memoryDropDownData, memoryDropDownCheckboxEnabled } = useSelector(_analysisData);

    return(
        <div className="memoryDropDownSelectorMultiSelect">
            <Switch
                checked={memoryDropDownCheckboxEnabled.includes(title)}
                onChange={e => {
                    dispatch(toggleMemoryDropDownCheckbox({
                        title: title,
                        state: e.target.checked
                    }))
                }}
            />
            <span className='label'>{label+" :"}</span>
            <div className="selections">
                {options.map((option, index) => {
                    return (
                        <div key={index}>
                            <Checkbox
                                disabled={!memoryDropDownCheckboxEnabled.includes(title)}
                                className='checkbox'
                                size="small"
                                checked={
                                    //@ts-ignore
                                    memoryDropDownData[title]?.filter(
                                        (item:string) => item === option.value
                                    ).length !== 0
                                }
                                name={title}
                                value={option}
                                onChange={(e) => {
                                    dispatch(changeMemoryDropDownSelected({
                                        title: title,
                                        value: (option.value as string)
                                    }))
                                }}
                            />
                            {option.label}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const MemoryDropDownSelector = () => {
    const language = useSelector(_language);
    const dispatch = useDispatch();

    return (
        <div id="memoryDropDownSelector">
            <Button
                variant="contained"
                onClick={() =>
                    dispatch(changeMemoryDropDownSelected({title: 'deleteAll'}))
                }
            >
                {dic[language]['clear']}
            </Button>
            <h3 style={{ marginBottom: 5, marginTop: 5 }}>
                {dic[language]['process_param']}
            </h3>

            <div id="memoryDropDownSelectorList">
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["process_name"]}
                    title={'processName'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["process_connect_ip"]}
                    title={'processConnectIP'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["dynamic_command"]}
                    title={'dynamicCommand'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["process_md5"]}
                    title={'processMD5'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["process_path"]}
                    title={'processPath'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["parent_process_path"]}
                    title={'parentProcessPath'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["digital_sign"]}
                    title={'digitalSign'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["import_other_dll"]}
                    title={'importOtherDLL'}
                />
                <MemoryDropDownSelectorInputField 
                    label={dic[language]["process_id"]}
                    title={'processId'}
                />
            </div>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3 style={{margin: 0}}>{dic[language]["analysis_risk_level"]}</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div id="memoryDropDownSelector2">
                        <MemoryDropDownSelectorMultiSelect 
                            label={dic[language]["analysis_risk_level"]}
                            title={'riskLevel'}
                            options={[
                                {label: '4', value: 4}, 
                                {label: '3', value: 3},
                                {label: '2', value: 2},
                                {label: '1', value: 1}
                            ]}
                        />
                        <MemoryDropDownSelectorMultiSelect 
                            label={dic[language]["analysis_inject_active"]}
                            title={'injectActive'}
                            options={[
                                {label: 'PE', value: '1,0'},
                                {label: dic[language]['non_pe_code'], value: '0,1'},
                                {label: dic[language]['havent'], value: '0,0'},
                            ]}
                        />
                        <MemoryDropDownSelectorMultiSelect 
                            label={dic[language]["analysis_process_be_injected"]}
                            title={'processBeInjected'}
                            options={[
                                {label: dic[language]['have'], value: 1},
                                {label: dic[language]['havent'], value: 0}
                            ]}
                        />
                        <MemoryDropDownSelectorMultiSelect 
                            label={dic[language]["analysis_boot"]}
                            title={'boot'}
                            options={[
                                {label: 'AutoRun', value: '0,1'},
                                {label: 'Service', value: '1,0'},
                                {label: dic[language]['havent'], value: '0,0'},
                            ]}
                        />
                        <MemoryDropDownSelectorMultiSelect 
                            label={dic[language]["analysis_hook"]}
                            title={'hook'}
                            options={[
                                {label: dic[language]['have'], value: '*'},
                                {label: dic[language]['havent'], value: null}
                            ]}
                        />
                        <MemoryDropDownSelectorMultiSelect 
                            label={dic[language]["analysis_hide"]}
                            title={'hide'}
                            options={[
                                {label: 'Process', value: '1,0'},
                                {label: 'File', value: '0,1'},
                                {label: dic[language]['havent'], value: '0,0'}
                            ]}
                        />
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default MemoryDropDownSelector
