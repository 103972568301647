import { useState } from 'react'
import YaraRule from './YaraRule'
import ImportTags from './ImportTags'
import VirusTotal from './VirusTotal'
import { Tabs, Tab } from '@mui/material'
import '../../index.scss'
import { _language } from 'redux/UtilSlice'
import { useSelector, useDispatch } from 'react-redux'
import dic from 'constant/dictionary'
import { useAlert } from 'hook/useAlert'
import { _isGlobalDirty, setGlobalDirty } from 'redux/SettingSlice'
import useDenyIfNot from 'hook/useDenyIfNot'

const SettingIoC = () => {
    const [value, setValue] = useState(0);
    const language = useSelector(_language);
    const isGlobalDirty = useSelector(_isGlobalDirty);
    const { showAlert } = useAlert();
    const dispatch = useDispatch();
    useDenyIfNot('setting');

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (isGlobalDirty) {
            showAlert(
                dic[language]['leave_alert_hint'],
                () => {
                    setValue(newValue);
                    dispatch(setGlobalDirty(false));
                },
            );
            return;
        }
        setValue(newValue);
    };

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>IoC</h2>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
            >
                <Tab
                    label="YARA"
                    sx={{ textTransform: 'none' }}
                />
                <Tab
                    label='VirusTotal'
                    sx={{ textTransform: 'none' }}
                />
                {/* <Tab
                    label={dic[language]['setting_import_tags']}
                    sx={{ textTransform: 'none' }}
                /> */}
            </Tabs>
            {value === 0 && <YaraRule />}
            {value === 1 && <VirusTotal />}
            {/* {value === 2 && <ImportTags />} */}
        </div>
    )
}

export default SettingIoC
