import { IFormatedDevice } from 'interface'
import {
    ActionPlugTitle,
} from '../../StyledComponents'
import ActionButton from './CommonComponents/ActionButton'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { useState } from 'react'
import { Button } from '@mui/material'
import ImagesDialog from '../../ImagesDialog'
import { _selectedID } from 'redux/DetectSlice'

interface ActionISOProps {
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
}

const ActionISO = (props: ActionISOProps) => {
    const { setData, data } = props;
    const language = useSelector(_language);
    const [openDialog, setOpenDialog] = useState(false);
    const ids = useSelector(_selectedID);

    const dialogClose = () => {
        setOpenDialog(false);
    }

    return (
        <div className='actionPlugContainer'>
            <ActionPlugTitle>
                {dic[language]['detect_task_type_image']}
            </ActionPlugTitle>
            <Button onClick={() => setOpenDialog(true)} variant='contained' disabled={ids.length === 0}>
                {dic[language]['detect_task_image_action']}
            </Button>
            <ImagesDialog open={openDialog} onClose={dialogClose} data={data} setData={setData} />
        </div>
    )
}

export default ActionISO
