import TableBox from './TableBox'
import '../../index.scss'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import useDenyIfNot from 'hook/useDenyIfNot'

const SettingGroup = () => {
    const language = useSelector(_language);
    useDenyIfNot('setting');

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>
                {dic[language]['setting_group']}
            </h2>
            <TableBox />
        </div>
    )
}

export default SettingGroup
