import CommonHeader from "component/CommonHeader"

interface IError404Page {
    errorMessage?: string
}

const Error404Page = ({ errorMessage }: IError404Page) => {
    errorMessage = errorMessage ?? '錯誤通知'

    return (
        <div style={{ height: '100vh' }}>
            <p style={{ textAlign: 'center' }}>
                {errorMessage}
            </p>
        </div>
    )
}

export default Error404Page
