import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DateSelector from '../DateSelector/DateSelector'
import './DateAndTimeSelector.css'
import TimeSelector from '../TimeSelector/TimeSelector'
import TextField from '@mui/material/TextField'
import { ITimeForm } from 'interface'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { FormControl } from '@mui/material'
import Popover from '@mui/material/Popover'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { RootState } from 'redux/store'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

type IselectedModule = 'date' | 'time'

interface DateAndTimeSelectorProps {
    timeSelector: (state: RootState) => ITimeForm,
    timeSetterDispatcher:
    ActionCreatorWithPayload<ITimeForm, "detect/setScheduleForensicsTime"> |
    ActionCreatorWithPayload<ITimeForm, "detect/setScheduleDownloadTime">
}

const DateAndTimeSelector = (props: DateAndTimeSelectorProps) => {
    const [selectedModule, setSelectedModule] =
        useState<IselectedModule>('date')
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const open = Boolean(anchorEl)

    const dispatch = useDispatch()
    const language = useSelector(_language)
    const time = useSelector(props.timeSelector)

    // focus 時跳出日期、時間選擇器
    const handleFocus = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }
    // 隱藏日期、時間選擇器
    const handleBlur = () => {
        console.log(open)
        setAnchorEl(null)
    }

    // 控制日期選擇器上點擊日期時
    const handleSelectDate = (param: number) => {
        dispatch(props.timeSetterDispatcher({ ...time, date: param }))
    }

    // 控制日期選擇器上點擊時間時要執行的事件
    const handleSelectedTime = (param: number) => {
        dispatch(props.timeSetterDispatcher({ ...time, time: param }))
    }

    return (
        <div
            style={{
                width: '320px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <FormControl
                sx={{ minWidth: 150, maxWidth: 200, margin: '3px' }}
                size="small"
            >
                <TextField
                    size="small"
                    id="date"
                    label={dic[language]['detect_schedule_date']}
                    multiline
                    value={time.date}
                    onClick={(e) => {
                        handleFocus(e)
                        setSelectedModule('date')
                    }}
                />
            </FormControl>

            <FormControl
                sx={{ minWidth: 150, maxWidth: 200, margin: '3px' }}
                size="small"
            >
                <TextField
                    size="small"
                    id="time"
                    label={dic[language]['detect_schedule_time']}
                    multiline
                    onClick={(e) => {
                        handleFocus(e)
                        setSelectedModule('time')
                    }}
                    value={`${time.time}:00`}
                />
            </FormControl>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleBlur}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {selectedModule === 'date' && (
                    <DateSelector
                        dateProps={time.date}
                        onDateSelectFunc={handleSelectDate}
                    />
                )}
                {selectedModule === 'time' && (
                    <TimeSelector
                        childProps={time.time}
                        onParamChange={handleSelectedTime}
                    ></TimeSelector>
                )}
                <Tabs
                    value={selectedModule}
                    onChange={(e) => { setSelectedModule(e.currentTarget.ariaLabel as IselectedModule) }}
                    aria-label="icon tabs example"
                    variant='fullWidth'
                >
                    <Tab
                        value="date"
                        aria-label="date"
                        onClick={() => setSelectedModule('date')}
                        icon={<DateRangeIcon
                            fontSize="small"
                            color={
                                selectedModule === 'date'
                                    ? 'primary'
                                    : 'action'
                            }
                        />}
                    />
                    <Tab
                        value="time"
                        aria-label="time"
                        onClick={() => setSelectedModule('time')}
                        icon={<AccessTimeIcon
                            fontSize="small"
                            color={
                                selectedModule === 'time'
                                    ? 'primary'
                                    : 'action'
                            }
                        />}
                    />
                </Tabs>
            </Popover>
        </div>
    )
}

export default DateAndTimeSelector