import ActionPlug from './ActionPlug/ActionPlug'
import IconSelector, { IIconSelectorProps } from './IconSelector'
import ActionPlugCollect from './ActionPlug/ActionPlugCollect'
import ActionPlugFile from './ActionPlug/ActionPlugFile'
import ActionISO from './ActionPlug/ActionISO'
import ActionYara from './ActionPlug/ActionYara'
import MissionAlert from './MissionAlert'
import { useSelector } from 'react-redux'
import { _dialogOpen, _selectedOption } from 'redux/DetectSlice'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { IFormatedDevice } from 'interface'
import assets from 'assets'
import ActionMemoryTree from './ActionPlug/ActionMemoryTree'

interface HorizontalBarProps {
    setData: React.Dispatch<React.SetStateAction<IFormatedDevice[]>>
    data: any[]
    ubuntuNumber: number
}

const HorizontalBar = (props: HorizontalBarProps) => {
    const { setData, data, ubuntuNumber } = props
    const dialogOpen = useSelector(_dialogOpen)
    const language = useSelector(_language)
    const option = useSelector(_selectedOption)
    const HeaderIcon: IIconSelectorProps[] = [
        { Icon: assets.MemoryIcon, title: dic[language]['detect_task_type_memory'] },
        { Icon: assets.EyeIcon, title: dic[language]['detect_task_type_collect'] },
        { Icon: assets.FileIcon, title: dic[language]['detect_task_type_drive'] },
        { Icon: assets.IsoIcon, title: dic[language]['detect_task_type_image'] },
        { Icon: assets.yaraRuleIcon, title: dic[language]['detect_task_type_yara']},
        { Icon: assets.Tree, title: dic[language]['detect_task_type_memory_tree'] },
        { Icon: assets.StopIcon, title: dic[language]['detect_task_terminate'] },
    ]

    return (
        <div className='horizontalBarContainer'>
            <div style={{ display: 'flex', minWidth: 200, marginLeft: 10 }}>
                {HeaderIcon.map((item, index) => {
                    return (
                        <IconSelector
                            key={index}
                            Icon={item.Icon}
                            title={item.title}
                        />
                    )
                })}
            </div>

            {dialogOpen && <MissionAlert data={data} setData={setData} />}

            {option === dic[language]['detect_task_type_memory'] && <ActionPlug data={data} setData={setData} />}
            {option === dic[language]['detect_task_type_collect'] && <ActionPlugCollect data={data} setData={setData} ubuntuNumber={ubuntuNumber} />}
            {option === dic[language]['detect_task_type_drive'] && <ActionPlugFile data={data} setData={setData} />}
            {option === dic[language]['detect_task_type_image'] && <ActionISO data={data} setData={setData} />}
            {option === dic[language]['detect_task_type_yara'] && <ActionYara data={data} setData={setData} />}
            {option === dic[language]['detect_task_type_memory_tree'] && <ActionMemoryTree data={data} setData={setData} />}
        </div>
    )
}

export default HorizontalBar
