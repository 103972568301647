// TODO: don't clean data when receive response, leave it to data grid value getter and formatter in dialog
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { API, urlRoot } from 'constant/index'
import { useEffect, useState } from 'react'
import { useGet } from 'hook/useGet'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import { DataGridPro, GridColDef, GridRowSelectionModel, gridClasses } from '@mui/x-data-grid-pro'
import HoverButton from './TableBox/Component/HoverButton'
import { Stack } from '@mui/material'
import { IRawGroupData } from 'interface'
import useDenyIfNot from 'hook/useDenyIfNot'
import TDictionaryKeys from 'constant/dict.type'

export interface PermissionData {
    id: number
    userID: number
    department: string
    username: string
    email: string
    password: string
    timezone: string
    permission: number // mask to permission array [setting, user, detect, analysis, dashboard]
    status: boolean
    isroot: boolean
    groups: string[]
}

const SettingUserPermission = () => {

    useDenyIfNot('user');

    const language = useSelector(_language);
    const [rows, setRows] = useState<PermissionData[]>([]);
    const [searchKeywordList, setSearchKeywordList] = useState<string[]>([]);
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [groupList, setGroupList] = useState<string[]>([]);

    const {
        data: groupListData,
        isSuccess: isGroupListIsSuccess,
    } = useGet<IRawGroupData[]>({
        query: 'groupList',
        root: `${urlRoot}`,
        route: `${API.group}`,
    });

    useEffect(() => {
        if (isGroupListIsSuccess && groupListData.data) {
            const groupList = groupListData.data.map((item) => item.name) || [];
            groupList.unshift('All');
            setGroupList(groupList);
        }
    }, [isGroupListIsSuccess, groupListData]);

    const {
        data: defaultuserPermissionList,
        isSuccess: isDefaultUserPermissionListSuccess,
        refetch
    } = useGet<PermissionData[]>({
        query: 'userPermission',
        root: `${urlRoot}`,
        route: `${API.user}`,
    });

    function createData(item: PermissionData): PermissionData {

        return {
            ...item,
            id: item.userID,
            password: "        ",
        }
    }

    const maskPermission = (permission: number): string => {
        type TPermissionMapping = {value: number, key: TDictionaryKeys}[];
        const permissionMapping:TPermissionMapping = [
            { value: 16, key: 'setting_user_permission_setting' },
            { value: 8, key: 'setting_user_permission_user' },
            { value: 4, key: 'setting_user_permission_detect' },
            { value: 2, key: 'setting_user_permission_analysis' },
            { value: 1, key: 'setting_user_permission_dashboard' },
        ];

        return permissionMapping
            .filter(({ value }) => permission & value)
            .map(({ key }) => dic[language][key])
            .join(', ');
    };

    useEffect(() => {
        if (!isDefaultUserPermissionListSuccess || !defaultuserPermissionList.data) return;
        if (searchKeywordList.length === 0) {
            setRows((defaultuserPermissionList?.data || []).map(createData) || [])
            return;
        }

        // Only search for the devices that contain all the keywords
        const newRows = defaultuserPermissionList?.data?.filter((item) => {
            return searchKeywordList.every((keyword) => {
                return Object.values(item).some((value) => {
                    if (typeof value === 'boolean') {
                        return value ?
                            dic[language]['setting_user_activate'].includes(keyword) :
                            dic[language]['setting_user_deactivate'].includes(keyword);
                    }
                    return value.toString().includes(keyword);
                })
            })
        })
        setRows(newRows?.map(createData) || []);
    }, [searchKeywordList, isDefaultUserPermissionListSuccess, defaultuserPermissionList, language])

    const userPermissionColumns: GridColDef[] = [
        { field: 'department', headerName: dic[language]['setting_user_department'], flex: 1 },
        { field: 'username', headerName: dic[language]['setting_user_username'], flex: 1 },
        { field: 'email', headerName: dic[language]['setting_user_email'], flex: 1 },
        { field: 'timezone', headerName: dic[language]['setting_user_timezone'], flex: 1 },
        {
            field: 'permission',
            headerName: dic[language]['setting_user_permission_header'], flex: 2,
            valueGetter: (params) => maskPermission(params.row.permission)
        },
        {
            field: 'status', headerName: dic[language]['setting_user_status'], flex: 1,
            renderCell: ({ value, row }) => {
                return (
                    <Stack direction="row" justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
                        <div>{value ? dic[language]['setting_user_activate'] : dic[language]['setting_user_deactivate']}</div>
                        <HoverButton data={row} listRefreshFn={refetch} groupList={groupList} />
                    </Stack>
                )
            }
        },
    ];

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>
                {dic[language]['setting_user_permission']}
            </h2>
            <div className='ChildInnerWhiteContainer'>
                <EnhancedTableToolbar
                    selectedList={rowSelectionModel as readonly string[]}
                    listRefetchFn={refetch}
                    searchKeywordList={searchKeywordList}
                    setSearchKeywordList={setSearchKeywordList}
                    groupList={groupList}
                />
                <DataGridPro
                    rows={rows}
                    rowHeight={34}
                    columnHeaderHeight={42}
                    columns={userPermissionColumns}
                    hideFooterSelectedRowCount
                    disableRowSelectionOnClick
                    checkboxSelection
                    pagination
                    initialState={{ pagination: { paginationModel: { pageSize: 25, page: 0 } } }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    sx={{
                        maxHeight: 'calc(100% - 5rem)',
                        bgcolor: 'background.default',
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .actionButton": {
                            opacity: 0
                        },
                        [`& .${gridClasses.row}:hover`]: {
                            ".actionButton": {
                                opacity: 1
                            }
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default SettingUserPermission
