import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import CusDialog from './Dialog'
import { PermissionData } from '../..'
import { IconButton } from '@mui/material'

interface IHoverButton {
    data: PermissionData
    listRefreshFn: () => void
    groupList: string[]
}

const HoverButton = (props: IHoverButton) => {
    const { data, listRefreshFn, groupList } = props
    const [open, setOpen] = useState(false);

    const openDialog = () => {
        setOpen(true);
    };

    return (
        <>
            <IconButton className="actionButton" onClick={openDialog}>
                <EditIcon fontSize="small" />
            </IconButton>
            <CusDialog open={open} setOpen={setOpen} data={data} groupList={groupList} listRefetchFn={listRefreshFn} />
        </>
    );
}

export default HoverButton