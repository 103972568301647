import Parent from './Child/Parent'
import '../../index.scss'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'
import useDenyIfNot from 'hook/useDenyIfNot'

const SettingSystem = () => {
    const language = useSelector(_language);
    useDenyIfNot('setting');

    return (
        <div className='ChildContainer'>
            <h2 className='ChildContainerTitle'>
                {dic[language]["setting_system"]}
            </h2>
            <Parent />
        </div>
    )
}

export default SettingSystem

// 主機伺服器與信箱 常用功能 AGENT設定 AGENT更新 AGENT派送 介接設定
