import { AxiosResponse } from "axios";

interface DownloadFileProps {
    readonly apiDefinition: (apiRoute: string) => Promise<AxiosResponse<Blob>>;
    readonly preDownloading?: (param?: any) => void;
    readonly postDownloading?: (param?: any) => void;
    readonly onError: (error: unknown, param?: any) => void;
}

interface DownloadedFileInfo {
    readonly download: (apiRoute: string, fileName: string, param?: any) => Promise<void>;
}

const useDownloadFile = ({
    apiDefinition,
    preDownloading,
    postDownloading,
    onError
}: DownloadFileProps): DownloadedFileInfo => {
    const download = async (apiRoute: string, fileName: string, param?: any) => {
        try {
            if (param !== undefined){
                preDownloading && preDownloading(param);
            } else {
                preDownloading && preDownloading();
            }
            const { data } = await apiDefinition(apiRoute);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            if (param !== undefined){
                postDownloading && postDownloading(param);
            } else {
                postDownloading && postDownloading();
            }
            window.URL.revokeObjectURL(url);
        } catch (error) {
            onError(error, param);
        }
    };

    return { download };
};

export default useDownloadFile;