import { MouseEvent, useEffect, useRef, useState } from 'react';
import { _language } from 'redux/UtilSlice';
import { useDispatch, useSelector } from 'react-redux';
import dic from 'constant/dictionary';
import { usePost } from 'hook/usePost';
import useFetchElasticDetail from 'page/analysis/useFetchElasticDetail';
import { _analysisData, setRightBar } from 'redux/AnalysisSlice';
import { AnalysisRightBarRowFactory } from './RightBarRowFactory';
import Markdown from 'react-markdown';
import PartialLoading from 'component/PartialLoading';
import { API, urlRoot } from 'constant';
import { IconButton, Tab, Tooltip } from '@mui/material';
import { TabPanel, TabContext, TabList } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IRightBarProps{
    searchKeywordList: any
    closeButton?: React.ReactNode
    open: boolean
}

type gptAnalysis = {
    report: string
    isSuccess: boolean
}

export const RightBar = (props: IRightBarProps) => {
    const { open } = props;
    const language = useSelector(_language);
    let originalX = useRef<number>(0);
    const [lastX, setLastX] = useState<number>(0);
    const lastWidth = useRef<number>(350);

    const analysis = useSelector(_analysisData);
    const dispatch = useDispatch();

    const fetchElasticDetail = useFetchElasticDetail();
    const fetchGPTAnalysis = usePost<gptAnalysis>();

    const onDragStart = (e: MouseEvent<HTMLDivElement>) => {
        originalX.current = e.clientX;
        setLastX(e.clientX);
    };

    const onDrag = (e: MouseEvent<HTMLDivElement>) => {
        if (e.clientX === 0) return;
        setLastX(e.clientX);
    };

    const onDragEnd = (e: MouseEvent<HTMLDivElement>) => {
        if (e.clientX === 0) return;
        lastWidth.current = calcWidth();
    };

    const [gptAnalysisResult, setGptAnalysisResult] = useState<gptAnalysis>({
        report: '',
        isSuccess: false
    });

    useEffect(() => {
        fetchGPTAnalysis.reset();
        fetchElasticDetail.mutate();
    }, [analysis.rightBar.uuid])

    useEffect(() => {
        if (fetchElasticDetail.status === 'success' && analysis.rightBar.tab === 'chatgpt' && fetchGPTAnalysis.isIdle){
            setGptAnalysisResult({
                report: '',
                isSuccess: false
            });
            if (fetchElasticDetail.status !== 'success') return;
            fetchGPTAnalysis.mutateAsync({
                root: `${urlRoot}`,
                route: `${API.gptAnalysis}`,
                body: fetchElasticDetail.data?.data.hits.hits[0]._source,
            }).then(res => {
                if (res !== undefined) {
                    //@ts-ignore
                    setGptAnalysisResult(res);
                }
            });
        }
    }, [fetchElasticDetail.status, analysis.rightBar.tab])

    const minWidth = 350;
    const maxWidth = 1000;
    const calcWidth = () => Math.max(Math.min(originalX.current - lastX + lastWidth.current,maxWidth),minWidth);

    return (
        <TabContext value={analysis.rightBar.tab}>
            <div id="rightBar" className={!open ? "hidden" : ""} style={{ width: calcWidth() + "px" }}>
                <div className='resizeHandle' draggable onDragStart={onDragStart} onDrag={onDrag} onDragEnd={onDragEnd}/>
                <div className="tabs-column">
                    <TabList orientation='vertical' sx={{ width: '50px' }} onChange={(e, val) => { if (val !== "") dispatch(setRightBar({ tab: val })); }}>
                        <Tab className="right-bar-tab" label={props.closeButton} value="" />
                        <Tab className="right-bar-tab" label={<Tooltip title={dic[language]['right_bar_title']}><InfoIcon /></Tooltip>} value="detail" />
                        <Tab className="right-bar-tab" label={<Tooltip title={dic[language]['inquire_chatgpt']}><ChatIcon /></Tooltip>} value="chatgpt" />
                    </TabList>
                </div>
                <div className="container scrollbarY tabbed">
                    <TabPanel value="detail">
                        {
                            fetchElasticDetail.isSuccess?(
                                <Tooltip title="Copy as JSON">
                                    <IconButton className="copy-btn" onClick={() => 
                                        navigator.clipboard.writeText(JSON.stringify(fetchElasticDetail.data.data.hits.hits[0]))
                                    }>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            ): null
                        }

                        <p className="title">
                            {dic[language]['right_bar_title']}
                        </p>
                        <AnalysisRightBarRowFactory
                            fetchElasticDetail={fetchElasticDetail}
                            searchKeywordList={props.searchKeywordList}
                        />
                    </TabPanel>
                    <TabPanel value="chatgpt">
                        <p className="title">
                            {dic[language]['inquire_chatgpt']}
                        </p>
                        {!gptAnalysisResult.isSuccess ?<PartialLoading />: null}
                        {gptAnalysisResult.isSuccess && gptAnalysisResult !== undefined ?
                            <>
                                <Tooltip title="Copy as Markdown">
                                    <IconButton className="copy-btn" onClick={() => navigator.clipboard.writeText(gptAnalysisResult.report)}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                                <Markdown>{gptAnalysisResult.report}</Markdown>
                            </>
                            : null}
                    </TabPanel>
                </div>
            </div>
        </TabContext>
    );
};

export default RightBar;