import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'

interface ChildProps {
    setTrueOrFalse: (input: boolean) => void
    defaultValue?: boolean
}

const RadioGroupEnd: React.FC<ChildProps> = ({ setTrueOrFalse, defaultValue }) => {
    const language = useSelector(_language)

    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue={defaultValue ? "open" : "close"}
            >
                <FormControlLabel
                    value="close"
                    control={<Radio onChange={() => setTrueOrFalse(false)} />}
                    label={dic[language]['close_simeple']}
                />
                <FormControlLabel
                    value="open"
                    control={<Radio onChange={() => setTrueOrFalse(true)} />}
                    label={dic[language]['open_simeple']}
                />
            </RadioGroup>
        </FormControl>
    )
}

export default RadioGroupEnd
