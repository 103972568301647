import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { IconButton, Tooltip, Stack, DialogTitle } from '@mui/material'
import { useState } from 'react'
import { MuiColorInput } from 'mui-color-input'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import { IGroupData } from '../subpage/group/TableBox'

interface IGroupDialog {
    titleText: string
    icon: React.ReactNode
    confirmAction: (input: string, color: string, selectedHostsIDs?: number) => void
    group?: IGroupData
}

const GroupDialog = (props: IGroupDialog) => {
    const {
        titleText,
        icon,
        confirmAction,
        group,
    } = props
    const [open, setOpen] = useState(false)
    const [inputString, setInputString] = useState(group?.name ? group?.name : '')
    const [color, setColor] = useState(group?.color ? group?.color : '#ffffff')
    const language = useSelector(_language)

    const handleColorChange = (newColor: string) => {
        setColor(newColor)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleEditConfirm = () => {
        setOpen(false)
        confirmAction(inputString, color, group?.id)
    }

    const handleConfirm = () => {
        setOpen(false)
        confirmAction(inputString, color)
        setInputString('')
        setColor('#ffffff')
    }

    return (
        <>
            <Tooltip title={titleText} arrow placement="top">
                <IconButton
                    size="small"
                    className='actionButton'
                    onClick={() => {
                        setOpen(true)
                    }}
                >
                    {icon}
                </IconButton>
            </Tooltip>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                    sx={{
                        backgroundColor: '#2196F3',
                        color: 'white',
                        padding: '5px 15px',
                        fontSize: '1rem',
                    }}
                >
                    {titleText}
                </DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        id="name"
                        label={dic[language]['setting_group_new_name']}
                        type="text"
                        fullWidth
                        variant="standard"
                        value={inputString}
                        onChange={(e) => setInputString(e.target.value)}
                    />
                    <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        sx={{ margin: '1rem 0' }}
                    >
                        <span>{dic[language]['setting_group_color']}：</span>
                        <MuiColorInput
                            format="hex8"
                            value={color}
                            onChange={handleColorChange}
                        />
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={{ color: 'gray' }}
                    >
                        {dic[language]['cancel']}
                    </Button>
                    <Button
                        onClick={
                            group ? handleEditConfirm : handleConfirm
                        }
                        disabled={inputString === ''}
                    >
                        {dic[language]['confirm']}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default GroupDialog