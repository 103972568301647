import { animated, useSpring } from '@react-spring/web'
import './PointStatus.scss'
import { useNavigate } from 'react-router-dom'
import { IFormatedDevice } from 'interface'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'

interface ExtractedTimes {
    connection: 'true' | 'false'
    scanFinishTime: string | number
    collectFinishTime: string | number
    fileFinishTime: string | number
    imageFinishTime: string | number
}
type ExtractedDeviceTimes = Pick<IFormatedDevice, keyof ExtractedTimes>

export type TState =
    | 'working' // 取證中
    | 'non-working' // 未取證
    | 'done' // 已完成
    | 'success' // 下載成功
    | 'fail' // 下載失敗
    | 'connection' // 已連線
    | 'non-connection' // 未連線
export type TCategory = keyof ExtractedDeviceTimes

export const translateState = {
    working: '執行中',
    'non-working': '未執行',
    done: '已完成',
    success: '已完成', //todo: 目前定義的部分還需要整理
    fail: '下載失敗',
    // only for connection
    connection: '已連線',
    'non-connection': '未連線',
}

export const translateCategory = {
    connection: '連線狀態',
    scanFinishTime: '記憶體',
    collectFinishTime: '痕跡取證',
    fileFinishTime: '檔案總表',
    imageFinishTime: '關鍵映像檔',
}

interface IPointStatusProps {
    point: number // the percentage of the point
    state: TState
    category: TCategory
}

const PointStatus = (props: IPointStatusProps) => {
    const { point, state, category } = props
    const language = useSelector(_language);
    const navigate = useNavigate()

    const aniProps = useSpring({
        from: { width: 0 },
        to: { width: point * 2 },
        config: { duration: 400 },
    })

    const navigateQuery = () => {
        navigate(`/detect/${category}/${state}`)
        // navigate('/detect',{state:{category,state}})
    }

    return (
        <div onClick={navigateQuery} className="PointStatus-container">
            <p style={{ textAlign: 'left' }}>
                {dic[language][`dashboard_translate_state_${state}`]}
            </p>
            <p>
                <span>{point}</span> %
            </p>
            <div className="PointStatus-bar">
                <animated.div
                    className="PointStatus-bar-progress"
                    style={aniProps}
                />
            </div>
        </div>
    )
}

export default PointStatus
