import { useMutation, useQuery } from '@tanstack/react-query'
import { elasticRoot, urlRoot } from 'constant/index'
import { useDispatch, useSelector } from 'react-redux'
import { _processFilter, _filterMode, _searchIP, _otherIP, _page, _lastRows, _timeFilter, setLastRows, _sortModel } from 'redux/NetworkSlice'
import { useState, useRef, useEffect } from 'react'
import { _token } from 'redux/AuthSlice'
import { flattenDoc, flattenDocStrict } from '../../util'

interface MNCell {
    id: string
    pName: string
    agentIP: string
    ["memory_network.agentName"]: string
    ["memory_network.processId"]: string
    ["memory_network.action"]: string
    ["memory_network.srcAddress"]: string
    ["memory_network.dstAddress"]: string
    ["memory_network.otherIP"]: string
    ["memory_network.srcPort"]: string
    ["memory_network.dstPort"]: string
    ["memory_network.processCreateTime"]: number
    ["memory_network.virusTotal"]: number
    ["memory_network.malicious"]: number
}

/**
 * tree structure:
 * 1st layer: agentIP
 * 2st layer: processId
 * 3rd layer: processCreateTime
 * value: name of the process
 */
type TNameTree = Map<string, Map<number, Map<number, string>>>

export const PAGE_SIZE = 1000;

const useFetchNetwork = () => {

    const processFilter = useSelector(_processFilter);
    const searchIP = useSelector(_searchIP);
    const otherIP = useSelector(_otherIP);
    const page = useSelector(_page);
    const networkFilter = useSelector(_filterMode);
    const networkFilterMode = networkFilter;
    const token = useSelector(_token);
    const lastRows = useSelector(_lastRows);
    const timeFilter = useSelector(_timeFilter);
    const sortModel = useSelector(_sortModel);

    const dispatch = useDispatch();

    const [table, setTable] = useState<Array<MNCell>>([]);
    const [total, setTotal] = useState<number>(-1);
    const [status, setStatus] = useState<"loading" | "error" | "success">("loading");

    const tempTable = useRef<Array<MNCell>>([]);
    const nameTrees = useRef<TNameTree>(new Map());
    const reqContent_2nd = useRef<{
        processCreateTime: number,
        processId: number,
        agentIP: string,
        id: string
    }[]>([]);
    
    let searchQuery = JSON.stringify({
        track_total_hits: true,
        sort: [
            ...(sortModel === null?[]:[{[(sortModel?.field as string)+".raw"]: sortModel?.sort}]),
            {"memory_network.timestamp.raw": "desc"},
            {"memory_network.processCreateTime.raw": "desc"},
            {uuid: "asc"}
        ],
        search_after: page === 0? undefined: [
            ...(sortModel === null?[]:[lastRows[page-1][(sortModel?.field as string)]]),
            lastRows[page-1]["memory_network.timestamp"],
            lastRows[page-1]["memory_network.processCreateTime"],
            lastRows[page-1].uuid
        ],
        size: PAGE_SIZE,
        query: {
            bool: {
                must: [
                    { query_string: { fields: ["memory_network.timestamp"], query: `[${Math.floor(timeFilter.startTime/1000)} TO ${Math.floor(timeFilter.endTime/1000)}]`} },
                    ...(networkFilterMode === 'otherIP' ? [
                        {
                            term: { "memory_network.otherIP": otherIP }
                        }
                    ] : []),
                    ...(networkFilterMode === 'specificProcess'?[
                        {term:{"memory_network.processId": processFilter.processId}},
                        {match:{"memory_network.agent": processFilter.agent}},
                        {term:{"memory_network.processCreateTime": processFilter.processCreateTime}}
                    ]:[]),
                    ...(searchIP !== ''?[
                        {
                            bool :{
                                should:[
                                    {term: {"memory_network.srcAddress": searchIP}},
                                    {term: {"agentIP": searchIP}},
                                    {term: {"memory_network.dstAddress": searchIP}}
                                ],
                                minimum_should_match : 1
                            }
                        }
                    ]:[]),
                    {
                        "term": {
                            "category": "memory_network"
                        }
                    }
                ]
            }
        }
    });

    const filter:any = { "specificProcess": processFilter, "specificProcessByName": processFilter, "otherIP": otherIP, "off": undefined }[networkFilterMode];

    const { refetch: r_1stf, isRefetching: ir_1stf } = useQuery({
        queryKey: ["network_page_1stf", networkFilter, filter, page, searchIP, sortModel],
        queryFn: async () => {
            setStatus("loading");
            const res = await fetch(`${elasticRoot}ed_memory/_search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: searchQuery
            })
            return await res.json()
        },
        onSuccess: (data) => {
            let _tempTable: MNCell[] = tempTable.current = [];
            reqContent_2nd.current = []
            data.hits.hits.forEach((hit: any, idx: number) => {
                const doc = flattenDoc(hit);
                const docStrict = flattenDocStrict(hit);
                const doc2id2nd = (doc: any) => doc.agentIP + doc.processId + doc.processCreateTime;
                const id_2ndreq = doc2id2nd(doc);
                _tempTable.push({
                    id: doc.agent + doc.action + doc.processCreateTime + '_' + idx,
                    pName: "Unknown",
                    ...docStrict
                })
                if ((reqContent_2nd.current.length === 0 ||
                    reqContent_2nd.current[reqContent_2nd.current.length - 1].id !== id_2ndreq) &&
                    nameTrees.current.get(doc.agentIP)?.get(doc.processId)?.get(doc.processCreateTime) === undefined
                ) {
                    reqContent_2nd.current.push({
                        agentIP: doc.agentIP,
                        processId: doc.processId,
                        processCreateTime: doc.processCreateTime,
                        id: id_2ndreq,
                    })
                }
            })
            if (data.hits.hits.length !== 0) {
                let newLastRows = JSON.parse(JSON.stringify(lastRows));
                newLastRows[page] = flattenDocStrict(data.hits.hits[data.hits.hits.length - 1]);
                dispatch(setLastRows(newLastRows));
            }
            if (total !== data.hits.total) setTotal(data.hits.total.value);
            if (reqContent_2nd.current.length > 0) {
                mutate_2ndf();
            } else {
                tempTable.current = _tempTable;
                setTableWithName();
                setStatus("success");
            }
        },
        onError: () => { setStatus("error"); }
    })

    const setTableWithName = () => {
        tempTable.current.forEach(row => {
            row.pName = nameTrees.current
                            ?.get(row.agentIP)
                            ?.get(parseInt(row['memory_network.processId']))
                            ?.get(row['memory_network.processCreateTime']) 
                            || row.pName;
        })
        setTable(tempTable.current);
    }

    const { status: s_2ndf, mutate: mutate_2ndf } = useMutation({
        mutationKey: ["network_page_2ndf", reqContent_2nd.current],
        mutationFn: async () => {
            let body = "";
            reqContent_2nd.current.forEach(process => {
                body = body.concat(`{}\n`, JSON.stringify(JSON.parse(`{
                    "query": {
                        "bool": {
                            "must": [
                                {"term": {"memory.processId": ${process.processId}}},
                                {"term": {"memory.processCreateTime": ${process.processCreateTime}}},
                                {"term": {"agentIP": "${process.agentIP}"}}
                            ]
                        }
                    }
                }`)), `\n`);
            });
            const res = await fetch(`${elasticRoot}ed_memory/_msearch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-ndjson',
                    'Authorization': token
                },
                body: body
            })
            return await res.json()
        },
        onSuccess: (data) => {
            data.responses.forEach((res: any) => {
                const doc = flattenDoc(res.hits.hits[0]);
                if (!nameTrees.current.has(doc.agentIP)) {
                    nameTrees.current.set(doc.agentIP, new Map());
                }
                if (!nameTrees.current.get(doc.agentIP)?.has(doc.processId)) {
                    nameTrees.current.get(doc.agentIP)?.set(doc.processId, new Map());
                }
                nameTrees.current.get(doc.agentIP)?.get(doc.processId)?.set(doc.processCreateTime, doc.processName);
            })
            setTableWithName();
            setStatus("success");
        },
        onError: () => { setStatus("error"); }
    })

    useEffect(() => {
        r_1stf();
    }, [timeFilter.startTime, timeFilter.endTime])

    return {
        status,
        table,
        total,
        refetch: r_1stf,
        isRefetching: ir_1stf || s_2ndf === 'loading',
        nameTrees
    };
}

export default useFetchNetwork;