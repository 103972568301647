import { ReducersMapObject, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import authReducer from './AuthSlice';
import settingReducer from './SettingSlice';
import detectReducer from './DetectSlice'
import viewDetailReducer from './ViewDetailSlice';
import networkReducer from './NetworkSlice';
import analysisReducer from './AnalysisSlice';
import reportReducer from './ReportSlice';
import utilReducer from './UtilSlice';
import dashboardReducer from './DashboardSlice';
import memoryTreeReducer from './MemoryTreeSlice';
import { VERSION } from 'constant/index';

const VERSION2NUM = parseInt((VERSION?.replaceAll('.','') as string));

const reducers = combineReducers({
    auth: authReducer,
    setting: settingReducer,
    detect: detectReducer,
    viewDetail: viewDetailReducer,
    network: networkReducer,
    analysis: analysisReducer,
    report: reportReducer,
    util: utilReducer,
    dashboard: dashboardReducer,
    memoryTree: memoryTreeReducer
})

const persistedReducer = persistReducer<ReturnType<typeof reducers>>({
        key: 'root',
        storage: storage,
        version: VERSION2NUM,
        migrate: (state) => {
            let newState = state;
            if (state?._persist.version !== VERSION2NUM){
                newState = undefined;
            }
            return Promise.resolve(newState)
        }
    }, (state, action) => {
        if (action.type === 'auth/logout') {
            return reducers(undefined, action);
        }
        return reducers(state, action);
    }
);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false,}),
});

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>