import { ReactNode, useEffect, useState } from 'react'
import { ReactComponent as SvgPCGroup } from 'assets/icons/icon_group_pc.svg'
import { ReactComponent as SvgHost } from 'assets/icons/icon_pc.svg'
import { ReactComponent as SvgHostOffline } from 'assets/icons/icon_pc_offline.svg'
import { ReactComponent as SvgFolder } from 'assets/icons/icon_folder.svg'
import { ReactComponent as SvgFolderPlus } from 'assets/icons/icon_folder-plus.svg'
import { ReactComponent as SvgFolderMinus } from 'assets/icons/icon_folder-minus.svg'
import { ReactComponent as SvgDisk } from 'assets/icons/icon_hdd.svg'
import { ReactComponent as SvgSD } from 'assets/icons/icon_memory.svg'
import { ReactComponent as SvgFile } from 'assets/icons/icon_file.svg'
import { ReactComponent as SvgMinusSign } from 'assets/icons/icon_dash-square.svg'
import { ReactComponent as SvgPlusSign } from 'assets/icons/icon_plus-square.svg'
import { ReactComponent as SvgExe } from 'assets/icons/icon_exe.svg'
import { ReactComponent as SvgExePlusSign } from 'assets/icons/icon_exe+.svg'
import { ReactComponent as SvgExeMinusSign } from 'assets/icons/icon_exe-.svg'
import { ReactComponent as SvgDll } from 'assets/icons/icon_dll.svg'
import './tree.scss'

interface TreeNodeProps {
    type: 'exe' | 'exe+' | 'exe-' |'dll'|'pc-group' | 'host' | 'host-offline' | 'disk' | 'sd' | 'folder' | 'file' | 'folder+' | 'folder-'
    root?: boolean
    lastRoot?: boolean
    children?: ReactNode
    label: string
    onClick?: () => void
    selected?: boolean
    closed?: boolean
    onContextMenu?: (event: React.MouseEvent) => void
    defaultOpen?: boolean
    disabled?: boolean
}

const TreeNode = (props: TreeNodeProps) => {
    const isClosedControl = props.closed !== undefined;

    let [_closed, setClosed] = useState<boolean>(true)
    let closed = isClosedControl?props.closed:_closed;

    useEffect(() => {
        if (props.defaultOpen) {
            setClosed(false);
        }
    },[])

    const iconDict = {
        'pc-group': <SvgPCGroup className="icon" />,
        'host': <SvgHost className="icon" />,
        'host-offline': <SvgHostOffline className='icon' />,
        'disk': <SvgDisk className="icon" />,
        'sd': <SvgSD className="icon" />,
        'folder': <SvgFolder className="icon" />,
        'file': <SvgFile className="icon" />,
        'folder+': <SvgFolderPlus className="icon" />,
        'folder-': <SvgFolderMinus className="icon" />,
        'dll': <SvgDll className="icon" />,
        'exe+': <SvgExePlusSign className="icon" />,
        'exe-': <SvgExeMinusSign className="icon" />,
        'exe': <SvgExe className="icon" />,
    }

    let iconElement = iconDict[props.type]

    const handleClick = ()=> {
        if (props.disabled) return;
        if (props.onClick) props.onClick();
        if (!isClosedControl) {setClosed(!closed)}
    };

    let tree = 
        <div className={'treeNode' + 
                        (props.root ? ' root' : ' non-root') + 
                        (closed?' closed':'') + 
                        (props.selected? ' selected':'') +
                        (props.disabled? ' disabled' : '')}>
            <div className="treeNodeContent" onClick={handleClick} onContextMenu={props.onContextMenu}>
                {iconElement}
                <span className="treeNodeContentText">{props.label}</span>
            </div>
            {props.children}
        </div>

    if (props.root){
        return (
            <div
                className={
                    'treeWrapper' +
                    (props.lastRoot ? ' last' : '') +
                    (!closed ? ' open' : ' closed')
                }
                >
                <div className="treeWrapperHeader" onClick={handleClick}>
                    {closed ? (
                        <SvgPlusSign
                            className="icon"
                        />

                    ) : (
                        <SvgMinusSign
                            className="icon"
                        />
                    )}
                    <div className="headertail"></div>
                </div>
                {tree}
                {props.lastRoot ? null : <div className="verticaltail"></div>}
            </div>
        )
    }

    return tree
}

export default TreeNode
