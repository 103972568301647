import { useEffect, useState } from 'react';
import { _language } from 'redux/UtilSlice';
import { useSelector } from 'react-redux';
import {
    TextField,
} from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useToast } from 'hook/useToast';
import '../../index.scss';
import dic from 'constant/dictionary';
import LoadingButton from '@mui/lab/LoadingButton';
import { usePost } from 'hook/usePost';
import { useGet } from 'hook/useGet';
import { API, urlRoot } from 'constant/index';

const VirusTotal = () => {
    const language = useSelector(_language);
    const toast = useToast();
    const [isVerifyError, setIsVerifyError] = useState(false);
    const [isVerifySuccess, setIsVerifySuccess] = useState(false);
    const [virusTotalToken, setVirusTotalToken] = useState<string>('');

    const {
        data: vtKey,
        isSuccess: isVtKeySuccess,
    } = useGet<string>({
        query: 'GetVirusTotalKey',
        root: `${urlRoot}`,
        route: `${API.virusTotalKey}`,
    });

    useEffect(() => {
        if (isVtKeySuccess && vtKey.data) {
            setVirusTotalToken(vtKey.data);
        }
    }, [isVtKeySuccess, vtKey]);

    const {
        mutate: verifyMutate,
        isLoading: isVerifyLoading,
    } = usePost();

    const handleVirulTotalVerify = () => {
        if (!isVerifyError && !isVerifySuccess) {
            verifyMutate({
                root: `${urlRoot}`,
                route: `${API.virulTotalVerify}`,
                body: { apikey: virusTotalToken },
            }, {
                onSuccess: () => {
                    setIsVerifyError(false);
                    setIsVerifySuccess(true);
                },
                onError: (error) => {
                    setIsVerifyError(true);
                    setIsVerifySuccess(false);
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        toast.show({content: dic[language]['verify_fail'] || error.response.data.message});
                    } else {
                        console.log(error.response?.data.message)
                    }
                }
            })
        } else {
            verifyMutate({
                root: `${urlRoot}`,
                route: `${API.virusTotalStore}`,
                body: { apikey: virusTotalToken },
            }, {
                onSuccess: () => {
                    setIsVerifyError(false);
                    setIsVerifySuccess(false);
                    toast.show({content: dic[language]['save_success']});
                },
                onError: (error) => {
                    setIsVerifyError(true);
                    setIsVerifySuccess(false);
                    // 4xx error -> alert
                    // 5xx error -> console.log
                    if (error.response && error.response.status < 500) {
                        toast.show({content: dic[language]['save_fail'] || error.response.data.message});
                    } else {
                        console.log(error.response?.data.message)
                    }
                }
            })
        }
    }

    return (
        <div className='ChildInnerWhiteContainerSmall'>
            <div className='virusTotalContainer'>
                <div>VirusTotal API Key: </div>
                <TextField
                    error={isVerifyError}
                    size='small'
                    className='input'
                    value={virusTotalToken}
                    onChange={(e) => {
                        setVirusTotalToken(e.target.value);
                        setIsVerifyError(false);
                        setIsVerifySuccess(false);
                    }}
                />
                <LoadingButton
                    onClick={handleVirulTotalVerify}
                    endIcon={
                        isVerifyError ? (
                            <ErrorOutlineRoundedIcon />
                        ) : isVerifySuccess ? (
                            <SaveAltRoundedIcon />
                        ) : (
                            <SendRoundedIcon />
                        )
                    }
                    loading={isVerifyLoading}
                    loadingPosition="end"
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    color={
                        isVerifyError ? 'error' :
                            isVerifySuccess ? 'success' :
                                'primary'
                    }
                >
                    <span>
                        {!isVerifyError && isVerifySuccess ?
                            dic[language]['save'] :
                            dic[language]['verify']}
                    </span>
                </LoadingButton>
            </div>
        </div>
    )
}

export default VirusTotal
