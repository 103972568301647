import { Skeleton } from "@mui/material"

const SkeletonTreeNode = (props: {root: boolean}) => {
    return (
        <div className={'treeNode' + (props.root ? ' root' : ' non-root')}>
            <div className="treeNodeContent" style={{display: 'flex', alignItems: 'center'}}>
                <Skeleton className="icon" variant="circular"/>
                <Skeleton className="treeNodeContentText" variant="rounded" width={64} sx={{display: 'inline-block', marginLeft:'.3rem', height: "1rem"}}/>
            </div>
        </div>
    )
}

export default SkeletonTreeNode