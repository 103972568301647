import {
    Box,
    Checkbox,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { visuallyHidden } from '@mui/utils'

import {
    IGenerateGroup,
    oneHostData,
} from 'interface'
import { useEffect, useState } from 'react'
import { areArraysHasOne, areArraysIncluding } from '../..'
import { Order } from 'util/index'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'

interface EnhancedTableProps {
    pageData: oneHostData[]
    selectedHostsIDs: readonly string[]
    numSelected: number
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof oneHostData
    ) => void
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    order: Order
    orderBy: string
    rowCount: number
    selectedHost: string
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    const {
        onSelectAllClick,
        order,
        orderBy,
        onRequestSort,
        pageData,
        selectedHostsIDs,
    } = props
    const language = useSelector(_language)
    const createSortHandler =
        (property: keyof oneHostData) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property)
        }

    const [compareCheck, setCompareCheck] = useState<boolean>(false)
    const [minusCheck, setMinusCheck] = useState<boolean>(false)

    useEffect(() => {
        const allIds: readonly string[] = pageData.map((item) => item.id) // all ids of this page and turn it into readonly array
        const compareResult = areArraysIncluding(selectedHostsIDs, allIds) // selectedId全部被選會是所有裝置 ,allIds被選會是當頁所有id
        setCompareCheck(compareResult)

        const compareMinus = areArraysHasOne(selectedHostsIDs, allIds, compareResult) //  只要有一個就回傳true，但不能兩減號ㄌㄨㄛ
        setMinusCheck(compareMinus)
    }, [selectedHostsIDs, pageData])

    const headCells: readonly GroupGrid_HeadCell[] = [
        {
            id: 'ip',
            numeric: false,
            disablePadding: false,
            label: dic[language]['detect_table_device_ip'],
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: dic[language]['detect_table_device_name'],
        },
        {
            id: 'macAddress',
            numeric: false,
            disablePadding: true,
            label: 'Mac Address',
        },
    ]

    return (
        <TableHead>
            <TableRow style={{ whiteSpace: 'pre-line' }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={minusCheck}
                        checked={compareCheck}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell, idx) => (
                    <TableCell
                        key={`${headCell.id}${idx}headcell`}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            IconComponent={ArrowDropDownIcon}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default EnhancedTableHead

interface GroupGrid_HeadCell {
    disablePadding: boolean
    id: keyof oneHostData
    label: string
    numeric: boolean
}
