import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Tooltip from '@mui/material/Tooltip'
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { LevelChange } from '../Interfaces'
import { IDateRange } from 'interface'
import { InitRequest } from '../functions'
import { processNumber } from 'util/index'
import './MiddleBar.scss'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import { useAlert } from 'hook/useAlert'

interface ChangeBoxProps {
    dateModuleData: IDateRange
}

const ChangeBox = (props: ChangeBoxProps) => {
    const { dateModuleData } = props;
    const postMutation = usePost<LevelChange>();
    const { showAlert } = useAlert();
    const [levelChange, setLevelChange] = useState<LevelChange>({
        device: 0,
        process: 0,
    });

    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.levelChange}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                setLevelChange(res.data ?? { device: 0, process: 0 });
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [dateModuleData])

    return (
        <div className='changeBoxContainer'>
            <BoxContent left={true} value={levelChange.device} />
            <BoxContent left={false} value={levelChange.process} />
        </div>
    )
}

export default ChangeBox

interface BoxContentProps {
    left: boolean
    value: number
}

const BoxContent = (props: BoxContentProps) => {
    const language = useSelector(_language);
    return (
        <Tooltip
            followCursor
            title={(props.value > 0 ? '+' : '') + props.value}
        >
            <div className={'changeBox' + (props.left ? 'Left' : 'Right')}>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '100%',
                    }}
                >
                    {props.left ? (
                        <PersonalVideoIcon className='changeBoxIcon' />
                    ) : (
                        <RemoveRedEyeIcon className='changeBoxIcon' />
                    )}
                    <Stack
                        direction="column"
                        spacing={0}
                    >
                        <div className='changeBoxTitle'>
                            {props.left ?
                                dic[language]["dashboard_change_box_left"] :
                                dic[language]["dashboard_change_box_right"]}
                        </div>
                        <Stack
                            direction="row"
                            spacing={0}
                            justifyContent="start"
                            alignItems="center"
                        >
                            {props.value > 0 ? (
                                <ArrowDropUpIcon sx={{ color: 'rgba(255, 0, 0, 1)' }} />
                            ) : (
                                <ArrowDropDownIcon sx={{ color: 'rgba(0, 255, 0, 1)' }} />
                            )}
                            <div className='changeBoxText'>
                                <span className='changeBoxTextHighlight'>
                                    {processNumber(Math.abs(props.value))}
                                </span>
                                {props.left ?
                                    dic[language]["dashboard_change_box_left_unit"] :
                                    dic[language]["dashboard_change_box_right_unit"]}
                            </div>
                        </Stack>
                    </Stack>
                </Stack>
            </div>
        </Tooltip>
    )
}
