import { Box, Chip, FormControl, FormLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

interface MultiSelectProps {
    value: string[]
    title: string
    selectOptions: string[]
    handleChange: (event: SelectChangeEvent<string[]>) => void
    handleDelete: (value: string) => void
}

const MultiSelect = (props: MultiSelectProps) => {
    const { title, value, selectOptions, handleChange, handleDelete } = props;

    return (
        <FormControl
            size="small"
            sx={{ width: '100%', margin: '0.5rem 0' }}
        >
            <FormLabel
                required
                component="legend"
                sx={{ marginBottom: '0.3rem' }}
            >
                {title}
            </FormLabel>
            <Select
                id="multiple-chip"
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                }}
                renderValue={(selected: string[]) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                clickable
                                onDelete={() => handleDelete(value)}
                                deleteIcon={
                                    <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                    />
                                }
                            />
                        ))}
                    </Box>
                )}
            >
                {selectOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default MultiSelect