import { CircularProgress, ClickAwayListener, Portal, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import RightBar, { RightBarCloseButton } from "component/RightBar";
import { useContext, useRef, useState } from "react";
import { ViewDetailRightBarRowFactory } from "component/RightBar/RightBarRowFactory";
import useFetchMemoryTreeAgents, { IAgentStatus } from "page/memory/useFetchMemoryTreeAgents";
import TreeNode from "component/TreeNode/index";
import HostTreeNode from "component/TreeNode/HostTreeNode";
import MemoryTreeNode from "../component/MemoryTreeNode";
import dic from "constant/dictionary";
import { useDispatch, useSelector } from "react-redux";
import { _elasticRestarting, _language } from "redux/UtilSlice";
import { IDllTreeNode, IMemoryTreeNode, _isHostSelected, _selectedHost, _selectedNode, setSelectedHost } from "redux/MemoryTreeSlice";
import { flattenDoc, formatTimestamp } from 'util/index';
import { processTimeField } from "util/index";
import dayjs from "dayjs";
import TreePageContextMenu, { TContextMenu } from "component/TreePageContextMenu";
import DownloadContext, { DumpRequestObj } from "context/DownloadContext";
import SyncIcon from '@mui/icons-material/Sync';
import DevicesIcon from '@mui/icons-material/Devices';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

const MemoryTreePage = (props: {
    pageRef: React.MutableRefObject<null>
}) => {
    
    const clostButtonContainer = useRef(null);
    
    const isHostSelected = useSelector(_isHostSelected);
    const selectedHost = useSelector(_selectedHost);
    const selectedNode = useSelector(_selectedNode);
    const elasticRestarting = useSelector(_elasticRestarting);
    const language = useSelector(_language);
    const dispatch = useDispatch();

    const [rightBarOpen, setRightBarOpen] = useState(false);
    const [rightBarData, setRightBarData] = useState({});

    const {addItem} = useContext(DownloadContext);

    let { rootsList, agentList, status, refetch, fireDetectMission } = useFetchMemoryTreeAgents();

    let uuidCounter = 0;

    const processProperties: GridColDef[] = [
        { field: 'processName', headerName: dic[language]['memory_tree_process_name'], flex: 1 },
        { field: 'processId', headerName: dic[language]['memory_tree_process_id'], flex: 1 },
        { field: 'processCreateTime', headerName: dic[language]['memory_tree_process_create_time'], flex: 1, renderCell: ({ row }) => {
            if (row.processCreateTime === 0) return '- -'
            else return dayjs.unix(row.processCreateTime).format("YYYY-MM-DD HH:mm:s")
        }},
        { field: 'parentProcessId', headerName: dic[language]['memory_tree_parent_process_ID'], flex: 1 },
        { field: 'userName', headerName: dic[language]['memory_tree_user_name'], flex: 1 },
    ];
    
    const [contextMenu, setContextMenu] = useState<TContextMenu | null>(null);

    const contextOnDownload = (node: any) => {
        const req: DumpRequestObj = node.item_main.substring(node.item_main.length-4) === '.dll'?
            {
                action: "StartDumpDll",
                name: `${node.agentIP}-${node.item_main}.zip`,
                filter: {
                    pid: node.processId?.toString(),
                    processCreateTime: node.processCreateTime,
                    path: node.path
                },
                deviceId: node.agent
            }:
            {
                action: "StartDumpProcess",
                name: `${node.agentIP}-${node.item_main}.zip`,
                filter: {
                    pid: node.processId?.toString(),
                    processCreateTime: node.processCreateTime
                },
                deviceId: node.agent
            }
        addItem(req);
    }

    const handleGridContextMenu = (event: React.MouseEvent) => {
        event.preventDefault();
        const row = Number(event.currentTarget.getAttribute("data-rowindex"));
        const data = selectedNode?.gridRows? selectedNode.gridRows[row] : null;

        setContextMenu(contextMenu === null? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            node: data,
            name: data? data.processName: '',
            onLoadDLL: undefined,
            onDownload: contextOnDownload,
            downloadEnabled: Boolean(data?.processId !== undefined && data.agent && data.processCreateTime !== undefined)
        } : null);
        console.log(agentList.find(agent => agent.id === data?.agent));
    };

    const handleTreeContextMenu = (event: React.MouseEvent, data: IMemoryTreeNode | IDllTreeNode, loadDllCallback?: () => void) => {
        event.preventDefault();

        setContextMenu(contextMenu === null? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
                node: data,
                name: data.item_main,
                onLoadDLL: (loadDllCallback)?loadDllCallback:undefined,
                onDownload: contextOnDownload,
                downloadEnabled: Boolean(data?.processId !== undefined && data.agent && data.processCreateTime !== undefined),
                loadDllEnabled: agentList.find(agent => agent.id === data.agent)?.connection
            } : null,
        );
    };

    const selectedHostStatus = agentList.find(agent => agent.id===selectedHost?.id);

    return (
        <>
            <TreePageContextMenu contextMenu={contextMenu} handleClose={() => {setContextMenu(null)}}/>
            <Portal container={clostButtonContainer.current}><RightBarCloseButton onClick={(e: any) => { setRightBarOpen(false) }} /></Portal>
            <PanelGroup direction="horizontal">
                <Panel className="hostList treeView" id="hostTree" minSizePixels={250} defaultSizePixels={250}>
                    <div id="hostListContainer">
                    {
                        status === 'success' ?
                            <TreeNode type="pc-group" defaultOpen label={dic[language]['view_detail_agent_list']} root lastRoot={true}>
                                {
                                    agentList.map((agent: IAgentStatus) => (
                                        <HostTreeNode
                                            offline={!agent.connection}
                                            disabled={(!agent.connection && !rootsList.get(agent.id)?.length )|| !(agent.os.includes("Windows") || agent.os === 'Unknown')} 
                                            selected={selectedHost?.id===agent.id}  
                                            agent={agent} 
                                            key={agent.id} 
                                            onClick={()=>{
                                                dispatch(setSelectedHost(agent));
                                            }}
                                        />)
                                    )
                                }
                            </TreeNode>
                            : null
                    }
                    {
                        status === 'loading' ?
                            <CircularProgress /> : null
                    }
                    </div>
                </Panel>
                <PanelResizeHandle className='resizeHandle' />
                <Panel>
                <PanelGroup direction="vertical" className="window">
                    <Panel className="windowHeader" defaultSizePixels={48}>
                        <WindowHeader 
                            agent={agentList.find(agent => (agent.id === selectedHost?.id))}
                            fireMission={fireDetectMission}
                            refetch={refetch}
                        />
                    </Panel>
                    <PanelResizeHandle disabled />
                    <Panel>
                    <PanelGroup direction="horizontal">

                    <Panel className="detailList treeView detailTree" minSizePixels={300} defaultSizePixels={300} key='middle-panel'>
                    <div id="detailTreeContainer">
                        {
                            (selectedHostStatus?.memory.status === 3 || !selectedHostStatus?.connection)
                                && status === "success" && !elasticRestarting && isHostSelected ?
                                rootsList.get((selectedHost?.id as string))?.map((root, idx: number) => {
                                    return (
                                        <MemoryTreeNode
                                            data={flattenDoc(root)}
                                            root
                                            key={root.uuid}
                                            lastRoot={idx === (rootsList.get((selectedHost?.id as string))?.length as number) - 1}
                                            onContextMenu={handleTreeContextMenu}
                                        />)
                                })
                                : null
                        }
                        {
                            selectedHostStatus?.connection  // if selectedhost is online && loading memory tree mission
                            && !(selectedHostStatus?.memory.status===3)
                            || status === "loading"?
                                <div className="center-inner">
                                    <CircularProgress 
                                        className="progress"
                                        style={{width: '4rem', height: '4rem'}}
                                    />
                                </div>
                            :null
                        }
                    </div>
                </Panel>
                <PanelResizeHandle className='resizeHandle' style={{width: '4px'}} />
                <Panel id="detailGridContainer">
                    <ClickAwayListener onClickAway={() => {
                        setRightBarOpen(false);
                    }}>
                        <div className='fullSizeContainer'>
                            <DataGridPro
                                hideFooter
                                rowHeight={32}
                                columns={processProperties}
                                rows={selectedNode?.gridRows?selectedNode?.gridRows:[]}
                                getRowId={data => data.uuid+(uuidCounter++)}
                                loading={selectedNode?.gridRowsStatus === 'loading'}
                                onRowClick={(params) => {
                                    setRightBarData(processTimeField(params.row));
                                    setRightBarOpen(true);
                                }}
                                slotProps={{
                                  row: {
                                    onContextMenu: handleGridContextMenu,
                                    style: { cursor: "context-menu" }
                                  }
                                }}
                            />
                            <Portal container={props.pageRef.current}>
                                <RightBar open={rightBarOpen} closeButton={<span ref={clostButtonContainer}></span>}>
                                    <ViewDetailRightBarRowFactory rightBarData={rightBarData} />
                                </RightBar>
                            </Portal>
                        </div>

                    </ClickAwayListener>
                </Panel>
                    </PanelGroup>
                    </Panel>
                </PanelGroup>
                </Panel>
            </PanelGroup>
        </>
    )
}


const WindowHeader= (props:{agent?: IAgentStatus, refetch: () => void, fireMission: () => void}) => {
    const agent = props.agent;
    if (agent === undefined) return <></>
    else return <>
        <span className="title">
            {
                agent.connection?
                    <DevicesIcon/>
                    :<PhonelinkOffIcon />
            }
            {agent.ip}
        </span>
        <span>
            {
                agent.memory.timestamp !== 0?
                    "snapshot at " + formatTimestamp(agent.memory.timestamp)
                : "previous snapshot"
            }
        </span>
        <ToggleButtonGroup className="btnGroup">
            <ToggleButton onClick={() => props.refetch()} value='sync'>
                <Tooltip title="sync with server">
                    <SyncIcon />
                </Tooltip>
            </ToggleButton>
            <ToggleButton onClick={() => props.fireMission()} value='snapshot'>
                <Tooltip title="take memory tree snapshot from agent">
                    <CenterFocusStrongIcon />
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    </>
}

export default MemoryTreePage;