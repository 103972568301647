import { useState } from 'react'
import { AlertButton, CustomedButton } from './TableBox/Component/CustomedButton'
import CusDialog from './TableBox/Component/Dialog'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import SearchBarWithChips from '../../../../component/SearchBarWithChips'

interface EnhancedTableToolbarProps {
    selectedList: readonly string[]
    searchKeywordList: string[]
    setSearchKeywordList: React.Dispatch<React.SetStateAction<string[]>>
    listRefetchFn: () => void
    groupList: string[]
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const {
        selectedList,
        searchKeywordList,
        setSearchKeywordList,
        listRefetchFn,
        groupList,
    } = props;
    const language = useSelector(_language);
    const [isOpenDialog, setIsOpenDialog] = useState(false);

    return (
        <div className='flex_align_items'>
            <p className='number-table-selected'>
                {dic[language]['checked']} <span>{selectedList.length}</span>
            </p>
            <SearchBarWithChips
                searchKeywordList={searchKeywordList}
                setSearchKeywordList={setSearchKeywordList}
                chipLength={5}
            />
            <CustomedButton setIsOpenDialog={setIsOpenDialog} />
            <AlertButton selectIds={selectedList} listRefetchFn={listRefetchFn} />
            <CusDialog open={isOpenDialog} setOpen={setIsOpenDialog} groupList={groupList} listRefetchFn={listRefetchFn} />
        </div>
    )
}

export default EnhancedTableToolbar
