import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import './AllFilesDropDownSelector.css'
import { IAllFilesDropDownData } from 'interface'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setallFilesDropDownData } from 'redux/AnalysisSlice'
import dic from 'constant/dictionary'
import { _language } from 'redux/UtilSlice'


const AllFilesDropDownSelector = () => {

    const analysisData = useSelector(_analysisData);
    const { allFilesDropDownData } = analysisData;
    const dispatch = useDispatch();
    const language = useSelector(_language);

    function changeAllFilesDropDownData(
        key: string,
        event:
            | SelectChangeEvent
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        if (key === 'onlySearchDeletedFile'){
            let tempValue = event.target.value === 'true' ? true : false
            dispatch(setallFilesDropDownData({
                ...allFilesDropDownData,
                [key]: tempValue,
            }))
        } else {

            dispatch(setallFilesDropDownData({
                ...allFilesDropDownData,
                [key]: event.target.value,
            }))
        }
    }

    // 關鍵路徑
    const AllFileDropDownSelectorKeyPath = () => {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto 1fr',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    關鍵路徑：
                </span>
                <TextField
                    id="outlined-basic"
                    size="small"
                    value={allFilesDropDownData.keyPath}
                    onChange={(e) => changeAllFilesDropDownData('keyPath', e)}
                />
                <div></div>
            </div>
        )
    }

    // 關鍵字類別:
    const AllFileDropDownSelectorKeywordType = () => {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto 1fr',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    關鍵字類別:
                </span>
                <div>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={allFilesDropDownData.keywordType}
                            onChange={(e) =>
                                changeAllFilesDropDownData('keywordType', e)
                            }
                        >
                            <FormControlLabel
                                value="影音檔"
                                control={<Radio />}
                                label="影音檔"
                            />
                            <FormControlLabel
                                value="圖片檔"
                                control={<Radio />}
                                label="圖片檔"
                            />
                            <FormControlLabel
                                value="文件檔"
                                control={<Radio />}
                                label="文件檔"
                            />
                            <FormControlLabel
                                value="自訂"
                                control={<Radio />}
                                label="自訂"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        )
    }

    // 關鍵字-檔名：
    const AllFileDropDownSelectorKeywordFileName = () => {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto 1fr',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    關鍵字-檔名：
                </span>
                <TextField
                    id="outlined-basic"
                    size="small"
                    value={allFilesDropDownData.keywordFileName}
                    onChange={(e) =>
                        changeAllFilesDropDownData('keywordFileName', e)
                    }
                />
                <div></div>
            </div>
        )
    }

    // 關鍵字-內文：
    const AllFileDropDownSelectorKeywordContent = () => {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto 1fr',
                }}
            >
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    關鍵字-內文：
                </span>
                <TextField
                    id="outlined-basic"
                    size="small"
                    value={allFilesDropDownData.keywordContent}
                    onChange={(e) =>
                        changeAllFilesDropDownData('keywordContent', e)
                    }
                />
                <div></div>
            </div>
        )
    }

    // 檔案大小：
    const AllFileDropDownSelectorFileSize = () => {
        return (
            <div>
                <div
                    className="allFileDropDownSelectorFileSizeBar"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto auto auto 1fr',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{dic[language]["view_detail_file_size"]} :</span>
                    </div>

                    <div>
                        <Box sx={{ width: 130 }}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={allFilesDropDownData.fileSize}
                                    label=""
                                    onChange={(e) =>
                                        changeAllFilesDropDownData(
                                            'fileSize',
                                            e
                                        )
                                    }
                                >
                                    <MenuItem value={'min'}>{dic[language]["at_least"]}</MenuItem>
                                    <MenuItem value={'max'}>{dic[language]["at_most"]}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div
                        style={{
                            width: '200px',
                            display: 'flex',
                            alignItems: 'end',
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            type="number"
                            value={allFilesDropDownData.fileSizeValue}
                            size="small"
                            // fullWidth
                            onChange={(e) =>
                                changeAllFilesDropDownData('fileSizeValue', e)
                            }
                        />
                    </div>
                    <div>
                        <Box sx={{ width: 100 }}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="fileSizeUnit"></InputLabel>
                                <Select
                                    labelId="fileSizeUnit"
                                    id="demo-simple-select"
                                    value={allFilesDropDownData.fileUnit}
                                    label=""
                                    onChange={(e) =>
                                        changeAllFilesDropDownData(
                                            'fileUnit',
                                            e
                                        )
                                    }
                                >
                                    <MenuItem value={'MB'}>MB</MenuItem>
                                    <MenuItem value={'GB'}>GB</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
            </div>
        )
    }

    // 關鍵字-內文：
    const AllFileDropDownSelectorOnlySearchDeletedFile = () => {
        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'auto auto 1fr',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '10px',
                    }}
                >
                    <span>{dic[language]["deleted_only"]} :</span>
                </div>

                <div>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="demo-form-control-label-placement"
                            name="position"
                            value={
                                allFilesDropDownData.onlySearchDeletedFile ===
                                true
                                    ? 'true'
                                    : 'false'
                            }
                            onChange={(e) =>
                                changeAllFilesDropDownData(
                                    'onlySearchDeletedFile',
                                    e
                                )
                            }
                        >
                            <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label={dic[language]['no']}
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio />}
                                label={dic[language]['yes']}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div></div>
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
                width: '750px',
            }}
        >
            <div style={{ minWidth: '300px', height: '311px' }}>
                <div
                    style={{
                        position: 'relative',
                        height: '311px',
                        zIndex: '100',
                        paddingLeft: '20px',
                    }}
                >
                    <div style={{ height: '311px' }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: 50,
                            }}
                        >
                            {dic[language]["ed_explorer"]}
                        </div>
                        {/* <QuickSelect keywordType={''} />
                        <KetWordType keywordType={''} />
                        <SearchKeyword keywordType={''} /> */}
                        <div className="allRow">
                            {/* {AllFileDropDownSelectorKeyPath()}
                            {AllFileDropDownSelectorKeywordType()}
                            {AllFileDropDownSelectorKeywordFileName()}
                            {AllFileDropDownSelectorKeywordContent()}
                            <hr /> */}
                            {AllFileDropDownSelectorFileSize()}
                            {AllFileDropDownSelectorOnlySearchDeletedFile()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllFilesDropDownSelector
