import { useQuery } from '@tanstack/react-query';
import TreeNode from '../../../component/TreeNode'
import { elasticRoot } from 'constant/index';
import { useEffect, useState } from 'react';
import { IFileData, updateSelectedNodeChildren } from 'redux/ViewDetailSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedNode, _selectedNode } from 'redux/ViewDetailSlice';
import SkeletonTreeNode from '../../../component/TreeNode/SkeletonTreeNode';
import { _token } from 'redux/AuthSlice';
import { flattenDoc } from 'util/index';
import { setElasticRestarting } from 'redux/UtilSlice';

interface FileTreeNodeProps {
    data: IFileData
    root: boolean
    lastRoot?: boolean
    onContextMenu?: (event: React.MouseEvent, data: IFileData) => void
}

const FileTreeNode = (props: FileTreeNodeProps) => {

    const [closed, setClosed] = useState(!props.root)
    const [openOnced, setOpenedOnce] = useState(false);
    useEffect(() => {
        if (!closed) setOpenedOnce(true);
    }, [closed])

    const dispatch = useDispatch();
    const selectedNode = useSelector(_selectedNode);
    const token = useSelector(_token);

    const doc = props.data;

    const [childrenStatusArr, setChildrenStatusArr] = useState<("success"|"loading"|"error")[]>([]);
    const [childrenData, setChildrenData] = useState<IFileData[]>([]);

    useEffect(() => {
        if (!openOnced || !doc.isDirectory) return;
        let loadingStatusArr:("success"|"loading"|"error")[] = [];
        for (let idx = 0; idx*1000 < props.data.child.length; idx++){
            loadingStatusArr[idx] = "loading";
        }
        setChildrenStatusArr(loadingStatusArr);
        for (let idx = 0; idx*1000 < props.data.child.length; idx++){
            const cur = idx*1000;
            fetch(`${elasticRoot}ed_explorer/_search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: `{"size":10000,"query":{"query_string": {"default_field": "uuid", "query": "${props.data.child.slice(cur, cur+1000).join(' OR ')}"}}}`
            })
            .then(res => res.json())
            .then((data:any) => {
                console.log('child split fetch',data)
                setChildrenData(prev => prev.concat(data.hits.hits.map((hit:any) => flattenDoc(hit))))
                setChildrenStatusArr(prev => {
                    let cloned = JSON.parse(JSON.stringify(prev));
                    cloned[idx] = "success";
                    return cloned;
                })
            })
            .catch(error => {
                setChildrenStatusArr(prev => {
                    let cloned = [...prev];
                    cloned[idx] = "error";
                    return cloned;
                })
            })

        }
    }, [openOnced])

    const reducedChildrenStatus = childrenStatusArr.reduce((acc, cur) => {
        if (acc === "error" || cur === "error") return "error";
        if (acc === "loading") return "loading";
        if (acc === "success" && cur === "success") return acc;
        return "loading"; 
    }, "success")

    const childrenReady = reducedChildrenStatus === "success" &&
        props.data.uuid === selectedNode?.uuid &&
        doc.isDirectory;

    useEffect(() => {
        // if (data && data.message === "Elastic is restarting"){
        //     dispatch(setElasticRestarting(true));
        // } else {
        //     dispatch(setElasticRestarting(false));
        // }
        if (childrenReady && childrenData) {
            dispatch(updateSelectedNodeChildren({
                children: childrenData.length === 0 ? [] : childrenData,
                childrenStatus: reducedChildrenStatus
            }));
        }
    }, [reducedChildrenStatus, childrenData])

    const volumnFormat = doc.disk ? doc.disk.split("|")[1] : "";

    const selectSelf = () => {
        dispatch(setSelectedNode({
            ...props.data,
            childrenStatus: doc.isDirectory ? reducedChildrenStatus : 'success',
            children: doc.isDirectory ? childrenReady ?
                childrenData.length === 0 ? [] : childrenData
                : null
                : [props.data]
        }));
        
    }

    return <TreeNode
        root={props.root}
        type={props.root ? 'disk' : doc.isDirectory ? (closed ? 'folder+' : "folder-") : 'file'}
        label={props.root ? props.data.etc_main : doc.fileName}
        closed={closed}
        selected={props.data.uuid === selectedNode?.uuid}
        lastRoot={props.lastRoot}
        onContextMenu={(event) => {
            selectSelf();
            if(props.onContextMenu) props.onContextMenu(event, props.data)}
        }
        onClick={() => {
            selectSelf();
            setClosed(!closed);
        }}>
        {
            props.root ?
                <div className="volumnFormatLabel">{volumnFormat}</div>
                : null
        }
        {
            (reducedChildrenStatus === 'success' && !closed && doc.isDirectory && props.data.child.length !== 0) ?
                childrenData.map((__data: IFileData, index: number) =>
                    <FileTreeNode data={__data} root={false} key={index} onContextMenu={props.onContextMenu}/>
                ) : null
        }
        {
            reducedChildrenStatus === 'error'?
                <TreeNode type="file" label="error fetching child node"/> : null
        }
        {
            doc.isDirectory && reducedChildrenStatus === 'loading' ?
                <>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                </>
                : null
        }
        {
            doc.isDirectory && reducedChildrenStatus === 'error' ? 'error' : null
        }
    </TreeNode>
}

export default FileTreeNode;