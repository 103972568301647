import { createTheme, ThemeProvider } from '@mui/material/styles'
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.scss'

// page components
import AnalysisPage from './page/analysis'
import DetectPage from './page/detect'
import Error404Page from './page/error/404'
import ExplorerPage from './page/explorer'
import LoginPage from './page/login'
import MemoryPage from './page/memory'
import SettingPage from './page/setting'
import ProtectedRoutes from './util/ProtectedRoutes'

import { LicenseInfo } from '@mui/x-license-pro'
import SettingDT from 'page/setting/subpage/DT'
import { AlertProvider } from './context/AlertContext'
import AnalysisDashboard from './page/home/subpage/analysisdashboard'
import DetectDashboard from './page/home/subpage/detectdashboard'
import SettingAgentActions from './page/setting/subpage/agentActions'
import SettingGroup from './page/setting/subpage/group'
import SettingImage from './page/setting/subpage/image'
import SettingIoC from './page/setting/subpage/IoC'
import SettingServer from './page/setting/subpage/server'
import SettingSoftware from './page/setting/subpage/software'
import SettingSystem from './page/setting/subpage/system/SettingSystem'
import SettingUserPermission from './page/setting/subpage/userPermission'
import SettingWhiteList from './page/setting/subpage/whiteList'

import ErrorBoundary from 'component/ErrorBoundary'
import { env } from 'constant/index'
import { DownloadProvider } from 'context/DownloadContext'
import ErrorPage from 'page/error'
import { useDispatch, useSelector } from 'react-redux'
import { AnalysisControllerProvider } from './context/AnalysisControllerContext'
import { _displayMode, _freshStartFlag, setFSFlagFalse, setLanguage } from './redux/UtilSlice'
import UnAuth from './util/UnAuth'
import { WebSocketProvider } from 'context/WebSocketContext'
import dic, { TLanguages } from 'constant/dictionary'

LicenseInfo.setLicenseKey('9839d2f2302aba8fc37ce64c45958bd7Tz04MDc5MyxFPTE3MzQ1ODg4ODgwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=');

const queryClient = new QueryClient()

function App() {
    const displayMode = useSelector(_displayMode);
    const theme = createTheme({ palette: { mode: displayMode } });
    const dispatch = useDispatch();
    const freshStartFlag = useSelector(_freshStartFlag);

    useEffect(() => {
        if (displayMode === 'dark') {
            document.body.setAttribute('data-theme', 'dark')
        } else {
            document.body.setAttribute('data-theme', 'light')
        }
    }, [displayMode])

    useEffect(() => {
        if (env.REACT_APP_IS_PRODUCTION === 'true') {
            // console.log = () => { }
            // console.warn = () => { }
        }
        if (freshStartFlag) {
            console.log(env)
            if (env.REACT_APP_DEFAULT_LANGUAGE === 'system' || env.REACT_APP_DEFAULT_LANGUAGE === undefined){
                const sysLang = navigator.language;
                if (sysLang) {
                    const templang = sysLang.split('-')[0];
                    if (Object.keys(dic).includes(templang)){
                        dispatch(setLanguage(templang as TLanguages));
                    }
                }
            } else {
                dispatch(setLanguage(env.REACT_APP_DEFAULT_LANGUAGE))
            }
            dispatch(setFSFlagFalse());
        }
    }, []);

    return (
            <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <AlertProvider>
                    <WebSocketProvider>
                        <QueryClientProvider client={queryClient}>
                            <DownloadProvider>
                                <AnalysisControllerProvider>
                                    <BrowserRouter>
                                        <Routes>
                                            <Route element={<ProtectedRoutes />}>
                                                <Route path="/"
                                                    element={<DetectDashboard />}
                                                />
                                                <Route
                                                    path="/analysisDashboard"
                                                    element={<AnalysisDashboard />}
                                                />
                                                <Route
                                                    path="/detect/:category?/:state?"
                                                    element={<DetectPage />}
                                                />
                                                <Route
                                                    path="analysis"
                                                    element={<AnalysisPage />}
                                                />
                                                <Route
                                                    path="explorer"
                                                    element={<ExplorerPage />}
                                                />
                                                <Route
                                                    path="memory"
                                                    element={<MemoryPage />}
                                                />

                                                <Route
                                                    path="/setting"
                                                    element={<SettingPage />}
                                                >
                                                    <Route
                                                        path="SettingSystem"
                                                        element={<SettingSystem />}
                                                    />
                                                    <Route
                                                        path="SettingGroup"
                                                        element={<SettingGroup />}
                                                    />
                                                    <Route
                                                        path="SettingAgentActions"
                                                        element={<SettingAgentActions />}
                                                    />
                                                    <Route
                                                        path="SettingSoftware"
                                                        element={<SettingSoftware />}
                                                    />
                                                    <Route
                                                        path="SettingServer"
                                                        element={<SettingServer />}
                                                    />
                                                    <Route
                                                        path="SettingUserPermission"
                                                        element={<SettingUserPermission />}
                                                    />
                                                    <Route
                                                        path="SettingWhiteList"
                                                        element={<SettingWhiteList />}
                                                    />
                                                    <Route
                                                        path="SettingImage"
                                                        element={<SettingImage />}
                                                    />
                                                    <Route
                                                        path="SettingIoC"
                                                        element={<SettingIoC />}
                                                    />
                                                    <Route
                                                        path="SettingDT"
                                                        element={<SettingDT />}
                                                    />
                                                    <Route
                                                        path="*"
                                                        element={<Error404Page />}
                                                    />
                                                </Route>
                                                </Route>
                                                <Route element={<UnAuth />} errorElement={<ErrorPage />}>
                                                    <Route path="/login" element={<LoginPage />} />
                                                </Route>
                                                <Route path="*" element={<Error404Page />} />
                                            </Routes>
                                        </BrowserRouter>
                                    </AnalysisControllerProvider>
                                </DownloadProvider>
                            </QueryClientProvider>
                        </WebSocketProvider>
                    </AlertProvider>
                </ErrorBoundary>
            </ThemeProvider>
    )
}

export default App