import Highlighter from "react-highlight-words";
import { restoreInString } from 'util/index';

const RightBarRow = (props: { name: string, data: string, searchList?: [] }) => {
    const searchList = props.searchList === undefined ? [] : props.searchList;
    const searchClasses = props.searchList === undefined ?
        "highlighted" :
        props.searchList.reduce((acc: any, cv: string, ci) => {
            acc[restoreInString(cv)] = "highlighted-" + (ci);
            return acc;
        }, {})

    const replacedSearchList = searchList.map((item: string) => restoreInString(item));

    return (
        <div className='property'>
            <span>{props.name}</span>
            <p className='propertyTextField'>
                <Highlighter
                    highlightClassName={searchClasses}
                    searchWords={replacedSearchList}
                    autoEscape={true} // todo : switch the boolean Data ,according to the chart include pecial Characters
                    textToHighlight={props.data.toString()}

                    //TODO: BUG in original library:
                    //https://github.com/bvaughn/react-highlight-words/issues/68
                />
            </p>
        </div>
    )
}


export default RightBarRow