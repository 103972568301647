import * as React from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'

import dayjs, { Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { IDateModule, IDateRange } from 'interface'
import { useEffect, useState } from 'react'
import { ListItemButton } from '@mui/material'
import dic from 'constant/dictionary'

import { useSelector, useDispatch } from 'react-redux'
import { _dateSelectIndex, setDateSelectIndex, setSelectTime } from 'redux/DashboardSlice'
import { _language } from 'redux/UtilSlice'

interface TimeSelectorProps {
    defaultValues: IDateModule[]
    dateRange: IDateRange
    setDateRange: React.Dispatch<React.SetStateAction<IDateRange>>
    selectRange: string[],
    labelValue: string,
}

const TimeSelector = (props: TimeSelectorProps) => {
    const {
        defaultValues,
        dateRange,
        setDateRange,
        selectRange,
        labelValue,
    } = props

    const dispatch = useDispatch();
    const language = useSelector(_language);
    const dateSelectIndex = useSelector(_dateSelectIndex);
    const [startDateValue, setStartDateValue] = useState<Dayjs | null>(null)
    const [endDateValue, setEndDateValue] = useState<Dayjs | null>(null)
    const [selectedIndex, setSelectedIndex] = useState(dateSelectIndex) // 選擇哪時間選項
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [tempDateRange, setTempDateRange] = useState<IDateRange>(dateRange)

    const handleAnalysisChange = (item: IDateModule) => {
        if (!selectRange.includes(item.value.toString())) return
        const startTimestamp = item.value === 0 ?
            (dateRange.startTime ? dayjs(dateRange.startTime) : dayjs(+new Date())) :
            dayjs(+new Date()).subtract(item.value / 24, 'day').minute(0).second(0)
        const endTimestamp = item.value === 0 ?
            (dateRange.endTime ? dayjs(dateRange.endTime) : dayjs(+new Date())) :
            dayjs(+new Date()).minute(0).second(0)
        setStartDateValue(startTimestamp)
        setEndDateValue(endTimestamp)
        setTempDateRange({
            ...tempDateRange,
            cache: item.value,
            startTime: startTimestamp.valueOf(),
            endTime: endTimestamp.valueOf(),
        })
    }

    useEffect(() => {
        setTempDateRange(dateRange)
    }, [dateRange])

    return (
        <div className="optionsContainer">
            <span>{labelValue}</span>
            <FormControl
                sx={{
                    m: 1,
                    width: '120px',
                    height: 'min-content',
                }}
                size="small"
            >
                <Select
                    multiple
                    value={['']}
                    renderValue={() => <em>{defaultValues[selectedIndex].name}</em>}
                    open={menuIsOpen}
                    onOpen={() => {
                        setMenuIsOpen(true)
                        handleAnalysisChange(defaultValues[selectedIndex])
                    }}
                    onClose={() => {
                        setSelectedIndex(dateSelectIndex)
                        setMenuIsOpen(false)
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gridTemplateColumns: '1fr 5fr',
                        }}
                    >
                        <div style={{ width: 150 }}>
                            {defaultValues.map(
                                (item: IDateModule, i) => {
                                    return (
                                        <ListItemButton
                                            defaultValue=""
                                            style={{
                                                paddingRight: '5px',
                                                marginRight: '0px',
                                            }}
                                            selected={selectedIndex === i}
                                            key={i}
                                            onClick={() => {
                                                setSelectedIndex(i)
                                                handleAnalysisChange(item)
                                            }}
                                        >
                                            {item.name}
                                        </ListItemButton>
                                    )
                                }
                            )}
                        </div>

                        <div style={{ width: 400, marginLeft: 5 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: 10,
                                    marginBottom: 5,
                                }}
                            >
                                {
                                    defaultValues[selectedIndex].name
                                }
                                <Button
                                    variant='contained'
                                    style={{
                                        marginRight: '20px',
                                    }}
                                    onClick={() => {
                                        setMenuIsOpen(false)
                                        setDateRange(tempDateRange)
                                        dispatch(setSelectTime({
                                            cache: tempDateRange.cache || 0,
                                            startTime: tempDateRange.startTime || 0,
                                            endTime: tempDateRange.endTime || 0,
                                        }))
                                        dispatch(setDateSelectIndex(selectedIndex))
                                    }}
                                >
                                    {dic[language]["save"]}
                                </Button>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 20,
                                            minWidth: 60,
                                        }}
                                    >
                                        {dic[language]["begin_time"]}
                                    </div>
                                    <DemoContainer
                                        sx={{
                                            paddingTop: '0px',
                                            height: 50,
                                            justifyContent: 'flex-end',
                                        }}
                                        components={[
                                            'DateTimePicker',
                                            'DateTimePicker',
                                        ]}
                                    >
                                        <DateTimePicker
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                },
                                            }}
                                            label={dic[language]["begin_time"]}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: null,
                                            }}
                                            value={startDateValue}
                                            onChange={(e) => {
                                                setStartDateValue(e)
                                                setSelectedIndex(3)
                                                setTempDateRange({
                                                    ...tempDateRange,
                                                    startTime: dayjs(e).valueOf(),
                                                })
                                            }}
                                        />
                                    </DemoContainer>
                                </div>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div style={{ display: 'flex' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 20,
                                            minWidth: 60,
                                        }}
                                    >
                                        {dic[language]["end_time"]}
                                    </div>
                                    <DemoContainer
                                        sx={{
                                            paddingTop: '0px',
                                            height: 50,
                                            justifyContent: 'flex-end',
                                        }}
                                        components={[
                                            'DateTimePicker',
                                            'DateTimePicker',
                                        ]}
                                    >
                                        <DateTimePicker
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                },
                                            }}
                                            label={dic[language]["end_time"]}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: null,
                                            }}
                                            value={endDateValue}
                                            onChange={(e) => {
                                                setEndDateValue(e)
                                                setSelectedIndex(3)
                                                setTempDateRange({
                                                    ...tempDateRange,
                                                    endTime: dayjs(e).valueOf(),
                                                })
                                            }}
                                        />
                                    </DemoContainer>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                </Select>
            </FormControl>
        </div>
    )
}

export default TimeSelector

