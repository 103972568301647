/** @format */

import { useForm } from 'react-hook-form'
import {
    HookFormSubmit,
    HookFormSubmitContainer,
} from '../../../../../component/FormInput/StyledComponent'
import FormInputByHooks from '../../../../../component/FormInput/FormInputByHooks'
import {
    CommonHoriLine,
    CommonTitle,
} from '../../../../../component/CommonStyledComponent'
import CheckInputByHooks from '../../../../../component/FormInput/CheckInputByHooks'
import SelectInputByHooks from '../../../../../component/FormInput/SelectInputByHooks'
import { MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { usePost } from 'hook/usePost'
import { useGet } from 'hook/useGet'
import { API, urlRoot } from 'constant/index'
import FullLoading from '../../../../../component/FullLoading'
import { useToast } from 'hook/useToast'
import InlineInputByHooks from '../../../../../component/FormInput/InlineInputByHooks'
import { useDispatch } from 'react-redux'
import { setGlobalDirty } from 'redux/SettingSlice'
import '../../../index.scss'
import dic from "constant/dictionary";
import { useSelector } from 'react-redux';
import { _language } from 'redux/UtilSlice';

interface ServerAndEmailType {
    workerPort: number
    detectPort: number
    detectDefault: boolean
    updatePort: number
    email: string
    password: string
    ip: string
    port: number
    encryption: string
}

const defaultValues: ServerAndEmailType = {
    workerPort: 0,
    detectPort: 0,
    detectDefault: true,
    updatePort: 0,
    email: 'test@example.com',
    password: 'password',
    ip: '192.168.0.0',
    port: 25,
    encryption: 'TLS',
}

// 轉換型別規則
const handleType = (data: ServerAndEmailType): ServerAndEmailType => {
    return {
        ...data,
        workerPort: Number(data.workerPort),
        detectPort: Number(data.detectPort),
        updatePort: Number(data.updatePort),
        port: Number(data.port),
    }
}

const Child_ServerAndEmail = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const language = useSelector(_language)
    const [pageData, setPageData] = useState<ServerAndEmailType>(defaultValues)

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isSubmitting, isDirty, isValid, isSubmitSuccessful },
    } = useForm<ServerAndEmailType>({ defaultValues: pageData })

    const { mutate, isSuccess, isLoading: postLoading } = usePost()
    const { 
        data: defaultData, 
        isLoading: getLoading, 
        isSuccess: isGetDefaultSuccess,
    } = useGet({
        query: 'ServerAndEmail',
        root: `${urlRoot}`,
        route: `${API.serverAndEmail}`,
        state: { data: isSubmitSuccessful },
    })

    useEffect(() => {
        if (isGetDefaultSuccess && defaultData.data) {
            const reTypeData = defaultData.data as ServerAndEmailType
            setPageData(reTypeData)
            reset(reTypeData)
        }
    }, [isGetDefaultSuccess, reset, defaultData])

    // request server date
    const onSubmit = (result: ServerAndEmailType) => {
        console.log('onSubmit', result)
        const reTypeResult = handleType(result) // 轉換型別規則，useForm會把全部自動轉換成string
        mutate({
            root: `${urlRoot}`,
            route: `${API.serverAndEmail}`,
            body: reTypeResult,
        })
    }

    useEffect(() => {
        if (isDirty) {
            dispatch(setGlobalDirty(true))
        }
        return () => {
            dispatch(setGlobalDirty(false))
        }
    }, [isDirty])

    useEffect(() => {
        if (isSuccess) {
            toast.show({content: dic[language]["save_success"]})
        }
    }, [isSuccess])

    return (
        <div className='NavigationBody'>
            <FullLoading open={postLoading || getLoading} />
            <form
                onSubmit={handleSubmit(onSubmit)}
                style={{ display: 'flex', flexWrap: 'wrap' }}
            >
                <CommonTitle>{dic[language]["setting_system_server"]}</CommonTitle>
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_port"]}
                    name="workerPort"
                    defaultType="number"
                />
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_detect_data_port"]}
                    name="detectPort"
                    defaultType="number"
                />
                <CheckInputByHooks
                    control={control}
                    title={dic[language]["setting_system_default_detect"]}
                    name="detectDefault"
                    watch={watch('detectDefault')}
                    defaultChecked={pageData.detectDefault}
                />
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_client_update_port"]}
                    name="updatePort"
                    marginGap={170}
                    defaultType="number"
                />
                <CommonHoriLine />
                <CommonTitle>
                    {dic[language]["setting_system_mail"]}
                </CommonTitle>
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_sender_mail"]}
                    name="email"
                />
                <FormInputByHooks
                    control={control}
                    title={dic[language]["setting_system_mail_password"]}
                    name="password"
                />
                <div style={{ width: '100%', display: 'flex', margin: '10px auto' }}>

                    <InlineInputByHooks
                        control={control}
                        title={dic[language]["setting_system_mail_server"]}
                        name="ip"
                        styleOutter={{ width: '560px' }}
                        FormControlStyle={{ width: '100%' }}
                    />
                    <InlineInputByHooks
                        control={control}
                        title={dic[language]["setting_system_mail_port"]}
                        name="port"
                        defaultType="number"
                        inputWidth={90}
                        styleOutter={{ width: '160px', marginLeft: '20px' }}
                        FormControlStyle={{ width: '100%' }}
                    />

                </div>
                <SelectInputByHooks
                    control={control}
                    title={dic[language]["setting_system_mail_encryption"]}
                    name="encryption"
                    label="encryption"
                    formControlWidth={150}
                >
                    <MenuItem value="TLS">TLS</MenuItem>
                    <MenuItem value="No Idea">No Idea</MenuItem>
                </SelectInputByHooks>

                <CommonHoriLine />

                <HookFormSubmitContainer>
                    <HookFormSubmit
                        type="submit"
                        value={dic[language]["submit"]}
                        disabled={!isDirty || !isValid || isSubmitting}
                    />
                </HookFormSubmitContainer>
            </form>
        </div>
    )
}

export default Child_ServerAndEmail
