import { useMutation } from "@tanstack/react-query";
import { axiosElastic } from "../../util";
import { elasticRoot } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { _analysisData } from "redux/AnalysisSlice";
import { setElasticRestarting } from "redux/UtilSlice";

const useFetchElasticDetail = () => {

    const analysisData = useSelector(_analysisData);
    const dispatch = useDispatch();
    
    // 取得右方詳細資料
    const fetchElasticDetail = useMutation({
        // elastic search entry
        mutationFn: () => {
            return axiosElastic.post(
                `${elasticRoot}_search`,
                {
                    size: 1,
                    query: {
                        query_string: {
                            fields: ['uuid'],
                            query: analysisData.rightBar.uuid,
                        },
                    },
                }
            )
        },
        onSuccess: (res) => {
            if (res.data.message === "Elastic is restarting"){
                dispatch(setElasticRestarting(true));
            } else {
                dispatch(setElasticRestarting(false));
            }
        }
    })

    return fetchElasticDetail;

}

export default useFetchElasticDetail;