import { AccountCircle, Lock, Visibility, VisibilityOff, Translate } from '@mui/icons-material'
import { Box, Button, FormControl, IconButton, InputAdornment, MenuItem, Select, SelectProps, TextField } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import assets from 'assets'
import axios from 'axios'
import FullLoading from 'component/FullLoading'
import dic, { useI18n } from 'constant/dictionary'
import { API, urlRoot } from 'constant/index'
import { useAlert } from 'hook/useAlert'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { login, logout } from 'redux/AuthSlice'
import { _language, _translatedPermission, setLanguage } from 'redux/UtilSlice'
import './index.scss'

const LoginPage = () => {
    const alert = useAlert().showAlert;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('123');
    const [usernameMessage, setUsernameMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const permission = useSelector(_translatedPermission);

    const language = useSelector(_language);
    const t = useI18n();

    const fetchLogAuth = useMutation({
        mutationFn: () => {
            return axios.post(`${urlRoot}${API.login}`, {
                username: username,
                password: password,
            })
        },
        onSuccess: (result) => {
            if (result.data.success && result.data.user.token) {
                dispatch(login(result.data.user.token));
            } else {
                dispatch(logout())
                setUsernameMessage(dic[language]['login_error_hint'])
            }
        },
        onError: (error) => {
            console.log('onError', error)
            alert(dic[language]['login_error'])
        },
    })

    const hadleClickLogin = () => {
        if (username === '') {
            setUsernameMessage(dic[language]['login_account_empty'])
            return
        }
        if (password === '') {
            setPasswordMessage(dic[language]['login_password_empty'])
            return
        }
        fetchLogAuth.mutate()
    }

    const [showPassword, setShowPassword] = useState(false);

    //used to fixed mui select offset bug
    //https://github.com/mui/material-ui/issues/35155
    const MenuProps: SelectProps['MenuProps'] = {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        marginThreshold: 0,
      };

    return (
        <div id="LoginPage">
            <FullLoading open={fetchLogAuth.isLoading} />
            <assets.LoginWorld className='loginBackgroundImg' />
            <div className="leftPanel">
                <assets.newLogo />
                <p>
                    {t('login_cc')}
                </p>
                {/* <p>© 2024 iForensics All right reserved. <br />鑒真數位有限公司 版權所有</p> */}
            </div>
            <div className='rightPanel'>
                <p className='loginTitle'>{dic[language]['login_title']}</p>
                <Box component="form" autoComplete="off" className="form">
                    <TextField
                        className='inputField'
                        error={!~usernameMessage}
                        helperText={usernameMessage ? usernameMessage : ' '}
                        onChange={(e) => {
                            setUsernameMessage('')
                            setUsername(e.target.value)
                        }}
                        variant="outlined"
                        InputProps={{
                            placeholder: dic[language]['login_account'],
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            )
                        }}
                    />
                    <TextField
                        className='inputField'
                        error={!~passwordMessage}
                        helperText={passwordMessage ? passwordMessage : ' '}
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => {
                            setPasswordMessage('')
                            setPassword(e.target.value)
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                hadleClickLogin()
                            }
                        }}
                        variant="outlined"
                        InputProps={{
                            placeholder: dic[language]['login_password'],
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword((show) => !show)}
                                        onMouseDown={event => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={hadleClickLogin}
                    >
                        {dic[language]['login_action']}
                    </Button>
                </Box>
            </div>
            <FormControl id="languageChange">
                <Select
                    value={language}
                    IconComponent={(props) => <Translate {...props}/>}
                    MenuProps={MenuProps}>
                    <MenuItem value="en" onClick={() => dispatch(setLanguage('en'))}>English</MenuItem>
                    <MenuItem value="zh" onClick={() => dispatch(setLanguage('zh'))}>中文</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default LoginPage
