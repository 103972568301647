import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from './store'
import dayjs from "dayjs";

export interface dashboardState {
    dateSelectIndex: number
    selectTime: {
        cache: number
        startTime: number
        endTime: number
    }
}

const initialState: dashboardState = {
    dateSelectIndex: 0,
    selectTime: {
        cache: 0,
        startTime: dayjs(+new Date()).subtract(1, 'day').valueOf(),
        endTime: dayjs(+new Date()).valueOf(),
    }
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        setSelectTime: (state, action: PayloadAction<dashboardState['selectTime']>) => {
            state.selectTime = action.payload
        },
        setDateSelectIndex: (state, action: PayloadAction<number>) => {
            state.dateSelectIndex = action.payload
        }
    }
});

export const { setSelectTime, setDateSelectIndex } = dashboardSlice.actions;
export const _selectTime = (state: RootState) => state.dashboard.selectTime;
export const _dateSelectIndex = (state: RootState) => state.dashboard.dateSelectIndex;

export default dashboardSlice.reducer;