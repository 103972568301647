/** @format */

import { useEffect, useRef, useState } from 'react'
import {
    IFormatedDevice,
    SelectedBoxes,
} from 'interface'
//import { DetectContext } from '../../../../AppContext/DetectProvider'
import {
    Checkbox,
    IconButton,
    Stack,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Order } from 'util/index'
import { DetectGrid_HeadCell } from 'interface'

interface EnhancedTableProps {
    headCells: readonly DetectGrid_HeadCell[]
    numSelected: number
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof IFormatedDevice
    ) => void
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    order: Order
    orderBy: string
    rowCount: number
    groups: string[]
    setGroups: React.Dispatch<React.SetStateAction<string[]>>
    groupsMenu: string[]
    filter: SelectedBoxes
    // render groups list
    selectedGroupsInFilter?: string[]
    setSelectedGroupsInFilter?: React.Dispatch<React.SetStateAction<string[]>>
}



interface MultiSelectDropdownProps {
    options: string[]
    selectedGroupsInFilter: string[]
    setSelectedGroupsInFilter: React.Dispatch<React.SetStateAction<string[]>>
    isGroupFilterOpen: boolean
    setIsGroupFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const MultiSelectDropdown = (option: MultiSelectDropdownProps) => {
    const {
        options,
        selectedGroupsInFilter,
        setSelectedGroupsInFilter,
        isGroupFilterOpen,
        setIsGroupFilterOpen,
    } = option
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsGroupFilterOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const toggleOption = (option: string) => {
        if (selectedGroupsInFilter.includes(option)) {
            setSelectedGroupsInFilter(
                selectedGroupsInFilter.filter((item) => item !== option)
            )
        } else {
            setSelectedGroupsInFilter([...selectedGroupsInFilter, option])
        }
    }

    return (
        <>
            <div className="multi-select-dropdown" ref={dropdownRef}>
                <IconButton
                    className="dropdown-header"
                    onClick={() => setIsGroupFilterOpen(!isGroupFilterOpen)}
                >
                    <FilterAltIcon fontSize="small" />
                </IconButton>
                {isGroupFilterOpen && (
                    // <Item>
                    <Paper
                        elevation={6}
                        className="filterListInTable"
                        sx={{
                            position: 'absolute',
                            marginTop: 1,
                            padding: 1,
                            transition: 'box-shadow .25s',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                maxHeight: 250,
                                width: 150,
                                overflow: 'auto',
                            }}
                        >
                            {options.map((option, i) => (
                                <div
                                    key={
                                        i.toString() +
                                        (
                                            Math.floor(Math.random() * 1000) + 1
                                        ).toString()
                                    }
                                >
                                    <label
                                        className='filterLabel'
                                        key={option}
                                        style={{ display: 'flex', alignItems: 'center', height: 30 }}
                                    >
                                        <input
                                            type="checkbox"
                                            value={option}
                                            checked={selectedGroupsInFilter.includes(
                                                option
                                            )}
                                            onChange={() =>
                                                toggleOption(option)
                                            }
                                        />
                                        <p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>
                                            {option}
                                        </p>

                                    </label>
                                </div>
                            ))}
                        </div>
                    </Paper>
                    // </Item>
                )}
            </div>
        </>
    )
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
    // const detectiveHead = useSelector(_dectectiveHead)
    const detectiveHead = props.headCells;
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        groups,
        setGroups,
        groupsMenu,
        filter,
        selectedGroupsInFilter,
        setSelectedGroupsInFilter,
    } = props

    // control filter menu

    const createSortHandler =
        (property: keyof IFormatedDevice) =>
            (event: React.MouseEvent<unknown>) => {
                onRequestSort(event, property)
            }


    // const [selectedGroupsInFilter, setSelectedGroupsInFilter] = useState<string[]>([])
    const [isGroupFilterOpen, setIsGroupFilterOpen] = useState(false)
    const handleDirectionType = (id: keyof IFormatedDevice) => {
        if (orderBy === id) {
            return order
        } else if (id === 'groups') {
            return 'desc'
        } else {
            return 'desc'
        }
    }

    

    const headerSickyStyle = (id: keyof IFormatedDevice) => {
        return id === 'deviceName' ? {
            position: 'sticky', zIndex: 5, left: '243px'
        } : id === 'InnerIP' ? {
            position: 'sticky', zIndex: 5, left: '103px',
        } : id === 'connection' ? {
            position: 'sticky', zIndex: 5, left: '48px',
        } : {}
    }

    return (
        <TableHead>
            <TableRow style={{ whiteSpace: 'pre-line' }}>
                <TableCell padding="checkbox"
                    sx={{ position: 'sticky', left: '0px', zIndex: 5 }}
                >
                    <Checkbox
                        size='small'
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {detectiveHead.map((headCell, id) => {
                    const key = headCell.id as keyof SelectedBoxes
                    if (filter[key] === false) return null

                    return (


                        headCell.id === 'groups' ? <TableCell
                            sx={{
                                minWidth: headCell.width,
                                padding: !headCell.disablePadding ? '0 0 0 0' : 'normal',
                                whiteSpace: 'pre-line',
                                position: 'sticky', left: '443px', zIndex: 5
                            }}
                            key={
                                headCell.id +
                                (
                                    Math.floor(Math.random() * 1000) + 1
                                ).toString()
                            }
                        >
                            <Stack alignItems={'center'} direction={'row'} padding={0}>
                                {headCell.label}
                                {groups &&
                                    selectedGroupsInFilter &&
                                    setSelectedGroupsInFilter && (
                                        <MultiSelectDropdown
                                            options={groups}
                                            selectedGroupsInFilter={
                                                selectedGroupsInFilter
                                            }
                                            setSelectedGroupsInFilter={
                                                setSelectedGroupsInFilter
                                            }
                                            isGroupFilterOpen={
                                                isGroupFilterOpen
                                            }
                                            setIsGroupFilterOpen={
                                                setIsGroupFilterOpen
                                            }
                                        ></MultiSelectDropdown>
                                    )}

                                {/* <IconButton>
                            <FilterAltIcon fontSize="small" />
                        </IconButton> */}
                            </Stack>
                        </TableCell> :
                            <TableCell
                                sx={{
                                    minWidth: headCell.width,
                                    padding: !headCell.disablePadding ? '0 0 0 0' : 'normal',
                                    height: 50,
                                    ...headerSickyStyle(headCell.id)
                                }}
                                key={`${headCell.id}-${id}`}
                                align="left"
                                sortDirection={
                                    orderBy === headCell.id ? order : false
                                }
                                onClick={
                                    createSortHandler(headCell.id)
                                    // openFilterHandler
                                }
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={handleDirectionType(headCell.id)}
                                    // hideSortIcon={headCell.id === 'groups'}
                                    IconComponent={ArrowDropDownIcon}
                                >
                                    {headCell.label}
                                    {/* filter drop */}
                                    {/* TODO : cover this function because this is like SHIT !!! fix it */}
                                    {/* {headCell.id === 'groups' &&
                                groupsMenu !== undefined && (
                                    <Select
                                        MenuProps={{
                                            transformOrigin: {
                                                vertical: 'center',
                                                horizontal: 'center',
                                            },
                                        }}
                                        IconComponent={FilterAltIcon}
                                        value={
                                            groupsMenu.length === 0
                                                ? ''
                                                : groupsMenu
                                        }
                                        sx={{
                                            boxShadow: 'none',
                                            '.MuiOutlinedInput-notchedOutline':
                                                {
                                                    border: 0,
                                                },
                                            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                                                {
                                                    border: 0,
                                                },
                                            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        {groupsMenu.length > 0 &&
                                            groupsMenu.map((item, idx) => {
                                                return (
                                                    <ChildMenuItem
                                                        title={item}
                                                        key={`${item}-${idx}`}
                                                        groups={groups}
                                                        setGroups={setGroups}
                                                    />
                                                )
                                            })}
                                    </Select>
                                )} */}
                                </TableSortLabel>
                            </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

export default EnhancedTableHead
