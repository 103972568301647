import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { API, elasticRoot, urlRoot, WEBSOCKET_STATUS } from 'constant/index';
import { flattenDoc } from 'util/index';
import TreeNode from '../../../component/TreeNode'
import SkeletonTreeNode from '../../../component/TreeNode/SkeletonTreeNode';
import { useDispatch, useSelector } from 'react-redux';
import { _token } from 'redux/AuthSlice';
import { setSelectedNode, _selectedNode, IMemoryTreeNode, updateSelectedNodeChildren, IDllTreeNode } from 'redux/MemoryTreeSlice';
import { setElasticRestarting } from 'redux/UtilSlice';
import DownloadContext, { LoadDllRequest } from 'context/DownloadContext';
import useSocketHandler from 'hook/useSocketHandler';
import { useAlert } from 'hook/useAlert';

interface MemoryTreeNodeProps {
    data: IMemoryTreeNode
    root: boolean
    lastRoot?: boolean
    onContextMenu?: (event: React.MouseEvent, data: IMemoryTreeNode|IDllTreeNode, loadDllCallback?: () => void) => void
}

const MemoryTreeNode = (props: MemoryTreeNodeProps) => {

    const [closed, setClosed] = useState(!props.root)
    const [openOnced, setOpenedOnce] = useState(false);
    const [dllInfo, setDllInfo] = useState<{
        list: IDllTreeNode[]
        loadUUID: string
    }>({
        list: [],
        loadUUID: ""
    })
    const alert = useAlert();

    useEffect(() => {
        if (!closed) setOpenedOnce(true);
    }, [closed])

    const dispatch = useDispatch();
    const selectedNode = useSelector(_selectedNode);
    const token = useSelector(_token);

    const doc = props.data;
    const isDirectory = doc.child.length > 0

    const { status, data } = useQuery({
        queryKey: ['memoryRelationLookup', props.data.uuid],
        queryFn: async () => {
            if (!isDirectory) return {};
            const res = await fetch(`${elasticRoot}ed_memory/_search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: `{"size":10000,"query":{"query_string": {"default_field": "uuid", "query": "${props.data.child.join(' OR ')}"}}}`
            })
            const json = await res.json()
            return json.hits.hits.map((hit:any) => flattenDoc(hit))
        }, enabled: openOnced
    });

    const childrenReady = status === 'success' &&
        props.data.uuid === selectedNode?.uuid &&
        isDirectory;

    useEffect(() => {
        if (data && data.message === "Elastic is restarting"){
            dispatch(setElasticRestarting(true));
        } else {
            dispatch(setElasticRestarting(false));
        }
        if (childrenReady && data) {
            dispatch(updateSelectedNodeChildren({
                gridRows: data.length === 0 ? [] : data,
                gridRowsStatus: status
            }));
        }
    }, [status, data])

    const requestLoadDll = () => {
        const reqBody:LoadDllRequest = {
            action: "StartLoadDll",
            deviceId: props.data.agent,
            name: "",
            filter: {
                pid: props.data.processId.toString(),
                processCreateTime: props.data.processCreateTime
            }
        };
        setDllInfo((prev) => ({
            ...prev,
            loadUUID: '-'
        }));
        (async function(){
            try {
                const res = await fetch(`${urlRoot}${API.detectDump}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token
                    },
                    body: JSON.stringify(reqBody),
                });
                const json = await res.json();
                if (!res.ok) {
                    alert.showAlert(json.message);
                }
                setDllInfo((prev) => ({...prev, loadUUID: json.data.uuid}));
                console.log('dll load socket uuid', json.data.uuid)
            } catch(err) {
                console.log(err);
            }
        })()
    }

    useSocketHandler(WEBSOCKET_STATUS.LOAD_DLL, (message, params) => {
        if (params.dllUUID === "" || params.dllUUID === "-")return;
        if (message.uuid === params.dllUUID){
            if (message.failed !== ""){
                alert.showAlert("Error loading dll: " + message.failed);
                setDllInfo(prev => ({
                    ...prev,
                    loadUUID: ""
                }));
                return;
            }
            const dllPathList = (message.data as string).split("|");
            const newList = dllPathList.map(path => ({
                processId: props.data.processId,
                path: path,
                processCreateTime: props.data.processCreateTime,
                item_main: path.split("\\").slice(-1)[0],
                parentUUID: props.data.uuid,
                agent: props.data.agent,
                agentIP: props.data.agentIP
            }))
            setDllInfo(prev => ({
                ...prev,
                loadUUID: "",
                list: newList
            }));
            setClosed(false);
        }
    }, {dllUUID: dllInfo.loadUUID})

    return <TreeNode
        root={props.root}
        type={isDirectory?(closed?'exe+':'exe-'):'exe'}
        label={doc.processName}
        closed={closed}
        selected={props.data.uuid === selectedNode?.uuid}
        lastRoot={props.lastRoot}
        onClick={() => {
            dispatch(setSelectedNode({
                ...props.data,
                gridRowsStatus: isDirectory ? status : 'success',
                gridRows: isDirectory ? 
                    childrenReady ?
                    data.length === 0 ? 
                    [] : [props.data, ...data]
                    : null
                    : [props.data]
            }));
            setClosed(!closed);
        }}
        onContextMenu={(event) => {
            if (props.onContextMenu) props.onContextMenu(event, props.data, requestLoadDll)
        }}>
            
        {
            (status === 'success' && !closed && isDirectory && props.data.child.length !== 0) ?
                data.map((__data: IMemoryTreeNode, index: number) =>
                    <MemoryTreeNode data={__data} root={false} key={index} onContextMenu={props.onContextMenu}/>
                ) : null
        }
        {
            dllInfo.list.map(dll => {
                return <DllTreeNode 
                            data={dll} 
                            onContextMenu={
                                (event: React.MouseEvent, data: IDllTreeNode) => {
                                    if (props.onContextMenu)
                                        props.onContextMenu(event, data)
                                }
                            }
                            key={dll.path}
                        />
            })
        }
        {
            status === 'error'?
                <TreeNode type="file" label="error fetching child node"/> : null
        }
        {
            isDirectory && status === 'loading' || dllInfo.loadUUID !== ""?
                <>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                    <SkeletonTreeNode root={false}></SkeletonTreeNode>
                </>
                : null
        }
        {
            isDirectory && status === 'error' ? 'error' : null
        }
    </TreeNode>
}

interface DllTreeNodeProps {
    data: IDllTreeNode
    onContextMenu: (event: React.MouseEvent, data: IDllTreeNode) => void
}

const DllTreeNode = (props: DllTreeNodeProps) => {
    const {addItem} = useContext(DownloadContext);
    return <TreeNode
        root={false}
        type={'dll'}
        label={props.data.item_main}
        closed={true}
        selected={false}
        lastRoot={false}
        onClick={() => {
            addItem({
                action: "StartDumpDll",
                deviceId: props.data.agent,
                name: props.data.item_main + ".zip",
                filter: {
                    pid: props.data.processId.toString(),
                    path: props.data.path,
                    processCreateTime: props.data.processCreateTime
                }})
        }}
        onContextMenu={(event) => {
            if (props.onContextMenu) props.onContextMenu(event, props.data)
        }}>
    </TreeNode>
}

export default MemoryTreeNode;