// TODO: move this to common components
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { _language } from "redux/UtilSlice";
import { useSelector } from "react-redux";
import dic from "constant/dictionary";

interface ICommonRadioForm {
    title: string
    value: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioInput = (props: ICommonRadioForm) => {
    const { title, value, onChange } = props;
    const language = useSelector(_language);

    return (
        <FormControl component="fieldset" >
            <FormLabel component="legend" sx={{ width: '100%' }}>{title}</FormLabel>
            <RadioGroup
                value={value}
                onChange={onChange}
            >
                <span style={{ display: 'flex', marginLeft: 10 }}>
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={dic[language]['setting_user_activate']}
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={dic[language]['setting_user_deactivate']}
                    />
                </span>
            </RadioGroup>
        </FormControl>
    )
}

export default RadioInput