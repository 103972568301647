import { createContext } from "react";
import useElasticQueryFactory, { IMutationQuery } from "page/analysis/useElasticQueryFactory";
import useFetchAnalysis from "page/analysis/useFetchAnalysis";
import { IAnalaysisIndexNode } from "page/analysis/constant/indexTree";

export type TAnalysisController = ReturnType<typeof useFetchAnalysis> & { factory: ReturnType<typeof useElasticQueryFactory>};

const AnalysisController = createContext<TAnalysisController|null>(null);

export const AnalysisControllerProvider = (props: any) => {

    const factory = useElasticQueryFactory();
    const fetcher = useFetchAnalysis(factory);

    return (
        <AnalysisController.Provider value={{...fetcher, factory}}>
            {props.children}
        </AnalysisController.Provider>
    )
}

export default AnalysisController;