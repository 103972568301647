import { Button, Container } from "@mui/material";
import CommonHeader from "component/CommonHeader";
import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
 
    const error = useRouteError();

    return <div style={{ height: '100vh' }}>
        <CommonHeader />
        <div className="page" id="errorPage">
            <Container maxWidth="sm">
                <h2>Something went wrong.</h2>
                <p>
                    We suggest follow the steps below to fix the error;
                </p>
                <ol>
                    <li>clear cache <Button>clear</Button></li>
                    <li>Log in again</li>
                    <li>if this doesn't work please try to consult your administrator or BlockChain Security</li>
                </ol>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {error? error.toString() : null}
                    <br />
                    {/* {props.errorInfo?.componentStack} */}
                </details>
            </Container>
        </div>
    </div>
}

export default ErrorPage;