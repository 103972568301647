import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'
import Globe from 'react-globe.gl'
import EarthBackground from 'assets/img/EarthBackground.png'
import DarkEarthBackground from 'assets/img/DarkEarthBackground.png'
import { countryData } from './countryData'
import { countriesMap } from './countries'
import { EarthData, CountryEarthData, Connection, CountryEarthItem } from '../../Interfaces'
import { IsEarthData } from '../../functions'
import '../MiddleBar.scss'
import Snackbar from '@mui/material/Snackbar'
import dic from 'constant/dictionary'
import { _language, _displayMode } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'

interface arcData {
    id: number
    startLat: number
    startLng: number
    endLat: number
    endLng: number
    label1: string
    label2: number
}

interface countryLabel {
    lat: number
    lng: number
    label: string
}

interface EarthProps {
    data: EarthData | CountryEarthData
    openSnackBar: boolean
    setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>
}

const Earth = (props: EarthProps) => {
    const { data, openSnackBar, setOpenSnackBar } = props
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);
    const ref = useRef<HTMLDivElement>(null)
    const globeEl = useRef<any>(null)
    const [height, setHeight] = useState<number>(0)
    const [width, setWidth] = useState<number>(0)
    const [labeledCountry, setLabeledCountry] = useState<countryLabel[]>([])
    const [hoverArc, setHoverArc] = useState<number | null>(null)
    const [arcsData, setArcsData] = useState<arcData[]>([])

    // merge connections with same lat and long for Connection
    const processConnections = (connections: Connection[]) => {
        const connectionsMap = new Map<string, Connection>()
        connections.forEach(connection => {
            const key = connection.latitude + "," + connection.longitude
            if (connectionsMap.has(key)) {
                const value = connectionsMap.get(key)
                if (value) {
                    value.times += connection.times
                    if (!value.ip.includes(connection.ip)) {
                        value.ip += ", " + connection.ip
                    }
                    connectionsMap.set(key, value)
                }
            } else {
                connectionsMap.set(key, connection)
            }
        })
        return Array.from(connectionsMap.values())
    }

    // merge connections with same lat and long for CountryEarthItem
    const processCountryEarthItem = (connections: CountryEarthItem[]) => {
        const connectionsMap = new Map<string, CountryEarthItem>()
        connections.forEach(connection => {
            const key = connection.start.latitude + "," + connection.start.longitude
            if (connectionsMap.has(key)) {
                const value = connectionsMap.get(key)
                if (value) {
                    value.times += connection.times
                    if (!value.start.ip.includes(connection.start.ip)) {
                        value.start.ip += ", " + connection.start.ip
                    }
                    connectionsMap.set(key, value)
                }
            } else {
                connectionsMap.set(key, connection)
            }
        })
        return Array.from(connectionsMap.values())
    }

    const getLat = (lat: number, country: string) => {
        return lat ? lat : countryData[country].lat
    }

    const getLong = (long: number, country: string) => {
        return long ? long : countryData[country].long
    }

    useEffect(() => {
        if (data.country !== "" && data.connections.length) {
            const countryList: countryLabel[] = []
            const arcsList: arcData[] = []
            if (IsEarthData(data)) { // for EarthData
                const { country, latitude, longitude, connections } = data as EarthData
                // proccess connections
                const mergedConnections = processConnections(connections)
                // start country
                countryList.push({
                    lat: getLat(latitude, country),
                    lng: getLong(longitude, country),
                    label: "",
                })
                // end countries
                mergedConnections.forEach((connection, index) => {
                    // country label list
                    countryList.push({
                        lat: getLat(connection.latitude, connection.country),
                        lng: getLong(connection.longitude, connection.country),
                        label: "",
                    })
                    // arcs data list
                    arcsList.push({
                        id: index,
                        startLat: getLat(latitude, country),
                        startLng: getLong(longitude, country),
                        endLat: getLat(connection.latitude, connection.country),
                        endLng: getLong(connection.longitude, connection.country),
                        label1: language === 'zh' ?
                            (countryData[country].name +
                                " - " + countryData[connection.country].name +
                                " (" + connection.ip + ")") :
                            ((country === '-' ? 'TW' : country) +
                                " - " + (connection.country === '-' ? 'TW' : connection.country) +
                                " (" + connection.ip + ")"),
                        label2: connection.times,
                    })
                })
                setLabeledCountry(Array.from(new Set(countryList)))
                setArcsData(arcsList)
                // move to start country
                globeEl.current.pointOfView({
                    lat: latitude ? latitude : countryData[country].lat - 3,
                    lng: longitude ? longitude : countryData[country].long,
                    altitude: 1.7
                }, 800)
            } else { // for CountryEarthData
                const { country, connections } = data as CountryEarthData
                const mergedConnections = processCountryEarthItem(connections)
                mergedConnections.forEach((connection, index) => {
                    const { latitude: sLat, longitude: sLong, country: sCountry } = connection.start
                    const { latitude: eLat, longitude: eLong, country: eCountry } = connection.end
                    // start country
                    countryList.push({
                        lat: getLat(sLat, sCountry),
                        lng: getLong(sLong, sCountry),
                        label: "",
                    })
                    // end country
                    countryList.push({
                        lat: getLat(eLat, eCountry),
                        lng: getLong(eLong, eCountry),
                        label: "",
                    })
                    // arcs data
                    arcsList.push({
                        id: index,
                        startLat: getLat(sLat, sCountry),
                        startLng: getLong(sLong, sCountry),
                        endLat: getLat(eLat, eCountry),
                        endLng: getLong(eLong, eCountry),
                        label1: language === 'zh' ? (
                            countryData[sCountry].name +
                            " (" + connection.start.ip + ")" +
                            " - " + countryData[eCountry].name
                        ) : (
                            (sCountry === '-' ? 'TW' : sCountry) +
                            " (" + connection.start.ip + ")" +
                            " - " + (eCountry === '-' ? 'TW' : eCountry)
                        ),
                        label2: connection.times,
                    })
                })
                setLabeledCountry(Array.from(new Set(countryList)))
                setArcsData(arcsList)
                // move to start country
                globeEl.current.pointOfView({
                    lat: countryData[country] ? countryData[country].lat - 3 : 0,
                    lng: countryData[country] ? countryData[country].long : 0,
                    altitude: 1.7
                }, 800)
            }
        } else {
            setLabeledCountry([])
            setArcsData([])
            // move to start country
            if (data.country !== '') { // connections = []
                globeEl.current.pointOfView({
                    lat: countryData[data.country] ? countryData[data.country].lat - 3 : 0,
                    lng: countryData[data.country] ? countryData[data.country].long : 0,
                    altitude: 1.7
                }, 800)
            } else { // country = ''
                globeEl.current.pointOfView({
                    lat: countryData['-'].lat - 3,
                    lng: countryData['-'].long,
                    altitude: 1.7
                }, 800)
            }
        }
    }, [data])

    useLayoutEffect(() => {
        if (ref.current) {
            setHeight(ref.current.offsetHeight)
            setWidth(ref.current.offsetWidth)
        }
    }, [])

    useEffect(() => {
        globeEl.current.pointOfView({
            lat: 23,
            lng: 125,
            altitude: 1.7
        })
    }, [])

    const handleClose = () => {
        setOpenSnackBar(false);
    }

    const handleArcHover = (arc: object | null) => {
        if (arc) setHoverArc((arc as arcData).id)
        else setHoverArc(null)
    }

    const handleArcClick = (arc: object | null) => {
        if (arc) {
            const arcData = arc as arcData
            console.log(arcData)
        }
    }

    return (
        <div ref={ref} className='chartContainer'>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleClose}
                message="No connections."
                sx={{ marginTop: '6rem' }}
            />
            <Globe
                ref={globeEl}
                width={width}
                height={height}
                globeImageUrl={displayMode === 'dark' ? DarkEarthBackground : EarthBackground}
                backgroundColor='rgba(0,0,0,0)'

                labelsData={labeledCountry}
                labelText={"label"}
                labelSize={2}
                labelColor={() => "rgba(255, 152, 0, 1)"}
                labelDotRadius={0.6}
                labelAltitude={0.01}

                hexPolygonsData={countriesMap.features}
                hexPolygonResolution={3}
                hexPolygonMargin={0.45}
                hexPolygonColor={() => displayMode === 'dark' ? "#BEBEBE" : "white"}

                onArcClick={handleArcClick}
                onArcHover={handleArcHover}
                arcsData={arcsData}
                arcStartLat={(d: object) => 'startLat' in d ? +(d as arcData).startLat : 0}
                arcStartLng={(d: object) => 'startLng' in d ? +(d as arcData).startLng : 0}
                arcEndLat={(d: object) => 'endLat' in d ? +(d as arcData).endLat : 0}
                arcEndLng={(d: object) => 'endLng' in d ? +(d as arcData).endLng : 0}
                arcLabel={(d: object) => 'label1' in d && 'label2' in d ?
                    '<div class="arcHoverLabel">' +
                    '<div>' + (d as arcData).label1 + '</div>' +
                    '<div>' + dic[language]["times"] + '：' + (d as arcData).label2 + '</div>' +
                    "</div>" : ''}
                arcStroke={() => 0.75}
                arcColor={(d: object) => hoverArc === (d as arcData).id ? 'rgba(255, 152, 0, 1)' : 'rgba(190, 190, 190, 1)'}
                arcsTransitionDuration={0}
            />
        </div>
    )
}

export default Earth