import { useState, useEffect } from "react"
import TopBar from "./component/TopBar"
import LeftBar from "./component/LeftBar"
import MiddleBar from "./component/MiddleBar"
import RightBar from "./component/RightBar"
import { EarthData, CountryEarthData, SelectedRow, KeywordHitData, LineChartData } from "./component/Interfaces"
import { IDateRange } from "interface"
import {
    InitSelectedRow,
    InitEarthData,
    InitLineChartData,
} from "./component/functions"
import dic from "constant/dictionary"
import { useSelector } from "react-redux"
import { _selectTime } from "redux/DashboardSlice"
import { _language } from "redux/UtilSlice"
import '../../index.scss'
import './index.scss'
import useDenyIfNot from "hook/useDenyIfNot"

const AnalysisDashboard = () => {

    useDenyIfNot('dashboard');
    const language = useSelector(_language);
    const selectTime = useSelector(_selectTime);
    const [dateModuleData, setDateModuleData] = useState<IDateRange>({
        cache: selectTime.cache,
        startTime: selectTime.startTime,
        endTime: selectTime.endTime,
    })
    const [selectedRow, setSelectedRow] = useState<SelectedRow>(InitSelectedRow())
    const [earthData, setEarthData] = useState<EarthData | CountryEarthData>(InitEarthData())
    const [lineChartData, setLineChartData] = useState<LineChartData>(InitLineChartData())
    const [barChartData, setBarChartData] = useState<KeywordHitData[]>([])
    const [displayChart, setDisplayChart] = useState<'earth' | 'bar' | 'line'>('earth')
    const [openSnackBar, setOpenSnackBar] = useState(false)

    useEffect(() => {
        setSelectedRow(InitSelectedRow())
        setEarthData(InitEarthData())
        setLineChartData(InitLineChartData())
        setBarChartData([])
        setDisplayChart('earth')
    }, [dateModuleData])

    useEffect(() => {
        setDisplayChart('bar')
    }, [barChartData])

    useEffect(() => {
        setDisplayChart('line')
    }, [lineChartData])

    useEffect(() => {
        setDisplayChart('earth')
    }, [earthData])

    // useEffect(() => {

    // throw new Error('haha');
    // })

    return (
        <div id="AnalysisDashboard" className="dashboard">
            <TopBar
                dateModuleData={dateModuleData}
                setDateModuleData={setDateModuleData}
            />
            <div style={{
                display: 'flex',
            }}>
                <LeftBar
                    dateModuleData={dateModuleData}
                    setEarthData={setEarthData}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setLineChartData={setLineChartData}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <MiddleBar
                    dateModuleData={dateModuleData}
                    earthData={earthData}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setEarthData={setEarthData}
                    displayChart={displayChart}
                    barChartData={barChartData}
                    lineChartData={lineChartData}
                    openSnackBar={openSnackBar}
                    setOpenSnackBar={setOpenSnackBar}
                />
                <RightBar
                    dateModuleData={dateModuleData}
                    setEarthData={setEarthData}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    setBarChartData={setBarChartData}
                    setLineChartData={setLineChartData}
                    setOpenSnackBar={setOpenSnackBar}
                />
            </div>
        </div>
    )
}

export default AnalysisDashboard
