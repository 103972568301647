import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { _language, setLanguage } from 'redux/UtilSlice';
import { useSelector, useDispatch } from 'react-redux';
import dic from 'constant/dictionary';

const Language = () => {
    const dispatch = useDispatch()
    const language = useSelector(_language)
    const [selectedLanguage, setSelectedLanguage] = useState<'zh' | 'en'>(language)

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value as 'zh' | 'en')
    }

    return (
        <div className='ChildInnerWhiteContainerSmall'>
            <Typography
                variant="h6"
                sx={{ marginTop: '1rem' }}
            >
                {dic[language]['setting_software_language']}
            </Typography>
            <Stack direction="row" alignItems="center">
                <span>{dic[language]['setting_software_please_select_language']}：</span>
                <Select
                    size='small'
                    value={selectedLanguage}
                    onChange={handleChange}
                    sx={{ marginLeft: '3rem', width: '8rem' }}
                >
                    <MenuItem value='zh'>{dic[language]['setting_software_language_zh']}</MenuItem>
                    <MenuItem value='en'>{dic[language]['setting_software_language_en']}</MenuItem>
                </Select>
            </Stack>
            <Divider sx={{ marginTop: '2rem' }} />
            <Button
                variant="contained"
                sx={{
                    marginTop: '2rem',
                    bgcolor: 'warning.light',
                    "&:hover": {
                        bgcolor: 'warning.dark',
                    }
                }}
                onClick={() => dispatch(setLanguage(selectedLanguage))}
            >
                {dic[language]['save']}
            </Button>
        </div>
    )
}

export default Language
