import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from './store'
import { IDateRange } from "interface";

export type SortModel = {field: string, sort: 'desc'|'asc'} | null;
export interface networkState {
    filterMode: 'off' | 'specificProcess' | 'otherIP',
    processFilter: {
        processId: number
        agentIP: string
        processCreateTime: number,
        agent: string
        processName: string
    }
    searchIP: string
    otherIP: string
    // lastRows: Array<{
    //     uuid: string,
    //     agentIP: string,
    //     processCreateTime: number,
    //     processId: number,
    // }>
    lastRows: Array<any>
    sortModel: SortModel
    page: number
    dateRange: IDateRange
}

const initialState: networkState = {
    filterMode: 'off',
    processFilter: {
        processId: 0,
        agentIP: '',
        processCreateTime: 0,
        agent: '',
        processName: ''
    },
    otherIP: '',
    searchIP: '',
    lastRows: [],
    page: 0,
    sortModel: null,
    dateRange: {
        startTime: 0,
        endTime: 0
    }
}

export const networkSlice = createSlice({
    name: 'network',
    initialState: initialState,
    reducers: {
        filterProcess: (state, action) => {
            state.filterMode = 'specificProcess';
            let newProcessFilter = {...state.processFilter, ...action.payload};
            if (JSON.stringify(state.processFilter)!==JSON.stringify(newProcessFilter)){
                state.processFilter = newProcessFilter;
                state.lastRows = [];
                state.page = 0;
            }
        },
        filterOtherIP: (state, action) => {
            state.filterMode = 'otherIP';
            if (state.otherIP !== action.payload){
                state.otherIP = action.payload;
                state.lastRows = [];
                state.page = 0;
            }
        },
        filterSearchIP: (state, action) => {
            if (state.searchIP !== action.payload){
                state.searchIP = action.payload;
                state.lastRows = [];
                state.page = 0;
            }
        },
        setDateRange: (state, action: PayloadAction<IDateRange>) => {
            state.dateRange = action.payload;
        },
        setLastRows: (state, action) => {
            state.lastRows = action.payload;
        },
        pageInc: (state, action) => {
            state.page += 1;
        },
        pageDec: (state, action) => {
            state.page -= 1;
        },
        clearFilter: (state) => {
            state.filterMode = 'off';
        },
        changeSortModel: (state, action: PayloadAction<SortModel | undefined>) => {
            state.page = 0;
            state.lastRows = [];
            if (action.payload === undefined){
                state.sortModel = null;
            } else {
                state.sortModel = action.payload;
            }
        }
    }
});

export const { changeSortModel, filterSearchIP, filterProcess, filterOtherIP, setDateRange, clearFilter, setLastRows, pageInc, pageDec } = networkSlice.actions;
export const _processFilter = (state: RootState) => state.network.processFilter;
export const _filterMode = (state: RootState) => state.network.filterMode;
export const _searchIP = (state: RootState) => state.network.searchIP;
export const _otherIP = (state: RootState) => state.network.otherIP;
export const _timeFilter = (state: RootState) => state.network.dateRange;
export const _page = (state: RootState) => state.network.page;
export const _lastRows = (state: RootState) => state.network.lastRows;
export const _dateModuleData = (state: RootState) => state.network.dateRange;
export const _sortModel = (state: RootState) => state.network.sortModel;

export default networkSlice.reducer;
