/** @format */
import { useContext, useEffect, useRef, useState } from 'react'
import * as R from 'ramda'

import { API, WEBSOCKET_STATUS, socketRoot, urlRoot } from 'constant/index'
import GridTable from './component/DetectGridTable/GridTable/GridTable'
import Error404Page from '../error/404'
import { IDevice, IFormatedDevice } from 'interface'
import { customData, matchDiffObjects } from 'util/index'
// import DetectProvider from '../AppContext/DetectProvider'
import { _token } from 'redux/AuthSlice'
import { useSelector } from 'react-redux'
import HorizontalBar from './component/HorizontalBar/HorizontalBar'
import { useGet } from 'hook/useGet'
import { usePost } from 'hook/usePost'
import FullLoading from '../../component/FullLoading'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'
import './index.scss'
import useSocketHandler from 'hook/useSocketHandler'
import useDenyIfNot from 'hook/useDenyIfNot'
import { error } from 'console'

// 2 types of table data
// 1. raw data → type: IDevice
// 2. formed data → type: IFormatedDevice

const DetectPage = () => {

    const [rows, setRows] = useState<IFormatedDevice[]>([])
    const [ubuntuNumber, setUbuntuNumber] = useState<number>(0)
    const language = useSelector(_language)

    const {
        data: detectData,
        isLoading: detectLoading,
        isError: detectError,
        dataUpdatedAt,
    } = useGet({
        query: 'detectData',
        root: `${urlRoot}`,
        route: `${API.agentAll}`
    })

    useDenyIfNot('detect');

    useEffect(() => {
        if (detectError || !detectData?.data) return;
        setRows((detectData.data as IDevice[]).map((obj: IDevice) => customData(obj, language)))
    }, [detectError, detectData, dataUpdatedAt])

    useSocketHandler(WEBSOCKET_STATUS.DETECT_UPDATE, (message) => {
        if (message.data === undefined || message.data.length === 0) return;
        const insertArr = (message.data as IDevice[]);
        setRows((prev) => {
            const oldDeviceIds = prev.map(ia => ia.deviceId);

            const filterAdded = insertArr.filter(row => !oldDeviceIds.includes(row.deviceId));
            for (let i = 0; i < prev.length; i++){
                for (let j = 0; j < insertArr.length; j++) {
                    if (prev[i].deviceId === insertArr[j].deviceId){
                        prev[i] = customData(insertArr[j], language)
                    }
                }
            }

            return prev.concat(filterAdded.map(row => customData(row, language)));
        })
    });
    
    if (detectError) return <Error404Page errorMessage={dic[language]['database_connect_fail']} />

    return (
        detectLoading ? <FullLoading open={detectLoading} /> :
            <div className='page' id="detectContainer">
                <HorizontalBar 
                    setData={(a:any) => {
                        setRows(a)
                    }} 
                    data={rows} 
                    ubuntuNumber={ubuntuNumber} />
                <GridTable
                    data={rows}
                    ubuntuNumber={ubuntuNumber}
                    setUbuntuNumber={setUbuntuNumber}
                />
            </div>
    )
}

export default DetectPage;