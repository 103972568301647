import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    DialogTitle,
    IconButton,
} from '@mui/material'
import { useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { _language } from 'redux/UtilSlice'
import { useSelector } from 'react-redux'
import dic from 'constant/dictionary'
import { useToast } from 'hook/useToast'
import { useAlert } from 'hook/useAlert'
import { API, urlRoot } from 'constant/index'
import { usePost } from 'hook/usePost'
import { KeywordData } from '.'

interface IFormDialog {
    titleText: string // 標題文字
    rows: KeywordData[] // 外部table的資料
    inputLableList: string[] // 輸入框的標籤名稱列表
    tableData?: KeywordData //tablerow 點擊編輯時的row data
    refetchDefaultKeywordsList: () => void
}

const KeywordFormDialog = (props: IFormDialog) => {
    const {
        titleText,
        inputLableList,
        tableData,
        rows,
        refetchDefaultKeywordsList,
    } = props;

    const toast = useToast();
    const { showAlert } = useAlert();
    const [open, setOpen] = useState(false);
    const [inputString, setInputString] = useState(
        tableData ? [tableData.keyword, tableData.reason] : ['', '']
    );
    const [isLegal, setIsLegal] = useState(true);
    const [isInitData, setIsInitData] = useState(true);
    const language = useSelector(_language);
    const addMutation = usePost();
    const updateMutation = usePost();

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const memoryInputString = (input: string, index: number) => {
        let newInputString = [...inputString];
        newInputString[index] = input;
        setInputString(newInputString);
        // 比較 input 是否為初始值
        setIsInitData(newInputString[0] === '' && newInputString[1] === '');
        // 比較 input 在 table 中是否已經存在
        setIsLegal(!rows.some((item: KeywordData) => (
            item.keyword === newInputString[0] &&
            item.reason === newInputString[1]
        )));
    }

    const handleAdd = () => {
        addMutation.mutate({
            method: 'post',
            root: `${urlRoot}`,
            route: `${API.keywordlist}`,
            body: {
                keyword: inputString[0],
                reason: inputString[1],
            },
        }, {
            onSuccess: () => {
                refetchDefaultKeywordsList();
                setInputString(['', '', '']);
                setOpen(false);
                toast.show({content: dic[language]['add_success']});
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(dic[language]['add_fail'] || error.response.data.message);
                } else {
                    console.log(error.response?.data.message)
                }
            }
        })
    }

    const handleUpdate = () => {
        updateMutation.mutate({
            method: 'put',
            root: `${urlRoot}`,
            route: `${API.keywordlist}`,
            body: {
                id: tableData?.id,
                keyword: inputString[0],
                reason: inputString[1],
            },
        }, {
            onSuccess: () => {
                refetchDefaultKeywordsList();
                setOpen(false);
                toast.show({content: dic[language]['save_success']});
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(dic[language]['save_fail'] || error.response.data.message);
                } else {
                    console.log(error.response?.data.message)
                }
            }
        })
    }

    return (
        <>
            {titleText === dic[language]['update'] && (
                <IconButton
                    size="small"
                    className='actionButton'
                    onClick={handleClickOpen}
                >
                    <EditIcon />
                </IconButton>
            )}
            {titleText === dic[language]['add'] && (
                <Button onClick={handleClickOpen} variant="contained">
                    {dic[language]['setting_keyword_list_add']}
                </Button>
            )}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle
                    sx={{
                        backgroundColor: titleText === dic[language]['delete'] ? '#D32F2F' : '#2196F3',
                        color: 'white',
                        padding: '5px 15px',
                        fontSize: '1rem',
                    }}
                >
                    {`${titleText}${language === 'en' ? ' ' : ''}${dic[language]['setting_keyword_list']}`}
                </DialogTitle>

                <DialogContent>
                    {inputLableList.map(
                        (item: string, index: number) => {
                            return (
                                <TextField
                                    required={index === 0}
                                    key={index}
                                    margin="dense"
                                    id="name"
                                    label={item}
                                    type='text'
                                    fullWidth
                                    variant="standard"
                                    value={inputString[index]}
                                    onChange={(e) =>
                                        memoryInputString(
                                            e.target.value,
                                            index
                                        )
                                    }
                                />
                            )
                        }
                    )}
                    {isInitData || inputString[0] === '' ? (
                        <DialogContentText style={{ color: 'gray' }}>
                            {dic[language]['no_input_data']}
                        </DialogContentText>
                    ) : isLegal ? null : (
                        <DialogContentText style={{ color: 'red' }}>
                            {dic[language]['input_repeated']}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={
                            titleText === dic[language]['update'] ? handleUpdate : handleAdd
                        }
                        disabled={!isLegal || isInitData || inputString[0] === ''}
                    >
                        {dic[language]['confirm']}
                    </Button>
                    <Button
                        onClick={handleClose}
                        sx={{ color: 'gray' }}
                    >
                        {dic[language]['cancel']}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default KeywordFormDialog