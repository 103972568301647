import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js'
import { Pie, Doughnut } from 'react-chartjs-2'
import dic from 'constant/dictionary'
import { _displayMode, _language } from 'redux/UtilSlice';
import { TLanguages } from 'constant/dictionary';
import { useSelector } from 'react-redux';

ChartJS.register(ArcElement, Tooltip, Legend)

export interface IData {
    name: string
    amount: number
    good: number
    bad: number
    color: string
}

export interface taskPieData {
    name: string
    amount: number
    done: number
    running: number
    unknown: number
    color: string
}

const cleanTaskData = (data: taskPieData[], language: TLanguages) => {
    // not showing data with amount 0
    const newData = data.filter((item) => item.amount !== 0)

    // group limit, only show 10 groups ,others group will be combined
    const groupLimit = 9;

    const sortedGroup = newData.sort((a, b) => b.amount - a.amount)
    if (data.length > groupLimit) {
        const otherGroup = sortedGroup.slice(groupLimit)
        const otherAmount = otherGroup.reduce((acc, cur) => acc + cur.amount, 0)
        const otherDone = otherGroup.reduce((acc, cur) => acc + cur.done, 0)
        const otherRunning = otherGroup.reduce((acc, cur) => acc + cur.running, 0)
        const otherUnknown = otherGroup.reduce((acc, cur) => acc + cur.unknown, 0)
        const otherColor = sortedGroup[groupLimit + 1].color
        const other = {
            name: dic[language]['setting_system_agent_other'],
            amount: otherAmount,
            done: otherDone,
            running: otherRunning,
            unknown: otherUnknown,
            color: otherColor,
        }
        const newGroup = sortedGroup.slice(0, groupLimit)
        newGroup.push(other)
        return newGroup
    }

    return newData
}

interface IPieChartProps {
    data: taskPieData[]
}

export const PieChart = (props: IPieChartProps) => {
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);

    const { data } = props

    const newData = cleanTaskData(data, language)

    const labels: string[] = [], amount: number[] = [], pieChartColors: string[] = []
    let running = 0, unknown = 0
    newData.forEach((item) => {
        running += item.running
        unknown += item.unknown
    })
    if (unknown > 0) {
        labels.push(dic[language]['unknown'])
        amount.push(unknown)
        pieChartColors.push('#555555')
    }
    if (running > 0) {
        labels.push(dic[language]['running'])
        amount.push(running)
        pieChartColors.push('#999999')
    }
    newData.forEach((item) => {
        labels.push(item.name)
        amount.push(item.done)
        pieChartColors.push(item.color)
    })

    const options: ChartOptions<'pie'> = {
        plugins: {
            legend: {
                position: 'right',

                labels: {
                    color: displayMode === 'dark' ? '#F7F7F7' : '#1F1F1F',
                }
            },
        },

        maintainAspectRatio: false,
    }
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: dic[language]['dashboard_pie_chart_label'],
                data: amount,
                backgroundColor: pieChartColors,
                borderColor: displayMode === 'dark' ? '#1F1F1F' : '#F7F7F7',
                borderWidth: 4,
            },
        ],
    }
    return (
        <div
            style={{
                width: '100%',
                height: data.length === 0 ? '50%' : '100%',
            }}
        >
            {data.length === 0 ? (
                <p style={{ textAlign: 'center', fontSize: 'bold' }}>
                    {dic[language]['no_group_hint']}
                </p>
            ) : (
                <Pie
                    data={chartData}
                    height={100}
                    width={100}
                    options={options}
                />
            )}
        </div>
    )
}

const cleanConnectData = (data: IData[], language: TLanguages) => {
    // not showing data with amount 0
    const newData = data.filter((item) => item.amount !== 0)

    // group limit, only show 10 groups ,others group will be combined
    const groupLimit = 9;

    const sortedGroup = newData.sort((a, b) => b.amount - a.amount)
    if (data.length > groupLimit) {
        const otherGroup = sortedGroup.slice(groupLimit)
        const otherAmount = otherGroup.reduce((acc, cur) => acc + cur.amount, 0)
        const otherGood = otherGroup.reduce((acc, cur) => acc + cur.good, 0)
        const otherBad = otherGroup.reduce((acc, cur) => acc + cur.bad, 0)
        const otherColor = sortedGroup[groupLimit + 1].color
        const other = {
            name: dic[language]['setting_system_agent_other'],
            amount: otherAmount,
            good: otherGood,
            bad: otherBad,
            color: otherColor,
        }
        const newGroup = sortedGroup.slice(0, groupLimit)
        newGroup.push(other)
        return newGroup
    }

    return newData
}

interface IDoughnutChartProps {
    data: IData[]
}

export const DonutChart = (props: IDoughnutChartProps) => {
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);

    const { data } = props
    const newData = cleanConnectData(data, language)

    const labels: string[] = [], amount: number[] = [], pieChartColors: string[] = []
    let disconnected = 0
    newData.forEach((item) => {
        disconnected += item.bad
    })
    if (disconnected > 0) {
        labels.push(dic[language]['disconnected'])
        amount.push(disconnected)
        pieChartColors.push('#555555')
    }
    newData.forEach((item) => {
        labels.push(item.name)
        amount.push(item.good)
        pieChartColors.push(item.color)
    })

    const options: ChartOptions<'doughnut'> = {
        plugins: {
            legend: {
                position: 'right',

                labels: {
                    color: displayMode === 'dark' ? '#F7F7F7' : '#1F1F1F',
                }
            },
        },
        maintainAspectRatio: false,
    }
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: dic[language]['dashboard_pie_chart_label'],
                data: amount,
                color: '#ffffff',
                backgroundColor: pieChartColors,
                borderColor: displayMode === 'dark' ? '#1F1F1F' : '#F7F7F7',
                borderWidth: 4,
            },
        ],
    }

    return (
        <div
            style={{
                width: '100%',
                height: data.length === 0 ? '50%' : '100%',
            }}
        >
            {data.length === 0 ? (
                <p style={{ textAlign: 'center', fontSize: 'bold' }}>
                    {dic[language]['no_group_hint']}
                </p>
            ) : (
                <Doughnut
                    data={chartData}
                    height={100}
                    width={100}
                    options={options}
                />
            )}
        </div>
    )
}


// todo : 如果沒有資料就顯示 "沒有群組"  等字樣 V
// todo : 如果有群組但沒有資料，不要顯示 V
// todo : 最多顯示十筆，超過的當作其他