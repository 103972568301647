import { useEffect, useState } from 'react'
import { IGenerateGroup } from 'interface'
import { IGroupCount } from './GroupDropDown'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setSelectedId } from 'redux/AnalysisSlice'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

interface GroupHeaderProps {
    groupCount: IGroupCount[]
}

const GroupHeader = (props: GroupHeaderProps) => {
    const analysisData = useSelector(_analysisData);
    const { groups, selectedHostsIDs } = analysisData
    const dispatch = useDispatch();
    const language = useSelector(_language);
    
    const { groupCount } = props
    const [count, setCount] = useState<number>(0)
    const [totalDevices, setTotalDevices] = useState<number>(0)

    useEffect(() => {
        const groupSet = groups.map((item) =>
            item.devices.map((item) => item.id)
        ) // get all the id
        const flatGroupSet = groupSet.flat() // flat the ID array
        const GroupSetLength = new Set(flatGroupSet).size // get the length of all the ID
        setTotalDevices(GroupSetLength)
    }, [])

    useEffect(() => {
        const stateCount = groupCount.filter((item) => item.state === true)
        setCount(stateCount.length)
    }, [groupCount])

    return (
        <div
            style={{
                width: '100%',
                margin: '3px auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
            }}
        >
            <div style={{ width: '80%', display: 'flex' }}>
                <p>{dic[language]['analysis_has_selected']}</p>
                <p
                    style={{ marginLeft: 10 }}
                >{`${dic[language]['group']} ${count}/${groups.length}`}</p>
                <p
                    style={{ marginLeft: 10 }}
                >{`${dic[language]['analysis_devices']} ${selectedHostsIDs.length}/${totalDevices}`}</p>
            </div>
            <div>
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                        dispatch(setSelectedId([]))
                    }}
                >
                    {dic[language]['clear']}
                </Button>
            </div>
        </div>
    )
}

export default GroupHeader
