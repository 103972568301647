import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { ThemeProvider } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'
import { theme } from './constant/theme'
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { WebSocketProvider } from 'context/WebSocketContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={<CircularProgress />} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
)

reportWebVitals()
