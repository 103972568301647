import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from './store';
import { TLanguages } from 'constant/dictionary';
import { userInfo } from "interface";

type TDisplayMode = 'dark' | 'light';
export type TPermission = 'setting' | 'user' | 'detect' | 'analysis' | 'dashboard';
export const PERMISSION:TPermission[] = ['dashboard', 'analysis', 'detect', 'user', 'setting'];
export interface utilState {
    displayMode: TDisplayMode
    language: TLanguages
    elasticRestarting: boolean
    userInfo: userInfo
    translatedPermission: TPermission[],
    freshStart:boolean
}

const initialState: utilState = {
    displayMode: 'dark',
    language: 'zh',
    elasticRestarting: false,
    userInfo: {
        userID: 0,
        department: '',
        username: '',
        email: '',
        permission: 0,
        timezone: '',
        groups: []
    },
    translatedPermission: [],
    freshStart: true
}

export const utilSlice = createSlice({
    name: 'util/index',
    initialState: initialState,
    reducers: {
        setDisplayMode: (state:utilState, action: PayloadAction<TDisplayMode>) => {
            state.displayMode = action.payload;
        },
        setLanguage: (state:utilState, action: PayloadAction<TLanguages>) => {
            state.language = action.payload;
        },
        setElasticRestarting: (state: utilState, action: PayloadAction<boolean>) => {
            state.elasticRestarting = action.payload
        },
        setUserInfo: (state: utilState, action: PayloadAction<userInfo>) => {
            state.userInfo = action.payload;
            let permission = action.payload.permission;
            let index = 0;
            let tp:TPermission[] = [];
            do{
                if (permission%2){
                    tp.push(PERMISSION[index]);
                }
                index++;
            }while(permission >>= 1);
            state.translatedPermission = tp;
        },
        setFSFlagFalse: (state: utilState) => {
            state.freshStart = false;
        }
    }
});


export const { setDisplayMode, setLanguage, setElasticRestarting, setUserInfo, setFSFlagFalse } = utilSlice.actions;
export const _displayMode = (state:RootState) => state.util.displayMode;
export const _language = (state:RootState) => state.util.language;
export const _elasticRestarting = (state:RootState) => state.util.elasticRestarting;
export const _userInfo = (state:RootState) => state.util.userInfo;
export const _translatedPermission = (state:RootState) => state.util.translatedPermission;
export const _freshStartFlag = (state:RootState) => state.util.freshStart;


export default utilSlice.reducer;
