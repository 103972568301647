import {
    useMutation,
    UseMutationResult,
    useQueryClient,
} from '@tanstack/react-query'
import { axiosClient } from '../util'
import { AxiosError } from 'axios'

interface IuseFetch {
    root: string
    route: string
    body: Record<string, any>
    contentType?: string
    method?: HttpMethod
}

export type HttpMethod = 'post' | 'put' | 'delete' | 'patch'

interface IResponse<T> {
    data: T
}

export interface ErrorResponse {
    message: string
}

const fetchData = async <T>({ root, route, body, method = 'post', contentType }: IuseFetch): Promise<IResponse<T>> => {
    const methodMap: Record<HttpMethod, (url: string, body?: Record<string, any>, contentType?: Record<string, any>) => Promise<any>> = {
        post: axiosClient.post,
        put: axiosClient.put,
        delete: axiosClient.delete,
        patch: axiosClient.patch,        
    }

    // Ensure the method exists in our map, otherwise default to POST
    const axiosMethod = methodMap[method] || axiosClient.post

    const { data } = await axiosMethod(`${root}${route}`, method === 'delete' ? { data: body } : body, { headers: { 'Content-Type': contentType } })
    return data
}

export const usePost = <T>(): UseMutationResult<IResponse<T>, AxiosError<ErrorResponse>, IuseFetch> => {
    const queryClient = useQueryClient();

    return useMutation(fetchData<T>, {
        onSuccess: (_, variables) => {
            // Invalidate every query with a key that starts with `variables.route`
            queryClient.invalidateQueries([variables.route]);
        },
    })
}
