import { Checkbox } from '@mui/material'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { HookFormContainer, HookFormFlexBox } from './StyledComponent'
import { ICommonFuncData } from '../../page/setting/subpage/system/Child/Child_CommonFunc'
import { IAgentSettingData } from '../../page/setting/subpage/system/Child/Child_AgentSetting'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import dic from 'constant/dictionary'

interface ICheckInputByHooks {
    control:
    | Control<FieldValues, any>
    | Control<ICommonFuncData | any>
    | Control<IAgentSettingData | any>
    title: string
    name: string
    subTitle?: string
    watch?: any
    defaultChecked?: boolean
}

const CheckInputByHooks = (props: ICheckInputByHooks) => {
    const { control, title, name, subTitle, watch, defaultChecked } = props
    // const [checked, setChecked] = useState(false);
    const language = useSelector(_language)

    return (
        <HookFormContainer>
            <HookFormFlexBox>
                <span>
                    {title} {title ? ':' : ''}
                </span>
                <div
                    style={{
                        width: 400,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Controller
                        name={name}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Checkbox
                                size="small"
                                value={value}
                                checked={watch}
                                onChange={(e) => {
                                    onChange(e.target.checked)
                                }}
                            />
                        )}
                    />
                    {subTitle && <span>{subTitle}</span>}
                    {!subTitle && <span>
                        {watch ? dic[language]["open"] : dic[language]["close"]}
                    </span>}
                </div>
            </HookFormFlexBox>
        </HookFormContainer>
    )
}

export default CheckInputByHooks
