import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { useDispatch, useSelector } from 'react-redux'
import { _analysisData, setSubKeyword, setMainKeyword } from 'redux/AnalysisSlice'
import { Avatar } from '@mui/material'
import { restoreInString } from 'util/index'

const SubKeywordBox = () => {
    const analysisData = useSelector(_analysisData);
    const dispatch = useDispatch();

    const restoreCharactersInArray = (stringList: string[]) => {
        return stringList.map((str) => restoreInString(str))
    }
    const handleDelete = (index: number) => {
        let newSubSearchKeywordList = [...analysisData.subKeywordList];
        if (newSubSearchKeywordList[index] === analysisData.mainKeyword) {
            dispatch(setMainKeyword(''));
        }
        newSubSearchKeywordList.splice(index, 1);
        dispatch(setSubKeyword(newSubSearchKeywordList));
    }

    const subKeywordList = restoreCharactersInArray(analysisData.subKeywordList)
    const colorList = ["#CBE4F9", "#CDF5F6", "#EFF9DA", "#D6CDEA", "#F9D8D6", "#F9EBDF"]

    return (
        <Stack direction="row" spacing={1} style={{ marginBottom: 5 }}>
            {subKeywordList.map((item: string, index: number) => {
                return (
                    <Chip
                        avatar={<Avatar sx={{backgroundColor: colorList[index%colorList.length]}}> </Avatar>}
                        key={index}
                        label={item}
                        onDelete={() => {
                            handleDelete(index)
                        }}
                    />
                )
            })}
        </Stack>
    )
}

export default SubKeywordBox
