import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import SearchBarWithChips from '../../../../../component/SearchBarWithChips';
import { _language } from 'redux/UtilSlice';
import { useSelector } from 'react-redux';
import dic from 'constant/dictionary';
import {
    Stack,
} from '@mui/material';

interface EnhancedTableToolbarProps {
    searchKeywordList: string[]
    setSearchKeywordList: React.Dispatch<React.SetStateAction<string[]>>
    handleApply: () => void
    isApplying: boolean
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const {
        searchKeywordList,
        setSearchKeywordList,
        handleApply,
        isApplying,
    } = props;
    const language = useSelector(_language);

    return (
        <Stack direction='row'>
            <SearchBarWithChips
                searchKeywordList={searchKeywordList}
                setSearchKeywordList={setSearchKeywordList}
                chipLength={5}
            />
            <LoadingButton
                size='small'
                onClick={handleApply}
                loading={isApplying}
                variant="contained"
                endIcon={<SendIcon />}
                sx={{ margin: '0.75rem 0' }}
            >
                <span>{dic[language]['apply']}</span>
            </LoadingButton>
        </Stack>
    )
}

export default EnhancedTableToolbar