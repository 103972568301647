import { useEffect } from "react";
import { useGet } from "./hook/useGet";
import { IGenerateGroup, oneHostData, requestHostData } from "./interface";
import dic from "./constant/dictionary";
import { useSelector, useDispatch } from "react-redux";
import { _language } from "./redux/UtilSlice";
import { setGroups } from "./redux/AnalysisSlice";
import { API, urlRoot } from "./constant";

const Initialize = () => {
    const dispatch = useDispatch();
    const language = useSelector(_language);

    const { 
        data: groupData, 
        isSuccess: isGroupDataSuccess 
    } = useGet<requestHostData[]>({
        query: 'groupAnalysisData',
        root: `${urlRoot}`,
        route: `${API.agentInfo}`,
        isRefetchOnWindowFocus: false,
    })

    //重新排序群組
    useEffect(() => {
        if (!(isGroupDataSuccess || groupData?.data)) return;
        const groupMap: Map<string, oneHostData[]> = new Map()
        groupData?.data?.forEach((item) => {
            if (item.group.length === 0) {
                item.group = [dic[language]['no_group']] // todo : fix this
            }
            item.group.forEach((groupName) => {
                const devicesInGroup = groupMap.get(groupName) || []
                devicesInGroup.push(item)
                groupMap.set(groupName, devicesInGroup)
            })
        })
        const result: IGenerateGroup[] = []
        groupMap.forEach((devices, groupName) => {
            result.push({ group: groupName, devices: devices })
        })

        dispatch(setGroups(result.sort()))
    }, [isGroupDataSuccess, groupData])

    return <></> 
}

export default Initialize;