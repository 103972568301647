import TreeNode from ".";
import {IAgent, _selectedHost, setSelectedHost} from 'redux/ViewDetailSlice'
import { useDispatch, useSelector } from "react-redux";

interface HostTreeNodeProps{
    agent: IAgent
    onClick: () => void
    selected: boolean
    disabled?: boolean
    offline?: boolean
}

const HostTreeNode = (props: HostTreeNodeProps) => {
    return <TreeNode 
            label={props.agent.ip} 
            type={props.offline?"host-offline":"host"}
            selected={props.selected}
            onClick={props.onClick}
            disabled={props.disabled}
    ></TreeNode>
}

export default HostTreeNode;