import { ReactComponent as AgentDownloadZh } from './zh/AgentDownload_zh.svg';
import { ReactComponent as AgentInstallAlertZh } from './zh/AgentInstallAlert_zh.svg';
import { ReactComponent as AgentInstallingZh } from './zh/AgentInstalling_zh.svg';
import { ReactComponent as AgentInstalledZh } from './zh/AgentInstalled_zh.svg';
import { ReactComponent as AgentInstallSuccessZh } from './zh/AgentInstallSuccess_zh.svg';
import { ReactComponent as AgentInstallFailZh } from './zh/AgentInstallFail_zh.svg';

import { ReactComponent as AgentDownloadEn } from './en/AgentDownload_en.svg';
import { ReactComponent as AgentInstallAlertEn } from './en/AgentInstallAlert_en.svg';
import { ReactComponent as AgentInstallingEn } from './en/AgentInstalling_en.svg';
import { ReactComponent as AgentInstalledEn } from './en/AgentInstalled_en.svg';
import { ReactComponent as AgentInstallSuccessEn } from './en/AgentInstallSuccess_en.svg';
import { ReactComponent as AgentInstallFailEn } from './en/AgentInstallFail_en.svg';

export const handbook_zh = {
    AgentDownloadZh,
    AgentInstallAlertZh,
    AgentInstallingZh,
    AgentInstalledZh,
    AgentInstallSuccessZh,
    AgentInstallFailZh,
};

export const handbook_en = {
    AgentDownloadEn,
    AgentInstallAlertEn,
    AgentInstallingEn,
    AgentInstalledEn,
    AgentInstallSuccessEn,
    AgentInstallFailEn,
};