import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Chart from "react-apexcharts"
import { ApexOptions } from "apexcharts"
import { usePost } from 'hook/usePost'
import { API, urlRoot } from 'constant/index'
import { KeywordHitData, SelectedRow } from '../Interfaces'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import { IDateRange } from 'interface'
import { InitSelectedRow, InitRequest } from '../functions'
import AnalysisTable, { Column } from "../AnalysisTable"
import '../../index.scss'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language, _displayMode } from 'redux/UtilSlice'
import { useAlert } from 'hook/useAlert'

interface KeywordHitProps {
    dateModuleData: IDateRange
    setSelectedRow: React.Dispatch<React.SetStateAction<SelectedRow>>
    setBarChartData: React.Dispatch<React.SetStateAction<KeywordHitData[]>>
}

const KeywordHit = (props: KeywordHitProps) => {
    const { dateModuleData, setSelectedRow, setBarChartData } = props;
    const language = useSelector(_language);
    const displayMode = useSelector(_displayMode);
    const navigate = useNavigate();
    const postMutation = usePost<KeywordHitData[]>();
    const [series, setSeries] = useState<{ x: string, y: number }[]>([]);
    const [allZero, setAllZero] = useState<boolean>(true);
    const [display, setDisplay] = useState<'Table' | 'Chart'>('Table');
    const { showAlert } = useAlert();

    const fetchData = () => {
        postMutation.mutate({
            root: `${urlRoot}`,
            route: `${API.keywordHit}`,
            body: InitRequest(
                0, // 分析儀表板沒有用 cache 了
                dateModuleData.startTime,
                dateModuleData.endTime
            ),
        }, {
            onSuccess: (res) => {
                const series: { x: string, y: number }[] = [];
                if (res.data) {
                    setAllZero(true);
                    res.data.forEach((keyword) => {
                        if (keyword.hit !== 0) setAllZero(false)
                        series.push({
                            x: keyword.keyword,
                            y: keyword.hit,
                        })
                    })
                }
                setSeries(series);
            },
            onError: (error) => {
                // 4xx error -> alert
                // 5xx error -> console.log
                if (error.response && error.response.status < 500) {
                    showAlert(error.response.data.message)
                } else {
                    console.error(error.response?.data.message)
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [dateModuleData])

    const handleClick = () => {
        if (series.length === 0) return
        setSelectedRow(InitSelectedRow())
        const barChartData: KeywordHitData[] = []
        series.forEach((keyword) => {
            barChartData.push({
                keyword: keyword.x,
                hit: keyword.y,
            })
        })
        setBarChartData(barChartData)
    }

    const handleDisplayClick = () => {
        if (display === 'Chart') {
            setDisplay('Table')
        } else {
            setDisplay('Chart')
        }
    }

    const filterSeriesZero = (series: { x: string, y: number }[]) => {
        const filterSeries = series.filter((keyword) => keyword.y !== 0)
        return filterSeries
    }

    const options: ApexOptions = {
        chart: {
            type: 'treemap',
            toolbar: {
                show: false
            }
        },
        grid: {
            padding: {
                top: -10,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        title: {
            text: dic[language]["dashboard_table_keyword_hit"],
            offsetX: -10,
            offsetY: 2,
            style: {
                fontSize: '17px',
                fontWeight: 'normal',
                color: displayMode === 'dark' ? 'var(--gray-100)' : 'var(--gray-900)',
            },
        },
        colors: [
            'rgba(211, 47, 47, 0.6)',
            'rgba(194, 24, 91, 0.6)',
            'rgba(123, 31, 162, 0.6)',
            'rgba(25, 118, 210, 0.6)',
            'rgba(56, 142, 60, 0.6)',
            'rgba(255, 160, 0, 0.6)',
            'rgba(0, 121, 107, 0.6)',
            'rgba(0, 151, 167, 0.6)',
            'rgba(48, 63, 159, 0.6)'
        ],
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false
            }
        }
    }

    const convertToKeywordHitData = (series: { x: string, y: number }[]): KeywordHitData[] => {
        const keywordHitData: KeywordHitData[] = []
        series.forEach((keyword) => {
            keywordHitData.push({
                keyword: keyword.x,
                hit: keyword.y,
            })
        })
        return keywordHitData
    }

    const keywordHitDataColumns: Column<KeywordHitData, keyof KeywordHitData>[] = [
        { key: 'keyword', label: dic[language]["keyword"], minWidth: 150 },
        { key: 'hit', label: dic[language]["times"], minWidth: 65 },
    ]

    return (
        <>{display === 'Table' ? (
            <Paper
                square={false}
                onClick={handleClick}
                className='keywordPaper'
            >
                <div className='chartContainer'>
                    {postMutation.isLoading ? (
                        <div className='circularProgressContainer'>
                            <CircularProgress disableShrink />
                        </div>
                    ) : (
                        <>
                            {allZero && (
                                <div style={{ marginLeft: '0.1rem', marginTop: '0.1rem' }}>
                                    {dic[language]["dashboard_table_keyword_hit"]}
                                </div>
                            )}
                            <Button
                                className='chartToggleButton'
                                size='small'
                                variant="text"
                                onClick={handleDisplayClick}
                            >
                                {display}
                            </Button>
                            {
                                allZero ? (
                                    series.length === 0 ? (
                                        <div className='circularProgressContainer'>
                                            <Button
                                                variant='contained'
                                                size='small'
                                                onClick={() => navigate('/setting/SettingWhiteList')}
                                            >
                                                {dic[language]["dashboard_keyword_add"]}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className='circularProgressContainer'>
                                            {dic[language]["all_zero"]}
                                        </div>
                                    )
                                ) : (
                                    <Chart
                                        options={options}
                                        series={[{ data: filterSeriesZero(series) }]}
                                        type="treemap"
                                        height={'100%'}
                                        width={'100%'}
                                    />
                                )
                            }
                        </>
                    )}
                </div>
            </Paper>
        ) : (
            <div
                className="tableContainer"
                onClick={handleClick}
            >
                <AnalysisTable
                    tableTitle={dic[language]["dashboard_table_keyword_hit"]}
                    lines={4}
                    tableData={convertToKeywordHitData(series)}
                    tableColumns={keywordHitDataColumns}
                    isLoading={postMutation.isLoading}
                    toggleBtn={
                        <Button
                            className='chartToggleButton'
                            size='small'
                            variant="text"
                            onClick={handleDisplayClick}
                        >
                            {display}
                        </Button>
                    }
                />
            </div>
        )}</>
    )
}

export default KeywordHit
