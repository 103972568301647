import { useSelector } from 'react-redux';
import dic from './dict.min'
import TDictionaryKeys from './dict.type'
import { _language } from 'redux/UtilSlice';
import React from 'react';

export type TLanguages = "en" | "zh";

export type TDictionary = {
    [key in TLanguages]: {
        [key in TDictionaryKeys]: string
    };
};

export const useI18n = () => {
    const language = useSelector(_language);
    return (key: TDictionaryKeys, br:boolean=true) => {
        const val = (dic as TDictionary)[language][key];
        if (!br) return val;
        return val
            .split('\\n')
            .map(v => <>{v}</>)
            .reduce((prev, curr) => <>{prev}<br/>{curr}</>);
    };
}

export const T = (props: {key: TDictionaryKeys}) => {
    const t = useI18n();
    return <>{t(props.key)}</>
}

export default dic as TDictionary;

// deprecated dictionary object because it was moved to google spreadsheet
// https://docs.google.com/spreadsheets/d/1D8GOPCdiZr1oFoc0OgyDePWtrpWZy3GVVuuB3EW6Vms/edit?gid=0#gid=0
// 
// const dic: TDictionary = {
//     "en": {
//         // common
//         "save": "Save",
//         "saving": "Saving...",
//         "customized": "Customized",
//         "begin_time": "Begin",
//         "end_time": "End",
//         "24_hours": "24 Hours",
//         "48_hours": "48 Hours",
//         "72_hours": "72 Hours",
//         "risk_level_4": "Risk Level 4",
//         "risk_level_3": "Risk Level 3",
//         "risk_level_2": "Risk Level 2",
//         "risk_level_1": "Risk Level 1",
//         "agent_name": "Agent Name",
//         "group_name": "Group Name",
//         "process_name": "Process Name",
//         "highest_process": "Highest Risk Process",
//         "agent_count": "agents",
//         "times": "Times",
//         "score": "Score",
//         "keyword": "Keyword",
//         "number_of_hits": "Number of Hits",
//         "simeple_times": "",
//         "memory": "Memory",
//         "storage": "Storage",
//         "group": "Group",
//         "number_of_messages": "Queue Messages",
//         "server": "Server",
//         "cpu": "CPU",
//         "agent": "Agent",
//         "number": "Quantity",
//         "more": "M",
//         "less": "L",
//         "connected": "Connected",
//         "disconnected": "Disconnected",
//         "finished": "Finished",
//         "running": "Running",
//         "unknown": "Unknown",
//         "no_group_hint": "No Group Data",
//         "device_unit": "devices",
//         "user": "User",
//         "no_data": "No Data",
//         "all_zero": "All Zero",
//         "no_task": "No Task Performed",
//         "no_select": "No Select",
//         "alert": "Alert",
//         "confirm": "Confirm",
//         "close": "Close",
//         "close_simeple": "Close",
//         "cancel": "Cancel",
//         "open": "Open",
//         "open_simeple": "Open",
//         "submit": "Submit",
//         "verify": "Verify",
//         "add": "Add",
//         "delete": "Delete",
//         "deleting": "Deleting...",
//         "delete_success": "Delete Success",
//         "renew": "Renew",
//         "update": "Update",
//         "save_success": "Save Success",
//         "add_success": "Add Success",
//         "add_fail": "Add Fail",
//         "edit_success": "Edit Success",
//         "edit_fail": "Edit Fail",
//         "setting_error": "Setting Error",
//         "no_action": "No Action",
//         "have_action": "Have Action",
//         "checked": "Checked",
//         "search": "Search",
//         "no_group": "No Group",
//         "no_input_data": "No Input Data",
//         "data_not_change": "Data Not Change",
//         "input_repeated": "Input Repeated",
//         "please_input_keyword": "Please Input Keyword",
//         "all": "All",
//         "upload": "Upload",
//         "download": "Download",
//         "start_download": "Start Download",
//         "download_success": "Download Success",
//         "apply": "Apply",
//         "applying": "Applying...",
//         "apply_success": "Apply Success",
//         "start": "Start",
//         "count": "Count",
//         "count_unit": "",
//         "leave_alert_hint": "You will lose all unsaved data if you leave this page. Are you sure you want to leave?",
//         "database_connect_fail": "Database Connect Failed",
//         "database_refresh_fail": "Database Refresh Failed",
//         "error": "Error",
//         "please_select_time": "Please Select a Time",
//         "refresh": "Refresh",
//         "clear": "Clear",
//         "invalid_option": "Invalid Option",
//         "dark_mode": "Dark Mode",
//         "upload_success": "Upload Success",
//         "update_success": "Update Success",
//         "__total":"TOTAL RESULT",
//         "last_24_hr": "Last 24 hour",
//         "last_month": "Last month",
//         "last_week": "Last week",
//         "custom": "Custom",
//         "real_time": "Real time",
//         "process_connect_ip": "Process connect IP",
//         "dynamic_command": "Dynamic command",
//         "process_md5": "Process MD5",
//         "process_path": "Process Path",
//         "parent_process_path": "Parent process path",
//         "digital_sign": "Digital Signiture",
//         "import_other_dll": "Import other DLL",
//         "process_id": "Process ID",
//         "process_param":"Process Paramater",
//         "have": "Yes",
//         "havent": "No",
//         "non_pe_code": "Non-PE code",
//         "quick_select": "Quick select",
//         "select_template": "Select template",
//         "save_template": "Save template",
//         "at_least": "At least",
//         "at_most": "At most",
//         "deleted_only": "Deleted files only",
//         "yes": "Yes",
//         "no": "No",
//         "inquire_chatgpt": "Inquire ChatGPT",
//         // ----------------------------------------------
//         // common header link
//         "common_header_dashboard": "Dashboard",
//         "common_header_detect": "Detect",
//         "common_header_analysis": "Analysis",
//         "common_header_memory": "Memory",
//         "common_header_detail": "Files",
//         "common_header_setting": "Setting",
//         // ----------------------------------------------
//         // dashboard
//         "detect_dashboard_title": "Dashboard",
//         "analysis_dashboard_title": "Dashboard",
//         "dashboard_server_status": "Server Status",
//         "dashboard_switch_btn_detect": "Detect",
//         "dashboard_switch_btn_analysis": "Analysis",
//         "dashboard_connection_status": "Connection Status",
//         "dashboard_connection_rate": "Connection Rate",
//         "dashboard_os_count": "OS Statistics",
//         "dashboard_user_task_timeline": "User Task Timeline",
//         "dashboard_table_title_detect_group": "Detect Group",
//         "dashboard_pie_chart_label": "Total",
//         "dashboard_start_scan": "Memory Scan",
//         "dashboard_start_memory_tree": "Memory Tree",
//         "dashboard_start_remove_agent": "Remove Agent",
//         "dashboard_start_update_agent": "Update Agent",
//         "dashboard_start_collect": "Collect Trace",
//         "dashboard_start_get_drive": "Get Drive",
//         "dashboard_start_get_image": "Get Image",
//         "dashboard_change_detect_mode": "Change Detect Mode",
//         "dashboard_terminate": "Terminate",
//         "dashboard_start_yara_rule": "Start Yara Rule",
//         "dashboard_task_status": "Task Status",
//         "dashboard_scan_status": "Scan Status",
//         "dashboard_Scan_rate": "Scan Rate",
//         "dashboard_collect": "Collect Trace",
//         "dashboard_collect_status": "Collect Status",
//         "dashboard_collect_rate": "Collect Rate",
//         "dashboard_collect_table_title": "Collect Group",
//         "dashboard_drive": "Get Drive",
//         "dashboard_drive_status": "Drive Status",
//         "dashboard_drive_rate": "Drive Rate",
//         "dashboard_drive_table_title": "Drive Group",
//         "dashboard_image": "Get Image",
//         "dashboard_image_status": "Image Status",
//         "dashboard_image_rate": "Image Rate",
//         "dashboard_image_table_title": "Finished Group",
//         "dashboard_translate_state_done": "Finished",
//         "dashboard_translate_state_success": "Finished",
//         "dashboard_translate_state_working": "Working",
//         "dashboard_translate_state_non-working": "Idle",
//         "dashboard_translate_state_connection": "Connected",
//         "dashboard_translate_state_non-connection": "Disconnected",
//         // ----------------------------------------------
//         // analysis dashboard
//         "dashboard_top_bar_agent_count": "Agent",
//         "dashboard_top_bar_level3_process_count": "Level3+ Process",
//         "dashboard_top_bar_time": "Time",
//         "dashboard_table_ip_connect": "Connection Ranking",
//         "dashboard_table_process_ip": "Process Destination IP",
//         "dashboard_table_login_failure": "Login Failure Ranking",
//         "dashboard_table_high_risk_process": "Level3+ Process",
//         "dashboard_table_no_sign_process": "Unsigned Process",
//         "dashboard_table_country_connection": "Country Connection Ranking",
//         "dashboard_table_keyword_hit": "Keyword Hit",
//         "dashboard_keyword_add": "Add Keyword",
//         "dashboard_change_box_left": "Agent Risk",
//         "dashboard_change_box_left_unit": "",
//         "dashboard_change_box_right": "Process Risk",
//         "dashboard_change_box_right_unit": "",
//         // ----------------------------------------------
//         // Setting Page
//         // Left bar
//         "setting_system": "System",
//         "setting_group": "Group",
//         "setting_image": "Image",
//         "setting_server": "Server",
//         "setting_agent_actions": "Agent Actions",
//         "setting_user_permission": "User Permission",
//         "setting_white_black_keyword_list": "White/Black List & Keyword",
//         "setting_software": "Software",
//         "setting_logout": "Logout",
//         "setting_logout_confirm": "Are you sure you want to log out?",
//         // ----------------------------------------------
//         // system
//         "setting_system_tab_server_mail": "Server & Mail",
//         "setting_system_tab_common": "Common",
//         "setting_system_tab_agent_setting": "Agent Setting",
//         "setting_system_tab_agent_update": "Agent Update",
//         "setting_system_tab_interface": "Interface",
//         "setting_system_server": "Server",
//         "setting_system_port": "Port",
//         "setting_system_detect_data_port": "Detect Data Port",
//         "setting_system_default_detect": "Default Detect",
//         "setting_system_client_update_port": "Client Update Port",
//         "setting_system_mail": "Mail",
//         "setting_system_sender_mail": "Sender Mail",
//         "setting_system_mail_password": "Mail Password",
//         "setting_system_mail_server": "Mail Server (IP)",
//         "setting_system_mail_port": "Port",
//         "setting_system_mail_encryption": "Mail Encryption",
//         // common
//         "setting_system_mail_notificaiton": "Mail Notification",
//         "setting_system_non_whitelist_program": "Non-Whitelist Program",
//         "setting_system_blacklist_program": "Blacklist Program",
//         "setting_system_recevier_mail": "Receiver Mail",
//         "setting_system_report_generate_time": "Report Generation",
//         "setting_system_daily_report_time": "Daily Report Time",
//         "setting_system_risk_level_bigger_than": "Higher Than",
//         "setting_system_auto_defense": "Auto Defense",
//         "setting_system_risk_process": "Risk Process Action",
//         "setting_system_memory_dump": "Memory Dump",
//         "setting_system_dump_to_virustotal": "Dump auto upload to Virustotal for deep analysis",
//         "setting_system_dump_to_hybrid_analysis": "Dump auto upload to hybrid-analysis for deep analysis",
//         "setting_system_sample_download": "Sample Download",
//         "setting_system_sample_download_to_virustotal": "Sample auto upload to Virustotal for deep analysis",
//         "setting_system_sample_download_to_hybrid_analysis": "Sample auto upload to hybrid-analysis for deep analysis",
//         // agent setting
//         "setting_system_agent_prescheduled_task": "Prescheduled Task",
//         "setting_system_agent_file_table_analysis": "File Table Analysis",
//         "setting_system_agent_primary_disk": "Primary Disk",
//         "setting_system_agent_memory_scan": "Memory Scan",
//         "setting_system_agent_collect": "Collect Trace",
//         "setting_system_agent_image": "Image",
//         "setting_system_agent_generate": "Agent Generate",
//         "setting_system_agent_server_ip": "Server IP",
//         "setting_system_agent_other": "Other",
//         "setting_system_agent_cpu_priority": "CPU Priority",
//         "setting_system_agent_cpu_priority_low": "Low",
//         "setting_system_agent_cpu_priority_normal": "Normal",
//         // agent
//         "setting_system_agent_update": "Agent Update",
//         "setting_system_agent_program": "Upload New Agent",
//         "setting_system_upload_key": "Upload Update Key",
//         "setting_system_agent_update_client": "Update Client",
//         "setting_system_agent_customized_device": "Customized Device",
//         "setting_system_agent_all_device": "All Device",
//         "setting_formtable_inner_ip": "Inner IP",
//         "setting_formtable_device_name": "Device Name",
//         "setting_formtable_version": "Version",
//         "setting_formtable_group": "Group",
//         "setting_system_agent_update_hash": "Update Key",
//         "setting_system_agent_update_verify": "Verify",
//         "setting_agent_update_choose_file_error": "File type should be exe",
//         "setting_agent_update_older_version_error": "Update version should be higher than the current version",
//         "setting_agent_update_sending": "Sending update request...",
//         "setting_agent_remove_sending": "Sending remove request...",
//         "setting_agent_remove_empty": "Please select a device",
//         "setting_agent_remove_hint": "Are you sure you want to remove the selected device?",
//         // ----------------------------------------------
//         // group
//         "add_to_selected_group": "Add to Group",
//         "remove_from_selected_group": "Remove from Group",
//         "please_select_group_or_device": "Please select a group or a device",
//         "setting_group_statistics": "Group Statistics",
//         "setting_group_name_repeat": "Group Name Repeat",
//         "setting_group_delete": "Delete",
//         "setting_group_delete_unit": "Groups Successfully",
//         "please_select_group": "Please select a group",
//         "setting_group_list": "Group List",
//         "setting_group_add": "Add Group",
//         "setting_group_delete_group": "Delete Group",
//         "setting_group_delete_group_confirm": "Are you sure you want to delete this group?",
//         "setting_group_delete_fail": "Delete Group Fail",
//         "setting_group_update_name": "Update Group Name",
//         "setting_group_new_name": "Group Name",
//         "setting_group_color": "Group Color",
//         // ----------------------------------------------
//         // image
//         "setting_image_select_path": "Select Path",
//         "setting_image_default": "Default",
//         "setting_image_advanced": "Advanced",
//         "setting_image_customized": "Customized (Can be updated in \"setting\" > \"Imgae\".)",
//         "setting_image_path_keyword": "Customize Path & Keyword",
//         "setting_image_enter_keyword": "Enter Keyword",
//         "setting_image_path": "Path",
//         "setting_image_keyword": "Keyword",
//         "setting_image_remove_hint": "Are you sure you want to remove the selected path and keyword list?",
//         "setting_image_renew_hint": "Are you sure you want to renew the customized list?",
//         "setting_image_tool": "Hacker Tool Setting",
//         "setting_image_add_score": 'Add Score',
//         // ----------------------------------------------
//         // blacklist/whitelist/hacklist
//         "setting_blacklist": "Blacklist",
//         "setting_whitelist": "Whitelist",
//         "setting_hacker_tool": "Hacker Tool Setting",
//         "setting_keyword_list": "Keyword List",
//         "setting_black/whitelist_filename": "Filename",
//         "setting_black/whitelist_md5": "MD5",
//         "setting_black/whitelist_signature": "Signature",
//         "setting_black/whitelist_path": "Path",
//         "setting_black/whitelist_setup_user": "Setup User",
//         "setting_black/whitelist_reason": "Reason",
//         "setting_hacklist_process_name": "Process Name",
//         "setting_hacklist_path": "Path",
//         "setting_hacklist_cmd": "CMD",
//         "setting_hacklist_adding_point": "Adding Point",
//         "setting_keyword_list_name": "Name",
//         "setting_keyword_list_reason": "Reason",
//         "setting_keyword_list_add": "Add Keyword",
//         "setting_keyword_remove": "Remove Keyword",
//         "setting_keyword_remove_hint": "Are you sure you want to remove the selected keyword?",
//         // ----------------------------------------------
//         // server
//         "setting_select_functionality": "Select Functionality",
//         "setting_server_install_start": "Install and Start Server",
//         "setting_server_restart": "Restart Server",
//         "setting_server_remove": "Remove Server",
//         // ----------------------------------------------
//         // agent remove
//         "setting_agent_remove": "Agent Remove",
//         "setting_agent_remove_btn": "Agent Remove",
//         "setting_agent_generate": "Agent Generate",
//         "setting_agent_install_error": "Install Failed?",
//         // ----------------------------------------------
//         // user permission
//         "setting_user_delete_user_confirm": "Are you sure you want to delete this user?",
//         "setting_user_delete_user_success": "Delete Success",
//         "setting_user_delete_user_fail": "Delete Fail",
//         "setting_user_add": "Add User",
//         "setting_user_remove": "Remove User",
//         "setting_user_select_user": "Please Select User",
//         "setting_user_department": "Department",
//         "setting_user_username": "Account",
//         "setting_user_email": "Email",
//         "setting_user_password": "Password",
//         "setting_user_permission_header": "Page Permission",
//         "setting_user_status": "Status",
//         "setting_user_timezone": "Timezone",
//         "setting_user_activate": "Activate",
//         "setting_user_deactivate": "Deactivate",
//         "setting_user_dashboard": "Dashboard",
//         "setting_user_permission_setting": "Setting",
//         "setting_user_permission_user": "User Permission",
//         "setting_user_permission_detect": "Detect",
//         "setting_user_permission_analysis": "Analysis",
//         "setting_user_permission_dashboard": "Dashboard",
//         // ----------------------------------------------
//         // software
//         "setting_software_language": "Language",
//         "setting_software_authorization_version": "Authorization & Version",
//         "setting_software_logs": "Logs",
//         "setting_software_please_select_language": "Please Select",
//         "setting_software_language_zh": "繁體中文",
//         "setting_software_language_en": "English",
//         "setting_software_authorization_information": "Authorization Information",
//         "setting_software_authorization_issuer": "Issuer",
//         "setting_software_authorization_code": "Authorization Code",
//         "setting_software_authorization_connections": "Connections",
//         "setting_software_authorization_date": "Date",
//         "setting_software_version": "Version",
//         "setting_software_update_license": "Update License",
//         "setting_software_update_license_success": "Update License Success",
//         "setting_software_update_license_fail": "File name should be license.key",
//         "setting_authorization_text1": "The intellectual property rights, copyrights and registered trademarks of all information on eDetector belong to Jianzhen Digital Co., Ltd. (hereinafter referred to as the company). If there is information, program software, graphics and trademarks provided by any other company, the intellectual property rights and copyrights belong to that company. It may not be received, paraphrased, copied, reproduced, stored in a system or translated in any language in any form without the formal consent of the Company, nor may it be used in any format reflecting the Company's trademarks.",
//         "setting_authorization_text2": "The copyright owner of eDetector disclaims any liability for special, indirect, related or similar damages, including loss of data or property resulting from the use or non-use of this software. This software is provided without warranty of any kind, express or implied, and is subject to the limitations set forth above.",
//         "setting_software_logs_time": "Time",
//         "setting_software_logs_level": "Level",
//         "setting_software_logs_source": "Source",
//         "setting_software_logs_content": "Content",
//         "setting_software_logs_detail": "Detail",
//         // ----------------------------------------------
//         // yara rule
//         "setting_import_tags": "標記情資導入",
//         "setting_yara_choose_upload_file": "Choose a File: ",
//         "setting_yara_choosed_file": "Files want to upload",
//         "setting_yara_uploaded_files": "Applied files: ",
//         "setting_yara_download_file": "Download",
//         "setting_yara_download_error": "Download failed",
//         "setting_yara_verified": "Verified",
//         "setting_yara_choose_file_first": "Please choose a file first",
//         "setting_yara_choose_file": "Choose",
//         "setting_yara_upload_file": "Apply",
//         "setting_yara_file_name": "File Name",
//         "setting_yara_file_size": "File Size",
//         "setting_yara_file_type": "File Type",
//         "setting_yara_upload_success": "Apply Success",
//         "setting_yara_wrong_file_type": "Please upload in the format of .zip, .tar or .yara",
//         "setting_yara_empty_file": "File size should not be empty and should be less than 10MB",
//         "setting_yara_file_exist": "File already exists",
//         // ----------------------------------------------
//         // DT
//         "setting_dt_import": "Import DT Data",
//         // ----------------------------------------------
//         // view detail
//         "right_bar_title": "Detail Information",
//         "view_detail_agent_list": "Agent List",
//         "view_detail_file_name": "File Name",
//         "view_detail_file_size": "File Size",
//         "view_detail_file_create_time": "File Create Time",
//         "view_detail_file_modify_time": "File Modify Time",
//         // ----------------------------------------------
//         // network
//         "network_wrong_ip_hint": "Wrong IP format, please input again",
//         "netwrok_search_ip": "Search IP",
//         "network_connection": "Memory Connection",
//         "network_memory_list": "Memory Tree",
//         // ----------------------------------------------
//         // detect
//         "detect_task_type_memory": "Memory Scan",
//         "detect_task_type_collect": "Collect Trace",
//         "detect_task_type_drive": "Get Drive",
//         "detect_task_type_image": "Get Image",
//         "detect_task_type_yara": "Yara Rule",
//         "detect_task_type_memory_tree": "Memory Tree",
//         "detect_task_terminate": "Task Execute",
//         "detect_task_memory_action": "Start Scan",
//         "detect_task_collect_action": "Start Collect",
//         "detect_task_drive_action": "Start Get Drive",
//         "detect_task_image_action": "Start Get Image",
//         "detect_task_start_making_image": "Start Making",
//         "detect_task_image_select_type_hint": "Please select the path to generate the images",
//         "detect_task_terminate_action": "Terminate",
//         "detect_task_yara_action": "Start Yara Rule",
//         "detect_task_memory_tree_action": "Start Memory Tree",
//         "detect_task_terminating": "Terminating Task...",
//         "detect_task_terminate_alert": "Are you sure you want to terminate the task?",
//         "detect_task_terminate_confirm": "Terminate",
//         "detect_scan_schedule": "Schedule",
//         "detect_detect_mode": "Detect",
//         "detect_schedule_time": "Schedule Time",
//         "detect_schedule_unit": "schedule time",
//         "detect_collect_schedule": "Schedule",
//         "detect_schedule_date": "Schedule Date",
//         "detect_file_schedule": "Schedule",
//         "detect_table_scan_schedule": "Scan Schedule",
//         "detect_table_scan_finish_time": "Last Scan Finish Time",
//         "detect_table_collect_schedule": "Collect Schedule",
//         "detect_table_collect_finish_time": "Last Collect Finish Time",
//         "detect_table_drive_schedule": "Drive Schedule",
//         "detect_table_drive_finish_time": "Last Drive Finish Time",
//         "detect_table_image_finish_time": "Last Image Finish Time",
//         "detect_table_yara_finish_time": "Last YARA Rule Finish Time",
//         "detect_table_memory_tree_finish_time": "Last Memory Tree Finish Time",
//         "detect_table_connection": "Connection",
//         "detect_table_device_ip": "Device IP",
//         "detect_table_device_name": "Device Name",
//         "detect_table_group": "Group",
//         "detect_task_failed": "Server Failed",
//         "detect_collect_table": "Table",
//         "detect_collect_failed_tables": "Collect Failed Tables",
//         // ----------------------------------------------
//         // Analysis
//         "analysis_search_hint": "Please select devices, filter and time",
//         "analysis_start": "Analyze",
//         "analysis_devices": "Devices",
//         "analysis_please_select_devices": "Please select devices",
//         "analysis_has_selected": "Selected ",
//         "analysis_has_selected_unit": " devices",
//         "analysis_filter_memory": "Memory",
//         "analysis_filter_collect": "Collect",
//         "analysis_filter_drive": "Drive",
//         "analysis_data": "Filter",
//         "analysis_time": "Time",
//         "analysis_time_24": "Last 24 Hours",
//         "analysis_time_week": "Last Week",
//         "analysis_time_month": "Last Month",
//         "analysis_global_keyword": "Global Keyword",
//         "analysis_global_keyword_search": "Keyword Search",
//         "analysis_table_number_rows": "Total Filter Rows",
//         "analysis_generate_report": "Report",
//         "analysis_show_figure": "Show Figure",
//         "analysis_ai_report": "AI Report",
//         "analysis_ai_report_no_select_error": "Please select data",
//         "analysis_ai_report_alert": "Utilize AI to generate reports, and the data will be uploaded to ChatGPT. Confirm if you would like to use this feature?",
//         "analysis_ai_report_error(over limit)":"Selected rows count is over limit, please deselect some rows to continue generating report",
//         "analysis_yara_export": "Export Tags",
//         "analysis_csv_file": "CSV File",
//         "analysis_csv_error(over limit)":"Selected rows count is over limit, please deselect some rows to continue generating report",
//         "analysis_hide_figure": "Hide Figure",
//         "analysis_report_selected_unit": "data rows",
//         "analysis_clear_all": "Clear All",
//         "analysis_table_current_rows": "Current Rows",
//         "analysis_table_total_rows": "Total Rows",
//         "analysis_risk_level": "Risk Level",
//         "analysis_inject_active": "Injection Behavior",
//         "analysis_process_be_injected": "Injected Process",
//         "analysis_boot": "Start on Boot",
//         "analysis_hook": "Hook",
//         "analysis_hide": "Hide",
//         // ----------------------------------------------
//         // login page
//         "login_title": "User Login",
//         "login_account": "Account",
//         "login_password": "Password",
//         "login_action": "Login",
//         "login_error_hint": "The account or password is wrong, or the account is not activated.",
//         "login_error": "Login Failed",
//         "login_account_empty": "Please input account",
//         "login_password_empty": "Please input password",
//         // ----------------------------------------------
//         // elastic table & analysis group
//         "ed_memory": "Memory",
//         "ed_chromehistory": "Chrome browse history",
//         "ed_chromedownload": "Chrome downloads",
//         "ed_chromekeywordsearch": "Chrome searched keywords",
//         "ed_chromelogin": "Chrome credentials",
//         "ed_chromebookmarks": "Chrome bookmarks",
//         "ed_edgehistory": "Edge browse history",
//         "ed_iehistory": "IE browse history",
//         "ed_edgelogin": "Edge credentials",
//         "ed_ielogin": "IE credentials",
//         "ed_edgebookmarks": "Edge bookmarks",
//         "ed_firefoxhistory": "Firefox browse history",
//         "ed_firefoxlogin": "Firefox credentials",
//         "ed_firefoxbookmarks": 'Firefox bookmarks',
//         "ed_chromecookies": "Chrome cookies",
//         "ed_chromecache": "Chrome cache",
//         "ed_edgecookies": "Edge cookies",
//         "ed_edgecache": "Edge cache",
//         "ed_firefoxcookies": "Firefox cookies",
//         "ed_firefoxcache": "Firefox cache",
//         "ed_iecache": "IE cache",
//         "ed_netadapters": "Network basic infomations", //basic 
//         "ed_networkresources": "Network resource",
//         "ed_wireless": "Wireless network information",
//         "ed_installedsoftware": "Installed softwares",
//         "ed_service": "General system service", //general
//         "ed_baseservice": "Detail system service", //detailed
//         "ed_startrun": "Program start on boot", //bootprogram
//         "ed_remotedesktoplogin": "Remote desktop credentials",
//         "ed_jumplist": "Jump List",
//         "ed_systeminfo": "System Information",
//         "ed_muicache": "MUI cache",
//         "ed_prefetch": "Prefetch",
//         "ed_eventapplication": "Application log",
//         "ed_taskschedule": "Scheduled works", //scheduledwork
//         "ed_userassist": "Registries traces", //ed_userassist
//         "ed_networkdatausagemonitor": "Program network traffic traces", //ed_networkdatausagemonitor
//         "ed_appresourceusagemonitor": "Program I/O traces", //ed_appresourceusagemonitor
//         "ed_dnsinfo": "DNS information",
//         "ed_process": "Executed Program", // executed program
//         "ed_network": "Program network connections", //ed_network
//         "ed_openedfiles": "File opened by program", //ed_openedfiles
//         "ed_arpcache": "ARP cache",
//         "ed_shortcuts": "Relative shortcuts", //ed_shortcuts
//         "ed_shellbags": "Opened folder path", //ed_shellbags
//         "ed_userprofiles": "User profiles on hosts", //ed_userprofiles
//         "ed_recentfile": "Recently opened files", //ed_recentfile
//         "ed_windowsactivity": "Windows Activity",
//         "ed_usbdevices": "USB device information", //ed_usbdevices
//         "ed_eventsecurity": "Security log", //ed_eventsecurity
//         "ed_eventsystem": "System log", //ed_eventsystem
//         "ed_emailpath": "Email path",
//         'ed_email': 'Email list record', //ed_email
//         "ed_explorer": "All file",// allfile

//         "group_email_list_records": 'Email list records', //emailListReacord
//         "group_usb_usage_record": "USB usage record",
//         "group_recently_opened_file_history": "Recently opened file history", //group_recently_opened_file_history
//         'group_evanescent_records': "Evanescent records", //group_evanescent_records
//         "group_recently_executed_software_log": "Recently executed software log",
//         "group_current_network_connection_history":"Network connection history",
//         "ed_collection": "Forensics",
//         "group_browsing_history_and_bookmarks": "Browsing history and bookmarks",
//         "group_cache_and_cookie_history": "Cookie and cache",
//         //------------memory tree----------
//         "memory_tree_process_name":"Process Name",
//         "memory_tree_process_id":"Process ID",
//         "memory_tree_process_create_time":"Process Create Time",
//         "memory_tree_parent_process_ID":"Parent Process ID",
//         "memory_tree_user_name":"Username",
//     },
//     "zh": {
//         // common
//         "save": "儲存",
//         "saving": "儲存中...",
//         "customized": "自訂",
//         "begin_time": "開始時間",
//         "end_time": "結束時間",
//         "24_hours": "24 小時",
//         "48_hours": "48 小時",
//         "72_hours": "72 小時",
//         "risk_level_4": "風險等級 IV",
//         "risk_level_3": "風險等級 III",
//         "risk_level_2": "風險等級 II",
//         "risk_level_1": "風險等級 I",
//         "agent_name": "主機名稱",
//         "group_name": "群組名稱",
//         "process_name": "程序名稱",
//         "highest_process": "風險最高程序",
//         "agent_count": "主機數",
//         "times": "次數",
//         "score": "分數",
//         "keyword": "關鍵字",
//         "number_of_hits": "命中次數",
//         "memory": "記憶體",
//         "storage": "儲存空間",
//         "group": "群組",
//         "number_of_messages": "佇列訊息數",
//         "server": "伺服器",
//         "cpu": "處理器",
//         "agent": "代理程式",
//         "number": "數量",
//         "simeple_times": "次",
//         "more": "多",
//         "less": "少",
//         "connected": "已連線",
//         "disconnected": "未連線",
//         "finished": "已完成",
//         "running": "執行中",
//         "unknown": "未執行",
//         "no_group_hint": "沒有群組資料",
//         "device_unit": "台",
//         "user": "使用者",
//         "no_data": "無資料",
//         "all_zero": "皆為零",
//         "no_task": "尚未執行任務",
//         "no_select": "未選擇",
//         "alert": "提醒",
//         "confirm": "確認",
//         "close": "關閉",
//         "close_simeple": "關",
//         "cancel": "取消",
//         "open": "開啟",
//         "open_simeple": "開",
//         "submit": "提交",
//         "verify": "驗證",
//         "add": "新增",
//         "delete": "刪除",
//         "deleting": "刪除中...",
//         "delete_success": "刪除成功",
//         "renew": "重製",
//         "update": "更新",
//         "save_success": "儲存成功",
//         "add_success": "新增成功",
//         "add_fail": "新增失敗",
//         "edit_success": "編輯成功",
//         "edit_fail": "編輯失敗",
//         "setting_error": "設定錯誤",
//         "no_action": "無動作",
//         "have_action": "有動作",
//         "checked": "已勾選",
//         "search": "搜尋",
//         "no_group": "未分類群組",
//         "no_input_data": "資料未輸入",
//         "data_not_change": "資料未更改",
//         "input_repeated": "輸入資料重複",
//         "please_input_keyword": "請輸入關鍵字",
//         "all": "全部",
//         "upload": "上傳",
//         "download": "下載",
//         "start_download": "開始下載",
//         "download_success": "下載成功",
//         "apply": "套用",
//         "applying": "套用中...",
//         "apply_success": "套用成功",
//         "start": "開始",
//         "count": "統計",
//         "count_unit": "筆",
//         "please_select": "請選擇",
//         "leave_alert_hint": "離開此頁面將會失去所有未儲存的資料，確定要離開嗎？",
//         "database_connect_fail": "資料庫連線錯誤",
//         "database_refresh_fail": "資料庫更新錯誤",
//         "error": "錯誤",
//         "please_select_time": "請選擇時間",
//         "refresh": "重新整理",
//         "clear": "清除",
//         "invalid_option": "無效的選項",
//         "dark_mode": "深色模式",
//         "upload_success": "上傳成功",
//         "update_success": "更新成功",
//         "__total":"TOTAL RESULT",
//         "last_24_hr": "最近24小時",
//         "last_month": "最近1個月",
//         "last_week": "最近1周",
//         "custom": "自訂",
//         "real_time": "即時",
//         "process_connect_ip": "程序連線IP",
//         "dynamic_command": "動態指令",
//         "process_md5": "程序MD5",
//         "process_path": "程序路徑",
//         "parent_process_path": "父程序路徑",
//         "digital_sign": "數位簽章",
//         "import_other_dll": "載入其他dll",
//         "process_id": "程序編號",
//         "process_param":"程序參數",
//         "have": "有",
//         "havent": "無",
//         "non_pe_code": "非PE程式碼",
//         "quick_select": "快篩",
//         "select_template": "選擇模板",
//         "save_template": "儲存模板",
//         "at_least": "至少",
//         "at_most": "至多",
//         "deleted_only": "只搜刪除檔",
//         "yes": "是",
//         "no": "否",
//         "inquire_chatgpt": "詢問 ChatGPT",
//         // ----------------------------------------------
//         // common header link
//         "common_header_dashboard": "監測儀表板",
//         "common_header_detect": "蒐證任務",
//         "common_header_analysis": "蒐證分析",
//         "common_header_memory": "記憶體動態",
//         "common_header_detail": "檔案總表",
//         "common_header_setting": "設定",
//         // ----------------------------------------------
//         // dashboard
//         "detect_dashboard_title": "監測儀表板",
//         "analysis_dashboard_title": "監測儀表板",
//         "dashboard_server_status": "伺服器狀態",
//         "dashboard_switch_btn_detect": "蒐證監測",
//         "dashboard_switch_btn_analysis": "分析監測",
//         "dashboard_connection_status": "連線狀態",
//         "dashboard_connection_rate": "連線比例",
//         "dashboard_os_count": "版本統計",
//         "dashboard_user_task_timeline": "使用者任務時間軸",
//         "dashboard_table_title_detect_group": "取證群組",
//         "dashboard_pie_chart_label": "總數(台)",
//         "dashboard_start_scan": "執行記憶體掃描",
//         "dashboard_start_collect": "執行痕跡取證",
//         "dashboard_start_get_drive": "製作檔案總表",
//         "dashboard_start_get_image": "製作關鍵映像檔",
//         "dashboard_start_memory_tree": "製作記憶體樹",
//         "dashboard_start_remove_agent": "移除 Agent",
//         "dashboard_start_update_agent": "更新 Agent",
//         "dashboard_change_detect_mode": "切換取證模式",
//         "dashboard_terminate": "終止任務",
//         "dashboard_start_yara_rule": "啟動 Yara Rule",
//         "dashboard_task_status": "蒐證狀態",
//         "dashboard_scan_status": "掃描狀態",
//         "dashboard_Scan_rate": "掃描比例",
//         "dashboard_collect": "痕跡取證",
//         "dashboard_collect_status": "取證狀態",
//         "dashboard_collect_rate": "取證比例",
//         "dashboard_collect_table_title": "取證群組",
//         "dashboard_drive": "檔案總表",
//         "dashboard_drive_status": "下載狀態",
//         "dashboard_drive_rate": "下載比例",
//         "dashboard_drive_table_title": "下載群組",
//         "dashboard_image": "關鍵映像檔",
//         "dashboard_image_status": "製作狀態",
//         "dashboard_image_rate": "製作比例",
//         "dashboard_image_table_title": "完成群組",
//         "dashboard_translate_state_done": "已完成",
//         "dashboard_translate_state_success": "已完成",
//         "dashboard_translate_state_working": "執行中",
//         "dashboard_translate_state_non-working": "未執行",
//         "dashboard_translate_state_connection": "已連線",
//         "dashboard_translate_state_non-connection": "未連線",
//         // ----------------------------------------------
//         // analysis dashboard
//         "dashboard_top_bar_agent_count": "主機總數",
//         "dashboard_top_bar_level3_process_count": "風險等級 III 以上程序",
//         "dashboard_top_bar_time": "統計時間",
//         "dashboard_table_ip_connect": "建立連線排行",
//         "dashboard_table_process_ip": "程序目的端 IP 統計",
//         "dashboard_table_login_failure": "帳戶登入失敗排行",
//         "dashboard_table_high_risk_process": "風險等級 III 以上程序",
//         "dashboard_table_no_sign_process": "未有簽章程序",
//         "dashboard_table_country_connection": "國家連線排行",
//         "dashboard_table_keyword_hit": "關鍵字命中",
//         "dashboard_keyword_add": "新增關鍵字",
//         "dashboard_change_box_left": "主機風險變化",
//         "dashboard_change_box_left_unit": " 台",
//         "dashboard_change_box_right": "風險程序數量變化",
//         "dashboard_change_box_right_unit": " 支",
//         // ----------------------------------------------
//         // Setting Page
//         // Left bar
//         "setting_system": "系統",
//         "setting_group": "主機群組",
//         "setting_image": "關鍵邏輯映像檔",
//         "setting_server": "安裝/重啟/移除伺服器",
//         "setting_agent_actions": "Agent 操作",
//         "setting_user_permission": "使用者權限",
//         "setting_white_black_keyword_list": "黑白名單與關鍵字清單",
//         "setting_software": "軟體相關",
//         "setting_logout": "系統登出",
//         "setting_logout_confirm": "確定要登出嗎？",
//         // ----------------------------------------------
//         // system
//         "setting_system_tab_server_mail": "主機伺服器與信箱",
//         "setting_system_tab_common": "常用功能",
//         "setting_system_tab_agent_setting": "Agent 設定",
//         "setting_system_tab_agent_update": "Agent 更新",
//         "setting_system_tab_interface": "介接設定",
//         "setting_system_server": "主機伺服器",
//         "setting_system_port": "系統連接埠",
//         "setting_system_detect_data_port": "偵測資料傳遞埠",
//         "setting_system_default_detect": "用戶端預設偵測",
//         "setting_system_client_update_port": "用戶端更新埠",
//         "setting_system_mail": "信箱",
//         "setting_system_sender_mail": "寄件人信箱",
//         "setting_system_mail_password": "信箱密碼",
//         "setting_system_mail_server": "郵件伺服器 (IP)",
//         "setting_system_mail_port": "埠",
//         "setting_system_mail_encryption": "郵件加密模式",
//         // common
//         "setting_system_mail_notificaiton": "郵件通知",
//         "setting_system_non_whitelist_program": "非白名單程式",
//         "setting_system_blacklist_program": "黑名單程式",
//         "setting_system_recevier_mail": "收件人信箱",
//         "setting_system_report_generate_time": "定時報表產製",
//         "setting_system_daily_report_time": "每日產製時間",
//         "setting_system_risk_level_bigger_than": "風險等級大於",
//         "setting_system_auto_defense": "自動防禦",
//         "setting_system_risk_process": "風險程序處理",
//         "setting_system_memory_dump": "記憶體 Dump",
//         "setting_system_dump_to_virustotal": "將 Dump 自動上傳至 Virustotal 進行深度分析",
//         "setting_system_dump_to_hybrid_analysis": "將 Dump 自動上傳至 hybrid-analysis 進行深度分析",
//         "setting_system_sample_download": "樣本下載",
//         "setting_system_sample_download_to_virustotal": "將樣本自動上傳至 Virustotal 進行深度分析",
//         "setting_system_sample_download_to_hybrid_analysis": "將樣本自動上傳至 hybrid-analysis 進行深度分析",
//         // agent setting
//         "setting_system_agent_prescheduled_task": "預先執行任務",
//         "setting_system_agent_file_table_analysis": "檔案總表分析",
//         "setting_system_agent_primary_disk": "主要磁區",
//         "setting_system_agent_memory_scan": "記憶體掃描",
//         "setting_system_agent_collect": "痕跡取證",
//         "setting_system_agent_image": "關鍵映像檔製作",
//         "setting_system_agent_generate": "Agent 產製",
//         "setting_system_agent_server_ip": "伺服器 IP",
//         "setting_system_agent_other": "其他",
//         "setting_system_agent_cpu_priority": "CPU 優先級",
//         "setting_system_agent_cpu_priority_low": "非常普通",
//         "setting_system_agent_cpu_priority_normal": "普通",
//         // agent
//         "setting_system_agent_update": "Agent 更新",
//         "setting_system_agent_program": "上傳新版 Agent",
//         "setting_system_upload_key": "上傳更新金鑰",
//         "setting_system_agent_update_client": "更新 Client",
//         "setting_system_agent_customized_device": "自訂電腦",
//         "setting_system_agent_all_device": "全部電腦",
//         "setting_formtable_inner_ip": "電腦內部 IP",
//         "setting_formtable_device_name": "電腦名稱",
//         "setting_formtable_version": "版本",
//         "setting_formtable_group": "所屬群組",
//         "setting_system_agent_update_hash": "更新 Key",
//         "setting_system_agent_update_verify": "驗證",
//         "setting_agent_update_choose_file_error": "檔案類型應為 exe",
//         "setting_agent_update_older_version_error": "更新版本應大於目前版本",
//         "setting_agent_update_sending": "已送出更新請求",
//         "setting_agent_remove_sending": "已送出移除請求",
//         "setting_agent_remove_empty": "請先選擇主機",
//         "setting_agent_remove_hint": "確定要移除所選主機嗎？",
//         // ----------------------------------------------
//         // group
//         "add_to_selected_group": "新增至已勾選群組",
//         "remove_from_selected_group": "從已勾選群組移除",
//         "please_select_group_or_device": "請選擇電腦或群組",
//         "setting_group_statistics": "群組統計",
//         "setting_group_name_repeat": "群組名稱重複",
//         "setting_group_delete": "成功刪除",
//         "setting_group_delete_unit": "筆群組",
//         "please_select_group": "請選擇群組",
//         "setting_group_list": "群組清單",
//         "setting_group_add": "新增群組",
//         "setting_group_delete_group": "刪除群組",
//         "setting_group_delete_group_confirm": "請確認是否刪除群組",
//         "setting_group_delete_fail": "刪除失敗",
//         "setting_group_update_name": "更新群組名稱",
//         "setting_group_new_name": "群組名稱",
//         "setting_group_color": "群組顏色",
//         // ----------------------------------------------
//         // image
//         "setting_image_select_path": "選擇所需路徑",
//         "setting_image_default": "程式預設",
//         "setting_image_advanced": "進階",
//         "setting_image_customized": "自訂 (可至「設定」>「關鍵邏輯映像檔」內，新增修改)",
//         "setting_image_path_keyword": "自訂路徑與關鍵字",
//         "setting_image_enter_keyword": "請輸入關鍵字",
//         "setting_image_path": "路徑",
//         "setting_image_keyword": "關鍵字",
//         "setting_image_remove_hint": "確定要移除勾選路徑與關鍵字列表？",
//         "setting_image_renew_hint": "確定要重製自訂列表？",
//         "setting_image_tool": "駭客工具檢定設定",
//         "setting_image_add_score": '增加分數',
//         // ----------------------------------------------
//         // blacklist/whitelist/hacklist
//         "setting_blacklist": "黑名單",
//         "setting_whitelist": "白名單",
//         "setting_hacker_tool": "駭客工具檢定設定",
//         "setting_keyword_list": "關鍵字清單",
//         "setting_black/whitelist_filename": "檔案名稱",
//         "setting_black/whitelist_md5": "MD5 值",
//         "setting_black/whitelist_signature": "簽章",
//         "setting_black/whitelist_path": "路徑",
//         "setting_black/whitelist_setup_user": "設定人",
//         "setting_black/whitelist_reason": "原因",
//         "setting_hacklist_process_name": "程序名稱",
//         "setting_hacklist_path": "路徑",
//         "setting_hacklist_cmd": "參數",
//         "setting_hacklist_adding_point": "增加分數",
//         "setting_keyword_list_name": "名稱",
//         "setting_keyword_list_reason": "原因",
//         "setting_keyword_list_add": "新增關鍵字",
//         "setting_keyword_remove": "移除關鍵字",
//         "setting_keyword_remove_hint": "確定要移除勾選關鍵字？",
//         // ----------------------------------------------
//         // server
//         "setting_select_functionality": "選擇所需功能",
//         "setting_server_install_start": "安裝並啟動主服務",
//         "setting_server_restart": "重啟主服務",
//         "setting_server_remove": "移除主服務",
//         // ----------------------------------------------
//         // agent remove
//         "setting_agent_remove": "Agent 移除",
//         "setting_agent_remove_btn": "移除用戶端",
//         "setting_agent_generate": "Agent 下載",
//         "setting_agent_install_error": "安裝失敗？",
//         // ----------------------------------------------
//         // user permission
//         "setting_user_delete_user_confirm": "確定要刪除此使用者？",
//         "setting_user_delete_user_success": "刪除成功",
//         "setting_user_delete_user_fail": "刪除失敗",
//         "setting_user_add": "新增使用者",
//         "setting_user_remove": "移除使用者",
//         "setting_user_select_user": "請選擇使用者",
//         "setting_user_department": "單位",
//         "setting_user_username": "帳號",
//         "setting_user_email": "Email",
//         "setting_user_password": "密碼",
//         "setting_user_permission_header": "頁面權限",
//         "setting_user_status": "狀態",
//         "setting_user_timezone": "時區",
//         "setting_user_activate": "啟用",
//         "setting_user_deactivate": "停用",
//         "setting_user_dashboard": "儀表板",
//         "setting_user_permission_setting": "設定",
//         "setting_user_permission_user": "使用者編輯權限",
//         "setting_user_permission_detect": "蒐證任務",
//         "setting_user_permission_analysis": "蒐證分析",
//         "setting_user_permission_dashboard": "監測儀表板",
//         // ----------------------------------------------
//         // software
//         "setting_software_language": "語言",
//         "setting_software_authorization_version": "授權與版本",
//         "setting_software_logs": "系統日誌",
//         "setting_software_please_select_language": "請選擇語言",
//         "setting_software_language_zh": "繁體中文",
//         "setting_software_language_en": "English",
//         "setting_software_authorization_information": "授權資訊",
//         "setting_software_authorization_issuer": "授權單位",
//         "setting_software_authorization_code": "授權序號",
//         "setting_software_authorization_connections": "連線數量",
//         "setting_software_authorization_date": "授權日期至",
//         "setting_software_version": "版本",
//         "setting_software_update_license": "更新授權",
//         "setting_software_update_license_success": "更新授權成功",
//         "setting_software_update_license_fail": "檔名應為 license.key",
//         "setting_authorization_text1": "eDetector 所有資訊的智慧財產權，版權和註冊商標歸屬於鑒真數位有限公司（以下簡稱本公司）。如果有任何其他公司提供的信息、程式軟體、圖案和商標，則該智慧財產權和版權歸屬於各該公司。未經本公司正式同意，不得以任何形式進行接收、轉述、抄寫、複製、存儲在系統中或以任何語言翻譯，也不得使用任何格式顯示本公司的商標。",
//         "setting_authorization_text2": "eDetector 版權擁有者不保證任何特殊、間接、相關或相似的損害賠償責任，包括使用或不使用該軟體而導致的資料或財產損失。本軟體不提供任何明示或隱含的擔保聲明，並受到上述限制的約束。",
//         "setting_software_logs_time": "時間",
//         "setting_software_logs_level": "等級",
//         "setting_software_logs_source": "來源",
//         "setting_software_logs_content": "內容",
//         "setting_software_logs_detail": "詳細資訊",
//         // ----------------------------------------------
//         // yara rule
//         "setting_import_tags": "標記情資導入",
//         "setting_yara_choose_upload_file": "選擇上傳檔案: ",
//         "setting_yara_choose_file_first": "請先選擇檔案",
//         "setting_yara_choosed_file": "欲新增檔案",
//         "setting_yara_uploaded_files": "已套用檔案",
//         "setting_yara_download_file": "下載",
//         "setting_yara_download_error": "下載失敗",
//         "setting_yara_verified": "驗證完成",
//         "setting_yara_choose_file": "選擇檔案",
//         "setting_yara_upload_file": "套用",
//         "setting_yara_file_name": "檔案名稱",
//         "setting_yara_file_size": "檔案大小",
//         "setting_yara_file_type": "檔案類型",
//         "setting_yara_upload_success": "套用成功",
//         "setting_yara_wrong_file_type": "請以 .tar, .zip 或是 .yara 的檔案格式上傳",
//         "setting_yara_empty_file": "檔案大小需大於 0 且小於 10MB",
//         "setting_yara_file_exist": "檔案已存在",
//         // ----------------------------------------------
//         // DT
//         "setting_dt_import": "載入DT資料",
//         // ----------------------------------------------
//         // view detail
//         "right_bar_title": "詳細資訊",
//         "view_detail_agent_list": "用戶端列表",
//         "view_detail_file_name": "檔案名稱",
//         "view_detail_file_size": "檔案大小",
//         "view_detail_file_create_time": "檔案建立時間",
//         "view_detail_file_modify_time": "檔案修改時間",
//         // ----------------------------------------------
//         // network
//         "network_wrong_ip_hint": "IP 地址格式錯誤, 請重新輸入",
//         "netwrok_search_ip": "搜尋 IP",
//         "network_connection": "記憶體連線",
//         "network_memory_list": "記憶體總表",
//         // ----------------------------------------------
//         // detect
//         "detect_task_type_memory": "記憶體",
//         "detect_task_type_collect": "痕跡取證",
//         "detect_task_type_drive": "檔案總表",
//         "detect_task_type_image": "關鍵映像檔",
//         "detect_task_type_yara": "Yara Rule",
//         "detect_task_type_memory_tree": "Memory Tree",
//         "detect_task_terminate": "終止任務",
//         "detect_task_memory_action": "立即掃描",
//         "detect_task_collect_action": "立即取證",
//         "detect_task_drive_action": "立即下載",
//         "detect_task_image_action": "立即製作",
//         "detect_task_start_making_image": "開始製作",
//         "detect_task_image_select_type_hint": "請選擇所需路徑",
//         "detect_task_terminate_action": "終止任務",
//         "detect_task_yara_action": "啟動 Yara Rule",
//         "detect_task_memory_tree_action": "啟動 Memory Tree",
//         "detect_task_terminating": "終止任務中",
//         "detect_task_terminate_alert": "確定要終止任務嗎？",
//         "detect_task_terminate_confirm": "確認終止",
//         "detect_scan_schedule": "定時掃描",
//         "detect_detect_mode": "偵測模式",
//         "detect_schedule_time": "每日時間",
//         "detect_schedule_unit": "個預定時間",
//         "detect_collect_schedule": "定時取證",
//         "detect_schedule_date": "每月日期",
//         "detect_file_schedule": "定時下載",
//         "detect_table_scan_schedule": "定期掃描時間",
//         "detect_table_scan_finish_time": "最近記憶體掃描完成時間",
//         "detect_table_collect_schedule": "定期取證時間",
//         "detect_table_collect_finish_time": "最近痕跡取證完成時間",
//         "detect_table_drive_schedule": "定期總表下載時間",
//         "detect_table_drive_finish_time": "最近總表下載完成時間",
//         "detect_table_image_finish_time": "關鍵映像檔完成時間",
//         "detect_table_yara_finish_time": "YARA Rule 完成時間",
//         "detect_table_memory_tree_finish_time": "Memory Tree 完成時間",
//         "detect_table_connection": "連線",
//         "detect_table_device_ip": "電腦內部 IP",
//         "detect_table_device_name": "電腦名稱",
//         "detect_table_group": "所屬群組",
//         "detect_task_failed": "伺服器錯誤",
//         "detect_collect_table": "痕跡",
//         "detect_collect_failed_tables": "取證失敗痕跡",
//         // ----------------------------------------------
//         // Analysis
//         "analysis_search_hint": "請選擇主機、分析條件、時間",
//         "analysis_start": "開始分析",
//         "analysis_devices": "主機",
//         "analysis_please_select_devices": "請選擇主機",
//         "analysis_has_selected": "已選擇",
//         "analysis_has_selected_unit": "台主機",
//         "analysis_filter_memory": "記憶體",
//         "analysis_filter_collect": "痕跡取證",
//         "analysis_filter_drive": "檔案總表",
//         "analysis_data": "分析資料",
//         "analysis_time": "分析時間",
//         "analysis_time_24": "最近 24 小時",
//         "analysis_time_week": "最近一週",
//         "analysis_time_month": "最近一個月",
//         "analysis_global_keyword": "全域關鍵字",
//         "analysis_global_keyword_search": "關鍵字搜尋",
//         "analysis_table_number_rows": "目前篩選之總筆數",
//         "analysis_generate_report": "產生報告",
//         "analysis_ai_report": "AI 生成報告",
//         "analysis_ai_report_no_select_error": "請選擇資料",
//         "analysis_ai_report_alert": "使用AI生成報告，資料將會上傳至ChatGPT，確認要使用該功能嗎？",
//         "analysis_ai_report_error(over limit)":"選取的行數超過上限，請取消選取一部分資料以繼續產生報告",
//         "analysis_csv_file": "CSV 檔",
//         "analysis_csv_error(over limit)":"選取的行數超過上限，請取消選取一部分資料以繼續產生報告",
//         "analysis_yara_export": "匯出標記",
//         "analysis_show_figure": "顯示圖表",
//         "analysis_hide_figure": "隱藏圖表",
//         "analysis_report_selected_unit": "筆報告資料",
//         "analysis_clear_all": "清除全部",
//         "analysis_table_current_rows": "目前頁面筆數",
//         "analysis_table_total_rows": "總筆數",
//         "analysis_risk_level": "風險等級",
//         "analysis_inject_active": "注入行為",
//         "analysis_process_be_injected": "程序被注入行為",
//         "analysis_boot": "開機啓動",
//         "analysis_hook": "隱藏",
//         "analysis_hide": "Hide",
//         // ----------------------------------------------
//         // login page
//         "login_title": "用戶登入",
//         "login_account": "帳號",
//         "login_password": "密碼",
//         "login_action": "登入",
//         "login_error_hint": "帳號或密碼錯誤，或帳號未啟用",
//         "login_error": "登入失敗",
//         "login_account_empty": "請輸入帳號",
//         "login_password_empty": "請輸入密碼",
//         // ----------------------------------------------
//         // elastic table & analysis group
//         "ed_memory": "記憶體",
//         "ed_chromehistory": "Chrome 瀏覽紀錄",
//         "ed_chromedownload": "Chrome 下載紀錄",
//         "ed_chromekeywordsearch": "Chrome 關鍵字搜尋紀錄",
//         "ed_chromelogin": "Chrome 登入帳密",
//         "ed_chromebookmarks": "Chrome 書籤",
//         "ed_edgehistory": "Edge 瀏覽紀錄",
//         "ed_iehistory": "IE 瀏覽紀錄",
//         "ed_edgelogin": "Edge 登入帳密",
//         "ed_ielogin": "IE 登入帳密",
//         "ed_edgebookmarks": "Edge 書籤",
//         "ed_firefoxhistory": "Firefox 瀏覽紀錄",
//         "ed_firefoxlogin": "Firefox 登入帳密",
//         "ed_firefoxbookmarks": 'Firefox 書籤',
//         "ed_chromecookies": "Chrome cookies",
//         "ed_chromecache": "Chrome cache",
//         "ed_edgecookies": "Edge cookies",
//         "ed_edgecache": "Edge cache",
//         "ed_firefoxcookies": "Firefox cookies",
//         "ed_firefoxcache": "Firefox cache",
//         "ed_iecache": "IE cache",
//         "ed_netadapters": "網路基本資訊", //basic 
//         "ed_networkresources": "網路資源",
//         "ed_wireless": "無線網路資訊",
//         "ed_installedsoftware": "已安裝軟體",
//         "ed_service": "一般系統服務", //general
//         "ed_baseservice": "細部系統服務", //detailed
//         "ed_startrun": "開機自啟", //bootprogram
//         "ed_remotedesktoplogin": "遠端桌面登入帳密",
//         "ed_jumplist": "Jump List",
//         "ed_systeminfo": "系統資訊",
//         "ed_muicache": "MUI 快取",
//         "ed_prefetch": "Prefetch",
//         "ed_eventapplication": "應用程式日誌檔",
//         "ed_taskschedule": "排程工作", //scheduledwork
//         "ed_userassist": "機碼使用痕跡", //ed_userassist
//         "ed_networkdatausagemonitor": "程式網路流量痕跡", //ed_networkdatausagemonitor
//         "ed_appresourceusagemonitor": "程式讀寫痕跡", //ed_appresourceusagemonitor
//         "ed_dnsinfo": "DNS資訊",
//         "ed_process": "執行程序", // executed program
//         "ed_network": "程序連線資訊", //ed_network
//         "ed_openedfiles": "程式開啟之檔案", //ed_openedfiles
//         "ed_arpcache": "ARP快取",
//         "ed_shortcuts": "相關捷徑", //ed_shortcuts
//         "ed_shellbags": "開啟的資料夾路徑", //ed_shellbags
//         "ed_userprofiles": "主機用戶資料", //ed_userprofiles
//         "ed_recentfile": "最近開啟檔案", //ed_recentfile
//         "ed_windowsactivity": "Windows Activity",
//         "ed_usbdevices": "USB 裝置資訊", //ed_usbdevices
//         "ed_eventsecurity": "安全性日誌檔", //ed_eventsecurity
//         "ed_eventsystem": "系統日誌檔", //ed_eventsystem
//         "ed_emailpath": "電子郵件路徑",
//         'ed_email': '電子郵件清單紀錄', //ed_email
//         "ed_explorer": "檔案總表",// allfile

//         "group_email_list_records": '電子郵件清單紀錄(.pst,.ost)', //emailListReacord
//         "group_usb_usage_record": "usb使用裝置紀錄",
//         "group_recently_opened_file_history": "最近開啟文件紀錄", //group_recently_opened_file_history
//         'group_evanescent_records': "消逝性紀錄", //group_evanescent_records
//         "group_recently_executed_software_log": "最近執行軟體紀錄",
//         "group_current_network_connection_history":"當下網路連線紀錄",
//         "ed_collection": "痕跡取證",
//         "group_browsing_history_and_bookmarks": "網站瀏覽紀錄與書籤",
//         "group_cache_and_cookie_history": "cookie和cache紀錄",
//         //------------memory tree----------
//         "memory_tree_process_name":"程序名稱",
//         "memory_tree_process_id":"程序 ID",
//         "memory_tree_process_create_time":"程序建立時間",
//         "memory_tree_parent_process_ID":"父程序 ID",
//         "memory_tree_user_name":"使用者名稱",
//     }
// }

// export default dic
