import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Typography } from '@mui/material'
import dic from 'constant/dictionary'
import { useSelector, useDispatch } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import { _SelectedImageType, setSelectedImageType } from 'redux/SettingSlice'

interface ImageOptions {
    value: string
    label: string
}

export default function RadioGroupBox() {
    const dispatch = useDispatch()
    const language = useSelector(_language)
    const radioValue = useSelector(_SelectedImageType)
    const optionsList: ImageOptions[] = [
        { value: 'default', label: dic[language]['setting_image_default'] },
        { value: 'advanced', label: dic[language]['setting_image_advanced'] },
        { value: 'customized', label: dic[language]['setting_image_customized'] },
    ]

    return (
        <FormControl>
            <Typography variant='h6'>
                {dic[language]['detect_task_image_select_type_hint']}
            </Typography>
            <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                value={radioValue}
                onChange={(e) => dispatch(setSelectedImageType(e.target.value))}
            >
                {optionsList.map((option, index) => {
                    return (
                        <FormControlLabel
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                            key={index}
                        />
                    )
                })}
            </RadioGroup>
        </FormControl>
    )
}
