import { Portal } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ImperativePanelGroupHandle, ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

import { RightBarCloseButton } from 'component/RightBar'
import RightBar from 'component/RightBar/RightBarWithChatGPT'
import { API, urlRoot } from 'constant/index'
import AnalysisController, { TAnalysisController } from 'context/AnalysisControllerContext'
import { useAlert } from 'hook/useAlert'
import { AnalysisTemplate, IForensicDropDownData } from 'interface'
import { _analysisData, analysisState, setForensicsSelectedData, setTemplateList } from 'redux/AnalysisSlice'
import { _elasticRestarting, _language } from 'redux/UtilSlice'
import { axiosClient } from 'util/index'
import AnalysisPageChart from './component/AnalysisPageChart'
import AnalysisSettingBar from './component/AnalysisSettingBar'
import ElasticGrid from './component/ElasticGrid'
import LeftSelectList from './component/LeftSelectList'

import useDenyIfNot from 'hook/useDenyIfNot'
import Initialize from 'Initialize'
import './index.scss'

const AnalysisPage = () => {
    const [rightBarOpen, setRightBarOpen] = useState(false);
    const [refreshTemplate, setRefreshTemplate] = useState<boolean>(true) // TODO: refactor w/ useQuery
    const analysisData: analysisState = useSelector(_analysisData);
    const elasticRestarting = useSelector(_elasticRestarting);
    const language = useSelector(_language);
    const dispatch = useDispatch();
    const alert = useAlert();
    const resultContainer = useRef(null);
    const clostButtonContainer = useRef(null);
    const chartPanel = useRef<ImperativePanelHandle>(null);
    const [chartOpen, setChartOpen] = useState(false);

    const {
        selectedTemplate,
        subKeywordList
    } = analysisData;
    
    const controller = useContext(AnalysisController);
    const {fetchChart, fetchTable, fetchListCount} = (controller as TAnalysisController);

    useDenyIfNot('analysis');

    useEffect(() => {
        if (selectedTemplate === null) return
        dispatch(setForensicsSelectedData(selectedTemplate))
    }, [selectedTemplate])

    useEffect(() => {
        if (elasticRestarting && fetchTable.status === "success") {
            alert.showAlert('Elasticsearch is restarting, please retry after a while');
        }
    },[elasticRestarting, rightBarOpen])

    useEffect(() => {
        if (refreshTemplate) {
            axiosClient
                .get(`${urlRoot}${API.template}`)
                .then((res) => {
                    setRefreshTemplate(false)
                    const templateList: IForensicDropDownData[] = res.data.templates.map((item: AnalysisTemplate) => {
                        return APITemplateAdaptor(item)
                    })
                    dispatch(setTemplateList(templateList))
                })
                .catch((err) => {
                    console.log(err)
                    setRefreshTemplate(false)
                })
        }
    }, [refreshTemplate])

    useEffect(() => {
        setChartOpen(fetchChart.isSuccess);
    }, [fetchChart.isSuccess])

    useEffect(() => {
        if (chartPanel.current) {
            if (chartOpen) {
                chartPanel.current.resize({sizePixels: 210});
            } else {
                chartPanel.current.collapse();
            }
        }
    },[chartOpen])

    return (
        <div className='page' id="analysisContainer">
            <Initialize />
            <AnalysisSettingBar
                setRefreshTemplate={setRefreshTemplate}
            />

            {fetchTable.isIdle && fetchListCount.isIdle && fetchChart.isIdle ? null : 
                <div id='resultContainer' ref={resultContainer}>
                    <Portal container={clostButtonContainer.current}><RightBarCloseButton onClick={(e: any) => { setRightBarOpen(false) }} /></Portal>
                    <PanelGroup direction='horizontal'>
                        <Panel
                            defaultSizePixels={320}
                            maxSizePixels={400}
                            minSizePixels={280}
                            >
                                <PanelGroup direction='vertical'>
                                    <Panel>
                                        <LeftSelectList/>
                                    </Panel>
                                    <PanelResizeHandle className="resizeHandle horizontal"/>
                                    <Panel defaultSizePixels={24} minSizePixels={24} collapsedSizePixels={24} collapsible ref={chartPanel}>
                                        <AnalysisPageChart isOpen={chartOpen} toggle={() => setChartOpen(prev => !prev)}/>
                                    </Panel>
                                </PanelGroup>
                        </Panel>
                        <PanelResizeHandle className="resizeHandle" />
                        <Panel className="mainTable">
                            <ElasticGrid
                                setRightBarOpen={setRightBarOpen}
                                rightBar={
                                    <Portal container={resultContainer.current}>
                                        <RightBar open={rightBarOpen} closeButton={<span ref={clostButtonContainer}></span>} searchKeywordList={subKeywordList} />
                                    </Portal>
                                }
                            />
                        </Panel>
                    </PanelGroup>
                </div>
            }
        </div>
    )
}

const APITemplateAdaptor = (item: AnalysisTemplate):IForensicDropDownData => {
    return {
        isForensicsSelected: true,
        templateId: item.template_id,
        templateName: item.template_name,
        keywordType: item?.keyword_type,
        keyword: item?.keyword,
        group_browsing_history_and_bookmarks: true,
        ed_chromehistory: item?.history_and_bookmark.chrome_browsing_history,
        ed_chromedownload: item?.history_and_bookmark.chrome_download_history,
        ed_chromekeywordsearch: item?.history_and_bookmark.chrome_keyword_search,
        ed_chromelogin: item?.history_and_bookmark.chrome_login_info,
        ed_chromebookmarks: item?.history_and_bookmark.chrome_bookmark,
        ed_edgehistory: item?.history_and_bookmark.edge_browsing_history,
        ed_iehistory: item?.history_and_bookmark.ie_browsing_history,
        ed_edgelogin: item?.history_and_bookmark.edge_login_info,
        ed_ielogin: item?.history_and_bookmark.ie_login_info,
        ed_edgebookmarks: item?.history_and_bookmark.edge_bookmark,
        ed_firefoxhistory: item?.history_and_bookmark.firefox_browsing_history,
        ed_firefoxlogin: item?.history_and_bookmark.firefox_login_info,
        ed_firefoxbookmarks: item?.history_and_bookmark.firefox_bookmark,
        group_cache_and_cookie_history: item?.cookie_and_cache.chrome_cache,
        ed_chromecache: item?.cookie_and_cache.chrome_cache,
        ed_chromecookies: item?.cookie_and_cache.chrome_cookie,
        ed_edgecache: item?.cookie_and_cache.edge_cache,
        ed_edgecookies: item?.cookie_and_cache.edge_cookie,
        ed_iecache: item?.cookie_and_cache.ie_cache,
        ed_firefoxcache: item?.cookie_and_cache.firefox_cache,
        ed_firefoxcookies: item?.cookie_and_cache.firefox_cookie,
        group_current_network_connection_history: true,
        ed_netadapters: item?.connection_history.network_info,
        ed_wireless: item?.connection_history.wireless_info,
        ed_networkresources: item?.connection_history.network_resource,
        group_recently_executed_software_log: true,
        ed_installedsoftware: item?.process_history.installed_software,
        ed_baseservice: item?.process_history.detailed_system_service,
        ed_remotedesktoplogin: item?.process_history.remote_desktop_info,
        ed_systeminfo: item?.process_history.system_info,
        ed_prefetch: item?.process_history.ed_prefetch,
        ed_taskschedule: item?.process_history.scheduled_task,
        ed_networkdatausagemonitor: item?.process_history.network_traffic,
        ed_dnsinfo: item?.process_history.dns_info,
        ed_service: item?.process_history.general_system_service,
        ed_startrun: item?.process_history.bootup_program,
        ed_jumplist: item?.process_history.jumplist,
        ed_muicache: item?.process_history.mui_cache,
        ed_eventapplication: true,
        ed_userassist: item?.process_history.machine_code_history,
        ed_appresourceusagemonitor: item?.process_history.program_read_write_history,
        group_evanescent_records: true,
        ed_process: item?.vanishing_history.process,
        ed_openedfiles: item?.vanishing_history.opened_file,
        ed_network: item?.vanishing_history.connection_info,
        ed_arpcache: item?.vanishing_history.arp_cache,
        group_recently_opened_file_history: item?.vanishing_history.opened_file,
        ed_shortcuts: item?.recent_opening.related_shortcut,
        ed_userprofiles: item?.recent_opening.user_info,
        ed_windowsactivity: item?.recent_opening.windows_activity,
        ed_shellbags: item?.recent_opening.opened_path,
        ed_recentfile: item?.recent_opening.opened_file,
        group_usb_usage_record: true,
        ed_usbdevices: item?.usb_history.usb_info,
        ed_eventsystem: item?.usb_history.system_log_file,
        ed_eventsecurity: item?.usb_history.security_log_file,
        group_email_list_records: true,
        ed_emailpath: item?.email_history.email_path,
        ed_email: item?.email_history.email_list,
    }
}

export default AnalysisPage;