import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import assets from 'assets'
import { useGet } from 'hook/useGet'
import { urlRoot, API, WEBSOCKET_STATUS } from 'constant/index'
import { processNumber } from 'util/index'
import dic from 'constant/dictionary'
import { useSelector } from 'react-redux'
import { _language } from 'redux/UtilSlice'
import StatusDiagram from './StatusDiagram'
import '../../../index.scss'
import { useAlert } from 'hook/useAlert'
import useSocketHandler from 'hook/useSocketHandler'

interface ServerStatusProps {
    canOpen?: boolean
}

export interface StatusInfo {
    server: number
    elasticMemory: number
    elasticCapacity: number
    rabbitMessages: number
}

const ServerStatus = (props: ServerStatusProps) => {
    const { canOpen = true } = props;
    const [viewServerArch, setViewServerArch] = useState(false);
    const [agentCount, setAgentCount] = useState(0);
    const language = useSelector(_language);
    const { showAlert } = useAlert();

    const [status, setStatus] = useState<StatusInfo>({
        server: -1,
        elasticMemory: -1,
        elasticCapacity: -1,
        rabbitMessages: -1
    })

    const {
        data,
        isSuccess,
    } = useGet<StatusInfo>({
        query: 'serverStatus',
        root: `${urlRoot}`,
        route: `dashboard/status`,
    })

    useEffect(() => {
        if (isSuccess && data.data) {
            setStatus(data.data);
        }
    }, [isSuccess, data])

    useSocketHandler(WEBSOCKET_STATUS.SERVER_STATUS_UPDATE, (message) => {
        setStatus(message.data)
    })

    const {
        data: agent,
        isLoading: isAgentLoading,
        isSuccess: isAgentSuccess
    } = useGet<number>({
        query: 'agentCount',
        root: `${urlRoot}`,
        route: `${API.agentCount}`,
        refetchInterval: 30000,
    })

    useEffect(() => {
        if (!isAgentLoading && isAgentSuccess && agent.data) {
            setAgentCount(agent.data ?? 0);
        }
    }, [isAgentSuccess, agent])

    return (
        <div className="serverStatus" style={canOpen ? { cursor: 'pointer' } : {}}>
            {canOpen && (
                <StatusDiagram
                    open={viewServerArch}
                    onClose={() => setViewServerArch(false)}
                    serverCPU={status.server}
                    elasticMemory={status.elasticMemory}
                    elasticCapacity={status.elasticCapacity}
                    rabbitMessages={status.rabbitMessages}
                    agentCount={agentCount}
                />
            )}

            <Stack
                onClick={() => setViewServerArch(true)}
                direction="row"
                alignItems="center"
                spacing={0.5}
            >
                <Stack direction="row" alignItems='center' className="head">
                    <assets.server style={{ width: '24px', height: '24px' }} />
                    <Typography variant="subtitle1" sx={{ font: 'Roboto' }}>
                        {dic[language]["dashboard_server_status"]}
                    </Typography>
                </Stack>
                <div className="triangle" />

                <Stack
                    direction="row"
                    alignItems='center'
                    useFlexGap
                    spacing={5}
                    width="70%"
                >
                    <Stack direction={'row'} alignItems='flex-end' spacing={1}>
                        <Typography variant="body2">{dic[language]['cpu']}</Typography>
                        <Typography variant="body2" className="statusDigit">
                            {status.server === -1? '...':status.server}
                        </Typography>
                        <Typography variant="body2" className="statusUnit">%</Typography>
                    </Stack>

                    <Stack direction={'row'} alignItems='flex-end' spacing={1}>
                        <Typography variant="body2">
                            {dic[language]["number_of_messages"]}
                        </Typography>
                        <Typography variant="body2" className="statusDigit">
                            {status.rabbitMessages === -1?'...':processNumber(status.rabbitMessages)}
                        </Typography>
                    </Stack>

                    <Stack direction={'row'} alignItems='flex-end' spacing={1}>
                        <Typography variant="body2">
                            Elastic {dic[language]["memory"]}
                        </Typography>
                        <Typography variant="body2" className="statusDigit">
                            {status.elasticMemory === -1? '...':status.elasticMemory}
                        </Typography>
                        <Typography variant="body2" className="statusUnit">%</Typography>
                    </Stack>

                    <Stack direction={'row'} alignItems='flex-end' spacing={1}>
                        <Typography variant="body2">
                            Elastic {dic[language]["storage"]}
                        </Typography>
                        <Typography variant="body2" className="statusDigit">
                            {status.elasticCapacity === -1? '...':status.elasticCapacity}
                        </Typography>
                        <Typography variant="body2" className="statusUnit">%</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </div>
    )
}

export default ServerStatus
