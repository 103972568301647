import { Button, Stack, Divider, IconButton, Slide, Box } from "@mui/material";
import useDownloadFile from "hook/useDownloadFile";
import { axiosClient } from "util/index";
import dic from "constant/dictionary";
import { useSelector } from "react-redux";
import { _language } from "redux/UtilSlice";
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { useEffect, useState } from "react";
import { API, urlRoot } from "constant";
import { handbook_zh, handbook_en } from "assets/handbook";
import { VersionListData } from "./AgentUpdate";
import { useGet } from "hook/useGet";
import DownloadIcon from '@mui/icons-material/Download';

const pageContent = {
    "zh": [
        <handbook_zh.AgentDownloadZh />,
        <handbook_zh.AgentInstallAlertZh />,
        <handbook_zh.AgentInstallingZh />,
        <handbook_zh.AgentInstalledZh />,
        <handbook_zh.AgentInstallSuccessZh />,
    ],
    "en": [
        <handbook_en.AgentDownloadEn />,
        <handbook_en.AgentInstallAlertEn />,
        <handbook_en.AgentInstallingEn />,
        <handbook_en.AgentInstalledEn />,
        <handbook_en.AgentInstallSuccessEn />,
    ]
}

const AgentDownload = () => {
    const language = useSelector(_language);
    const [page, setPage] = useState(0);
    const [slideIn, setSlideIn] = useState(true);
    const [direction, setDirection] = useState<'left' | 'right' | 'up'>('up');
    const [showButton, setShowButton] = useState(true);
    const [windowsVersion, setWindowsVersion] = useState('');
    const [linuxVersion, setLinuxVersion] = useState('');

    const {
        data: versionListData,
        isSuccess: isVersionListDataSuccess,
    } = useGet<VersionListData>({
        query: 'getVersionList',
        root: `${urlRoot}`,
        route: `${API.agentVersion}`,
    })

    useEffect(() => {
        if (isVersionListDataSuccess && versionListData.data) {
            setWindowsVersion(versionListData.data.windows[versionListData.data.windows.length - 1]);
            setLinuxVersion(versionListData.data.linux[versionListData.data.linux.length - 1]);
        }
    }, [isVersionListDataSuccess, versionListData])

    useEffect(() => {
        return () => {
            // remove handbook.AgentInstallFail if it is in pageContent
            if (pageContent[language].length === 6) {
                pageContent[language].splice(3, 1);
            }
        }
    }, [language]);

    const { download } = useDownloadFile({
        apiDefinition: (apiRoute: string) => {
            return axiosClient.get(apiRoute, { responseType: 'blob' });
        },
        onError: (error) => {
            console.error(error);
        }
    });

    const handleDownload = (os: string) => {
        if (os === "windows") {
            download(`${API.agent}/windows`, 'Windows_Agent_Installer.bat');
        } else {
            download(`${API.agent}/linux`, 'Linux_Agent_Installer.sh');
        }
    }

    const handlePageChange = (action: 'right' | 'left') => {
        setSlideIn(!slideIn);
        setPage(page + (action === 'left' ? 1 : -1));
        setDirection(action);
    }

    const buttonClickAction = () => {
        setShowButton(false);
        // add handbook.AgentInstallFail to position 4 in pageContent
        pageContent[language].splice(3, 0,
            language === 'zh' ?
                <handbook_zh.AgentInstallFailZh /> :
                <handbook_en.AgentInstallFailEn />
        );
        setSlideIn(!slideIn);
        setDirection('up');
    }

    return (
        <>
            <Stack
                direction='row'
                alignItems='center'
                spacing={2}
                sx={{ margin: '1rem 0' }}
            >
                <div>Windows Agent</div>
                <Button
                    variant="contained"
                    onClick={() => handleDownload('windows')}
                    startIcon={<DownloadIcon />}
                >
                    {windowsVersion}
                </Button>

                <Divider orientation="vertical" flexItem />

                <div>Linux Agent</div>
                <Button
                    variant="contained"
                    onClick={() => handleDownload('linux')}
                    startIcon={<DownloadIcon />}
                >
                    {linuxVersion}
                </Button>
            </Stack>

            <Divider sx={{ margin: '1.5% 0' }} />

            <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                sx={{ height: 'calc(97% - 6rem)' }}
            >
                <IconButton
                    sx={{ bgcolor: 'rgba(100, 100, 100, 0.1)' }}
                    disabled={page === 0}
                    onClick={() => handlePageChange('right')}
                >
                    <KeyboardArrowLeftRoundedIcon />
                </IconButton>
                <Box
                    sx={{
                        flex: 1,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    {slideIn && (
                        <Handbook
                            slideIn={slideIn}
                            direction={direction}
                            page={page}
                            showButton={showButton && page === 3}
                            buttonAction={buttonClickAction}
                        />
                    )}
                    {!slideIn && (
                        <Handbook
                            slideIn={!slideIn}
                            direction={direction}
                            page={page}
                            showButton={showButton && page === 3}
                            buttonAction={buttonClickAction}
                        />
                    )}
                </Box>
                <IconButton
                    sx={{ bgcolor: 'rgba(100, 100, 100, 0.1)' }}
                    disabled={page === pageContent[language].length - 1}
                    onClick={() => handlePageChange('left')}
                >
                    <KeyboardArrowRightRoundedIcon />
                </IconButton>
            </Stack>
        </>
    )
}

interface HandbookProps {
    slideIn: boolean
    page: number
    direction: 'up' | 'left' | 'right'
    showButton?: boolean
    buttonAction?: () => void
}

const Handbook = (props: HandbookProps) => {
    const { slideIn, page, direction, showButton, buttonAction } = props;
    const language = useSelector(_language);

    return (
        <Slide
            in={slideIn}
            mountOnEnter
            unmountOnExit
            direction={direction}
        >
            <Box
                sx={{
                    position: 'relative',
                    height: '99%', width: '95%',
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}
            >
                {pageContent[language][page]}
                {showButton && buttonAction && (
                    <Button
                        size="small"
                        variant='outlined'
                        onClick={buttonAction}
                        sx={{ position: 'absolute', bottom: '40%', left: '20%' }}
                    >
                        {dic[language]['setting_agent_install_error']}
                    </Button>
                )}
            </Box>
        </Slide>
    )
}

export default AgentDownload